<mat-dialog-content class="browse-dialog">
  <div class="content" role="main">
    <div class="container">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let row">{{row.versionLabel}}</td>
          </ng-container>

          <ng-container matColumnDef="payAmount">
            <th mat-header-cell *matHeaderCellDef>Requested Total</th>
            <td mat-cell *matCellDef="let row">{{ row.payAmount | currency}}</td>
          </ng-container>

          <ng-container matColumnDef="quickBookReference">
            <th mat-header-cell *matHeaderCellDef>QBref#</th>
            <td mat-cell *matCellDef="let element"> {{element.quickBookReference || '-' }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="paidOn">
            <th mat-header-cell *matHeaderCellDef>Sent Date</th>
            <td mat-cell *matCellDef="let row">{{(row.paidOn | date:'shortDate') || '-'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionCreatedAt">
            <th mat-header-cell *matHeaderCellDef>Last Edit</th>
            <td mat-cell *matCellDef="let row">{{(row.versionCreatedAt | date:'short') || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionAuthor">
            <th mat-header-cell *matHeaderCellDef>Edited By</th>
            <td mat-cell *matCellDef="let row">
              <span class="pull-left">{{row.versionAuthor || 'N/A'}}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="!dataSource.filteredData.length">
          <p class="no-data">No data</p>
        </ng-container>
      </div>

      <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
</mat-dialog-actions>
