<div class="content" role="main">
  <div class="header">
    <h1>{{currentUser}}'s {{roleMarker}} Dashboard</h1>
    <div class="header-action">
      <span class="refresh-time">{{getCalculationDate() | date:'short'}}</span>
      <button mat-mini-fab color="primary" aria-label="Refresh" (click)="getDashboardInfo()">
        <mat-icon>cached</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="values; context: {columns: columns, columnWidth: columnWidth}"></ng-container>
  <ng-container *ngTemplateOutlet="labels; context: {columns: columns, columnWidth: columnWidth}"></ng-container>
  <ng-container *ngTemplateOutlet="links; context: {columns: columns, columnWidth: columnWidth}"></ng-container>


  <ng-container *ngIf="hasAccountantPermissions">
    <h1 class="missing-title">Missing QBref#</h1>
    <ng-container *ngTemplateOutlet="values; context: {columns: missingQB, columnWidth: missingColumnWidth}"></ng-container>
    <ng-container *ngTemplateOutlet="labels; context: {columns: missingQB, columnWidth: missingColumnWidth}"></ng-container>
    <ng-container *ngTemplateOutlet="links; context: {columns: missingQB, columnWidth: missingColumnWidth}"></ng-container>
  </ng-container>
</div>

<ng-template #values let-columns="columns" let-width="columnWidth">
  <div class="dashboard-row values">
    <div class="dashboard-column" *ngFor="let column of columns" [ngStyle]="{'width': width}">
      <mat-spinner [diameter]="19" *ngIf="getQuickLinkValue(column) === -1"></mat-spinner>
      <span *ngIf="getQuickLinkValue(column) > -1">{{getQuickLinkValue(column)}}</span>
    </div>
  </div>
</ng-template>

<ng-template #labels let-columns="columns" let-width="columnWidth">
  <div class="dashboard-row labels">
    <div class="dashboard-column" *ngFor="let column of columns" [ngStyle]="{'width': width}">
      {{items.get(column).name}}
    </div>
  </div>
</ng-template>

<ng-template #links let-columns="columns" let-width="columnWidth">
  <div class="dashboard-row">
    <div class="dashboard-column" *ngFor="let column of columns" [ngStyle]="{'width': width}">
      <button mat-stroked-button (click)="onViewClicked(column)">View</button>
    </div>
  </div>
</ng-template>
