import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Order } from '../shared/models';
import { OrderListComponent } from '../order-list/order-list.component';

@Component({
  selector: 'app-browse-order-dialog',
  templateUrl: './browse-order-dialog.component.html',
  styleUrls: ['./browse-order-dialog.component.scss']
})
export class BrowseOrderDialogComponent implements AfterViewInit {

  @ViewChild(OrderListComponent, {static: true}) orderList: OrderListComponent;
  selectedOrder: Order | Order[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number[], listMethod: Observable<Order[]>,
    columns: string[], isMultiselect: boolean}) {}

  ngAfterViewInit(): void {
    if (!this.orderList) {
      return;
    }

    this.orderList.selection.changed.subscribe(account => {
      this.selectedOrder = this.data.isMultiselect ? account.source.selected : account.source.selected[0];
    });

    if (this.data.selected) {
      this.orderList.initSelected(this.data.selected);
    }
  }
}
