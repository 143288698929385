import { DatePipe } from '@angular/common';

export class DateUtils {
  static dateFormatToShort = (date: Date): string => {
    if (!date) {
      return '';
    }
    return new DatePipe('en-US').transform(date, 'MM/dd/yyyy');
  }

  static dateFormatToYear = (date: Date): number => {
    if (!date) {
      return null;
    }
    return date.getFullYear();
  }
}
