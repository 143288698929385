  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="big-cell-width"> {{nameColumnTitle}}</th>
      <td mat-cell *matCellDef="let element" class="big-cell-width bolder"> {{element.name}} </td>
      <td mat-footer-cell *matFooterCellDef>Total:</td>
    </ng-container>

    <ng-container matColumnDef="flights">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Flight dates<br>FTC-LTC</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transaction-type">
      <th mat-header-cell *matHeaderCellDef class="big-cell-width"> Transaction Type</th>
      <td mat-cell *matCellDef="let element" class="big-cell-width"> {{element.type}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transaction-date">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Transaction Date</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.transactionDate}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transaction-id">
      <th mat-header-cell *matHeaderCellDef class="bigger-cell-width"> Transaction ID#</th>
      <td mat-cell *matCellDef="let element" class="bigger-cell-width"> {{element.transactionId}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="quickBookReference-id">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> QBref#</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.quickBookReference}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="grossOrderedAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Gross Ordered</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.grossOrderedAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.grossOrderedAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="netOrderedAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Net Ordered</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.netOrderedAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.netOrderedAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="expenseAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Expense </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.expenseAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.expenseAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="paidAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Paid </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.paidAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.paidAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="vendorMemoInputAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Vendor Memo Input </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.vendorMemoInputAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.vendorMemoInputAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="refundAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Refund </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.refundAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.refundAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="balanceAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Balance </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.balanceAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.balanceAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="invoicedAmount">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> Invoiced </th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.invoicedAmount |  negativeCurrency}} </td>
      <td mat-footer-cell *matFooterCellDef>{{total.invoicedAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="small-cell-width">&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="small-cell-width" (click)="element.isExpanded = !element.isExpanded">
        <span *ngIf="element.isExpanded" class="material-icons">expand_less</span>
        <span *ngIf="!element.isExpanded" class="material-icons">expand_more</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Template for details row -->
    <ng-container matColumnDef="expandedDetail">
      <td class="expanded-td" *matCellDef="let row" [attr.colspan]="displayedColumns.length">

        <div class="row element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="row.entries">

            <ng-container matColumnDef="name">
              <td mat-cell *matCellDef="let element" class="big-cell-width">
                {{element.title ? element.title : ''}}
                <div class="check-info" *ngIf="element.checkRequestId">
                  Included in
                  <div class="transaction-ref">
                    <a target="_blank" [routerLink]="['/orders/checks/detail', element.checkRequestId]">{{element.checkRequestCode}}</a>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="flights">
              <td mat-cell *matCellDef="let element"
                  class="standard-cell-width"> {{element.ftcOn ? element.ftcOn + ' - ' + element.ltcOn : '&nbsp;'}} </td>
            </ng-container>

            <ng-container matColumnDef="transaction-type">
              <td mat-cell *matCellDef="let element" class="big-cell-width"> {{element.total ? '&nbsp;' : transactionType.get(element.type)}} </td>
            </ng-container>

            <ng-container matColumnDef="transaction-date">
              <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.transactionDate}} </td>
            </ng-container>

            <ng-container matColumnDef="transaction-id">
              <td mat-cell *matCellDef="let element" class="bigger-cell-width">
                <div class="transaction-ref">
                  <a target="_blank" [routerLink]="[getRouteLink(element, row.id)]">{{element.transactionId}}</a>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="quickBookReference-id">
              <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.quickBookReference}} </td>
            </ng-container>

            <ng-container matColumnDef="grossOrderedAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.grossOrderedAmount |  negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="netOrderedAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.netOrderedAmount |  negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="expenseAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.expenseAmount |  negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="paidAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">{{element.paidAmount |  negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="vendorMemoInputAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.vendorMemoInputAmount | negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="refundAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.refundAmount |  negativeCurrency}} </td>
            </ng-container>

            <ng-container matColumnDef="balanceAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{hideBalance(element.type) ? '' : (element.balanceAmount |   negativeCurrency)}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <td mat-cell *matCellDef="let element" class="small-cell-width">
                &nbsp;
              </td>
            </ng-container>

            <ng-container matColumnDef="invoicedAmount">
              <td mat-cell *matCellDef="let element" class="standard-cell-width">
                {{element.invoicedAmount |  negativeCurrency}} </td>
            </ng-container>

            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                [class.expanded-row]="element.isExpanded" [ngClass]="{'background-gray': element.groupNumber % 2,
                'bolder': element.total}"></tr>
          </table>
        </div>

      </td>
    </ng-container>


    <ng-container matColumnDef="sub-name">
      <td mat-cell *matCellDef="let element" class="big-cell-width"> {{element.subTotal?.title}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-flights">
      <td mat-cell *matCellDef="let element" class="standard-cell-width">&nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="sub-transaction-type">
      <td mat-cell *matCellDef="let element" class="big-cell-width"> &nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="sub-transaction-date">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> &nbsp; </td>
    </ng-container>

    <ng-container matColumnDef="sub-transaction-id">
      <td mat-cell *matCellDef="let element" class="bigger-cell-width"> &nbsp; </td>
    </ng-container>

    <ng-container matColumnDef="sub-quickBookReference-id">
      <th mat-header-cell *matHeaderCellDef class="standard-cell-width"> QBref#</th>
      <td mat-cell *matCellDef="let element" class="standard-cell-width">&nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="sub-grossOrderedAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.grossOrderedAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-netOrderedAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.netOrderedAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-expenseAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.expenseAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-paidAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.paidAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-vendorMemoInputAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.vendorMemoInputAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-refundAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.refundAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-balanceAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.balanceAmount |  negativeCurrency}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-actions">
      <td mat-cell *matCellDef="let element" class="small-cell-width"> &nbsp; </td>
    </ng-container>


    <ng-container matColumnDef="sub-invoicedAmount">
      <td mat-cell *matCellDef="let element" class="standard-cell-width"> {{element.subTotal?.invoicedAmount |  negativeCurrency}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
        [class.expanded-row]="element.isExpanded"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

    <!-- Extra row to show detail content column -->
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-row *matRowDef="let row; columns: subDisplayedColumns;" class="color-mat-accent bolder"  [hidden]="!row.subTotal"></tr>

  </table>
