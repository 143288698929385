<div class="content" role="main">
  <div class="header">
    <div class="header_arrow-title">
      <button mat-icon-button (click)="returnClicked()">
        <span class="material-icons">keyboard_backspace</span>
      </button>
      <h1>Check Request</h1>
    </div>

    <div class="header_buttons">
      <button mat-raised-button appLoading
        type="button" class="header-button" color="primary"
        [loading]="approving" [disabled]="isRequesting"
        (click)="approve()"
        *ngIf="isAllowApprove"
      >Approve
      </button>
      <button mat-raised-button appLoading
              type="button" class="header-button" color="primary"
              [loading]="approving" [disabled]="isRequesting"
              (click)="approve(true)"
              *ngIf="isAllowApprove"
      >Approve and Create Payment Batch
      </button>
      <button mat-raised-button appLoading
        type="button" color="primary" class="header-button"
        [loading]="declining" [disabled]="isRequesting"
        (click)="decline()"
        *ngIf="isAllowDecline"
      >Decline
      </button>

      <button mat-mini-fab color="primary" aria-label="Edit CheckRequest"
        *ngIf="hasEditPermission && checkRequestData" [disabled]="isRequesting"
        [routerLink]="['/orders/checks/edit', checkRequestData.id]" class="edit-button">
          <mat-icon>edit</mat-icon>
      </button>
    </div>

  </div>
  <div *ngIf="checkRequestData as checkRequest; else loader">
    <mat-card class="container">
      <mat-card-content>
        <div class="form-content">
          <div class="form-content_column">
            <div class="subheader subheader-general mat-title">General Info</div>
            <mat-card class="container">
              <div class="column_item">
                <div class="title">Account</div>
                {{ checkRequest.account.code }} - {{ checkRequest.account.name }}
              </div>
              <div class="column_item">
                <div class="title">Request #</div>
                {{ checkRequest.code }}
              </div>
              <div *ngIf="checkRequest.filter.order">
                <div class="column_item">
                  <div class="title">Order #</div>
                  {{ checkRequest.filter.order.code }}
                </div>
                <div class="column_item">
                  <div class="title">Election type</div>
                  {{ checkRequest.filter.order.electionType?.type || 'N/A' }}
                </div>
                <div class="column_item">
                  <div class="title">Election Date</div>
                  {{ checkRequest.filter.order.electionDate || 'N/A'}}
                </div>
              </div>
              <div class="column_item">
                <div class="title">Assigned Accountant</div>
                {{getAssignedAccountant(checkRequest) || 'N/A'}}
              </div>
            </mat-card>
            <ng-container *ngIf="checkRequest.notes">
              <div class="subheader mat-title">Notes</div>
              <mat-card class="notes-container">
                <mat-card-content>
                  <div class="note" *ngFor="let note of checkRequest.notes.reverse()">
                    <div><b>{{note | noteHeader}}</b></div>
                    <div><span>{{note.message}}</span></div>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
          <div class="form-content_column">
            <app-table-version-history *ngIf="versions?.length"
                                       [versionData]="versions"></app-table-version-history>
          </div>
        </div>
        <div *ngFor="let data of checkRequest.payments; let i=index" class="note">
          <app-station-flight-checks [detailView]="true" [payment]="data" [index]="i" [readonly]="true"
                                     [showBalance]="isShowBalance"></app-station-flight-checks>
        </div>
      </mat-card-content>
      <mat-card class="summary-container">
        <div class="subheader subheader-summary mat-title">Payment Summary</div>
        <div *ngFor="let data of checkRequest.payments" class="row">
          <div class="note summary-field-name">
            <h3>
              <span class="bolder">{{data.station.name}}</span>&nbsp;
              <span class="smaller">{{data.station.mediaType.type}}</span>:
            </h3>
          </div>
          <div class="note summary-field-value">
            <h3 class="bolder">{{data.payAmount | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row">
          <h3 class="large summary-field-name bolder">Total Requested:</h3>
          <div class="row summary-field-value" [ngClass]="{'marked': getTotalRequested(checkRequest) < 0}">
            <h3 class="bolder">{{getTotalRequested(checkRequest) | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Current Account Balance:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': currentAccountBalance < 0}">
            <h3 class="bolder">{{currentAccountBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Total of outstanding requests:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': totalOfOutstandingRequestsAmount < 0}">
            <h3 class="bolder">{{totalOfOutstandingRequestsAmount | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row" *ngIf="isShowBalance">
          <h3 class="summary-field-name">
            Balance After This Request:</h3>
          <div class="note marked summary-field-value" [ngClass]="{'marked': afterThisRequestBalance < 0}">
            <h3 class="bolder">{{afterThisRequestBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Outstanding Invoices:</h3>
          <div class="notes summary-field-value" [ngClass]="{'marked': outstandingInvoicesBalance < 0}">
            <h3 class="bolder">{{outstandingInvoicesBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Balance After This Request and Invoices:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': afterThisRequestAndInvoicesBalance < 0}">
            <h3 class="bolder">{{afterThisRequestAndInvoicesBalance | negativeCurrency}}</h3>
          </div>
        </div>
      </mat-card>
    </mat-card>
  </div>
</div>

<ng-template #loader>
  <div class="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
</ng-template>

