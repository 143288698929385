import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Role } from './models/role.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected keycloakAngular: KeycloakService) {
  }

  get hasEditPermission() {
    const roles = this.keycloakAngular.getUserRoles();
    const requiredRoles: string[] = [Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.ACCOUNTANT];
    return requiredRoles.some(r => roles.includes(r));
  }

  hasPermission(requiredRoles: string[]) {
    const roles = this.keycloakAngular.getUserRoles();
    return requiredRoles.some(r => roles.includes(r));
  }

  get keycloakUrl() {
    const inst = this.keycloakAngular.getKeycloakInstance();
    return `${inst.authServerUrl}admin/${inst.realm}/console`;
  }
}
