export interface OrderStatus {
  id: number;
  status: string;
}

export const orderStatuses = {
  DRAFT: {id: 1, status: 'Draft'},
  SUBMITTED: {id: 2, status: 'Submitted'}
};

