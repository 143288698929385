<div class="commission-rates">
  <div class="header">
    <h2>{{headerName}}</h2>
  </div>

  <div>
    <div class="slider">
      <div class="boundary-value_left">0%</div>

      <app-slider *ngIf="!reloading"
                  (loadNext)="onLoadNext()"
                  [handles]="sliderValues">
      </app-slider>

      <div class="boundary-value_right">15%</div>
    </div>

    <form class="rates-fields" [formGroup]="ratesForm">
      <div class="rates-fields__column" *ngFor="let consultant of consultantsControls; index as i">
        <mat-form-field>
          <input [formControl]="consultant"
                 step="0.01"
                 min="0"
                 (keypress)="keyPress($event)"
                 matInput
                 required
                 type="number"
                 appTwoDigitDecimalNumber>
          <span matSuffix>%</span>
        </mat-form-field>
        <h4> {{getConsultantLabel(i)}}</h4>
      </div>
      <div class="rates-fields__column">
        <mat-form-field>
          <input formControlName="canal"
                 step="0.01"
                 min="0"
                 (keypress)="keyPress($event)"
                 matInput required type="number"
                 appTwoDigitDecimalNumber>
          <span matSuffix>%</span>
          <mat-error *ngIf="ratesForm.get('canal').hasError('wrongSum')">
          </mat-error>
        </mat-form-field>
        <h4>Canal</h4>
      </div>
      <div class="rates-fields__column_right">
        <mat-form-field>
          <input formControlName="roll"
                 step="0.01"
                 min="0"
                 (keypress)="keyPress($event)"
                 matInput
                 required
                 type="number"
                 [contentEditable]="false"
                 appTwoDigitDecimalNumber>
          <span matSuffix>%</span>
        </mat-form-field>

        <h4>Roll</h4>
      </div>

    </form>
    <h5 class="amount-error"
        *ngIf="ratesForm.get('canal').hasError('wrongSum')">
      Commission Rates amount should be equal to 15%.
    </h5>

  </div>

</div>
