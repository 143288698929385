import { Component, OnInit } from '@angular/core';
import { UatService } from './shared/uat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private uatService: UatService) {
  }

  ngOnInit() {
    this.uatService.setUatTrademark();
  }

}


