import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap } from 'rxjs/operators';

import {
  AccountLight,
  PaymentCheckRequest,
  CheckRequestCreationFiler,
  checkRequestStatuses,
  Order
} from '../shared/models';
import { FormMode } from '../../shared/models/form-mode.enum';
import { User } from '../../shared/models/user';
import { NotificationService } from '../../shared/notification.service';
import { UserService } from '../../shared/user.service';
import { DateUtils } from '../../shared/utils/date-utils';
import { FormUtils } from '../../shared/utils/form-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { OrderService } from '../shared/order.service';
import { MediaType, ParentCompany } from '../../entities/shared/models';
import { RelatedItemOption } from '../../shared/models/related-item-option';
import { ParentCompanyService } from '../../entities/shared/parent-company.service';
import { PaymentCheckRequestService } from '../shared/payment-check-request.service';
import { EntityDictionaryService } from '../../entities/shared/entity-dictionary.service';
import ValidatorsExtension from '../../shared/utils/validators-extension';
import { NoteUtil } from '../shared/utils/note-util';
import { AfterSaveAction } from '../shared/models/after-save-action.enum';
import { CheckRequestTotalUtil } from '../shared/utils/check-request-total-util';
import { AuthService } from '../../shared/auth.service';
import { Role } from '../../shared/models/role.enum';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { BrowseUserDialogComponent } from '../browse-user-dialog/browse-user-dialog.component';
import { BrowseCompanyDialogComponent } from '../../entities/browse-company-dialog/browse-company-dialog.component';
import { StationFlightChecksComponent } from '../station-flight-checks/station-flight-checks.component';


@Component({
  selector: 'app-check-edit',
  templateUrl: './check-edit.component.html',
  styleUrls: ['./check-edit.component.scss']
})
export class CheckEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  checkRequest: PaymentCheckRequest = {} as any;
  form = this.fb.group({
    account: [null, ValidatorsExtension.required],
    code: [''],
    assignedAccountant: [null, ValidatorsExtension.required],
    noteToAdd: [null]
  });
  filterForm = this.fb.group({
    range: this.fb.group({
      flightStartOn: [''],
      flightFinishOn: ['']
    }, {validators: ValidatorsExtension.dateRangeRequiredValidator}),
    stationMediaType: [null],
    parentCompany: [null],
    order: [null],
  });

  accounts: AccountLight[];
  filteredAccounts: Observable<AccountLight[]>;
  accountants: User[];
  filteredAccountants: Observable<User[]>;
  mediaTypes: MediaType[] = [];
  companies: ParentCompany[];
  filteredCompanies: Observable<ParentCompany[]>;

  @ViewChildren(StationFlightChecksComponent) flightChecks!: QueryList<StationFlightChecksComponent>;
  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  submitting = false;
  openedDialog = false;

  get template(): CheckRequestCreationFiler {
    const template = {...this.filterForm.value, account: this.form.value.account};
    template.flightStartOn = DateUtils.dateFormatToShort(template.range.flightStartOn);
    template.flightFinishOn = DateUtils.dateFormatToShort(template.range.flightFinishOn);
    delete template.range;

    return template;
  }

  get dateRangeGroup(): FormGroup {
    return this.filterForm.get('range') as FormGroup;
  }

  get order(): Order {
    return this.filterForm.get('order').value;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private service: PaymentCheckRequestService,
              private orderService: OrderService,
              private notificationService: NotificationService,
              private userService: UserService,
              private companyService: ParentCompanyService,
              private dictionaryService: EntityDictionaryService,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    const orderId = this.route.snapshot.queryParamMap.get('orderId'); // only in case to create by order save
    const accountId = this.route.snapshot.queryParamMap.get('accountId'); // only in case to create by order save
    this.loadData(id, orderId, accountId);

    this.subscription.add(this.userService.getAccountants().subscribe(data => {
      this.accountants = data;
      this.filteredAccountants = TypeaheadUtil.manageAccountantFilter(this.form, this.accountants);
    }));

    this.filteredCompanies = this.filterForm.get('parentCompany').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => this.companyService.getTypeaheadParentCompanyList(term))
    ).pipe(map(results => {
      this.companies = results.map(item => ({id: Number(item.id), name: item.text}) as any);
      return this.companies;
    }));

    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  loadData = (id: string, orderId: string, accountId: string) => {
    const accounts$ = this.isEditMode ? of(null) : this.orderService.getAccountList(); // account is readonly for edit
    const checkRequest$ = this.isEditMode ? this.service.getCheckRequest(id, true) : of(null);
    const types$ = this.dictionaryService.getMediaTypes();
    const order$ = orderId ? this.orderService.getOrder(accountId, orderId) : of(null);

    forkJoin([accounts$, checkRequest$, types$, order$])
      .pipe(first())
      .subscribe(([accounts, checkRequest, types, order]) => {
        this.accounts = accounts ? accounts.map(item => ({id: Number(item.id), code: item.data, name: item.text} as AccountLight)) : [];
        this.mediaTypes = types;

        if (checkRequest) {
          this.checkRequest = checkRequest;
          this.form.patchValue({
            account: checkRequest.account, assignedAccountant: checkRequest.assignedAccountant, code: checkRequest.code
          });
          const range = {
            flightStartOn: checkRequest.filter.flightStartOn ? new Date(checkRequest.filter.flightStartOn) : null,
            flightFinishOn: checkRequest.filter.flightFinishOn ? new Date(checkRequest.filter.flightFinishOn) : null
          };
          this.filterForm.patchValue({
            parentCompany: checkRequest.filter.parentCompany || null,
            stationMediaType: types.find(item => item.id === checkRequest.filter.stationMediaType?.id) || null,
            range,
            order: checkRequest.filter.order || null
          });
        } else {
          this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);

          if (order) {
            this.filterForm.patchValue({order});
            this.form.patchValue({account: order.account});
            this.getStationFlightChecks();
          }
        }
        this.loading = false;
      });
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/checks');
  }

  get isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  get totalRequested() {
    return CheckRequestTotalUtil.totalRequested(this.checkRequest);
  }

  get currentAccountBalance() {
    return this.checkRequest.summary?.currentAccountBalance ?? 0;
  }

  get totalOfOutstandingRequestsAmount() {
    return this.checkRequest.summary?.totalOfOutstandingRequestsAmount ?? 0;
  }

  get afterThisRequestBalance() {
    return this.checkRequest.summary?.afterThisRequestBalance ?? 0;
  }

  get outstandingInvoicesBalance() {
    return this.checkRequest.summary?.outstandingInvoicesBalance ?? 0;
  }

  get afterThisRequestAndInvoicesBalance() {
    return this.checkRequest.summary?.afterThisRequestAndOutstandingInvoicesBalance ?? 0;
  }

  get isRequesting() {
    return this.saving || this.submitting;
  }

  get isShowBalance() {
    const statuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.SUBMITTED.id, checkRequestStatuses.RETURNED_TO_BUYER.id];
    return !this.checkRequest?.status || statuses.includes(this.checkRequest.status.id);
  }

  onBlurAccount(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
  }

  onBlurAccountant(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccountant(this.form, this.accountants, inputElement);
  }

  onAccountantOptionSelect(option) {
    TypeaheadUtil.onAccountantOptionSelect(this.form, option);
  }

  onBlurParentCompany(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurParentCompany(this.filterForm, this.companies, inputElement);
  }

  onParentCompanyOptionSelect(option) {
    TypeaheadUtil.onParentCompanyOptionSelect(this.filterForm, option);
  }

  displayAccount(account?: AccountLight): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  displayAccountant(accountant?: User): string {
    return TypeaheadUtil.displayAccountant(accountant);
  }

  displayParentCompany(item: RelatedItemOption): string {
    return item && item.name ? item.name : '';
  }

  markPayIsFull(value: boolean) {
    this.flightChecks?.forEach((item) => item.setPayInFullAll(value));
  }

  openAccountDialog() {
    const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
      {data: {selected: this.form.value.account?.id}}
    );
    dialogRef.afterClosed().subscribe((result: AccountLight) => {
      if (result) {
        this.form.patchValue({account: result});
      }
    });
  }

  browseUser() {
    const dialogRef = this.dialog.open(BrowseUserDialogComponent,
      {data: {selected: this.form.value.assignedAccountant?.id}}
    );
    dialogRef.afterClosed().subscribe((result: User) => {
      if (result) {
        this.form.patchValue({assignedAccountant: result});
      }
    });
  }

  browseCompanies() {
    const dialogRef = this.dialog.open(BrowseCompanyDialogComponent,
      {data: {selected: this.filterForm.get('parentCompany').value?.id}});
    dialogRef.afterClosed().subscribe((result: ParentCompany) => {
      if (result) {
        this.filterForm.get('parentCompany').setValue(result);
      }
    });
  }

  save(form: FormGroup, nextStatus?: AfterSaveAction): void {
    const check = this.prepareCheckRequest(form);

    if (nextStatus) {
      check.afterSaveAction = nextStatus;
    }

    const request = this.mode === FormMode.EDIT
      ? this.service.updateCheckRequest(check.id, check).pipe(
        tap(() => this.notificationService.success('Check request was updated!'))
      )
      : this.service.createCheckRequest(check).pipe(
        tap(() => this.notificationService.success('New check request created!'))
      );

    request.pipe(finalize(() => this.resetRequesing())).subscribe(
      () => this.returnClicked(),
      (e) => this.actionErrorHandler(e, this.mode),
    );
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.checkRequest.notes);
  }

  private prepareCheckRequest(form: FormGroup): PaymentCheckRequest {
    const check = {
      ...this.checkRequest,
      ...form.value,
      filter: this.template,
    };
    NoteUtil.processNotes(check, form);
    return check;
  }

  resetRequesing() {
    this.saving = false;
    this.submitting = false;
  }

  saveAndSubmit(decline?: boolean) {
    this.submitting = true;
    this.save(this.form, decline ? AfterSaveAction.DECLINE_TO_PREVIOUS_STATUS : AfterSaveAction.APPROVE_NEXT_STATUS);
  }

  get isStationFlightChecksReadonly() {
    if (this.mode === FormMode.CREATE) {
      return false;
    }
    const allowedStatuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.RETURNED_TO_BUYER.id];
    return !this.canEdit || !this.checkRequest || !this.checkRequest.status || !allowedStatuses.includes(this.checkRequest.status.id);
  }

  get isCanEditFilter() {
    const allowedStatuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.RETURNED_TO_BUYER.id];
    return this.canEdit && (!this.checkRequest || !this.checkRequest.status || allowedStatuses.includes(this.checkRequest.status.id));
  }

  get isCanEditAccountant() {
    const allowedStatuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.SUBMITTED.id,
      checkRequestStatuses.RETURNED_TO_BUYER.id];
    return this.canEdit && (!this.checkRequest || !this.checkRequest.status || allowedStatuses.includes(this.checkRequest.status.id));
  }

  get isAllowSubmit() {
    const allowedStatuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.RETURNED_TO_BUYER.id];
    return this.canEdit && (!this.checkRequest || !this.checkRequest.status || allowedStatuses.includes(this.checkRequest.status.id));
  }

  getStationFlightChecks() {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    if (!this.form.get('account').value) {
      this.form.markAllAsTouched();
      return;
    }

    const updateCheckRequest = result => {
      this.checkRequest.payments = result.payments;
      this.checkRequest.summary = result.summary;
      this.checkRequest.filter = result.filter;
      if (!this.checkRequest.status) {
        this.checkRequest.status = result.status;
      }
    };

    if (this.mode === FormMode.EDIT) {
      this.service.resetWith(this.checkRequest.id, this.template).subscribe(updateCheckRequest);
    } else {
      this.service.getCheckRequestByTemplate(this.template).subscribe(updateCheckRequest);
    }
  }

  get canEdit() {
    return this.authService.hasPermission([Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.BUYER]);
  }

  onPayAmountChanged() {
    const check = this.prepareCheckRequest(this.form);
    this.service.updateSummary(check).subscribe(updatedCheck => {
      this.checkRequest.summary = updatedCheck.summary;
    });
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} check request.`);
  }

}
