import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';

import { SharedComponentsModule } from '../shared-components.module';
import { OrdersRoutingModule } from './orders-routing.module';
import { BrowseStationDialogComponent } from './browse-station-dialog/browse-station-dialog.component';
import { OrdersComponent } from './orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { BrowseAccountDialogComponent } from './browse-account-dialog/browse-account-dialog.component';
import { FlightCardComponent } from './flight-card/flight-card.component';
import { BrowseOrderDialogComponent } from './browse-order-dialog/browse-order-dialog.component';
import { CheckListComponent } from './check-list/check-list.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { CheckEditComponent } from './check-edit/check-edit.component';
import { PaymentEditComponent } from './payment-edit/payment-edit.component';
import { CheckDetailComponent } from './check-detail/check-detail.component';
import { SharedModule } from '../shared/shared.module';
import { UserListComponent } from './user-list/user-list.component';
import { TransactionEditComponent } from './transaction-edit/transaction-edit.component';
import { BrowseUserDialogComponent } from './browse-user-dialog/browse-user-dialog.component';
import { BrowseCheckDialogComponent } from './browse-check-dialog/browse-check-dialog.component';
import { PaymentSelectorComponent } from './payment-selector/payment-selector.component';
import { StationFlightChecksComponent } from './station-flight-checks/station-flight-checks.component';
import { PaymentBatchStationComponent } from './payment-batch-station/payment-batch-station.component';
import { StationInvoiceEditComponent } from './station-invoice-edit/station-invoice-edit.component';
import { PayExpenseComponent } from './pay-expense/pay-expense.component';
import { StationInvoiceListComponent } from './station-invoice-list/station-invoice-list.component';
import { StationInvoiceDetailComponent } from './station-invoice-detail/station-invoice-detail.component';
import { BrowseExpenseDialogComponent } from './browse-expense-dialog/browse-expense-dialog.component';
import { InvoiceExpenseListComponent } from './invoice-expense-list/invoice-expense-list.component';
import { BatchAssignmentSummaryComponent } from './batch-assignment-summary/batch-assignment-summary.component';
import { AcceptPaymentListComponent } from './accept-payment-list/accept-payment-list.component';
import { AcceptPaymentEditComponent } from './accept-payment-edit/accept-payment-edit.component';
import { AcceptPaymentDetailComponent } from './accept-payment-detail/accept-payment-detail.component';
import { AccountInvoicesDialogComponent } from './account-invoices-dialog/account-invoices-dialog.component';
import { AccountInvoiceEditComponent } from './account-invoice-edit/account-invoice-edit.component';
import { AccountInvoiceListComponent } from './account-invoice-list/account-invoice-list.component';
import { AccountInvoiceDetailComponent } from './account-invoice-detail/account-invoice-detail.component';
import { AccountInvoiceOrdersComponent } from './account-invoice-orders/account-invoice-orders.component';
import { AccountInvoiceExpensesComponent } from './account-invoice-expenses/account-invoice-expenses.component';
import { ExpensesListComponent } from './expenses-list/expenses-list.component';
import { MemoRefundListComponent } from './memo-refund-list/memo-refund-list.component';
import { StationCreateDialogComponent } from './station-create-dialog/station-create-dialog.component';
import { CommissionListComponent } from './commission-list/commission-list.component';
import { ConsultantCommissionComponent } from './consultant-commission/consultant-commission.component';
import { EntitiesModule } from '../entities/entities.module';
import { TransactionReportDialogComponent } from './transaction-report-dialog/transaction-report-dialog.component';
import { AcceptPaymentVersionsDialogComponent } from './accept-payment-versions-dialog/accept-payment-versions-dialog.component';
import { StationInvoiceVersionsDialogComponent } from './station-invoice-versions-dialog/station-invoice-versions-dialog.component';
import { MemoRefundVersionsDialogComponent } from './memo-refund-versions-dialog/memo-refund-versions-dialog.component';
import { ExpenseVersionsDialogComponent } from './expense-versions-dialog/expense-versions-dialog.component';
import { CommissionVersionsDialogComponent } from './commission-versions-dialog/commission-versions-dialog.component';
import { PaymentVersionsDialogComponent } from './payment-versions-dialog/payment-versions-dialog.component';
import { StationTransactionListComponent } from './station-transaction-list/station-transaction-list.component';
import { BrowseOrderedStationDialogComponent } from './browse-ordered-station-dialog/browse-ordered-station-dialog.component';

@NgModule({
  declarations: [
    OrdersComponent,
    OrderListComponent,
    OrderEditComponent,
    OrderDetailComponent,
    BrowseStationDialogComponent,
    BrowseAccountDialogComponent,
    FlightCardComponent,
    BrowseOrderDialogComponent,
    CheckListComponent,
    PaymentListComponent,
    CheckEditComponent,
    PaymentEditComponent,
    CheckDetailComponent,
    UserListComponent,
    BrowseUserDialogComponent,
    TransactionEditComponent,
    BrowseCheckDialogComponent,
    PaymentSelectorComponent,
    StationFlightChecksComponent,
    PaymentBatchStationComponent,
    StationInvoiceEditComponent,
    StationInvoiceListComponent,
    PayExpenseComponent,
    StationInvoiceListComponent,
    StationInvoiceDetailComponent,
    BrowseExpenseDialogComponent,
    InvoiceExpenseListComponent,
    PayExpenseComponent,
    BatchAssignmentSummaryComponent,
    AcceptPaymentListComponent,
    AcceptPaymentEditComponent,
    AcceptPaymentDetailComponent,
    AccountInvoicesDialogComponent,
    AccountInvoiceEditComponent,
    AccountInvoiceListComponent,
    AccountInvoiceDetailComponent,
    AccountInvoiceOrdersComponent,
    AccountInvoiceExpensesComponent,
    ExpensesListComponent,
    MemoRefundListComponent,
    StationCreateDialogComponent,
    CommissionListComponent,
    ConsultantCommissionComponent,
    TransactionReportDialogComponent,
    AcceptPaymentVersionsDialogComponent,
    StationInvoiceVersionsDialogComponent,
    MemoRefundVersionsDialogComponent,
    ExpenseVersionsDialogComponent,
    CommissionVersionsDialogComponent,
    PaymentVersionsDialogComponent,
    StationTransactionListComponent,
    BrowseOrderedStationDialogComponent
  ],
    imports: [
        CommonModule,
        OrdersRoutingModule,
        HttpClientModule,
        SharedComponentsModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        MatNativeDateModule,
        MatButtonModule,
        MatBadgeModule,
        MatSelectModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatDividerModule,
        MatChipsModule,
        MatTooltipModule,
        SharedModule,
        MatTabsModule,
        MatProgressBarModule,
        MatGridListModule,
        EntitiesModule
    ],
  providers: [CurrencyPipe]
})
export class OrdersModule {
}
