<div class="content" role="main">
  <div class="header" #header>
    <h1>
      <button mat-icon-button>
        <span (click)="onBackClicked()" class="back-arrow material-icons">keyboard_backspace</span>
      </button>
      <span *ngIf="title">{{title}} - </span>Transactions
    </h1>
    <div class="header-action">
      <button mat-raised-button appLoading
              [loading]="isReportLoading$ | async"
              class="export-button" type="button"
              (click)="onExportClicked()"
              [disabled]="!appliedFilter"
      >Export Page View</button>
    </div>
  </div>

  <div class="filter-panel" #filterPanel>
    <div class="consultants-container" *ngIf="account">
      <div class="title">Consultant(s)</div>
      <div class="consultant-ref" *ngFor="let consultant of account.consultantCompanies"
           [routerLink]="['/entities/consultants/detail', consultant?.id]">
        <a>{{consultant?.code}}, {{consultant?.name}}</a>
      </div>

      <div *ngIf="!account.consultantCompanies?.length">N/A</div>
    </div>
    <form class="filter-container" [formGroup]="filterForm" (ngSubmit)="searchTransactions(filterForm.value)">
      <mat-radio-group formControlName="view" aria-label="Select Type">
        <mat-radio-button [value]="TransactionsReportType.ACCOUNT_ORDER">Order View</mat-radio-button>
        <mat-radio-button class="filter-radio" [value]="TransactionsReportType.ACCOUNT_INVOICE">Invoice View
        </mat-radio-button>
      </mat-radio-group>
      <div class="dashboard-container">
        <div class="filter-method-container">
          <div class="report-view-button-toggle">
            <mat-button-toggle-group aria-label="Select Date Type" [value]="true"
                                     (change)="handleSelectDateType($event.value)">
              <mat-button-toggle [value]="true">Date range</mat-button-toggle>
              <mat-button-toggle [value]="false">Year</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-form-field *ngIf="isDateRange">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matInput matStartDate formControlName="since" placeholder="Start date">
              <input matInput matEndDate formControlName="to" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field *ngIf="!isDateRange" appYearFormat>
            <mat-label>Year</mat-label>
            <input matInput [matDatepicker]="yearDateDatepicker" formControlName="year">
            <mat-datepicker-toggle matSuffix [for]="yearDateDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #yearDateDatepicker startView="multi-year"
                            (yearSelected)="chosenYearHandler($event, yearDateDatepicker)">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="filter-method-container_large">
          <div class="filter-method-container-title">
            Select Media Type
            <button mat-raised-button (click)="toggleAll(true)" type="button">All</button>
            <button mat-raised-button (click)="toggleAll(false)" type="button">None</button>
          </div>
          <div class="media-types-list">
            <mat-checkbox *ngFor="let mediaType of mediaTypes" (change)="toggleMediaType($event.checked, mediaType.id)"
                          [checked]="selectedMediaTypes.includes(mediaType.id)">{{mediaType.type}}</mat-checkbox>
            <button mat-raised-button (click)="specifyStations()" type="button">Specify</button>
          </div>
        </div>
        <div class="filter-method-container">
          <div class="filter-method-container-title">
            Select Stations
          </div>
          <div class="station-list">
            <span class="station-list-empty" *ngIf="stations?.length === 0">No ordered Stations for Account</span>
            <mat-checkbox *ngFor="let station of stations" (change)="toggleStation($event.checked, station.id)"
                          [checked]="selectedStations.includes(station.id)">
              {{station.name}}- {{station.mediaType?.type}}</mat-checkbox>
          </div>
        </div>
        <div class="filter-method-container">
          <div class="report-view-button-toggle">
            <mat-button-toggle-group aria-label="Select Transaction" [value]="true"
                                     (change)="handleSelectTransactionType($event.value)">
              <mat-button-toggle [value]="true">Transaction ID</mat-button-toggle>
              <mat-button-toggle [value]="false">QBref#</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-form-field *ngIf="isTransactionId">
            <input formControlName="transactionId" matInput maxlength="50">
          </mat-form-field>

          <mat-form-field *ngIf="!isTransactionId">
            <input formControlName="quickBookReference" matInput maxlength="50">
          </mat-form-field>

          <button mat-raised-button class="search-button" type="submit" color="accent">
            Search...
          </button>
        </div>
      </div>
    </form>
  </div>

  <h2 #tableNameRow>Account Transactions - {{ isOrderReport ? 'ORDER' : 'INVOICE' }} view</h2>
  <div class="no-data" *ngIf="!sections.length && !isLoadingReport">No data - Please use search...</div>
  <div style="position: relative">
    <div class="loading-shade" *ngIf="isLoadingReport">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
  </div>

  <div class="collapse-table-pre-info" *ngIf="sections.length" #collapseRow>
    <div class="collapse-container">
      <mat-form-field>
        <mat-label><div>Collapse All</div></mat-label>
        <mat-select class="form-field" [(value)]="collapseAllTable">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <div class="container mat-elevation-z8 tables-container" *ngIf="sections.length" #tableContainer>
    <app-transaction-collapsable-table [collapseAll]="collapseAllTable" [viewType]="viewType" [sections]="sections"
                                       [total]="total"
                                       [accountId]="accountId"></app-transaction-collapsable-table>
  </div>
</div>
