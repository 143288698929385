<mat-dialog-content class="browse-dialog">
  <app-order-list [listMethod]="data.listMethod" [columns]="data.columns" [isMultiselect]="data.isMultiselect"></app-order-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="selectedOrder" *ngIf="!data.isMultiselect"
          [disabled]="!selectedOrder">Apply</button>
  <button mat-raised-button color="primary" *ngIf="data.isMultiselect" [mat-dialog-close]="{items: selectedOrder, overwrite: false}"
          [disabled]="!selectedOrder">Add selected</button>
  <button mat-raised-button color="primary" *ngIf="data.isMultiselect" [mat-dialog-close]="{items: selectedOrder, overwrite: true}"
          [disabled]="!selectedOrder">Overwrite with selected</button>
</mat-dialog-actions>
