export enum ComponentType {
  Transaction,
  PaymentBatch,
  StationInvoice,
  PayExpense,
  ConsultantCommission,
  AcceptPayment,
  ParentCompany,
  Station
}
