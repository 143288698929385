<div class="content" role="main">
  <div class="header">
    <h1>
      Candidate / Client Invoices
    </h1>

    <div class="header-action" *ngIf="!isSelectMode">
<!--      <button mat-raised-button appLoading
        [loading]="isReportLoading$ | async"
        class="export-button" type="button"
        (click)="onExportClicked()"
      >Export...
      </button>-->

      <button mat-mini-fab color="primary" aria-label="New Invoice" [routerLink]="['/orders/cinvoices/edit', '']" *ngIf="hasCreatePermission">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="dashboard-container" *ngIf="!isSelectMode">
    <div class="table-range-filter">
      <mat-form-field>
        <mat-label>Filter Table by Date</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matInput matStartDate formControlName="start" placeholder="Start date">
          <input matInput matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <div class="container" [ngClass]="{'mat-elevation-z8': !isSelectMode}">
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <div class="table-container" [ngClass]="{'no-data-table-container': !dataSource.filteredData.length}">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="tableFilterForm">

        <ng-container matColumnDef="select" sticky>
        <th class="select" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
        </ng-container>

        <ng-container matColumnDef="select-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-filter">
              <div class="form-field"></div>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="code" sticky>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Invoice#</th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="['/orders/cinvoices/detail', row.id]" *ngIf="!isSelectMode">{{row.code}}</a>
            <span *ngIf="isSelectMode">{{row.code}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="code-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="code"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.accountInvoiceStatus?.status || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="status-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="status">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let status of statuses" [value]="status">
                  {{status}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Client Name</th>
          <td mat-cell *matCellDef="let row">
            {{row.account?.name || 'N/A'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="clientName-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="clientName"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="clientCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Client ID#</th>
          <td mat-cell *matCellDef="let row">
            {{row.account?.code ||'N/A'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="clientCode-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="clientCode"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="consultant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Consultant Name</th>
          <td mat-cell *matCellDef="let row">
            {{getLeadConsultantCompanyName(row) ||'N/A'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="consultant-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="consultant"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Type</th>
          <td mat-cell *matCellDef="let row">{{getAccountType(row.account)  || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="type-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="type">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let type of types" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="requested">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount Requested</th>
          <td mat-cell *matCellDef="let row">{{(row.requestedAmount || 0) | currency}}</td>
        </ng-container>

        <ng-container matColumnDef="paid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount Paid</th>
          <td mat-cell *matCellDef="let row">{{(row.paidAmount || 0) | currency}}</td>
        </ng-container>

        <ng-container matColumnDef="remaining">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount Remaining</th>
          <td mat-cell *matCellDef="let row">
            {{getRemainingAmountValue(row) | currency}}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Edit</th>
          <td mat-cell *matCellDef="let row">{{row.lastUpdatedAt ? (row.lastUpdatedAt | date:'short') : 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="editedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Edited By</th>
          <td mat-cell *matCellDef="let row">
            <span class="pull-left">{{row.lastUpdatedBy || 'N/A'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="editedBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="editedBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let row">{{row.createdAt ? (row.createdAt | date:'short') : 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Creator</th>
          <td mat-cell *matCellDef="let row">
            <span class="pull-left">{{row.createdBy || 'N/A'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="createdBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="submittedOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Submitted</th>
          <td mat-cell *matCellDef="let row">{{row.completedOn ? (row.completedOn |  date:'MM/dd/yyyy') : 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="ftcOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>FTC</th>
          <td mat-cell *matCellDef="let row">{{row.invoicedOrdersData?.orderedData?.length > 0
            ? (getInvoiceFTC(row) | date:'shortDate')
            : 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="ltcOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>LTC</th>
          <td mat-cell *matCellDef="let row">{{row.invoicedOrdersData?.orderedData?.length > 0
            ? (getInvoiceLTC(row) | date:'shortDate')
            : 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="electionType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Election Type</th>
          <td mat-cell *matCellDef="let row">{{row.electionType?.type || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="electionType-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="electionType">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let type of electionTypes" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="electionDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Election Date</th>
        <td mat-cell *matCellDef="let row">{{row.electionDate ? (row.electionDate | date:'shortDate') : 'N/A'}}</td>
        </ng-container>

        <ng-container *ngFor="let digitFilter of digitFilters" [matColumnDef]="digitFilter.filterDef">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" [formGroupName]="digitFilter.name">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="digit-filter-input">
                <input matInput
                       type="number"
                       class="form-field"
                       formControlName="value"
                       [readonly]="false">
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container *ngFor="let dateFilter of dateFilters; let i = index;" [matColumnDef]="dateFilter.filterDef">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" [formGroupName]="dateFilter.name">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput [matDatepicker]="i" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="menu" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="button pull-right"
                    *ngIf="showMenu(row)"
                    [matMenuTriggerFor]="appMenu"
                    [matMenuTriggerData]="{ id: row.id }">
              <span class="material-icons">
                more_vert
              </span>
            </button>

            <mat-menu #appMenu>
              <ng-template matMenuContent>
                <a mat-menu-item [routerLink]="'/orders/cinvoices/edit/' + row.id" *ngIf="row.permissions?.indexOf('EDIT') > -1">Edit</a>
                <a mat-menu-item *ngIf="canCreatePayment(row)" [routerLink]="['/orders/accept-payment/edit/']"
                   [queryParams]="{accountId: row.account.id, accountInvoiceId: row.id}">Create Accept Payment</a>
              </ng-template>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="menu-filter" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <button mat-icon-button class="button pull-right" (click)="clearFilterForm()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                <path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/>
              </svg>
            </button>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columnFilters; sticky: true" class="filter-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-container *ngIf="!dataSource.filteredData.length">
        <p class="no-data">No data</p>
      </ng-container>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
