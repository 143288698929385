import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

import { ConsultantCompany } from '../shared/models';
import { ConsultantCompanyService } from '../shared/consultant-company.service';
import { NotificationService } from '../../shared/notification.service';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { customSortingDataAccessor, SortingDataTypes, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { AccountService } from '../shared/account.service';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-consultant-list',
  templateUrl: './consultant-list.component.html',
  styleUrls: ['./consultant-list.component.scss']
})
export class ConsultantListComponent implements OnInit {
  @Input() isSelectMode: boolean;
  @Input() isTransactionDialog = false;
  @Input() accountId: number;
  @Input() exclude: number[] = [];
  @Input() consultantList: Observable<ConsultantCompany[]>;

  dataSource = new MatTableDataSource<ConsultantCompany>();
  displayedColumns: string[] = [
    'name',
    'code',
    'address',
    'city',
    'state',
    'zipCode', 'menu'
  ];
  selection: SelectionModel<ConsultantCompany>;
  selectedId: number[];
  isLoadingResults = true;
  hasEditPermission = false;

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    name: new FormControl(''),
    cpmCompany: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipCode: new FormControl(''),
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private consultantService: ConsultantCompanyService,
              private notificationService: NotificationService,
              private accountService: AccountService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.selection = new SelectionModel<ConsultantCompany>(!!this.consultantList, []);
    this.hasEditPermission = this.authService.hasEditPermission;
    this.displayedColumns = this.isSelectMode ? ['select', 'name', 'code'] : this.displayedColumns;

    const consultantList$ = this.consultantList || (this.isTransactionDialog && !!this.accountId
      ? this.accountService.getConsultantCompanies(String(this.accountId))
      : this.consultantService.getConsultantCompanyList());

    consultantList$.subscribe(
      (data) => {
        this.dataSource.data = sortListByLastEdit(data);
        this.isLoadingResults = false;

        if (this.selectedId) {
          this.initSelected(this.selectedId);
        }
      },
      () => this.notificationService.error('Error occurred while trying to get consultant info.')
    );

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = customSortingDataAccessor(SortingDataTypes.Consultant);

    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();
  }

  initSelected(selected: number[]) {
    if (this.isLoadingResults) {
      this.selectedId = selected;
      return;
    }

    if (this.selectedId.length > 0) {
      const rows = this.dataSource.data.filter((item: ConsultantCompany) => {
        return selected.includes(item.id);
      });
      rows.forEach(row => this.selection.toggle(row as ConsultantCompany));
    }
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get states() {
    return [...new Set(this.dataSource.data.filter(item => !!item.address?.state).map(item => item.address.state?.name))];
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);
      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const addressSearch = FilterUtils.searchFunction(searchTerms.address, data.address?.addressLine1);
      const citySearch = FilterUtils.searchFunction(searchTerms.city, data.address?.city);
      const stateSearch = FilterUtils.createSelectSearch(searchTerms.state, data.address?.state?.name);
      const zipCodeSearch = FilterUtils.searchFunction(searchTerms.zipCode, data.address?.zipCode);

      const filterFunctions = [
        nameSearch(),
        codeSearch(),
        addressSearch(),
        citySearch(),
        stateSearch(),
        zipCodeSearch(),
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  onEditClicked(consultantId: number) {
    this.router.navigate(['/entities/consultants/edit', consultantId]);
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
