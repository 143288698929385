import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountCheckReport,
  AccountCheckReportEntry,
  AccountCheckReportEntryType
} from '../shared/models/report-type';

@Component({
  selector: 'app-account-check-report-table',
  templateUrl: './account-check-report-table.component.html',
  styleUrls: ['./account-check-report-table.component.scss']
})
export class AccountCheckReportTableComponent implements OnInit {
  @Input()  accountCheckReport: AccountCheckReport;

  transactionTypes = new Map([
    [AccountCheckReportEntryType.ACCEPT_PAYMENT, 'Accept Payment'],
    [AccountCheckReportEntryType.ACCOUNT_INVOICE, 'Account Invoice'],
    [AccountCheckReportEntryType.EXPENSE, 'Expense'],
    [AccountCheckReportEntryType.PAYMENT, 'Payment'],
    [AccountCheckReportEntryType.REFUND, 'Refund'],
    [AccountCheckReportEntryType.TOTAL, ' '],
  ]);

  displayedColumns: string[] = [
    'title', 'transactionType', 'transactionDate', 'transactionId', 'quickBookReference', 'grossAmount', 'adjustedGrossAmount',
    'netAmount', 'expenseAmount', 'receivedAmount', 'balanceAmount', 'outstandingAccountInvoiceAmount'
  ];
  dataSource = new MatTableDataSource<AccountCheckReportEntry>();
  AccountCheckReportEntryType = AccountCheckReportEntryType;

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.accountCheckReport.entries;
  }

  getTitle(element: AccountCheckReportEntry): string {
    return element.title ? element.title : ' ';
  }

  getTransactionDate(element: AccountCheckReportEntry): string {
    return element.transactionDate ? element.transactionDate :  ' ';
  }

  getTransactionLink(element: AccountCheckReportEntry) {
    switch (element.type) {
      case AccountCheckReportEntryType.ACCEPT_PAYMENT:
        const accountId = this.accountCheckReport.filter.accountId;
        return `/orders/accept-payment/detail/${accountId}-${element.transactionTypeId}`;
      case AccountCheckReportEntryType.ACCOUNT_INVOICE:
        return `/orders/cinvoices/detail/${element.transactionTypeId}`;
      case AccountCheckReportEntryType.EXPENSE:
        return `/orders/expenses/edit/${element.transactionTypeId}`;
      case AccountCheckReportEntryType.PAYMENT:
        return `/orders/payments/edit/${element.transactionTypeId}`;
      case AccountCheckReportEntryType.REFUND:
        return `/orders/memo-refund/edit/${element.transactionTypeId}`;

    }
  }
}
