import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

export class CardParts {
  constructor(
    public first: string,
    public second: string,
    public third: string,
    public fourth: string
  ) {}
}

@Component({
  selector: 'app-credit-card-input',
  templateUrl: './credit-card-input.component.html',
  styleUrls: ['./credit-card-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CreditCardInputComponent }]
})
export class CreditCardInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  static nextId = 0;
  @HostBinding('class.floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding('id') id = `card-input-${CreditCardInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @ViewChild('first') firstInput: HTMLInputElement;
  @ViewChild('second') secondInput: HTMLInputElement;
  @ViewChild('third') thirdInput: HTMLInputElement;
  @ViewChild('fourth') fourthInput: HTMLInputElement;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'card-input';
  private cardPlaceholder: string;
  private cardRequired = false;
  private cardDisabled = false;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { first, second, third, fourth }
    } = this.parts;

    return !first && !second && !third && !fourth;
  }

  @Input()
  get placeholder(): string {
    return this.cardPlaceholder;
  }
  set placeholder(value: string) {
    this.cardPlaceholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.cardRequired;
  }
  set required(value: boolean) {
    this.cardRequired = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.cardDisabled;
  }
  set disabled(value: boolean) {
    this.cardDisabled = coerceBooleanProperty(value);
    this.cardDisabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    if (this.parts.valid) {
      const {
        value: { first, second, third, fourth }
      } = this.parts;

      return `${first}${second}${third}${fourth}`;
    }
    return null;
  }
  set value(value: string) {
  }

  constructor(
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      first: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      second: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      third: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      fourth: [
        null,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ]
    });

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && !!nextElement) {
      this.focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this.focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (this.parts.controls.fourth.valid) {
      this.focusMonitor.focusVia(this.fourthInput, 'program');
    } else if (this.parts.controls.third.valid) {
      this.focusMonitor.focusVia(this.fourthInput, 'program');
    } else if (this.parts.controls.second.valid) {
      this.focusMonitor.focusVia(this.thirdInput, 'program');
    } else if (this.parts.controls.first.valid) {
      this.focusMonitor.focusVia(this.secondInput, 'program');
    } else {
      this.focusMonitor.focusVia(this.firstInput, 'program');
    }
  }

  writeValue(value: string | null): void {
    if (value) {
      this.parts.setValue({
        first: value.substr(0, 4),
        second: value.substr(4, 4),
        third: value.substr(8, 4),
        fourth: value.substr(12, 4)
      });
    } else {
      this.parts.setValue(new CardParts('', '', '', ''));
    }

    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
