import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { Commissionable, CommissionableTransactionStatus, CommissionableTransactionType } from '../shared/models';

@Component({
  selector: 'app-commissionable-transaction-list',
  templateUrl: './commissionable-transaction-list.component.html',
  styleUrls: ['./commissionable-transaction-list.component.scss']
})
export class CommissionableTransactionListComponent implements OnInit {
  @Input() accountId: string = null;
  @Input() consultantCompanyId: string = null;
  dataSource = new MatTableDataSource<Commissionable>();
  displayedColumns: string[] =
    ['select', 'code', 'type', 'status', 'createdOn', 'grossPlaced', 'paidGrossPlaced', 'notPaidGrossPlaced', 'menu'];
  selection = new SelectionModel<Commissionable>(false, []);
  isLoadingResults = true;
  selectedId: number;
  isSelectMode = true;

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    type: new FormControl(''),
    status: new FormControl(''),
    createdOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    grossPlaced: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    paidGrossPlaced: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    notPaidGrossPlaced: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    })
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
  }

  ngOnInit(): void {
/*    this.service.getCommissions(this.consultantCompanyId).subscribe(data => {
      this.dataSource.data = data;

      this.isLoadingResults = false;
      if (this.selectedId) {
        this.initSelected(this.selectedId);
      }
    });*/

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;

    this.tableFilterForm.valueChanges.subscribe(value => {
      value.createdOn.value = value.createdOn?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'createdOn':
        return new Date(item.createdOn);
      default:
        return item[property];
    }
  }

  get commissionableTransactionTypes() {
    const keys = Object.keys(CommissionableTransactionType).map(k => CommissionableTransactionType[k as any]);
    return keys.map(k => CommissionableTransactionType[k as any]);
  }

  get commissionableTransactionStatuses() {
    const keys = Object.keys(CommissionableTransactionStatus).map(k => CommissionableTransactionStatus[k as any]);
    return keys.map(k => CommissionableTransactionStatus[k as any]);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: Commissionable) => item.id === id);
    if (row) {
      this.selection.toggle(row as Commissionable);
    }
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const typeSearch = FilterUtils.createSelectSearch(searchTerms.type, data.type);
      const statusSearch = FilterUtils.createSelectSearch(searchTerms.status, data.status);
      const createdOnSearch = FilterUtils.createDateSearch('createdOn', 'createdOn', searchTerms, data);

      const grossSearch = FilterUtils.createFieldDigitSearch('grossPlaced', searchTerms, data.gross);
      const paidGrossPlacedSearch = FilterUtils.createFieldDigitSearch('paidGrossPlaced', searchTerms, data.paidGrossPlaced);
      const notPaidGrossPlacedSearch =
        FilterUtils.createFieldDigitSearch('notPaidGrossPlaced', searchTerms, data.paidGrossPlaced + data.gross);

      const filterFunctions = [
        codeSearch(),
        typeSearch(),
        statusSearch(),
        createdOnSearch(),
        grossSearch(),
        paidGrossPlacedSearch(),
        notPaidGrossPlacedSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
