import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

import { FormMode } from '../../shared/models/form-mode.enum';
import { AccountLight, ElectionType, Note, Order, OrderFlight, StationLight } from '../shared/models';
import { TypeaheadItem } from '../../shared/models/typeahead-item';
import { Role } from '../../shared/models/role.enum';
import { AuthService } from '../../shared/auth.service';
import { OrderService } from '../shared/order.service';
import { OrderDictionaryService } from '../shared/order-dictionary.service';
import { NotificationService } from '../../shared/notification.service';
import { UserService } from '../../shared/user.service';
import { ComposeRouteUtils } from '../shared/utils/route-util';
import { FormUtils } from '../../shared/utils/form-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { FlightUtil } from '../shared/utils/flight-util';
import { NoteUtil } from '../shared/utils/note-util';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { BrowseOrderDialogComponent } from '../browse-order-dialog/browse-order-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogResult } from '../../shared/models/confirmation-dialog-result';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  ids: Observable<string | undefined>;
  order: Order = {} as any;
  orderForm = this.fb.group({
    id: [{value: null, disabled: true}],
    code: [null],
    account: [null, Validators.required],
    disabled: [false],
    electionType: [null],
    electionDate: [null],
    noteToAdd: [null],
    flights: this.fb.array([])
  });
  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  savingAndCreateCheck = false;
  submitting = false;
  disabling = false;
  openedDialog = false;

  electionTypes: ElectionType[];
  accounts: AccountLight[];
  filteredAccounts: Observable<AccountLight[]>;
  stations: StationLight[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private service: OrderService,
              private dictionary: OrderDictionaryService,
              private userService: UserService,
              private dialog: MatDialog,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.ids = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.mode = params.get('id') ? FormMode.EDIT : FormMode.CREATE;
        return of(params.get('id')) || of(null);
      })
    );

    this.subscription.add(this.ids.subscribe(this.loadData,
      () => this.notificationService.error('Error occurred while trying to get order info.')));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get isRequesting() {
    return this.saving || this.submitting || this.savingAndCreateCheck;
  }

  get flights(): FormArray {
    return this.orderForm.get('flights') as FormArray;
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.order.notes);
  }

  get marketsNumber(): number {
    return new Set(this.flights.controls
      .map((control) => control.value.flightStations)
      .flat()
      .map(flightStation => flightStation.station)
      .flat()
      .filter(station => !!station?.mediaMarkets)
      .map(station => station?.mediaMarkets)
      .flat()
      .map(market => market?.id)).size;
  }

  get stationsNumber(): number {
    return new Set(this.flights.controls
      .map((control) => control.value.flightStations)
      .flat()
      .map(flightStation => flightStation.station)
      .filter(station => !!station?.id)
      .flat()
      .map(station => station.id))
      .size;
  }

  get netTotal(): number {
    return this.flights.controls
      .map((control) => control.value.flightStations)
      .flat()
      .map(flightStation => flightStation.netAmount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  loadData = (ids: string) => {
    const accountId = ComposeRouteUtils.separateAccountId(ids);
    const orderId = ComposeRouteUtils.separateEntityId(ids);

    const types$ = this.dictionary.getElectionTypes();
    const accounts$ = (this.mode === FormMode.EDIT) ? of(null) : this.service.getAccountList(); // account is readonly for edit
    const stations$ = this.service.getStationsList();
    const order$ = (this.mode === FormMode.EDIT) ? this.service.getOrder(accountId, orderId) : of(null);

    forkJoin([types$, accounts$, order$, stations$])
      .pipe(first())
      .subscribe(([types, accounts, order, stations]) => {
        this.accounts = accounts ? accounts.map(item => this.convertToAccountLight(item)) : [];
        this.electionTypes = types;
        this.stations = stations.map(item => ({id: +item.id, name: item.text}));
        if (order) {
          this.order = order;
          this.initOrderForm(order);
        } else {
          this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.orderForm, this.accounts);
        }

        if (this.isCreate) {
          this.addFlight();
        }
        this.loading = false;
      });
  }

  get isCreate() {
    return this.mode === FormMode.CREATE;
  }

  updateStations() {
    this.service.getStationsList().subscribe(stations => {
      this.stations = stations.map(item => ({id: +item.id, name: item.text}));
    });
  }

  initOrderForm(order: Order) {
    const {flights} = order;
    delete order.flights;
    this.orderForm.patchValue(order);
    this.orderForm.get('electionType')
      .setValue(this.electionTypes.filter(electionType => electionType.id === order.electionType?.id)[0]);
    this.orderForm.get('electionDate').setValue(order.electionDate ? new Date(order.electionDate) : null);
    this.initFlights(flights);
    if (order.disabled) {
      this.orderForm.disable();
    }
  }

  initFlights(flights: OrderFlight[]) {
    flights = flights.sort(FlightUtil.flightSortNumberCompareFn);
    flights.forEach((flight: OrderFlight) => {
      const flightGroup = this.fb.group({...flight});
      delete flightGroup.controls.flightStations;

      const stationArray = this.fb.array([]);
      flight.flightStations.forEach(station => {
        stationArray.push(this.fb.group({...station}));
      });
      flightGroup.addControl('flightStations', stationArray);

      this.flights.push(flightGroup);
    });
  }

  convertToAccountLight = (typeaheadItem: TypeaheadItem) => {
    return {
      id: Number(typeaheadItem.id),
      code: typeaheadItem.data,
      name: typeaheadItem.text
    } as AccountLight;
  }

  saveAndSubmit(isCreateCheck?: boolean) {
    this.submitting = true;
    this.save(this.orderForm, true, isCreateCheck);
  }

  save(orderForm: FormGroup, submitted?: boolean, isCreateCheck?: boolean): void {
    const order = this.prepareOrder(orderForm);

    if (submitted) {
      order.submitted = true;
    }
    const request = this.mode === FormMode.EDIT
      ? this.service.updateOrder(String(order.account.id), this.orderForm.get('id').value, order).pipe(
        tap(() => this.notificationService.success('Order was updated!'))
      )
      : this.service.createOrder(String(order.account.id), FormUtils.filterEmptyFields(order)).pipe(
        tap(() => this.notificationService.success('New order created!'))
      );

    request.pipe(finalize(() => this.resetRequesting())).subscribe(
      () => isCreateCheck ? this.createCheckRequest() : this.returnClicked(),
      (e) => this.actionErrorHandler(e, this.mode),
    );
  }

  private prepareOrder(orderForm): Order {
    const order = {
      ...this.order,
      ...orderForm.value
    } as Order;
    NoteUtil.processNotes(order, orderForm);
    order.flights = this.flights.value;
    order.electionDate = DateUtils.dateFormatToShort(orderForm.get('electionDate').value);
    order.flights.sort(FlightUtil.flightCompareFn);
    order.flights.forEach((flight, index) => flight.sortNumber = index);
    return order;
  }

  resetRequesting() {
    this.saving = false;
    this.savingAndCreateCheck = false;
    this.submitting = false;
  }

  displayAccount(account?: AccountLight): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/orders');
  }

  addFlight(flightStations?: FormArray) {
    this.flights.push(this.fb.group({
      id: [''],
      estimateNumber: [''],
      ftcOn: ['', Validators.required],
      ltcOn: ['', Validators.required],
      flightStations: flightStations ?? this.fb.array([]),
      sortNumber: null,
      couldBeDeleted: true
    }));
  }

  duplicateFlight(index: number) {
    const duplicateStations: FormGroup[] = (this.flights.at(index).get('flightStations') as FormArray)
      .controls
      .map(station => {
        return this.fb.group({
          station: station.get('station'),
          netAmount: ['']
        });
      });
    this.addFlight(this.fb.array(duplicateStations));
  }

  deleteFlight(index: number) {
    this.flights.controls.splice(index, 1);
    this.flights.updateValueAndValidity();
  }

  reuseOldFlights() {
    const dialogRef = this.dialog.open(BrowseOrderDialogComponent,
      {
        data: {
          listMethod: this.service.getAccountOrders(this.orderForm.value.account?.id),
          columns: ['select', 'code', 'versionLabel', 'status', 'earliestFlightStartOn', 'latestFlightFinishOn']
        }
      }
    );

    dialogRef.afterClosed().subscribe((order: Order) => {
      if (order && order.flights) {
        order.flights.forEach(flight => {
          flight.flightStations = flight.flightStations.map(item => ({...item, couldBeDeleted: true}));
        });
        const flights = order.flights.map(item => ({...item, id: null, couldBeDeleted: true}));
        this.initFlights(flights);
      }
    });
  }

  setFlightsToZero() {
    this.flights.controls.forEach(flight => {
      const stations = flight.get('flightStations') as FormArray;
      stations.controls.forEach(station => station.patchValue({netAmount: 0}));
    });
  }

  onBlurAccount(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccount(this.orderForm, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.orderForm, option);
  }

  disableOrder() {
    if (NoteUtil.getNoteToAddMessage(this.orderForm)?.length < 1) {
      this.showNoteRequiredDialog();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {
          message: `Are you sure you want to disable this order?`
        }});
    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result?.value) {
        this.disabling = true;
        const order = this.prepareOrder(this.orderForm);

        this.service.updateOrder(String(order.account.id), this.orderForm.get('id').value, order)
          .subscribe((updatedOrder) => {
            this.service.disableOrder(updatedOrder.account.id, updatedOrder.id)
              .pipe(finalize(() => this.disabling = false))
              .subscribe(
                () => {
                  this.notificationService.success('Order disabled!');
                  this.returnClicked();
                },
                () => this.notificationService.success('Order disabled!')
              );
          });
      }});
  }

  createCheckRequest() {
    this.router.navigate(['/orders/checks/edit/', ''],
      { queryParams: {orderId: this.order.id, accountId: this.order.account.id} });
  }

  get disabled() {
    return this.order.disabled ?? false;
  }

  get isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  get canDisableOrder() {
    return this.order && this.order.submitted === false;
  }

  get canEditAccount() {
    return !this.order;
  }

  get canEditFlights() {
    return !this.order || !this.order.submitted;
  }

  get canDeleteFlights() {
    return !this.order || !this.order.submitted;
  }

  get isAllowSaveAndSubmit() {
    return this.order && !this.order.submitted;
  }

  get canCreateCheckRequest() {
    const permissions = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
    return permissions && this.order && this.order.submitted;
  }

  get isAllowCreateCheckRequest() {
    return  this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.orderForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} order.`);
  }

  private showNoteRequiredDialog() {
    this.dialog.open(ConfirmationDialogComponent,
      {data: {message: 'Please, fill the note field before disabling order.', cancelDisabled: true}});
  }

  openDialog() {
    const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
      {data: {selected: this.orderForm.value.account?.id}}
    );

    dialogRef.afterClosed().subscribe((result: AccountLight) => {
      if (result) {
        this.orderForm.patchValue({account: result});
      }
    });
  }
}
