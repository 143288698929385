import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.keycloakAngular.isTokenExpired()) {
      this.keycloakAngular.login()
        .catch(e => console.error(e));
    }

    const requiredRoles: string[] = route.data.roles;
    const roles = this.keycloakAngular.getUserRoles();
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (!roles || roles.length === 0) {
        this.router.navigate(['access-denied']);
        return false;
      }

      const isAllow = !!requiredRoles.find(r => roles.includes(r));
      if (!isAllow) {
        this.router.navigate(['access-denied']);
      }
      return isAllow;
    }
  }

}
