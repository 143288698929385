<div class="content" role="main">

  <div class="header">
    <h1>Expenses</h1>
    <div class="header-action" *ngIf="!isSelectMode && hasEditPermission">
      <button mat-mini-fab color="primary" aria-label="New Expense"
        [routerLink]="['/orders/expenses/edit', '']"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="dashboard-container" *ngIf="!isSelectMode">
    <div class="table-range-filter">
      <mat-form-field>
        <mat-label>Filter Table by Date</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matInput matStartDate formControlName="start" placeholder="Start date">
          <input matInput matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <div class="container" [ngClass]="{'mat-elevation-z8': !isSelectMode}">
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="tableFilterForm">
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="select-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-filter">
              <div class="form-field"></div>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="code" sticky>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Expense ID</th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="['/orders/expenses/edit', row.id]"
               *ngIf="!isSelectMode">
              {{row.code}}
            </a>
            <span *ngIf="isSelectMode">{{row.code}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="code-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="code">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account ID</th>
          <td mat-cell *matCellDef="let row">{{row.account?.code || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="accountId-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="accountId">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Name</th>
          <td mat-cell *matCellDef="let row">{{row.account?.name || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="accountName-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="accountName">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment amount</th>
          <td mat-cell *matCellDef="let row">{{(row.amount | currency) || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="amount-filter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" formGroupName="amount">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput
                       type="number"
                       class="form-field"
                       formControlName="value">
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="quickBookReference">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>QBref#</th>
          <td mat-cell *matCellDef="let row">{{row.quickBookReference || '-'}}</td>
        </ng-container>

        <ng-container matColumnDef="quickBookReference-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="quickBookReference">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="whoDidGoTo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Who did this payment go to?</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.whoDidGoTo || 'N/A'}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="whoDidGoTo-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="whoDidGoTo">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="station">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Station</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.station?.name || 'N/A'}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="station-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="station">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="completed">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
              {{ row.completed ? 'Paid' : 'Draft' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="completed-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="completed">
                <mat-option>-- None --</mat-option>
                <mat-option [value]="'true'">Paid</mat-option>
                <mat-option [value]="'false'">Draft</mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment Sent Date</th>
          <td mat-cell *matCellDef="let row">{{(row.date | date:'shortDate') || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="date-filter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" formGroupName="date">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput [matDatepicker]="pickerCreated" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
                <mat-datepicker #pickerCreated></mat-datepicker>
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let row">{{(row.createdAt | date:'short') || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt-filter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" formGroupName="createdAt">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput [matDatepicker]="pickerCreated" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
                <mat-datepicker #pickerCreated></mat-datepicker>
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Creator</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.createdBy || 'N/A'}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="createdBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="createdBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Edit</th>
          <td mat-cell *matCellDef="let row">{{(row.lastUpdatedAt | date:'short') || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedAt-filter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" formGroupName="lastUpdatedAt">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput [matDatepicker]="pickerCreated" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
                <mat-datepicker #pickerCreated></mat-datepicker>
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Edited By</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.lastUpdatedBy || 'N/A'}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="lastUpdatedBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="paymentMethod">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Transaction Type</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.paymentMethod?.name || '-'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentMethod-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="paymentMethod">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="assignedAccountant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assigned accountant</th>
          <td mat-cell *matCellDef="let row">
            <span>{{getAssignedAccountant(row) || 'N/A'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="assignedAccountant-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="assignedAccountant">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="menu" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="button pull-right"
                    [matMenuTriggerFor]="appMenu"
                    [matMenuTriggerData]="{ id: row.id }">
              <span class="material-icons">
                more_vert
              </span>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="menu-filter" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <button type="button" mat-icon-button class="button pull-right" (click)="clearFilterForm()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                <path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/>
              </svg>
            </button>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columnFilters; sticky: true" class="filter-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-container *ngIf="!dataSource.filteredData.length">
        <p class="no-data">No data</p>
      </ng-container>
    </div>

    <mat-menu #appMenu>
      <ng-template matMenuContent let-id="id">
        <button mat-menu-item (click)="showVersions(id)">Version history</button>
      </ng-template>
    </mat-menu>

    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
