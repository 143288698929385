import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Account } from 'src/app/entities/shared/models';
import { TransactionService } from '../shared/transaction.service';
import { ConsultantCompanyCommissionService } from '../shared/consultant-company-commission.service';
import { AccountListType } from '../shared/models';
import { AccountInvoiceService } from '../shared/account-invoice.service';

import { AccountListComponent } from '../../entities/account-list/account-list.component';

@Component({
  selector: 'app-browse-account-dialog',
  templateUrl: './browse-account-dialog.component.html',
  styleUrls: ['./browse-account-dialog.component.scss']
})
export class BrowseAccountDialogComponent implements OnInit {
  @ViewChild(AccountListComponent, {static: true}) accountList: AccountListComponent;
  selectedAccount: Account;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number, type: AccountListType, id: number},
              private invoiceService: AccountInvoiceService,
              private transactionService: TransactionService,
              private consultantCompanyCommissionService: ConsultantCompanyCommissionService) {}

  ngOnInit(): void {
    if (!this.accountList) {
      return;
    }

    this.accountList.selection.changed.subscribe(account => {
      this.selectedAccount = account.source.selected[0];
    });

    if (this.data.selected) {
      this.accountList.initSelected(this.data.selected);
    }
  }

  get listMethod() {
    const type = this.data.type || AccountListType.ACCOUNT;
    switch (type) {
      case AccountListType.ACCOUNT:
        return null;
      case AccountListType.INVOICE:
        return this.invoiceService.getAccountForInvoice();
      case AccountListType.CONSULTANT_COMPANY:
        return this.data.id ? this.consultantCompanyCommissionService.getAccountList(String(this.data.id)) : null;
      case AccountListType.STATION_TRANSACTION:
        return this.data.id ? this.transactionService.getAccountList(this.data.id) : null;
      default:
        return null;
    }
  }
}
