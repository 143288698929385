<div class="flight-container" [ngClass]="{'bg-white': index % 2}">
  <div class="table-title">
    <h2>{{station?.name}}</h2>
    <div>{{station?.mediaType.type}}, {{station?.parentCompany?.name}}</div>
    <div *ngIf="!stationPaymentMethod" class="no-payment">
      <mat-icon>priority_high</mat-icon>NO PAYMENT METHOD LISTED FOR STATION
    </div>
    <div *ngIf="!readonly && payment.editable" class="payment">
      PAY IN FULL:&nbsp;
      <mat-form-field>
        <mat-select class="form-field" [formControl]="payInFullAll">
          <mat-option [value]="false">NO</mat-option>
          <mat-option [value]="true">YES</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <h3 *ngIf="showBalance">STATION BALANCE: {{payment.currentStationBalance | negativeCurrency}}</h3>

  <table mat-table [dataSource]="dataSource" *ngIf="payment.stationFlights?.length">
    <ng-container matColumnDef="flight">
      <th mat-header-cell *matHeaderCellDef>Flight Dates</th>
      <td mat-cell *matCellDef="let row">{{row.flight?.ftcOn}}-{{row.flight?.ltcOn}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
      <td mat-cell *matCellDef="let row">{{row.flight?.order?.createdAt ? (row.flight?.order?.createdAt | date:'MM/dd/yyyy') : 'N/A'}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Transaction type</th>
      <td mat-cell *matCellDef="let row">Flight</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
      <td mat-cell *matCellDef="let row"> # {{row.flight?.order?.code}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="grossPlaced">
      <th mat-header-cell *matHeaderCellDef>Gross Ordered</th>
      <td mat-cell *matCellDef="let row">
        {{row.grossAmount | negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>
        {{getTotal('grossAmount') === 0 ? '$ -' : (getTotal('grossAmount') | negativeCurrency)}} </td>
    </ng-container>

    <ng-container matColumnDef="netOrdered">
      <th mat-header-cell *matHeaderCellDef>Net Ordered</th>
      <td mat-cell *matCellDef="let row">
        {{row.netAmount | negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>
        {{getTotal('netAmount') === 0 ? '$ -' : (getTotal('netAmount') | negativeCurrency)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentOptions">
      <th mat-header-cell *matHeaderCellDef>Pay for this flight</th>
      <td mat-cell *matCellDef="let row; let index = index">
        <span *ngIf="readonly || !payment.editable">{{row.includedInPayment ? 'YES' : 'NO'}}</span>
        <mat-form-field *ngIf="!readonly && payment.editable">
          <mat-select class="form-field" [formControl]="getControl(index)" (selectionChange)="updatePaymentOption(index)">
            <mat-option [value]="false">NO</mat-option>
            <mat-option [value]="true">YES</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{totalPaid === 0 ? '$ -' : (totalPaid | negativeCurrency)}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" [ngClass]="{'odd-findex-bgr': i % 2}"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <div class="summary">
    <div class="summary-row">
      <div class="summary-row-title pay">PAY: </div>
      <div>
        <span *ngIf="readonly || !payment.editable">{{payment.payAmount | negativeCurrency}}</span>
        <mat-form-field *ngIf="!readonly && payment.editable">
          <input type="number"
                 matInput
                 [formControl]="payAmount"
                 [readonly]="readonly"
                 maxLength="17"
                 oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="summary-row" *ngIf="showBalance">
      <div class="summary-row-title">BALANCE OWED: </div>
      <div>{{balanceOwned | negativeCurrency}}</div>
    </div>
  </div>
</div>

