import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';

import { ParentCompanyService } from '../shared/parent-company.service';
import { ParentCompany, MediaType } from '../shared/models';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { customSortingDataAccessor, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-parent-company-list',
  templateUrl: './parent-company-list.component.html',
  styleUrls: ['./parent-company-list.component.scss']
})
export class ParentCompanyListComponent implements OnInit {
  @Input() isSelectMode: boolean;

  dataSource = new MatTableDataSource<ParentCompany>();
  displayedColumns: string[] = ['name', 'mediaTypes', 'menu'];
  selection = new SelectionModel<ParentCompany>(false, []);
  isLoadingResults = true;
  selectedId: number;
  hasEditPermission = false;

  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    mediaTypes: new FormControl('')
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: ParentCompanyService, private authService: AuthService) { }

  ngOnInit(): void {
    this.hasEditPermission = this.authService.hasEditPermission;
    this.displayedColumns = this.isSelectMode ? ['select', 'name', 'mediaTypes', 'menu'] : this.displayedColumns;
    this.service.getParentCompanyList().subscribe(data => {
      this.dataSource.data = sortListByLastEdit(data);

      this.isLoadingResults = false;
      if (this.selectedId) {
        this.initSelected(this.selectedId);
      }
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();

    this.dataSource.sortingDataAccessor = customSortingDataAccessor();
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get mediaTypes() {
    return [...new Set(this.dataSource.data.map(item =>
      item.mediaTypes?.map(mediaType => mediaType.type)
    ).flat().filter(item => item))];
  }

  getMediaTypes(mediaTypes: MediaType[]) {
    return mediaTypes ?
    mediaTypes.map(mediaType => mediaType.type).join(', ')
    :
    'N/A';
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: ParentCompany) => item.id === id);
    if (row) {
      this.selection.toggle(row as ParentCompany);
    }
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);

      const mediaTypeSearch = FilterUtils.createMediaTypeSearch(searchTerms.mediaTypes, data.mediaTypes);

      return nameSearch() && mediaTypeSearch();
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
