import { FormArray, FormBuilder } from '@angular/forms';
import { Commissionable, CommissionTier } from '../models';

export class CommissionUtils {

  static generateBreakPoint(fb: FormBuilder, value: string, consultantsAmount: number, nextBreakPoint?: any) {
    const canal = nextBreakPoint ? nextBreakPoint.canal : 2;
    const roll = nextBreakPoint ? nextBreakPoint.roll : (13 - consultantsAmount);
    const breakPoint = fb.group({
      breakPoint: value,
      consultantRates: fb.array([]),
      canal: [canal],
      roll: [roll],
      sortNumber: [0]
    });
    for (let i = 0; i < consultantsAmount; i++) {
      const consultantRate = nextBreakPoint ? nextBreakPoint.consultantRates[i] : 1;
      (breakPoint.get('consultantRates') as FormArray).push(fb.control(consultantRate));
    }
    return breakPoint;
  }

  static fillTiersArray(fb: FormBuilder, commissionTiers: CommissionTier[], commissionArray: FormArray) {
    // array of consultantCompanies for each breakPoint
    commissionTiers.forEach((commissionTier) => {
      const consultantsFormArray = fb.array([]);
      commissionTier.consultantRates.forEach((consultantRate) => {
        consultantsFormArray.push(fb.control(consultantRate.rate));
      });

      // breakPoint to tiers array
      commissionArray.push(fb.group({
        breakPoint: commissionTier.breakPoint,
        consultantRates: consultantsFormArray,
        canal: commissionTier.cpmRate,
        roll: commissionTier.rollRate,
        sortNumber: commissionTier.sortNumber
      }));
    });
  }

  static getRateHeaderName(index, controls) {
    if (controls.length === 1) {
      return 'Default Commission Rates';
    }
    const predValue = index === 0 ? '0' : controls[index - 1].get('breakPoint').value;
    const nextValue = index === (controls.length - 1) ? '∞' : controls[index].get('breakPoint').value;
    return `C${index + 1} Rates from $${predValue} - $${nextValue}`;
  }

  static resortCommissionTiers(commissionTiersArray: FormArray) {
    // get tier with 'null' breakPoint
    const lastBreakPointControl = commissionTiersArray.controls
      .find((formGroup) => !formGroup.get('breakPoint').value);

    // get tiers with not 'null' breakPoints
    const firstsBreakPoints = commissionTiersArray.controls
      .filter((formGroup) => formGroup.get('breakPoint').value);

    // sort firsts tiers
    commissionTiersArray.controls = firstsBreakPoints
      .sort((formGroup1, formGroup2) => {
        return formGroup1.get('breakPoint').value - formGroup2.get('breakPoint').value;
      });

    // add the last tier
    commissionTiersArray.controls.push(lastBreakPointControl);

    // set sortNumbers to tier array
    commissionTiersArray.controls.forEach((control, i) => {
      control.get('sortNumber').setValue(i);
    });
  }

  static getNextBreakPoint(value: string, commissionTiersArray: FormArray) {
    const breakPoints = commissionTiersArray.controls.map((formGroup) => formGroup.get('breakPoint').value);
    for (let i = 0; i < breakPoints.length - 1; i++) {
      if (breakPoints[i] > value) {
        return commissionTiersArray.at(i);
      }
    }
    return commissionTiersArray.at(breakPoints.length - 1);
  }

  static getEmptyCommissionable = (consultantSplit: CommissionTier) => {
    return ({
      id: null,
      code: null,
      ftc: null,
      ltc: null,
      grossPaymentTotal: null,
      netPaymentTotal: null,
      consultantSplit: [
        consultantSplit
      ],
      quickBookReference: null,
      expenseId: null,
      orderId: null,
    } as Commissionable);
  }
}
