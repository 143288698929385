import { QuickLink } from './quick-link.enum';
import { accountInvoiceStatuses, checkRequestStatuses, orderStatuses, paymentBatchStatuses } from '../../../orders/shared/models';
import { BalanceTransactionType } from '../../../orders/shared/utils/balance-transaction-type';

export interface DashboardQuickLinkInfo {
  quickLink: QuickLink;
  quantity: number;
  quantityCountedAt: string;
  stillCounting: boolean;
}

export const dashboardItems = new Map([
  [QuickLink.DECLINED_CHECKREQUESTS_LINK, {name: 'Declined Check Requests', showValue: true, url: '/orders/checks',
    queryParams: {status: checkRequestStatuses.RETURNED_TO_BUYER.status}}],
  [QuickLink.UNDER_REVIEW_CHECKREQUESTS_LINK, {name: 'Considered Check Requests', showValue: true, url: '/orders/checks',
    queryParams: {status: [checkRequestStatuses.STARTED.status, checkRequestStatuses.SUBMITTED.status].join(',')}}],
  [QuickLink.OUTSTANDING_ACCOUNT_INVOICES_LINK, {name: 'Outstanding Account Invoices', showValue: true, url: '/orders/cinvoices',
    queryParams: {status: accountInvoiceStatuses.SUBMITTED.status}}],
  [QuickLink.SUBMITTED_ORDERS_LINK, {name: 'Submitted Orders', showValue: true, url: '/orders/orders',
    queryParams: {status: orderStatuses.SUBMITTED.status}}],
  [QuickLink.OUTSTANDING_PAYMENT_BATCHES_LINK, {name: 'Outstanding Payment Batches', showValue: true, url: '/orders/payments',
    queryParams: {status: [paymentBatchStatuses.PROCESSING_1.status, paymentBatchStatuses.PROCESSING_2.status].join(',')}}],
  [QuickLink.GLOBAL_ACCOUNT_VIEW_ORDER_LINK, {name: 'Global Account View (Order)', showValue: false, url: '/entities/accounts',
    queryParams: {runningOrderBalance: true}}],
  [QuickLink.GLOBAL_ACCOUNT_VIEW_INVOICE_LINK, {name: 'Global Account View (Invoice)', showValue: false, url: '/entities/accounts',
    queryParams: {runningInvoiceBalance: true}}],
  [QuickLink.GLOBAL_STATION_VIEW_ORDER_LINK, {name: 'Global Station View (Order)', showValue: false, url: '/entities/stations',
    queryParams: {runningOrderBalance: true}}],
  [QuickLink.GLOBAL_STATION_VIEW_INVOICE_LINK, {name: 'Global Station View (Invoice)', showValue: false, url: '/entities/stations',
    queryParams: {runningInvoiceBalance: true}}],
  [QuickLink.UNBALANCED_CONSULTANT_COMMISSION_LINK, {name: 'Unbalanced Consultant Commission', showValue: true, url: '/orders/commissions',
    queryParams: {type: 'COMMISSION_OWED'}}],
  [QuickLink.MISSING_QB_REF_PAYMENT_BATCHES_LINK, {name: 'Payment Batches', showValue: true, url: '/orders/payments',
    queryParams: {status: paymentBatchStatuses.PAID.status, quickBooks: 'missing'}}],
  [QuickLink.MISSING_QB_REF_ACCEPT_PAYMENTS_LINK, {name: 'Accept Payments', showValue: true, url: '/orders/accept-payment',
    queryParams: {quickBooks: '-'}}],
  [QuickLink.MISSING_QB_REF_REFUNDS_LINK, {name: 'Refunds', showValue: true, url: '/orders/memo-refund',
    queryParams: {quickBooks: '-', transactionType: BalanceTransactionType.REFUND.type}}],
  [QuickLink.MISSING_QB_REF_COMMISSIONS_LINK, {name: 'Commissions', showValue: true, url: '/orders/commissions',
    queryParams: {type: 'COMMISSION_PAID', quickBooks: '-', status: 'Paid'}}],
  [QuickLink.MISSING_QB_REF_EXPENSES_LINK, {name: 'Expenses', showValue: true, url: '/orders/expenses',
    queryParams: {quickBooks: '-', completed: true}}],
]);

export const dashboardColumns = [QuickLink.DECLINED_CHECKREQUESTS_LINK, QuickLink.OUTSTANDING_ACCOUNT_INVOICES_LINK,
  QuickLink.UNDER_REVIEW_CHECKREQUESTS_LINK, QuickLink.SUBMITTED_ORDERS_LINK, QuickLink.OUTSTANDING_PAYMENT_BATCHES_LINK,
  QuickLink.UNBALANCED_CONSULTANT_COMMISSION_LINK, QuickLink.GLOBAL_ACCOUNT_VIEW_ORDER_LINK,
  QuickLink.GLOBAL_ACCOUNT_VIEW_INVOICE_LINK, QuickLink.GLOBAL_STATION_VIEW_ORDER_LINK, QuickLink.GLOBAL_STATION_VIEW_INVOICE_LINK];

export const buyerDashboardColumns = [QuickLink.DECLINED_CHECKREQUESTS_LINK, QuickLink.OUTSTANDING_ACCOUNT_INVOICES_LINK,
  QuickLink.SUBMITTED_ORDERS_LINK, QuickLink.GLOBAL_ACCOUNT_VIEW_ORDER_LINK, QuickLink.GLOBAL_ACCOUNT_VIEW_INVOICE_LINK,
  QuickLink.GLOBAL_STATION_VIEW_ORDER_LINK, QuickLink.GLOBAL_STATION_VIEW_INVOICE_LINK, QuickLink.UNBALANCED_CONSULTANT_COMMISSION_LINK];

export const accountantDashboardColumns = [QuickLink.OUTSTANDING_ACCOUNT_INVOICES_LINK, QuickLink.OUTSTANDING_PAYMENT_BATCHES_LINK,
  QuickLink.UNDER_REVIEW_CHECKREQUESTS_LINK, QuickLink.UNBALANCED_CONSULTANT_COMMISSION_LINK,
  QuickLink.GLOBAL_ACCOUNT_VIEW_ORDER_LINK, QuickLink.GLOBAL_ACCOUNT_VIEW_INVOICE_LINK, QuickLink.GLOBAL_STATION_VIEW_ORDER_LINK,
  QuickLink.GLOBAL_STATION_VIEW_INVOICE_LINK];

export const missingQBColumns = [QuickLink.MISSING_QB_REF_PAYMENT_BATCHES_LINK, QuickLink.MISSING_QB_REF_ACCEPT_PAYMENTS_LINK,
  QuickLink.MISSING_QB_REF_REFUNDS_LINK, QuickLink.MISSING_QB_REF_COMMISSIONS_LINK, QuickLink.MISSING_QB_REF_EXPENSES_LINK];
