import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-commision-tier',
  templateUrl: './commission-tier.component.html',
  styleUrls: ['./commission-tier.component.scss']
})
export class CommissionTierComponent implements OnInit {
  static SLIDER_MAX_VALUE = 15;
  @Input() breakPoints: number[] = [];
  @Output() addBreakPointEvent = new EventEmitter();
  @Output() deleteBreakPointEvent = new EventEmitter();
  newBreakPointControl = this.fb.control(null, Validators.min(0));
  reloading = false;

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  get shownBreakPoints() {
    return this.breakPoints.filter((breakPoint) => {
      return breakPoint;
    });
  }

  get sliderHandles() {
    const unit = CommissionTierComponent.SLIDER_MAX_VALUE / this.breakPoints.length;
    const returnValues =  this.breakPoints.map(function(_) {
      this.acc += unit;
      return this.acc;
    }, {acc: 0});
    return returnValues.slice(0, returnValues.length - 1);
  }

  addBreakPoint() {
    this.breakPoints.push(this.newBreakPointControl.value);
    this.cdRef.detectChanges();
    this.addBreakPointEvent.emit(this.newBreakPointControl.value);
  }

  onLoadNext() {
    this.reloading = true;
    this.cdRef.detectChanges();
    this.reloading = false;
    this.cdRef.detectChanges();
  }

  deleteBreakPoint(breakPoint) {
    this.breakPoints = this.breakPoints.filter((currentBreakPoint) => {
      return currentBreakPoint !== breakPoint;
    });
    this.cdRef.detectChanges();
    this.deleteBreakPointEvent.emit(breakPoint);
  }

  isAddDisabled() {
    if (!this.newBreakPointControl.value) {
      return true;
    }
    return this.newBreakPointControl.value.length === 0 || this.breakPoints.includes(this.newBreakPointControl.value);
  }

}
