import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { VERSION } from '../core/apiUrls';
import { tap } from 'rxjs/operators';
import { Version } from './models/version';
import { UIPipelineVersion } from './models/ui-pipeline-version';
import { RealiseDate } from './models/realise-date';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  backendVersion: Version;
  dateUI: RealiseDate;
  uiPipelineId: UIPipelineVersion;

  constructor(private http: HttpClient) {
  }

  getBackEndVersion(): Observable<Version> {
    if (this.backendVersion) {
      return of(this.backendVersion);
    }
    return this.http.get<Version>(`${VERSION}`).pipe(
      tap(data => this.backendVersion = data)
    );
  }

  getUIPipelineId(): Observable<UIPipelineVersion> {
    if (this.uiPipelineId) {
      return of(this.uiPipelineId);
    }
    return this.http.get<UIPipelineVersion>('assets/version.json')?.pipe(
      tap(data => this.uiPipelineId = data as UIPipelineVersion)
    ) || of({pipelineId: ''});

  }

  getUIDate(): Observable<RealiseDate> {
    if (this.dateUI) {
      return of(this.dateUI);
    }
    return this.http.get<RealiseDate>('assets/realiseDate.json').pipe(
      tap(data => this.dateUI = data as RealiseDate)
    );
  }
}
