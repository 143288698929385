<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Expense</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit(form)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <div class="expense-info" *ngIf="isEditMode">
            <mat-form-field>
              <mat-label>Expense ID#</mat-label>
              <input formControlName="code" matInput readonly>
            </mat-form-field>
            <h2 class="expense-info_paid" *ngIf="isPaid">PAID</h2>
          </div>

          <mat-form-field>
            <mat-label>Account</mat-label>
            <input type="text" matInput
              required
              maxlength="50"
              #accountInput
              (blur)="onBlurAccount(accountInput)"
              [matAutocomplete]="autoAccounts"
              formControlName="account"
              [readonly]="isEditMode"
            >
            <mat-autocomplete #autoAccounts="matAutocomplete" [displayWith]="displayAccount">
              <mat-option *ngIf="accounts.length === 0" [value]="null">No Accounts with ordered Station</mat-option>
              <mat-option *ngFor="let option of filteredAccounts | async"
                [value]="option"
                (mousedown)="onAccountOptionSelect(option)"
              >
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openAccountDialog()" *ngIf="!isEditMode">Browse Accounts ></div>

          <div class="opposing-radio-group" *ngIf="!isPaid && hasEditPermission">
            <h4>* Expense from station?</h4>
            <section class="form-element">
              <mat-radio-group [formControl]="exposeModeControl" class="margin-element">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>

          <mat-form-field *ngIf="exposeModeControl.value">
            <mat-label>Station</mat-label>
            <input type="text" matInput
              maxlength="50"
              #stationInput
              (blur)="onBlurStation(stationInput)"
              [matAutocomplete]="autoStations"
              formControlName="station"
              required
              [readonly]="isPaid"
            >
            <mat-autocomplete #autoStations="matAutocomplete" [displayWith]="displayStation">
              <mat-option *ngIf="accountControl.value && stations.length === 0" [value]="null">No ordered Stations for Account</mat-option>
              <mat-option *ngIf="!accountControl.value && stations.length === 0" [value]="null">Enter the first two letters of the name to start filtering</mat-option>
              <mat-option *ngFor="let option of filteredStations | async"
              [value]="option"
              (mousedown)="onStationOptionSelect(option)"
              >
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openStationDialog()" *ngIf="exposeModeControl.value && !isPaid && hasEditPermission">
            Browse Stations >
          </div>

          <mat-form-field *ngIf="!exposeModeControl.value">
            <mat-label>Who did this payment go to?</mat-label>
            <input formControlName="whoDidGoTo" matInput maxlength="50" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Transaction Type</mat-label>
            <mat-select formControlName="paymentMethod">
              <mat-option *ngFor="let paymentType of paymentMethods" aria-selected="true" [value]="paymentType">
                {{paymentType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Payment Sent Date</mat-label>
            <input [formControl]="dateControl" [disabled]="!hasEditPermission" [matDatepicker]="pickerCreated" matInput required>
            <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
            <mat-datepicker #pickerCreated></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Payment Amount</mat-label>
            <input
              type="number" matInput
              formControlName="amount"
              maxLength="17" required
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            >
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Assigned Accountant</mat-label>
            <input type="text"
                   matInput
                   formControlName="assignedAccountant"
                   #accountantInput
                   (blur)="onBlurAccountant(accountantInput)"
                   [matAutocomplete]="autoAccountant"
            >
            <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayAccountant">
              <mat-option *ngFor="let option of filteredAccountants | async" [value]="option"
                          (mousedown)="onAccountantOptionSelect(option)">
                {{option.name}} {{option.lastName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="browseUser()" *ngIf="hasEditPermission">Browse Accountants ></div>

          <div class="opposing-radio-group">
            <h4>Is this payment commissionable?</h4>
            <section class="form-element">
              <mat-radio-group formControlName="commissionable" class="margin-element" [disabled]="true">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </section>
          </div>
        </div>
        <div class="form-content_column">
          <mat-form-field>
            <mat-label>QBref#</mat-label>
            <input formControlName="quickBookReference" matInput maxlength="25">
          </mat-form-field>

          <h3 class="upload-title">Upload Proof of Payment</h3>
          <div class="file-upload-container">
            <app-file-upload
              [filesUploadedBefore]="documentsUploadedBefore"
              (filesChanges)="setExpenseDocumentation($event)"
              [mode]="mode"
              [id]="expenseId"
              [component]="component"
              [readonly]="!hasEditPermission"
            >
            </app-file-upload>
          </div>

          <div>
            <mat-checkbox class="checkbox-control"
                          formControlName="completed"
                          *ngIf="!isPaid"
                          [checked]="isCompleted"
            >
              I have completed this payment
            </mat-checkbox>
          </div>

          <h2>Notes</h2>
          <div *ngFor="let note of noteReverse" class="note">
            <div><b>{{note | noteHeader}}</b></div>
            <div><span>{{note.message}}</span></div>
          </div>
          <mat-form-field appearance="fill">
            <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
          </mat-form-field>

        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button
        appLoading color="primary"
        [loading]="saving"
        [disabled]="!form.valid || saving"
        *ngIf="hasEditPermission"
      >Save</button>
      <button mat-raised-button type="button" [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

