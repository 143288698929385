<div class="container">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="accountName">
      <th mat-header-cell *matHeaderCellDef>Account name</th>
      <td mat-cell *matCellDef="let row">{{row.accountName}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.accountName}}</td>
    </ng-container>

    <ng-container matColumnDef="consultantCompanyName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Consultant Name</th>
      <td mat-cell *matCellDef="let row">{{row.consultantCompanyName}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="grossAmount">
      <th mat-header-cell *matHeaderCellDef>GROSS ORDERED</th>
      <td mat-cell *matCellDef="let row">{{row.grossAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.grossAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="adjustedGrossAmount">
      <th mat-header-cell *matHeaderCellDef>ADJUSTED GROSS</th>
      <td mat-cell *matCellDef="let row">{{row.adjustedGrossAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.adjustedGrossAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="netAmount">
      <th mat-header-cell *matHeaderCellDef>NET</th>
      <td mat-cell *matCellDef="let row">{{row.netAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.netAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="expenseAmount">
      <th mat-header-cell *matHeaderCellDef>Expense (Non Station and Station)</th>
      <td mat-cell *matCellDef="let row">{{row.expenseAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.expenseAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="receivedAmount">
      <th mat-header-cell *matHeaderCellDef>RECEIVED</th>
      <td mat-cell *matCellDef="let row">{{row.receivedAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.receivedAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="balanceAmount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>BALANCE</th>
      <td mat-cell *matCellDef="let row">{{row.balanceAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.balanceAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="outstandingAccountInvoiceAmount">
      <th mat-header-cell *matHeaderCellDef>OUTSTANDING ACCOUNT INVOICE</th>
      <td mat-cell *matCellDef="let row">{{row.outstandingAccountInvoiceAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.outstandingAccountInvoiceAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="commissionOwedAmount">
      <th mat-header-cell *matHeaderCellDef>COMMISSION OWED</th>
      <td mat-cell *matCellDef="let row">{{row.commissionOwedAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.commissionOwedAmount |  negativeCurrency}}</td>
    </ng-container>

    <ng-container matColumnDef="cpmCommissionAmount">
      <th mat-header-cell *matHeaderCellDef>OUR COMMISSION</th>
      <td mat-cell *matCellDef="let row">{{row.cpmCommissionAmount |  negativeCurrency}}</td>
      <td mat-footer-cell *matFooterCellDef>{{total.cpmCommissionAmount |  negativeCurrency}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns" class="bolder"></tr>
  </table>
</div>

<div class="table-container" *ngIf="entries.length === 0">
  <p class="no-data">No data</p>
</div>
