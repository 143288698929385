import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { OrderService } from '../shared/order.service';
import { ReportsService } from '../../entities/shared/reports.service';
import { OrderDictionaryService } from '../shared/order-dictionary.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { getClientCandidateType, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { FlightUtil } from '../shared/utils/flight-util';
import { DateUtils } from '../../shared/utils/date-utils';
import { Order, OrderFlight, OrderStatus, StatusSummary, orderStatuses } from '../shared/models';
import { Filename } from '../../shared/models/filename.enum';
import { EntityType } from '../../entities/shared/models/entity-type.enum';
import { Role } from '../../shared/models/role.enum';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() listMethod: Observable<Order[]>;
  @Input() columns: string[];
  @Input() isMultiselect: boolean;
  dataSource = new MatTableDataSource<Order>();
  displayedColumns: string[] = [
    'code',
    'versionLabel',
    'status',
    'accountName', 'accountId', 'accountType',
    'consultantName', 'netTotalAmount',
    'ftc', 'nextFlight', 'ltc', 'estimateNumber',
    'electionDate', 'flight', 'electionType',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'lastUpdatedBy',
    'menu'
  ];
  statusSummary: StatusSummary[] = [];
  orderStatuses: OrderStatus[] = Object.values(orderStatuses);

  digitFilters = [
    {name: 'netTotalAmount', filterDef: 'netTotalAmount-filter'},
  ];

  dateFilters = [
    {name: 'lastPaymentDate', filterDef: 'lastPaymentDate-filter'},
    {name: 'electionDate', filterDef: 'electionDate-filter'},
    {name: 'ftc', filterDef: 'ftc-filter'},
    {name: 'ltc', filterDef: 'ltc-filter'},
    {name: 'earliestFlightStartOn', filterDef: 'earliestFlightStartOn-filter'},
    {name: 'latestFlightFinishOn', filterDef: 'latestFlightFinishOn-filter'},
    {name: 'nextFlight', filterDef: 'nextFlight-filter'},
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
  ];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    versionLabel: new FormControl(''),
    status: new FormControl(''),
    accountName: new FormControl(''),
    accountId: new FormControl(''),
    consultantName: new FormControl(''),
    accountType: new FormControl(''),
    netTotalAmount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ftc: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    nextFlight: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ltc: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    earliestFlightStartOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    latestFlightFinishOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    electionType: new FormControl(''),
    electionDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    flight: new FormControl(''),
    estimateNumber: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl('')
  });
  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  isLoadingResults = true;
  selection: SelectionModel<Order>;
  selectedId: number[];
  start: Date | null;
  end: Date | null;

  hasCreatePermission = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
  hasEditAll = this.authService.hasPermission([Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private notificationService: NotificationService,
              private dictionary: OrderDictionaryService,
              private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private authService: AuthService,
              private reportsService: ReportsService) { }

  get isSelectMode() {
    return !!this.listMethod;
  }

  ngOnInit(): void {
    this.selection = new SelectionModel<Order>(this.isMultiselect, []);
    this.displayedColumns = this.isSelectMode ? this.columns : this.displayedColumns;
    const orders$ = this.listMethod || this.orderService.getOrders();
    this.subscription.add(orders$.subscribe((data) => {
        this.initOrders(data);
        this.initSummary(data);
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get order list.')
    ));

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.ftc.value = value.ftc?.value?.toString();
      value.ltc.value = value.ltc?.value?.toString();
      value.earliestFlightStartOn.value = value.earliestFlightStartOn?.value?.toString();
      value.latestFlightFinishOn.value = value.latestFlightFinishOn?.value?.toString();
      value.electionDate.value = value.electionDate?.value?.toString();
      value.nextFlight.value = value.nextFlight?.value?.toString();
      value.netTotalAmount.value = value.netTotalAmount?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));

    this.manageDateFilter();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();
  }

  applyDashboardFilter = () => {
    const status = this.route.snapshot.queryParamMap.get('status'); // in case to go from dashboard
    if (status) {
      this.tableFilterForm.patchValue({status});
    }
  }

  initOrders = (data) => {
    const orders = sortListByLastEdit(data);
    this.dataSource.data = orders.map(this.transformData);
    this.isLoadingResults = false;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  initSummary = (data) => {
    const statusSummary: StatusSummary[] = [];
    statusSummary.push({status: orderStatuses.DRAFT, quantity: data.filter(item => !item.submitted).length});
    statusSummary.push({status: orderStatuses.SUBMITTED, quantity: data.filter(item => item.submitted).length});
    this.statusSummary = statusSummary;
  }

  transformData = (order: Order) => {
    const ftcString = this.getFTC(order);
    const ftc = ftcString ? new Date(ftcString) : ftcString;
    const ltcString = this.getLTC(order);
    const ltc = ltcString ? new Date(ltcString) : ltcString;
    const nextFlight = this.getNextFlight(order);
    let flight;
    let estimateNumber;
    if (order.flights && nextFlight) {
      const flights: string[] = [];
      const estimates: string[] = [];
      order.flights.forEach((item: OrderFlight, index: number) => {
        if (nextFlight === item.ftcOn) {
          flights.push(`${index + 1} / ${order.flights.length}`);
          if (item.estimateNumber) {
            estimates.push(item.estimateNumber);
          }
        }
      });
      flight = flights.join(', ');
      estimateNumber = estimates.join(', ');
    }
    const consultantName = (order.account as any).consultantCompanies.find(consultant =>
      consultant.code === (order.account as any).leadConsultantCompanyCode)?.name;
    const electionDate = order.electionDate ? new Date(order.electionDate) : order.electionDate;
    return {
      ...order, ftc, ltc, nextFlight: nextFlight ? new Date(nextFlight) : nextFlight, flight, estimateNumber,
      consultantName, electionDate
    };
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.account)).filter(item => !!item))
    ];
  }

  get electionTypes() {
    return [...new Set(this.dataSource.data.map(item => item.electionType?.type).filter(item => !!item))
    ];
  }

  get statuses() {
    return this.orderStatuses.map(item => item.status);
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  getFTC(order: Order) {
    if (order.flights) {
      const sortedFlights = order.flights.sort(FlightUtil.flightSortNumberCompareFn);
      return sortedFlights[0]?.ftcOn;
    }
    return null;
  }

  getLTC(order: Order) {
    return order.flights && order.flights[order.flights.length - 1]?.ltcOn;
  }

  getNextFlight(order: Order) {
    return order.flights && order.flights.find(item => new Date(item.ftcOn) > OrderListComponent.currentDate)?.ftcOn;
  }

  getStatusSummary(status: OrderStatus) {
    const summary = this.statusSummary.find(item => item.status.id === status.id);
    return summary ? summary.quantity : '0';
  }

  getStatusSummaryTooltip(status: OrderStatus) {
    return `${this.getStatusSummary(status)} order(s)`;
  }

  onEditClicked(orderId: number, accountId: number) {
    this.router.navigate(['/orders/orders/edit', `${accountId}-${orderId}`]);
  }

  getDetailLink(accountId, orderId) {
    return `/orders/orders/detail/${accountId}-${orderId}`;
  }

  filtrateStatus(status: OrderStatus) {
    this.clearFilterForm();
    this.tableFilterForm.get('status').patchValue(status.status);
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.orderService.getOrders(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe(this.initOrders,
        () => this.notificationService.error('Error occurred while trying to get order list.'));
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  private static get currentDate() {
    const current = new Date();
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);
    return current;
  }

  sortingDataAccessor = (item: Order, property: string) => {
    switch (property) {
      case 'status':
        return !item.submitted;
      case 'accountType':
        return this.getAccountType(item.account);
      case 'accountName':
        return item.account.name;
      case 'accountId':
        return item.account.code;
      case 'electionType':
        return item.electionType?.type;
      default:
        return item[property];
    }
  }

  getOrderStatus(submitted: boolean): OrderStatus {
    return submitted ? orderStatuses.SUBMITTED : orderStatuses.DRAFT;
  }

  onExportClicked() {
    this.isReportLoading$.next(true);

    const sortedFilteredData = ReportsUtil.getSortedFilteredData<Order>(this.dataSource);
    this.reportsService.generateExcelReport(ReportsUtil.createRequestReport(sortedFilteredData, EntityType.Order)).subscribe(
      data => ReportsUtil.downloadFile(data, Filename.OrderList),
      err => this.notificationService.error('Error occurred while trying to download report file.'),
      () => this.isReportLoading$.next(false)
    );
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const versionLabelSearch = FilterUtils.searchFunction(searchTerms.versionLabel, data.versionLabel);
      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const statusSearch = FilterUtils.createSelectSearch(searchTerms.status, this.getOrderStatus(data.submitted).status);
      const accountIdSearch = FilterUtils.searchFunction(searchTerms.accountId, data.account.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account.name);
      const consultantNameSearch = FilterUtils.searchFunction(searchTerms.consultantName, data.consultantName);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.account));
      const amountSearch = FilterUtils.createDigitSearch('netTotalAmount', 'netTotalAmount', searchTerms, data);
      const ftcSearch = FilterUtils.createDateSearch('ftc', 'ftc', searchTerms, data);
      const nextFlightSearch = FilterUtils.createDateSearch('nextFlight', 'nextFlight', searchTerms, data);
      const ltcSearch = FilterUtils.createDateSearch('ltc', 'ltc', searchTerms, data);
      const ftcOnSearch = FilterUtils.createDateSearch('earliestFlightStartOn', 'earliestFlightStartOn', searchTerms, data);
      const ltcOnSearch = FilterUtils.createDateSearch('latestFlightFinishOn', 'latestFlightFinishOn', searchTerms, data);
      const electionTypeSearch = FilterUtils.createSelectSearch(searchTerms.electionType, data.electionType?.type);
      const electionDateSearch = FilterUtils.createDateSearch('electionDate', 'electionDate', searchTerms, data);
      const flightSearch = FilterUtils.searchFunction(searchTerms.flight, data.flight);
      const estimateNumberSearch = FilterUtils.searchFunction(searchTerms.estimateNumber, data.estimateNumber);
      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);

      const filterFunctions = [
        codeSearch(), versionLabelSearch(), statusSearch(),
        accountNameSearch(), accountIdSearch(),
        consultantNameSearch(), accountTypeSearch(), amountSearch(),
        ftcSearch(), nextFlightSearch(), ltcSearch(), ftcOnSearch(), ltcOnSearch(),
        electionTypeSearch(), electionDateSearch(),
        flightSearch(), estimateNumberSearch(),
        createdAtSearch(), createdBySearch(), lastUpdatedAtSearch(), lastUpdatedBySearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  initSelected(id: number[]) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }

    if (this.selectedId.length > 0) {
      const rows = this.dataSource.data.filter((item: Order) => {
        return id.includes(item.id);
      });
      rows.forEach(row => this.selection.toggle(row as Order));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkEditPermission(row) {
    if (this.hasEditAll) {
      return true;
    }
    return this.hasCreatePermission && row.permissions ? row.permissions?.indexOf('EDIT') > -1 : false;
  }
}
