<div class="container">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Station + Media Type </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total || element.transactionDateHeader,
       'tier-bcgrnd': element.tierRow}"> {{getTitle(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionType">
      <th mat-header-cell *matHeaderCellDef> Transaction Type </th>
      <td mat-cell *matCellDef="let element"> {{transactionTypes.get(element.type)}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef>  Transaction Date </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'tier-bcgrnd': element.tierRow}"> {{getTransactionDate(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionId">
    <th mat-header-cell *matHeaderCellDef class="w-180"> Transaction ID </th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'tier-bcgrnd': element.tierRow}">
      <a target="_blank" [routerLink]="[getTransactionLink(element)]"
         *ngIf="element.type !== AccountCheckReportEntryType.TOTAL;
          else noHyperlink">
        {{element.transactionId ? element.transactionId : '&nbsp;'}}
      </a>
      <ng-template #noHyperlink>{{'&nbsp;'}}</ng-template>
    </td>
    </ng-container>

    <ng-container matColumnDef="quickBookReference">
      <th mat-header-cell *matHeaderCellDef>  QBref# </th>
      <td mat-cell *matCellDef="let element">{{element.quickBookReference}}</td>
    </ng-container>

    <ng-container matColumnDef="grossAmount">
      <th mat-header-cell *matHeaderCellDef>  GROSS </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.grossAmount !== null ? (element.grossAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="adjustedGrossAmount">
      <th mat-header-cell *matHeaderCellDef> ADJUSTED GROSS </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.adjustedGrossAmount !== null ? (element.adjustedGrossAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="netAmount">
      <th mat-header-cell *matHeaderCellDef>  NET </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.netAmount !== null ? (element.netAmount | negativeCurrency) : '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="expenseAmount">
      <th mat-header-cell *matHeaderCellDef>  Expense </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.expenseAmount !== null  ? (element.expenseAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="receivedAmount">
      <th mat-header-cell *matHeaderCellDef>  RECEIVED </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.receivedAmount !== null  ? (element.receivedAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="balanceAmount">
      <th mat-header-cell *matHeaderCellDef>   BALANCE </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.balanceAmount !== null ? (element.balanceAmount | negativeCurrency) : '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="outstandingAccountInvoiceAmount">
      <th mat-header-cell *matHeaderCellDef class="w-135"> OUTSTANDING ACCOUNT INVOICE</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.total}">
        {{element.outstandingAccountInvoiceAmount !== null ? (element.outstandingAccountInvoiceAmount | negativeCurrency) : '&nbsp;'}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="table-container" *ngIf="dataSource.data.length === 0">
  <p class="no-data">No data</p>
</div>


