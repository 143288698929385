<table *ngIf="invoicedExpensesData?.length" mat-table [dataSource]="invoicedExpensesData" class="mat-elevation-z8">

  <ng-container matColumnDef="station">
    <th mat-header-cell *matHeaderCellDef> Station </th>
    <td mat-cell *matCellDef="let element"> {{element.station?.name}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef> Expense ID# </th>
    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="whoDidGoTo">
    <th mat-header-cell *matHeaderCellDef> Who did the payment go to? </th>
    <td mat-cell *matCellDef="let element"> {{element.whoDidGoTo}} </td>
    <td mat-footer-cell *matFooterCellDef class="total-label">
      <b class="total-label-bold">Total: </b>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef> Payment Amount </th>
    <td mat-cell *matCellDef="let element"> {{element.amount | currency }} </td>
    <td mat-footer-cell *matFooterCellDef><b>{{ totalAmount | currency}}</b></td>
  </ng-container>

  <ng-container matColumnDef="commissionable">
    <th mat-header-cell *matHeaderCellDef> Commission? </th>
    <td mat-cell *matCellDef="let element">
      <span class="material-icons" *ngIf="element.commissionable">done</span>
      <span class="material-icons" *ngIf="!element.commissionable">clear</span>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="delete" stickyEnd>
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-icon class="delete-icon" color="accent"
                (click)="deleteClicked.emit(element.id)">delete</mat-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
