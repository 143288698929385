import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StationInvoice } from '../shared/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StationInvoiceService } from '../shared/station-invoice.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-station-invoice-versions-dialog',
  templateUrl: './station-invoice-versions-dialog.component.html',
  styleUrls: ['./station-invoice-versions-dialog.component.scss']
})
export class StationInvoiceVersionsDialogComponent implements OnInit {
  isLoadingResults = true;
  displayedColumns: string[] = [
    'version', 'invoiceNumber', 'netRanAmount', 'invoiceCreatedDate', 'ftcOn', 'ltcOn', 'versionCreatedAt', 'versionAuthor'
  ];
  dataSource = new MatTableDataSource<StationInvoice>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number}, private service: StationInvoiceService) { }

  ngOnInit(): void {
    this.service.getInvoiceVersions(this.data.id).pipe(take(1))
      .subscribe(data => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
      });
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }
}
