import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Order, OrderFlight, OrderStatus } from './models';
import { TypeaheadItem } from '../../shared/models/typeahead-item';
import { ACCOUNTS, ORDERS, TYPEAHEAD } from '../../core/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  static SUMMARY = '/summaryByStatus';
  static STATUS = '/status';
  static FLIGHTS = '/flights';
  static ACCOUNTS = '/accounts';
  static STATIONS = '/stations';

  constructor(private http: HttpClient) { }

  getOrders(createdAfter?: string, createdBefore?: string): Observable<Order[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('createdAfter', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('createdBefore', createdBefore);
    }

    return this.http.get<Order[]>(`${ACCOUNTS}${ORDERS}`, { params: httpParams});
  }

  getAccountOrders(accountId: string): Observable<Order[]> {
    return this.http.get<Order[]>(`${ACCOUNTS}/${accountId}${ORDERS}`);
  }

  createOrder(accountId: string, order: Order): Observable<Order> {
    return this.http.post<Order>(`${ACCOUNTS}/${accountId}${ORDERS}`, order);
  }

  getOrder(accountId: string, orderId: string, includeVersions?: boolean): Observable<Order> {

    let httpParams = new HttpParams();
    if (includeVersions) {
      httpParams = httpParams.set('includeVersions', String(includeVersions));
    }

    return this.http.get<Order>(`${ACCOUNTS}/${accountId}${ORDERS}/${orderId}`, { params: httpParams});
  }

  updateOrder(accountId: string, orderId: string, order: Order): Observable<Order> {
    return this.http.put<Order>(`${ACCOUNTS}/${accountId}${ORDERS}/${orderId}`, order);
  }

  disableOrder(accountId: number, orderId: number): Observable<boolean> {
    return this.http.put<boolean>(`${ACCOUNTS}/${accountId}${ORDERS}/${orderId}/disable`, {});
  }

  downloadOrderNABForm(accountId: number, orderId: number): Observable<HttpResponse<any>> {
    // @ts-ignore
    return this.http.get(`${ACCOUNTS}/${accountId}${ORDERS}/${orderId}/nabForms/download`, { observe: 'response' as 'body', responseType: 'blob'});
  }

  updateOrderStatus(accountId: number, orderId: number, orderStatus: OrderStatus): Observable<Order> {
    return this.http.put<Order>(`${ACCOUNTS}/${accountId}${ORDERS}/${orderId}${OrderService.STATUS}`, orderStatus);
  }

  getFlightsOfAllOrdersOfAccount(accountId: string): Observable<OrderFlight[]> {
    return this.http.get<OrderFlight[]>(`${ACCOUNTS}/${accountId}${ORDERS}${OrderService.FLIGHTS}`);
  }

  getAccountList(): Observable<TypeaheadItem[]> {
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}${OrderService.ACCOUNTS}`);
  }

  getStationsList(): Observable<TypeaheadItem[]> {
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}${OrderService.STATIONS}`);
  }
}
