import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-payment-selector',
  templateUrl: './payment-selector.component.html',
  styleUrls: ['./payment-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PaymentSelectorComponent),
    multi: true
  }]
})
export class PaymentSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() options = [];
  @Input() inputValue = null;
  @Output() propagateChange = new EventEmitter();
  private formControlValue = null;
  applied = false;

  get value() {
    return this.formControlValue;
   }

   @Input()
   set value(val) {
    this.formControlValue = val;
    this.onChange(this.formControlValue);
   }

  constructor() { }

  ngOnInit(): void {
     if (this.inputValue){
       this.value = this.inputValue;
     }
  }

  writeValue(value: any) {
    this.value = value;
  }

  onChange(_: any) {
    this.propagateChange.emit(this.value);
  }

  setSelected(paymentSelector) {
    this.value = this.options.find(item => item.name === paymentSelector.value);
    this.applied = true;
    paymentSelector.close();
  }

  openedChange(paymentSelector) {
    this.applied ? this.applied = false : paymentSelector.value = this.formControlValue;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }
}
