<div class="main">
  <h1>
    <button mat-icon-button>
      <span routerLink="/orders/orders" class="back-arrow material-icons">keyboard_backspace</span>
    </button>
    <span>Order Detail</span>
    <div class="buttons-container edit-button">
      <button mat-raised-button appLoading
        [loading]="nabLoading" [disabled]="isRequesting"
        (click)="downloadNABForm()"
        *ngIf="canDownloadNABNeeded"
      >
        Download NAB Form
      </button>
      <button mat-raised-button appLoading
        [loading]="approving" [disabled]="isRequesting"
        (click)="approve()"
        *ngIf="canSubmit"
      >Submit
      </button>
      <button mat-raised-button [disabled]="isRequesting"
              *ngIf="canCreateCheckRequest" (click)="createCheckRequest()">
        Create Check Request
      </button>
      <button mat-raised-button [disabled]="isRequesting"
              *ngIf="canSubmit" (click)="approveAndCreateCheckRequest()">
        Submit and Create Check Request
      </button>
      <button mat-mini-fab color="primary" [disabled]="isRequesting" aria-label="Edit Station"
        *ngIf="hasEditPermission"
        [routerLink]="['/orders/orders/edit', orderData?.account.id + '-' + orderData?.id]">
          <mat-icon>edit</mat-icon>
      </button>
    </div>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading && orderData" class="container">
    <mat-card-content class="content">
      <div class="content_right">
        <app-table-version-history *ngIf="versions" [versionData]="orderData.versions"></app-table-version-history>

        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Order Status</div>
                {{getOrderStatus(orderData.submitted).status}}
              </div>
              <div class="column_item">
                <div class="title">Account</div>
                {{orderData.account.name}}
              </div>
              <div class="column_item">
                <div class="title">Order Number</div>
                {{orderData.code}}
              </div>
            </div>

            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Election Type</div>
                {{orderData.electionType?.type || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Election Date</div>
                {{orderData.electionDate || 'N/A'}}
              </div>
            </div>
          </div>
        </mat-card>

        <ng-container *ngIf="orderData.notes">
          <div class="subheader mat-title">Notes</div>
          <mat-card class="notes-container">
            <mat-card-content>
              <div class="note" *ngFor="let note of orderData.notes.reverse()">
                <div><b>{{note | noteHeader}}</b></div>
                <div><span>{{note.message}}</span></div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>

      <div class="content_left">
        <div class="subheader mat-title">Flights</div>
        <div class="flights-container">
          <ng-container *ngIf="orderData.flights; else noFlights">
            <div class="flights-container">
              <mat-accordion class="headers-align" multi>
                <mat-expansion-panel *ngFor="let flight of orderData.flights; let i=index;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="flight-number">
                        {{i+1}}/{{orderData.flights.length}}
                      </div>
                      <div>
                        <div class="flight-duration">
                          {{flight.ftcOn || 'N/A'}} - {{flight.ltcOn || 'N/A'}}
                        </div>
                        <div class="flight-estimate">Estimate #{{flight.estimateNumber || 'N/A'}}</div>
                      </div>
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="flight-amount-due-label">Amount Due: </span>
                      <span class="flight-amount-due">{{ getSummaryAmountDue(flight.flightStations) | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="panel-inner">
                    <table class="flights-table">
                      <tr *ngFor="let flightStation of flight.flightStations;">
                        <td class="col-name">{{flightStation.station?.name}}</td>
                        <td class="col-type">{{flightStation.station?.mediaType?.type || 'N/A'}}</td>
                        <td class="col-amount">{{flightStation.netAmount | currency}}</td>
                      </tr>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>
          <ng-template #noFlights>
            <div>N/A</div>
          </ng-template>
        </div>

        <h2 class="net-total">Net Total {{(orderData.netTotalAmount | negativeCurrency) || 'N/A'}}</h2>

<!--        <div class="balance-container">
          <div class="balance-container_item">
            <span class="item-title">Amount Due for this Order: </span>
            <span class="item-amount" [class.negative]="false">{{ amountDueTotal | currency }}</span>
          </div>
          <div class="balance-container_item">
            <span class="item-title">Current Account Balance: </span>
            <span class="item-amount" [class.negative]="false">
              {{ orderData.orderSummary.currentAccountBalance | currency }}
            </span>
          </div>
          <div class="balance-container_item">
            <span class="item-title">Balance After This Order: </span>
            <span class="item-amount" [class.negative]="false">
              {{ orderData.orderSummary.balanceAfterThisOrder | currency }}
            </span>
          </div>
          <div class="balance-container_item">
            <span class="item-title">Outstanding Invoices: </span>
            <span class="item-amount" [class.negative]="false">
              {{ orderData.orderSummary.outstandingInvoices | currency }}
            </span>
          </div>
          <div class="balance-container_item">
            <span class="item-title">Balance After This Request and Invoices: </span>
            <span class="item-amount" [class.negative]="true">
              {{ orderData.orderSummary.balanceAfterThisRequestAndInvoices | currency }}
            </span>
          </div>
        </div>-->
      </div>
    </mat-card-content>
  </mat-card>
</div>
