import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountInvoiceOrderedList } from '../shared/models';
import { DateUtils } from '../../shared/utils/date-utils';

@Component({
  selector: 'app-account-invoice-orders',
  templateUrl: './account-invoice-orders.component.html',
  styleUrls: ['./account-invoice-orders.component.scss']
})
export class AccountInvoiceOrdersComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  get invoicedOrdersData(): AccountInvoiceOrderedList {
    return this.pInvoicedOrdersData;
  }

  @Input()
  set invoicedOrdersData(value: AccountInvoiceOrderedList) {
    this.pInvoicedOrdersData = value;
    if (value) {
      this.initControls();
    }
  }
  @Input() isDetail = false;

  private subscription = new Subscription();
  private pInvoicedOrdersData: AccountInvoiceOrderedList;
  invoicedOrder: FormArray = new FormArray([]);
  grossSumm: number;
  netAmountSumm: number;

  constructor() { }

  ngOnInit(): void {
    if (this.invoicedOrdersData?.orderedData && !this.disabled) {
      this.setSumm(this.invoicedOrdersData.orderedData);
    }

    if (this.disabled) {
      this.setFormDisabled();
    }

    this.subscription.add(this.invoicedOrder.valueChanges.subscribe(values => {
      if (!this.invoicedOrdersData) {
        return;
      }

      this.setSumm(values);
    }));
  }

  setFormDisabled() {
    (this.invoicedOrder.controls as FormGroup[]).forEach((formGroup) => {
      formGroup.get('description').disable();
      formGroup.get('mediaType').disable();
      formGroup.get('customColumn1Value').disable();
      formGroup.get('customColumn2Value').disable();
      formGroup.get('startDate').disable();
      formGroup.get('endDate').disable();
      formGroup.get('gross').disable();
      formGroup.get('adjustedGross').disable();
      formGroup.get('netAmount').disable();
    });
  }

  setSumm(orderedData) {
    orderedData.forEach((item, index) => {
      const data = this.invoicedOrdersData.orderedData[index];
      if (data) {
        Object.keys(item).forEach(key => data[key] = key.indexOf('Date') > -1 ? DateUtils.dateFormatToShort(item[key]) : item[key]);
      }
    });
    this.grossSumm = orderedData.map(item => item.gross).reduce((a, b) => b ? a + b : a, 0);
    this.netAmountSumm = orderedData.map(item => item.netAmount).reduce((a, b) => b ? a + b : a, 0);
  }

  get isCustomColumn1() {
    return !this.isDetail
      || (!this.rows.map(row => row.value.customColumn1Value).every(item => !item) || this.invoicedOrdersData.customColumn1Name);
  }

  get isCustomColumn2() {
    return !this.isDetail
      || (!this.rows.map(row => row.value.customColumn2Value).every(item => !item) || this.invoicedOrdersData.customColumn2Name);
  }

  get percent() {
    return this.invoicedOrdersData?.grossPercent !== undefined ? this.invoicedOrdersData?.grossPercent : 15;
  }

  get percentView() {
    return Number(this.percent).toFixed(2);
  }

  initControls() {
    this.invoicedOrder.clear();
    this.invoicedOrdersData?.orderedData.forEach(item => this.invoicedOrder.push(
      new FormGroup({
        description: new FormControl(item.description),
        mediaType: new FormControl(item.mediaType),
        customColumn1Value: new FormControl(item.customColumn1Value),
        customColumn2Value: new FormControl(item.customColumn2Value),
        startDate: new FormControl(item.startDate ? new Date(item.startDate) : ''),
        endDate: new FormControl(item.endDate ? new Date(item.endDate) : ''),
        gross: new FormControl(item.gross),
        adjustedGross: new FormControl(item.adjustedGross),
        netAmount: new FormControl(item.netAmount),
      }, { updateOn: 'blur' })
    ));
  }

  getControl(index, fieldName) {
    return this.invoicedOrder.at(index).get(fieldName) as FormControl;
  }

  getAdjustedGross(index) {
    const value = this.invoicedOrder.at(index).get('gross').value || 0;
    return Number(((100 - this.percent) / 100) * value).toFixed(2);
  }

  updateGross($event, index) {
    const value = $event.target.value || 0;
    this.getControl(index, 'netAmount').patchValue(Number(Number(value * 0.85).toFixed(2)));
  }

  updateNetAmount($event, index) {
    const value = $event.target.value || 0;
    this.getControl(index, 'gross').patchValue(Number(Number(value / 0.85).toFixed(2)));
  }

  get adjustedGrossSumm() {
    const summ = this.invoicedOrdersData.orderedData.map((item, index) => Number(this.getAdjustedGross(index)))
      .reduce((a, b) => b ? a + b : a, 0);
    return summ ? summ.toFixed(2) : '0';
  }

  get rows() {
    return this.invoicedOrder.controls;
  }

  hasError(index) {
    const current = this.rows[index].value;
    const filtered = this.rows.filter(row => {
      const value = row.value;
      return value.description === current.description && value.mediaType === current.mediaType &&
        DateUtils.dateFormatToShort(value.startDate) === DateUtils.dateFormatToShort(current.startDate) &&
        DateUtils.dateFormatToShort(value.endDate) === DateUtils.dateFormatToShort(current.endDate);
    });
    return filtered.length > 1;
  }

  addRow() {
    this.invoicedOrder.push(
      new FormGroup({
        description: new FormControl(''),
        mediaType: new FormControl(''),
        customColumn1Value: new FormControl(''),
        customColumn2Value: new FormControl(''),
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        gross: new FormControl(''),
        adjustedGross: new FormControl(''),
        netAmount: new FormControl(''),
      }, { updateOn: 'blur' })
    );
  }

  deleteRow(index: number) {
    this.rows.splice(index, 1);
    this.invoicedOrdersData.orderedData.splice(index, 1);
    this.grossSumm = this.invoicedOrdersData.orderedData.map(item => item.gross).reduce((a, b) => b ? a + b : a, 0);
    this.netAmountSumm = this.invoicedOrdersData.orderedData.map(item => item.netAmount).reduce((a, b) => b ? a + b : a, 0);
  }

  updateCustomName($event, field) {
    this.invoicedOrdersData[field] = $event.target.value;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
