<mat-card>
  <h2 class="summary-header">Batch Assignment Summary</h2>
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- user Column display none-->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> No. </th>
      <td mat-cell *matCellDef="let element"> {{displayAccountant(element.accountant)}} </td>
    </ng-container>

    <ng-container matColumnDef="user-span">
      <th mat-header-cell class="grand-column" *matHeaderCellDef [attr.rowspan]="2">Users</th>
    </ng-container>

    <!-- total Column display none-->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> No. </th>
      <td mat-cell *matCellDef="let element">
        {{element.assignedForPaymentsTotal}} / {{element.assignedForPaymentBatchesTotal}}
      </td>
    </ng-container>

    <ng-container matColumnDef="total-span">
      <th mat-header-cell  class="grand-column" *matHeaderCellDef [attr.rowspan]="2">Total Payments / Batches</th>
    </ng-container>

    <ng-container matColumnDef="AmEx">
      <th mat-header-cell class="type-column" *matHeaderCellDef> AmEx </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['AmEx'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Visa">
      <th mat-header-cell class="type-column" *matHeaderCellDef> Visa </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Visa'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Wire Transfer">
      <th mat-header-cell class="type-column" *matHeaderCellDef> Wire Transfer </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Wire Transfer'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Portal">
      <th mat-header-cell class="type-column" *matHeaderCellDef> Portal </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Portal'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Check">
      <th mat-header-cell class="type-column" *matHeaderCellDef> Check </th>
      <td mat-cell *matCellDef="let element"> {{element.assignedForPaymentsPerMethod['Check'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Direct ACH">
      <th mat-header-cell class="type-column" *matHeaderCellDef>  ACH (Manual) </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Direct ACH'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Batch ACH (MPI)">
      <th mat-header-cell class="type-column" *matHeaderCellDef>  ACH (Batch) </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Batch ACH (MPI)'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Other">
      <th mat-header-cell class="type-column" *matHeaderCellDef> Other </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Other'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="Unassigned">
      <th mat-header-cell class="type-column" *matHeaderCellDef> No payment method </th>
      <td mat-cell *matCellDef="let element"> {{ element.assignedForPaymentsPerMethod['Unassigned'] || 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="header-row-second-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="8"> Number of Payments Per Payment Type </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['user-span', 'total-span', 'header-row-second-group']"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>
