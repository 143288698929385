<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Account</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="accountForm" (ngSubmit)="submit(accountForm.value)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <div class="account-info">
            <h2>General Info</h2>
            <div class="account-info__archive" *ngIf="hasEditArchivedPermission">
              <span class="archive-label">Archive</span>
              <mat-slide-toggle formControlName="archived"></mat-slide-toggle>
            </div>
          </div>
          <mat-form-field>
            <mat-label>Account Name</mat-label>
            <input formControlName="name" matInput required maxlength="50">
            <mat-error *ngIf="accountForm.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="accountForm.get('name').hasError('validation')">
              {{accountForm.get('name').getError('validation')}}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isEditMode()">
            <mat-label>Account ID</mat-label>
            <input formControlName="code" matInput maxlength="50" disabled>
          </mat-form-field>
          <div formArrayName="consultantCompanies" *ngFor="let item of consultantsControl.controls; let i = index;">
            <div class="flex-row">
              <mat-form-field>
                <input type="text"
                  [placeholder]="getConsultantLabel(i)"
                  matInput
                  required
                  [formControlName]="i" [matAutocomplete]="auto"
                  (blur)="onBlurConsultant($event.target, i)"
                >
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayConsultant">
                  <mat-option *ngFor="let option of filteredConsultantCompanies[i] | async" [value]="option" (mousedown)="onConsultantOptionSelect(option, i)">
                    {{option.code}} - {{option.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="item.hasError('required')">
                  Consultant is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <button mat-icon-button color="primary" *ngIf="i > 0 || consultantsControl.controls.length > 1"
                      type="button" (click)="removeConsultant(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="browse-link" (click)="openDialog(i)">Browse Consultants ></div>
          </div>
          <button mat-raised-button [disabled]="consultantsControl.controls.length > 4" type="button"
                  (click)="addNewConsultant()" class="add-consultant"> Add Consultant </button>
<!--          <mat-label>Years</mat-label>
          <div class="flex-row" >
            <ng-container formArrayName="ordersExistInYears" *ngFor="let item of yearsControl.controls; let i = index;">
              <ng-container [formGroupName]="i">
                <mat-checkbox formControlName="checked" [disabled]="item.get('immutable').value" class="year-item">
                  {{item.get('year').value}}
                </mat-checkbox>
              </ng-container>
            </ng-container>
          </div>-->
          <h2>Account Type</h2>
          <section class="form-element">
            <mat-radio-group aria-label="Select Account Type" formControlName="candidate" class="margin-element">
              <mat-radio-button [value]="true">Candidate</mat-radio-button>
              <mat-radio-button [value]="false">Client</mat-radio-button>
            </mat-radio-group>
          </section>
          <h2>{{accountForm.get('candidate').value ? 'Candidate' :  'Client'}} Info</h2>
          <section class="form-element" *ngIf="!accountForm.get('candidate').value">
            <mat-checkbox formControlName="advocacy" class="margin-element">Advocacy Political</mat-checkbox>
          </section>
          <div class="flex-row" *ngIf="accountForm.get('candidate').value">
            <mat-checkbox formControlName="federal" class="year-item">Federal</mat-checkbox>
            <mat-checkbox formControlName="state" class="year-item">State</mat-checkbox>
          </div>
          <div formGroupName="address">
            <mat-form-field>
              <mat-label>Address 1</mat-label>
              <input formControlName="addressLine1" matInput maxlength="100">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address 2</mat-label>
              <input formControlName="addressLine2" matInput maxlength="100">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address 3</mat-label>
              <input formControlName="addressLine3" matInput maxlength="100">
            </mat-form-field>
            <mat-form-field>
              <mat-label>City</mat-label>
              <input formControlName="city" matInput required maxlength="50">
              <mat-error *ngIf="accountForm.get('address').get('city').hasError('required')">
                City is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state">
                    <mat-option *ngFor="let option of states" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>ZIP Code</mat-label>
                  <input formControlName="zipCode" matInput maxlength="10">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div formGroupName="cnd" *ngIf="accountForm.get('candidate').value">
            <h2>Candidate NAB Info *</h2>
            <mat-form-field>
              <mat-label>Candidate Name</mat-label>
              <input formControlName="name" matInput maxlength="50">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Authorized Committee</mat-label>
              <input formControlName="committee" matInput maxlength="50">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Candidate's Political Party *</mat-label>
              <mat-select formControlName="party">
                <mat-option *ngFor="let option of candidateParties" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Treasurer of candidate's authorized committee</mat-label>
              <input formControlName="treasurer" matInput maxlength="50">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Office sought (no acronyms or abbreviations)</mat-label>
              <input formControlName="sought" matInput maxlength="50">
            </mat-form-field>
            <mat-radio-group aria-label="Select undersigned represents" formControlName="represent">
              <mat-radio-button [value]="true">The candidate listed above who is a legally qualified candidate, or</mat-radio-button>
              <mat-radio-button [value]="false" class="margin-element">The authorized committee of the legally qualified candidate listed above.</mat-radio-button>
            </mat-radio-group>
          </div>
          <div formGroupName="cli" *ngIf="!accountForm.get('candidate').value && accountForm.get('advocacy').value">
            <h2>Political Advocacy NABs *</h2>
            <mat-radio-group aria-label="Select undersigned represents" formControlName="importance" class="radio-column">
              <mat-radio-button [value]="true">
                <span class="wrap-mat-radio-label">
                  Ad "communicates a message relating to any political matter of national importance" by referring to (1) a legally qualified candidate for federal office, (2) an election to federal office; (3) a national legislative issue of public importance (e.g., health care legislation, IRS tax code, etc.); or (4) a political issue that is the subject of controversy or discussion at a national level.
                </span>
              </mat-radio-button>
              <mat-radio-button [value]="false">
                <span class="wrap-mat-radio-label">
                  Ad does NOT communicate a message relating to any political matter of national importance (e.g., relates only to a state or local issue).
                </span>
              </mat-radio-button>
            </mat-radio-group>
            <div class="info-text">
              Name of Advertiser/Sponsor (list entity's full legal name as disclosed to the Federal Election Commission [for federal committees] with no acronymns; name must match the sponsorship ID in ad);
            </div>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput maxlength="50">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Contact</mat-label>
              <input formControlName="contact" matInput maxlength="50">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input formControlName="phone" matInput appPhoneMask maxlength="14">
              <mat-error *ngIf="accountForm.get('cli').get('phone').hasError('pattern')">
                Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput maxlength="50" placeholder="Ex. pat@example.com">
              <mat-error
                *ngIf="accountForm.get('cli').get('email').touched && accountForm.get('cli').get('email').hasError('pattern')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
            <mat-label>List the chief executive officers or members of the executive committee and the board of directors or other governing group(s) of the advertiser/sponsor </mat-label>
            <mat-form-field appearance="fill">
              <textarea rows="4" formControlName="officers" matInput [maxLength]="255"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="form-content_column">
          <app-commision-tier [breakPoints]="commissionTiersBreakPoints"
                              (addBreakPointEvent)="addBreakPoint($event)"
                              (deleteBreakPointEvent)="deleteBreakPoint($event)"
          ></app-commision-tier>
          <mat-card *ngFor="let commissionTier of commissionTiersArray.controls; let i = index;">
            <app-commission-rates
              [ratesForm]="commissionTier"
              [headerName]="getRateHeaderName(i, commissionTiersArray.controls)"
            ></app-commission-rates>
          </mat-card>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading color="primary" [loading]="saving" [disabled]="!accountForm.valid">Save</button>
      <button type="button" mat-raised-button appLoading color="primary"
              [disabled]="accountForm.invalid || saving" (click)="submit(accountForm.value, true)"
      >Save and Add New
      </button>
      <button mat-raised-button [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>
