import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { ConsultantCompany, FormMode, PaymentMethod, State, Title } from '../shared/models';
import { NotificationService } from '../../shared/notification.service';
import { EntityDictionaryService } from '../shared/entity-dictionary.service';
import { ConsultantCompanyService } from '../shared/consultant-company.service';
import { FormUtils } from '../../shared/utils/form-utils';
import { CommissionUtils } from '../shared/utils/commission-utils';
import { PhoneUtils } from '../shared/utils/phone-utils';
import { EmailUtils } from '../shared/utils/email-utils';
import { TypeaheadUtil } from 'src/app/shared/utils/typeahead-util';

@Component({
  selector: 'app-consultant-edit',
  templateUrl: './consultant-edit.component.html',
  styleUrls: ['./consultant-edit.component.scss']
})
export class ConsultantEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  consultantId: Observable<string | undefined>;
  consultantForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    cpmCompany: [null], // no form-field for edit, need to keep in model for BE
    code: [null],
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: [''],
      phone1: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone1Ext: [''],
      phone2: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone2Ext: [''],
      fax1: [''],
      contactEmail: ['', Validators.pattern(EmailUtils.EMAIL_REGEXP_PATTERN)],
      contactName: [''],
      contactTitle: [null]
    }),
    paymentMethod: [null],
  });
  FormMode = FormMode;

  commissionTiersArray = this.fb.array([]);

  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;

  states: State[];
  paymentMethods: PaymentMethod[];

  titles: Title[];
  filteredTitles: Observable<Title[]>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: ConsultantCompanyService,
              private dictionaryService: EntityDictionaryService,
              private notificationService: NotificationService,
              private fb: FormBuilder) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.consultantId = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.mode = params.get('id') ? FormMode.EDIT : FormMode.CREATE;
        return of(params.get('id')) || of(null);
      })
    );

    this.subscription.add(this.consultantId.subscribe(this.loadConsultantData,
      () => this.notificationService.error('Error occurred while trying to get consultant info.')));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get commissionTiersBreakPoints() {
    // @ts-ignore
    return Array.from(this.commissionTiersArray.value, (tier) => tier.breakPoint);
  }

  addBreakPoint = (value) => {
    const nextBreakPoint = CommissionUtils.getNextBreakPoint(value, this.commissionTiersArray) as FormGroup;
    this.commissionTiersArray.push(CommissionUtils.generateBreakPoint(this.fb, value, 1, nextBreakPoint.value));
    this.resortCommissionTiers();
  }

  deleteBreakPoint = (value) => {
    const currentBreakPoint = this.commissionTiersArray.controls
      .find((formGroup) => formGroup.get('breakPoint').value === value) as FormGroup;
    const nextBreakPoint = CommissionUtils.getNextBreakPoint(value, this.commissionTiersArray) as FormGroup;
    nextBreakPoint.patchValue({canal: currentBreakPoint.value.canal, roll: currentBreakPoint.value.roll,
      consultantRates: currentBreakPoint.value.consultantRates});

    const newTiersArray = this.commissionTiersArray.controls.filter((formGroup) => {
      return formGroup.get('breakPoint').value !== value;
    });

    this.commissionTiersArray.clear();

    newTiersArray.forEach((tierControl) => {
      this.commissionTiersArray.push(tierControl);
    });

    this.resortCommissionTiers();
  }

  loadConsultantData = (id: string) => {
    const states$ = this.dictionaryService.getStates();
    const paymentMethods$ = this.dictionaryService.getPaymentMethods();
    const titles$ = this.dictionaryService.getTitles();
    const consultant$ = (this.mode === FormMode.EDIT) ? this.service.getConsultantCompany(id) : of(null);

    forkJoin([states$, paymentMethods$, titles$, consultant$])
      .pipe(first())
      .subscribe(([states, paymentMethods, titles, consultant]) => {
        this.states = states;
        this.paymentMethods = paymentMethods;
        this.titles = titles;
        this.manageTitleFilters();

        if (consultant) {
          consultant.paymentMethod = this.paymentMethods.find(item => item.id === consultant.paymentMethod?.id);
          this.consultantForm.patchValue(consultant);

          this.consultantForm.get('address').patchValue({
            state: this.states.find(item => item.code === consultant.address?.state?.code)
          });
          CommissionUtils.fillTiersArray(this.fb, consultant.commissionTiers, this.commissionTiersArray);
        } else {
          this.commissionTiersArray.push(CommissionUtils.generateBreakPoint(this.fb, null, 1));
        }
        this.loading = false;
      });
  }

  manageTitleFilters() {
    this.filteredTitles = TypeaheadUtil.manageTitleFilter(this.consultantForm.get('address'), this.titles);
  }

  displayTitle(item?: Title): string | undefined {
    return item ? item.title : undefined;
  }

  onBlurTitle(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurTitle(this.consultantForm.get('address').get('contactTitle'), this.titles, inputElement);
  }

  onTitleOptionSelect(option) {
    TypeaheadUtil.onTitleOptionSelect(this.consultantForm.get('address').get('contactTitle'), option);
  }

  getRateHeaderName = (index, controls) => CommissionUtils.getRateHeaderName(index, controls);

  submit(consultant: ConsultantCompany, addNew?: boolean): void {
    this.saving = true;
    this.setCommissionTiers(consultant);

    const request = this.mode === FormMode.EDIT
      ? this.service.updateConsultantCompany(consultant).pipe(
        tap(() => this.notificationService.success('Consultant company was successfully updated'))
      )
      : this.service.createConsultantCompany(FormUtils.filterEmptyFields(consultant)).pipe(
        tap(() => this.notificationService.success('New consultant company created!'))
      );

    request.pipe(finalize(() => this.saving = false)).subscribe(
      () => addNew ? this.createNew() : this.returnClicked(),
      (e) => this.actionErrorHandler(e, this.mode),
    );
  }

  createNew() {
    this.router.navigate(['/entities/consultants/edit/', '']);
  }

  setCommissionTiers(consultant) {
    consultant.commissionTiers = [];
    this.commissionTiersArray.controls.forEach((tierControl) => {
      const consultantRates = [];
      tierControl.get('consultantRates').value.forEach((consultantRate, index) => {
        consultantRates.push(
          {
            consultantCode: this.mode === FormMode.EDIT ? consultant.code : null,
            rate: consultantRate,
            sortNumber: index
          });
      });

      consultant.commissionTiers.push(
        {
          breakPoint: tierControl.get('breakPoint').value,
          consultantRates,
          cpmRate: tierControl.get('canal').value,
          rollRate: tierControl.get('roll').value,
          sortNumber: tierControl.get('sortNumber').value
        });
    });
  }

  returnClicked() {
    this.router.navigateByUrl('/entities/consultants');
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.consultantForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} consultant company.`);
  }

  private resortCommissionTiers() {
    CommissionUtils.resortCommissionTiers(this.commissionTiersArray);
  }
}
