<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Consultant Commission Transaction</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <div class="commission-info" *ngIf="!isCreateMode">
            <mat-form-field>
              <mat-label>Commission ID#</mat-label>
              <input formControlName="code" matInput readonly>
            </mat-form-field>
            <h2 class="commission-info_paid" *ngIf="isPaid">PAID</h2>
          </div>

          <mat-form-field>
            <mat-label>Consultant Company</mat-label>
            <input type="text"
                   matInput
                   required
                   maxlength="50"
                   formControlName="consultantCompany"
                   [matAutocomplete]="autoConsultantCompany"
                   #consultantCompany
                   (blur)="onBlurConsultantCompany(consultantCompany)"
                   [readonly]="!isCreateMode"
            >
            <mat-autocomplete #autoConsultantCompany="matAutocomplete" [displayWith]="displayItem">
              <mat-option *ngIf="consultantCompanies.length === 0" [value]="null">No Submitted Orders for chosen Account</mat-option>
              <mat-option *ngFor="let option of filteredConsultantCompanies | async"
                          [value]="option"
                          (mousedown)="onConsultantCompanyOptionSelect(option)"
              >
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openConsultantCompanyDialog()" *ngIf="isCreateMode && hasEditPermission">
            Browse Consultant Companies >
          </div>

          <mat-form-field>
            <mat-label>Account</mat-label>
            <input type="text"
                   matInput
                   required
                   maxlength="50"
                   formControlName="account"
                   #accountInput
                   (blur)="onBlurAccount(accountInput)"
                   [matAutocomplete]="autoAccounts"
                   [readonly]="!isCreateMode"
            >
            <mat-autocomplete #autoAccounts="matAutocomplete" [displayWith]="displayItem">
              <mat-option *ngIf="accounts.length === 0" [value]="null">No Accounts for chosen Consultant</mat-option>
              <mat-option *ngFor="let option of filteredAccounts | async"
                          [value]="option"
                          (mousedown)="onAccountOptionSelect(option)"
              >
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openAccountDialog()" *ngIf="isCreateMode">Browse Accounts ></div>

          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <section class="form-element">
                <mat-form-field>
                  <mat-label>Assigned Accountant</mat-label>
                  <input type="text"
                         matInput
                         formControlName="assignedAccountant"
                         #accountantInput
                         (blur)="onBlurAccountant(accountantInput)"
                         [matAutocomplete]="autoAccountant"
                  >
                  <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayAccountant">
                    <mat-option *ngFor="let option of filteredAccountants | async" [value]="option"
                                (mousedown)="onAccountantOptionSelect(option)">
                      {{option.name}} {{option.lastName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div class="browse-link" (click)="browseUser()"
                     *ngIf="hasEditPermission">Browse Accountants >
                </div>

                <mat-form-field>
                  <mat-label>Payment Method</mat-label>
                  <mat-select formControlName="paymentMethod">
                    <mat-option *ngFor="let type of paymentMethods" [value]="type">
                      {{type.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Payment Sent Date</mat-label>
                  <input [formControl]="dateControl" [disabled]="!hasEditPermission" [matDatepicker]="pickerCreated" matInput required>
                  <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
                  <mat-datepicker #pickerCreated></mat-datepicker>
                </mat-form-field>
              </section>
              <div class="total-larger margin-top-button">Commission owed
                = {{(form.get('currentCommissionOwed').value || 0) | currency}}</div>

              <mat-form-field class="margin-top-button">
                <mat-label>Pay this amount:</mat-label>
                <mat-icon matSuffix>attach_money</mat-icon>
                <input type="number" formControlName="toPayAmount" matInput>
              </mat-form-field>
            </div>


            <div class="inner-form-content_column">
              <mat-card class="margin-top-button" *ngIf="commissionTiers?.length > 0 && !!form.get('consultantCompany').value">
                <mat-card-header>Consultant Commission Tiers</mat-card-header>
                <mat-card-content>
                  <div class="tier-row" *ngFor="let tier of commissionTiers; let i = index">
                    <div class="tier-column">
                      Tier {{ i + 1 }} - {{getConsultantRate(tier)}}%
                    </div>
                    <div class="tier-column">
                      {{getTierBreakpoint(tier, i)}}
                    </div>
                  </div>

                </mat-card-content>
              </mat-card>
             <button mat-raised-button color="accent" (click)="showReport()"
                     [disabled]="!isAccountAndConsultantCompanySelected">Details</button>
            </div>
          </div>
        </div>
        <div class="form-content_column">
          <div class="upload-consultant-commission">
            <h2>Upload Proof of Payment</h2>
            <app-file-upload
              [filesUploadedBefore]="documentsUploadedBefore"
              (filesChanges)="setConsultantCompanyTransactionDocumentation($event)"
              [id]="consultantCompanyId"
              [propId]="consultantCompanyTransactionId"
              [mode]="mode"
              [component]="component"
              [readonly]="!hasUploadDocumentPermission"
            >
            </app-file-upload>

            <div class="margin-top">
              <mat-checkbox class="checkbox-control"
                            formControlName="completed"
                            *ngIf="!isPaid"
                            [checked]="isCompleted"
              >
                I have completed this payment
              </mat-checkbox>
            </div>

            <mat-form-field>
              <mat-label>QBref#</mat-label>
              <input formControlName="quickBookReference" matInput maxlength="25">
            </mat-form-field>

            <h2>Notes</h2>
            <div *ngFor="let note of noteReverse" class="note">
              <div><b>{{note | noteHeader}}</b></div>
              <div><span>{{note.message}}</span></div>
            </div>
            <mat-form-field appearance="fill">
              <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
            </mat-form-field>

          </div>
        </div>
      </mat-card-content>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button
        type="submit"
        mat-raised-button
        appLoading
        [loading]="saving"
        color="primary"
        [disabled]="!form.valid || !hasEditPermission || isRequesting"
        (click)="saving = true; save(form);"
      >Save
      </button>
      <button type="button" mat-raised-button
              [disabled]="isRequesting" (click)="returnClicked()">Cancel
      </button>
    </mat-card-actions>
  </form>
</div>


