import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[formControlName][appZipCodeMask]'
})
export class ZipCodeMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!event) {
      return;
    }
    if (typeof event !== 'string') {
      event = event.target.value;
    }
    let newVal = event.replace(/\W/g, '');
    if (backspace && newVal.length <= 5) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\w{0,5})/, '$1');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\w{0,5})(\w{0,4})/, '$1-$2');
    }
    this.ngControl.control.patchValue(newVal, {emitModelToViewChange: false});
    this.ngControl.valueAccessor.writeValue(newVal);
  }

}


@Directive({
  selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {

}
