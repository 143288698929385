import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { first, switchMap, finalize, tap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Account, Station } from '../../entities/shared/models';
import { AccountListType, AccountStationTransaction } from '../shared/models';
import { BalanceTransactionType } from '../shared/utils/balance-transaction-type';
import { Role } from '../../shared/models/role.enum';
import { FormMode } from '../../shared/models/form-mode.enum';
import { ComponentType } from '../../shared/models/component-type.enum';
import { DateUtils } from '../../shared/utils/date-utils';
import { FormUtils } from '../../shared/utils/form-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { TransactionService } from '../shared/transaction.service';
import { StationService } from '../../entities/shared/station.service';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { BrowseOrderedStationDialogComponent } from '../browse-ordered-station-dialog/browse-ordered-station-dialog.component';

@Component({
  selector: 'app-transaction-edit',
  templateUrl: './transaction-edit.component.html',
  styleUrls: ['./transaction-edit.component.scss']
})
export class TransactionEditComponent implements OnInit, OnDestroy {
  stationId;
  accountId;
  id: Observable<string | undefined>;
  private subscription = new Subscription();
  form = this.fb.group({
    id: [ '' ],
    account: [null, Validators.required],
    station: [null, Validators.required],
    code: [''],
    amount: ['', Validators.required],
    reason: [''],
    referenceNumber: [''],
    type: [null, Validators.required],
    enteredOn: ['', Validators.required]
  });

  BalanceTransactionType = BalanceTransactionType;
  accounts: Account[];
  stations: Station[] = [];
  filteredAccounts: Observable<Account[]>;
  filteredStations: Observable<Station[]>;

  documents: File[] = [];
  documentsUploadedBefore = [];
  transactionId;
  component = ComponentType.Transaction;

  loading = true;
  saving = false;
  openedDialog = false;

  mode: FormMode = FormMode.CREATE;
  hasAccountantPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);
  hasBuyerPermission = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER]);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private service: TransactionService,
              private stationService: StationService,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.id = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        params.get('id')
          ? this.mode = FormMode.EDIT
          : this.form.patchValue({ enteredOn: new Date() });

        return of(params.get('id')) || of(null);
      })
    );

    this.accountId = this.route.snapshot.queryParamMap.get('accountId');
    this.stationId = this.route.snapshot.queryParamMap.get('stationId');

    this.subscription.add(this.id.subscribe(this.loadData,
      () => this.notificationService.error('Error occurred while trying to get credit info.')
    ));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  get hasEditPermission() {
    const commonPermission = this.hasAccountantPermission || this.hasBuyerPermission;
    return this.mode === FormMode.EDIT ?
      (this.form.get('type').value === BalanceTransactionType.REFUND ? this.hasAccountantPermission : commonPermission)
      : commonPermission;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  loadData = (id: string) => {
    const accounts$ = this.isCreateMode ? this.service.getAccountList() : of([]);
    const stations$ = this.isCreateMode && this.accountId ? this.service.getStationList(this.accountId) : of([]);
    const transaction$ = (this.mode === FormMode.EDIT) ? this.service.getTransaction(id) : of(null);
    const station$ = this.isCreateMode && this.stationId ? this.stationService.getStation(this.stationId) : of(null);

    forkJoin([ accounts$, stations$, transaction$, station$ ])
    .pipe(first())
    .subscribe(([ accounts, stations, transaction, station ]) => {
      this.accounts = accounts || [];
      this.stations = stations || [];

      if (transaction) {
        const type = transaction.type.type === BalanceTransactionType.REFUND.type ? BalanceTransactionType.REFUND :
          BalanceTransactionType.VENDOR_MEMO_CREDIT;

        this.form.patchValue({...transaction, type, enteredOn: new Date(transaction.enteredOn)});
        this.documentsUploadedBefore = transaction.documents;
        this.transactionId = id;
      } else {
        const selectedAccount = this.accounts.find(account => account.id === +this.accountId);
        if (selectedAccount) {
          this.form.patchValue({ account: selectedAccount });
          this.loadStationList(selectedAccount);
        } else if (station) {
          this.form.patchValue({ station });
        }

        if (this.hasBuyerPermission && !this.hasAccountantPermission) {
          this.form.patchValue({ type: BalanceTransactionType.VENDOR_MEMO_CREDIT});
        }

        this.manageAccountFilters();
        this.manageStationFilters();
        this.subscription.add(this.stationControl.valueChanges.subscribe(value => {
          if (!value) {
            this.loadAccountList();
          }
        }));
        this.subscription.add(this.accountControl.valueChanges.subscribe(value => {
          if (!value) {
            this.stations = [];
            this.manageAccountFilters();
            this.manageStationFilters();
          }
        }));
      }

      if (!this.hasEditPermission) {
        FormUtils.disableFormControls(this.form);
      }

      this.loading = false;
    });
  }

  loadStationList(account?: Account) {
    const stations$ = this.service.getStationList(account?.id);
    stations$.subscribe(stations => {
      this.stations = stations;
      this.filteredStations = of(stations);
      this.manageStationFilters();
    });
  }

  loadAccountList(station?: Station) {
    const accounts$ = this.service.getAccountList(+station?.id);
    accounts$.subscribe(accounts => {
      this.accounts = accounts;
      this.filteredAccounts = of(accounts);
      this.manageAccountFilters();
    });
  }

  get isCreateMode() {
    return this.mode === FormMode.CREATE;
  }

  get stationControl() {
    return this.form.get('station');
  }

  get accountControl() {
    return this.form.get('account');
  }

  displayAccount(account?: Account): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  displayStation(station?: Station): string {
    return station ? ` ${station.name}` : '';
  }

  manageAccountFilters() {
    this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);
  }

  onBlurAccount(inputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
    this.loadStationList(option);
  }

  manageStationFilters() {
    const accountId = this.accountControl.value?.id;
    this.filteredStations = accountId ? TypeaheadUtil.manageStationFilter(this.stationControl, this.stations)
      : this.stationControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.stationService.getTypeaheadStationList(term))
      ).pipe(map(results => {
        this.stations = results.map(item => ({id: Number(item.id), name: item.text}) as any);
        return this.stations;
      }));
  }

  onBlurStation(inputElement) {
    TypeaheadUtil.onBlurStation(this.stationControl, this.stations, inputElement);
  }

  onStationOptionSelect(option) {
    TypeaheadUtil.onStationOptionSelect(this.stationControl, option);
    this.loadAccountList(option);
  }

  openAccountDialog() {
    if (this.accounts.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Accounts with ordered Station', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
        {
          data: {
            selected: this.form.value.account?.id,
            type: AccountListType.STATION_TRANSACTION,
            id: this.stationControl.value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Account) => {
        if (result) {
          this.accountControl.setValue(result);
          this.loadStationList(this.accountControl.value);
        }
      });
    }
  }

  openStationDialog() {
    if (!this.accountControl.value) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'First select an account', cancelDisabled: true }});
    } else if (this.stations.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No ordered Stations for Account', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseOrderedStationDialogComponent,
        {
          data: {
            selected: this.form.value.station?.id,
            accountId: this.accountControl.value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Station) => {
        if (result) {
          this.stationControl.setValue(result);
          this.loadAccountList(this.stationControl.value);
        }
      });
    }
  }

  returnClicked() {
    this.router.navigate(['/orders/memo-refund']).then();
  }

  submit(form: FormGroup): void {
    this.createTransaction(form.value);
  }

  private createTransaction(transaction: AccountStationTransaction) {
    this.saving = true;
    transaction.enteredOn = DateUtils.dateFormatToShort(new Date(transaction.enteredOn));

    const request: any = this.mode === FormMode.EDIT
      ? this.service.updateTransaction(transaction).pipe(
        tap(() => this.notificationService.success('Transaction was updated!'))
      )
      : this.service.createTransaction(transaction).pipe(
        tap(() => this.notificationService.success('New transaction created!'))
      );

    request.subscribe(
      data => {
        if (this.documents.length) {
          this.uploadDocuments(data.id);
        } else {
          this.returnClicked();
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  uploadDocuments = (transactionId: number) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.service.uploadDocument(transactionId, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
      () => this.returnClicked(),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} transaction.`);
  }

  setCreditDocumentation(data: any) {
    this.documents = data.files;
  }

  checkAmountLength(value) {
    if (value.length === 25) {
      return false;
    }
  }
}
