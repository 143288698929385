import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CreditCardInputComponent } from './credit-card-input/credit-card-input.component';
import { ZipCodeMaskDirective } from './shared/directives/zip-code-mask.directive';
import { ReportsComponent } from './reports/reports.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { SharedComponentsModule } from '../shared-components.module';
import { ReportPreviewComponent } from './report-preview/report-preview.component';
import { SharedModule } from '../shared/shared.module';
import { AccountBalanceTableComponent } from './account-balance-table/account-balance-table.component';
import { AccountCheckReportTableComponent } from './account-check-report-table/account-check-report-table.component';
import { CommonDashboardComponent } from './common-dashboard/common-dashboard.component';
import { EntitiesModule } from '../entities/entities.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { UserAccessComponent } from './user-access/user-access.component';
import { _MatMenuDirectivesModule, MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    DashboardComponent,
    PaymentInfoComponent,
    CreditCardInputComponent,
    ZipCodeMaskDirective,
    ReportsComponent,
    AccountBalanceTableComponent,
    ReportPreviewComponent,
    AccountCheckReportTableComponent,
    CommonDashboardComponent,
    UserAccessComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    SharedComponentsModule,
    MatTableModule,
    SharedComponentsModule,
    SharedModule,
    EntitiesModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    _MatMenuDirectivesModule
  ],
  exports: [ DashboardComponent ]
})
export class DashboardModule {
}
