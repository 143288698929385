import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { AccountListType, StationInvoice } from '../shared/models';
import { Account, PaymentMethod, Station } from '../../entities/shared/models';
import { User } from '../../shared/models/user';
import { ComponentType } from '../../shared/models/component-type.enum';
import { FormMode } from '../../shared/models/form-mode.enum';
import { NotificationService } from '../../shared/notification.service';
import { PaymentService } from 'src/app/dashboard/shared/payment.service';
import { StationInvoiceService } from '../shared/station-invoice.service';
import { TransactionService } from '../shared/transaction.service';
import { StationService } from '../../entities/shared/station.service';
import { UserService } from '../../shared/user.service';
import { DateUtils } from '../../shared/utils/date-utils';
import { FormUtils } from '../../shared/utils/form-utils';
import { NoteUtil } from '../shared/utils/note-util';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { BrowseOrderedStationDialogComponent } from '../browse-ordered-station-dialog/browse-ordered-station-dialog.component';

@Component({
  selector: 'app-station-invoice-edit',
  templateUrl: './station-invoice-edit.component.html',
  styleUrls: ['./station-invoice-edit.component.scss']
})
export class StationInvoiceEditComponent implements OnInit, OnDestroy {
  stationId;
  accountId;
  id: Observable<string | undefined>;
  loading = true;
  saving = false;
  submitting = false;
  accountants: User[] = [];
  paymentMethods: PaymentMethod[] = [];
  private subscription = new Subscription();
  invoice: StationInvoice = {} as any;
  form = this.fb.group({
    id: [''],
    account: [null, Validators.required],
    station: [null, Validators.required],
    code: [''],
    referenceNumber: ['', Validators.required],
    addedOn: ['', Validators.required],
    ftcOn: new FormControl(''),
    ltcOn: new FormControl(''),
    netRanAmount: ['', Validators.required],
    noteToAdd: [null],
  });

  accounts: Account[];
  stations: Station[];
  filteredAccounts: Observable<Account[]>;
  filteredStations: Observable<Station[]>;

  documents: File[] = [];
  documentsUploadedBefore = [];
  stationInvoiceId;
  component = ComponentType.StationInvoice;

  mode: FormMode = FormMode.CREATE;
  title;

  accountLocked = false;
  stationLocked = false;
  lockedByDefault = false;
  openedDialog = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private service: StationInvoiceService,
              private transactionService: TransactionService,
              private stationService: StationService,
              private dialog: MatDialog,
              private userService: UserService,
              private paymentMethodService: PaymentService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.id = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        params.get('id')
        ? this.mode = FormMode.EDIT
        : this.form.patchValue({ addedOn: new Date() });

        return of(params.get('id')) || of(null);
      })
    );

    this.accountId = this.route.snapshot.queryParamMap.get('accountId');
    this.stationId = this.route.snapshot.queryParamMap.get('stationId');
    this.lockedByDefault = !!this.route.snapshot.queryParamMap.get('locked');
    this.title = this.route.snapshot.queryParamMap.get('title');

    this.subscription.add(this.id.subscribe(this.loadData,
      () => this.notificationService.error('Error occurred while trying to get credit info.')
    ));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.invoice.notes);
  }

  loadData = (id: string) => {
    const accounts$ = this.isCreateMode ? this.transactionService.getAccountList(this.stationId) : of([]);
    const stations$ = this.isCreateMode && this.accountId ? this.transactionService.getStationList(this.accountId) : of([]);
    const accountants$ = this.userService.getAccountants();
    const paymentMethods$ = this.paymentMethodService.getPaymentMethodList();
    const invoice$ = (this.mode === FormMode.EDIT) ? this.service.getInvoice(id) : of(null);
    const station$ = this.isCreateMode && this.stationId ? this.stationService.getStation(this.stationId) : of(null);

    forkJoin([accounts$, stations$, invoice$, accountants$, paymentMethods$, station$])
      .pipe(first())
      .subscribe(([accounts, stations, invoice, accountants, paymentMethods, station]) => {
        this.accounts = accounts || [];
        this.stations = stations || [];
        this.accountants = accountants;
        this.paymentMethods = paymentMethods;

        if (invoice) {
          this.documentsUploadedBefore = invoice.documents;
          this.stationInvoiceId = id;
          this.invoice = invoice;
          this.initInvoiceForm(invoice);
        } else {
          const selectedAccount = this.accounts.find(account => account.id === +this.accountId);

          if (selectedAccount && station) {
            this.form.patchValue({account: selectedAccount, station});
            if (this.lockedByDefault) {
              this.stationLocked = true;
              this.accountLocked = true;
            }
          } else if (selectedAccount) {
            this.form.patchValue({account: selectedAccount});
            if (this.lockedByDefault) {
              this.accountLocked = true;
            }
          } else if (station) {
            this.form.patchValue({station});
            if (this.lockedByDefault) {
              this.stationLocked = true;
            }
          }

          this.manageAccountFilters();
          this.manageStationFilters();

          this.subscription.add(this.stationControl.valueChanges.subscribe(value => {
            if (!value) {
              this.loadAccountList();
            }
          }));
          this.subscription.add(this.accountControl.valueChanges.subscribe(value => {
            if (!value) {
              this.stations = [];
              this.manageAccountFilters();
              this.manageStationFilters();
            }
          }));
        }

        this.loading = false;
      });
  }

  initInvoiceForm(invoice: StationInvoice) {
    this.form.patchValue(invoice);
    this.form.patchValue({
      addedOn: new Date(invoice.addedOn),
      ftcOn: new Date(invoice.ftcOn),
      ltcOn: new Date(invoice.ltcOn)
    });
  }

  manageAccountFilters() {
    this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);
  }

  onBlurAccount(inputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
    this.loadStationList(option);
  }

  loadStationList(account?: Account) {
    const stations$ = this.transactionService.getStationList(account?.id);
    stations$.subscribe(stations => {
      this.stations = stations;
      this.filteredStations = of(stations);
      this.manageStationFilters();
    });
  }

  manageStationFilters() {
    const accountId = this.accountControl.value?.id;
    this.filteredStations = accountId ? TypeaheadUtil.manageStationFilter(this.stationControl, this.stations)
      : this.stationControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.stationService.getTypeaheadStationList(term))
      ).pipe(map(results => {
        this.stations = results.map(item => ({id: Number(item.id), name: item.text}) as any);
        return this.stations;
      }));
  }

  onBlurStation(inputElement) {
    TypeaheadUtil.onBlurStation(this.stationControl, this.stations, inputElement);
  }

  onStationOptionSelect(option) {
    TypeaheadUtil.onStationOptionSelect(this.stationControl, option);
    this.loadAccountList(option);
  }

  loadAccountList(station?: Station) {
    const accounts$ = this.transactionService.getAccountList(+station?.id);
    accounts$.subscribe(accounts => {
      this.accounts = accounts;
      this.filteredAccounts = of(accounts);
      this.manageAccountFilters();
    });
  }

  get isCreateMode() {
    return this.mode === FormMode.CREATE;
  }

  get stationControl() {
    return this.form.get('station');
  }

  get accountControl() {
    return this.form.get('account');
  }

  displayAccount(account?: Account): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  displayStation(station?: Station): string {
    return station ? ` ${station.name}` : '';
  }

  openAccountDialog() {
    if (this.accounts.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Accounts with ordered Station', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
        {
          data: {
            selected: this.form.value.account?.id,
            type: AccountListType.STATION_TRANSACTION,
            id: this.stationControl.value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Account) => {
        if (result) {
          this.accountControl.setValue(result);
          this.loadStationList(this.accountControl.value);
        }
      });
    }
  }

  openStationDialog() {
    if (!this.accountControl.value) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'First select an account', cancelDisabled: true }});
    } else if (this.stations.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No ordered Stations for Account', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseOrderedStationDialogComponent,
        {
          data: {
            selected: this.form.value.station?.id,
            accountId: this.accountControl.value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Station) => {
        if (result) {
          this.stationControl.setValue(result);
          this.loadAccountList(this.stationControl.value);
        }
      });
    }
  }

  get accountDisabled() {
    return this.accountControl.disabled;
  }

  get stationDisabled() {
    return this.stationControl.disabled;
  }

  returnClicked() {
    this.router.navigate(['/orders/transactions/invoice']).then();
  }

  lockStation($event) {
    this.stationLocked = !this.stationLocked;
    $event.stopPropagation();
  }

  lockAccount($event) {
    this.accountLocked = !this.accountLocked;
    $event.stopPropagation();
  }

  openStationInvoiceCreate(accountId: string, stationId: string) {
    const queryParams = {
      accountId: this.accountLocked ? accountId : null,
      stationId: this.stationLocked ? stationId : null,
      locked: true
    };
    this.router.navigate(['/orders/transactions/invoice/edit/', ''], {queryParams});
  }

  get isRequesting() {
    return this.saving || this.submitting;
  }

  submit(form: FormGroup, addNew?: boolean): void {
    addNew ? this.submitting = true : this.saving = true;
    this.createInvoice(this.constructStationInvoice(form), addNew);
  }

  private createInvoice(invoice: StationInvoice, addNew?: boolean) {
    invoice.addedOn = DateUtils.dateFormatToShort(new Date(invoice.addedOn));
    invoice.ftcOn = DateUtils.dateFormatToShort(new Date(invoice.ftcOn));
    invoice.ltcOn = DateUtils.dateFormatToShort(new Date(invoice.ltcOn));

    const request: any = this.isCreateMode
      ? this.service.createInvoice(FormUtils.filterEmptyFields(invoice)).pipe(
        tap(() => this.notificationService.success('New invoice created!'))
      )
      : this.service.updateInvoice(invoice).pipe(
        tap(() => this.notificationService.success('Invoice was updated!'))
      );

    request.subscribe(
      data => {
        if (this.documents.length) {
          this.uploadDocuments(data, this.afterSaveReturnFn(!!addNew));
        } else {
          this.resetRequesting();
          this.afterSaveReturnFn(!!addNew)(data.account.id, data.station.id);
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.resetRequesting();
      }
    );
  }

  afterSaveReturnFn = (addNew: boolean) => (accountId: string, stationId: string) => {
    addNew ? this.openStationInvoiceCreate(accountId, stationId) : this.returnClicked();
  }

  uploadDocuments = (stationInvoiceData: StationInvoice, uploadNextFn: (accountId: string, stationId: string) => void) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.service.uploadDocument(stationInvoiceData.id, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.resetRequesting())).subscribe(
      () => uploadNextFn(stationInvoiceData.account.id.toString(), stationInvoiceData.station.id.toString()),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  resetRequesting() {
    this.saving = false;
    this.submitting = false;
  }

  private constructStationInvoice(form: FormGroup): StationInvoice {
    const invoiceValue = {
      ...this.invoice,
      ...form.value as StationInvoice
    };
    NoteUtil.processNotes(invoiceValue, form);

    invoiceValue.account = form.get('account').value;
    invoiceValue.station = form.get('station').value;
    invoiceValue.submitted = true; // need to support autosubmit on any save
    return invoiceValue;
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    if (err.status === 400 && err.error?.code === 'SIN008') {
      this.notificationService.error('Station invoice number should be unique for account and station.');
      this.form.get('referenceNumber').setErrors({validation: 'Station invoice number should be unique for account and station.'});
    } else {
      FormUtils.formValidationHandler(err, this.form, this.notificationService,
        `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} invoice.`);
      this.resetNote();
    }
  }

  resetNote() {
    const note = this.form.get('noteToAdd').value?.message || '';
    this.form.addControl('newNote', new FormControl(note));
  }

  setStationInvoiceDocumentation(data: any) {
    this.documents = data.files;
  }

}
