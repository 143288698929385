<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Order</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="orderForm" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">

          <mat-form-field>
            <mat-label>Account</mat-label>
            <input type="text"
              matInput
              required
              formControlName="account"
              #accountInput
              (blur)="onBlurAccount(accountInput)"
              [matAutocomplete]="auto"
              [readonly]="isEditMode" [disabled]="!canEditAccount"
            >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAccount">
              <mat-option *ngFor="let option of filteredAccounts | async" [value]="option" (mousedown)="onAccountOptionSelect(option)">
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="orderForm.get('account').hasError('required')">
              Account is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <div class="browse-link" (click)="openDialog()" *ngIf="!isEditMode">Browse Accounts ></div>

          <mat-form-field *ngIf="isEditMode">
            <mat-label>Order ID#</mat-label>
            <input formControlName="code" matInput readonly>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Election type</mat-label>
            <mat-select formControlName="electionType">
              <mat-option [value]="">
                N/A
              </mat-option>
              <mat-option *ngFor="let option of electionTypes" [value]="option">
                {{option.type}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Election Date</mat-label>
            <input matInput [matDatepicker]="pickerElectionDate" formControlName="electionDate">
            <mat-datepicker-toggle matSuffix [for]="pickerElectionDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerElectionDate ></mat-datepicker>
          </mat-form-field>

          <h2>Notes</h2>
          <div *ngFor="let note of noteReverse" class="note">
            <div><b>{{note | noteHeader}}</b></div>
            <div><span>{{note.message}}</span></div>
          </div>
          <mat-form-field appearance="fill">
            <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
          </mat-form-field>

        </div>
        <div class="form-content_column">

          <mat-accordion multi>
            <app-flight-card *ngFor="let flight of flights.controls; let i = index"
              (duplicateFlight)="duplicateFlight(i)"
              (deleteFlight)="deleteFlight(i)"
              (stationCreated)="updateStations()"
              [flightForm]="flight"
              [flightNumber]="i + 1"
              [totalFlights]="flights.length"
              [stations]="stations"
              [disabled]="!canEditFlights || disabled"
              [canDeleteFlights]="canDeleteFlights && !disabled && flight.value.couldBeDeleted"
              [expanded]="isCreate && (i === 0)"
            ></app-flight-card>
          </mat-accordion>

          <div class="flex-container flight-actions-row">
            <button type="button" mat-raised-button (click)="addFlight()"
                    [disabled]="!canEditFlights || disabled">New Flight</button>
            <button type="button" mat-raised-button (click)="reuseOldFlights()"
                    [disabled]="!orderForm.get('account').value || !canEditFlights || disabled">Reuse Old Flights</button>
<!--            <button type="button" mat-raised-button (click)="importStrataOrder()"
                    [disabled]="!canEditFlights || disabled">Import Strata Order</button>-->
            <button type="button" mat-raised-button (click)="setFlightsToZero()"
                    [disabled]="disabled">Set to zero</button>
          </div>

          <div class="summary-container">
            <div class="subheader subheader-summary mat-title">Order Summary</div>

            <div class="summary-container_inner">
              <div class="summary-container_inner_column">
                <div class="cost">{{ flights.length }}</div>
                <div class="cost-caption">Flights</div>
              </div>
              <div class="summary-container_inner_column">
                <div class="cost">{{ marketsNumber }}</div>
                <div class="cost-caption">Markets</div>
              </div>
              <div class="summary-container_inner_column">
                <div class="cost">{{ stationsNumber }}</div>
                <div class="cost-caption">Stations</div>
              </div>

            </div>
            <div class="net-total-summary">
              <div class="total-number"><b>{{netTotal.toFixed(2) | currency}}</b></div>
              <div class="total-caption"><b>Net total</b></div>
            </div>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading color="primary"
        [loading]="saving" [disabled]="!orderForm.valid || (flights.length === 0) || isRequesting"
        (click)="saving=true; save(orderForm)"
      >Save</button>
      <button mat-raised-button appLoading color="primary"
        [loading]="submitting" [disabled]="!orderForm.valid || (flights.length === 0) || isRequesting" type="button"
        (click)="saveAndSubmit()"
        *ngIf="isAllowSaveAndSubmit"
      >Save and Submit
      </button>
      <button mat-raised-button appLoading color="primary"
              [loading]="submitting" [disabled]="!orderForm.valid || (flights.length === 0) || isRequesting"
              type="button"
              (click)="saveAndSubmit(true)"
              *ngIf="isAllowSaveAndSubmit && isAllowCreateCheckRequest && isEditMode"
      >Submit and Create Check Request
      </button>

      <button mat-raised-button appLoading color="primary"
              [loading]="disabling" [disabled]="!orderForm.valid || isRequesting" type="button"
              (click)="disableOrder()"
              *ngIf="canDisableOrder && !disabled"
      >Disable
      </button>

      <button mat-raised-button appLoading
        [loading]="savingAndCreateCheck" [disabled]="!orderForm.valid || (flights.length === 0) || isRequesting" type="button"
        *ngIf="canCreateCheckRequest"
        (click)="savingAndCreateCheck=true; save(orderForm, false, true)"
      >
        Save and Create Check Request
      </button>

      <button mat-raised-button [disabled]="isRequesting" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>
