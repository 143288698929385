<mat-dialog-content class="browse-dialog">
  <div class="content" role="main">
    <div class="container">
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let row">{{row.versionLabel}}</td>
          </ng-container>

          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef>Accepted Payment Amount</th>
            <td mat-cell *matCellDef="let row">{{row.paidAmount | negativeCurrency}}</td>
          </ng-container>

          <ng-container matColumnDef="quickBookReference">
            <th mat-header-cell *matHeaderCellDef>QBref#</th>
            <td mat-cell *matCellDef="let row">{{row.quickBookReference || '-'}}</td>
          </ng-container>

          <ng-container matColumnDef="paidOn">
            <th mat-header-cell *matHeaderCellDef>Payment Received Date</th>
            <td mat-cell *matCellDef="let row">
              {{row.paidOn ? (row.paidOn | date:'shortDate') : 'N/A'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="paidBy">
            <th mat-header-cell *matHeaderCellDef>Paid By</th>
            <td mat-cell *matCellDef="let row">{{row.paidBy || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionCreatedAt">
            <th mat-header-cell *matHeaderCellDef>Last Edit</th>
            <td mat-cell *matCellDef="let row">{{row.versionCreatedAt ? (row.versionCreatedAt | date:'short') : 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionAuthor">
            <th mat-header-cell *matHeaderCellDef>Edited By</th>
            <td mat-cell *matCellDef="let row">{{row.versionAuthor || 'N/A'}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="!dataSource.filteredData.length">
          <p class="no-data">No data</p>
        </ng-container>
      </div>

      <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
</mat-dialog-actions>
