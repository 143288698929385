import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { sortListByLastEdit, getClientCandidateType } from '../../shared/utils/sort-list-util';
import { StationInvoiceService } from '../shared/station-invoice.service';
import { NotificationService } from '../../shared/notification.service';
import { StationInvoice } from '../shared/models';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { ReportsService } from '../../entities/shared/reports.service';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { EntityType } from '../../entities/shared/models/entity-type.enum';
import { Filename } from 'src/app/shared/models/filename.enum';
import { AuthService } from '../../shared/auth.service';
import { Role } from '../../shared/models/role.enum';
import { DateUtils } from 'src/app/shared/utils/date-utils';

import { StationInvoiceVersionsDialogComponent } from '../station-invoice-versions-dialog/station-invoice-versions-dialog.component';

@Component({
  selector: 'app-station-invoice-list',
  templateUrl: './station-invoice-list.component.html',
  styleUrls: ['./station-invoice-list.component.scss']
})
export class StationInvoiceListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isLoadingResults = true;
  dataSource = new MatTableDataSource<any>();

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  start: Date | null;
  end: Date | null;

  tableFilterForm = new FormGroup({
    invoiceNumber: new FormControl(''),
    accountName: new FormControl(''),
    accountCode: new FormControl(''),
    stationName: new FormControl(''),
    accountType: new FormControl(''),
    netRanAmount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ftcOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ltcOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    invoiceCreatedDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    createdDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    editBy: new FormControl(''),
    editDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    })
  });
  displayedColumns: string[] = [
    'invoiceNumber', 'accountCode',
    'accountName', 'stationName',
    'accountType', 'netRanAmount',
    'ftcOn', 'ltcOn',
    'invoiceCreatedDate',
    'createdBy', 'createdDate',
    'editBy', 'editDate', 'menu'
  ];

  dateFilters = [
    {name: 'ftcOn', filterDef: 'ftcOn-filter'},
    {name: 'ltcOn', filterDef: 'ltcOn-filter'},
    {name: 'invoiceCreatedDate', filterDef: 'invoiceCreatedDate-filter'},
    {name: 'createdDate', filterDef: 'createdDate-filter'},
    {name: 'editDate', filterDef: 'editDate-filter'},
  ];

  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private stationInvoiceService: StationInvoiceService,
              private notificationService: NotificationService,
              private reportsService: ReportsService,
              private authService: AuthService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    const invoiceList$ = this.stationInvoiceService.getInvoices();

    this.subscription.add(invoiceList$.subscribe(
      data => {
        this.dataSource.data = sortListByLastEdit(data);
        this.isLoadingResults = false;
      },
      () => this.notificationService.error('Error occurred while trying to get invoice list.')
    ));

    this.manageDateFilter();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.ftcOn.value = value.ftcOn?.value?.toString();
      value.ltcOn.value = value.ltcOn?.value?.toString();
      value.invoiceCreatedDate.value = value.invoiceCreatedDate?.value?.toString();
      value.createdDate.value = value.createdDate?.value?.toString();
      value.editDate.value = value.editDate?.value?.toString();
      value.netRanAmount.value = value.netRanAmount?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.stationInvoiceService.getInvoices(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe((data) => this.dataSource.data = sortListByLastEdit(data),
        () => this.notificationService.error('Error occurred while trying to get station invoice list.'));
  }

  sortingDataAccessor = (item: StationInvoice, property: string) => {
    switch (property) {
      case 'invoiceNumber':
        return item.referenceNumber;
      case 'accountCode':
        return item.account.code;
      case 'accountName':
        return item.account.name;
      case 'stationName':
        return item.station.name;
      case 'accountType':
        return this.getAccountType(item.account);
      case 'ftcOn':
        return  new Date(item.ftcOn);
      case 'ltcOn':
        return  new Date(item.ltcOn);
      case 'invoiceCreatedDate':
        return new Date(item.addedOn);
      case 'createdBy':
        return item.createdBy;
      case 'createdDate':
        return  new Date(item.createdAt);
      case 'editBy':
        return item.lastUpdatedBy;
      case 'editDate':
        return  new Date(item.lastUpdatedAt);
      default: return item[property];
    }
  }

  onViewClicked(invoiceId) {
    this.router.navigate(['/orders/transactions/invoice/detail', invoiceId]);
  }

  showVersions(invoiceId: number) {
    this.dialog.open(StationInvoiceVersionsDialogComponent, { data: { id: invoiceId } });
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.account)).filter(item => !!item))];
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const invoiceNumberSearch = FilterUtils.searchFunction(searchTerms.invoiceNumber, data.referenceNumber);
      const accountCodeSearch = FilterUtils.searchFunction(searchTerms.accountCode, data.account?.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account?.name);
      const stationNameSearch = FilterUtils.searchFunction(searchTerms.stationName, data.station?.name);
      const ftcOnSearch = FilterUtils.createDateSearch('ftcOn', 'ftcOn', searchTerms, data);
      const ltcOnSearch = FilterUtils.createDateSearch('ltcOn', 'ltcOn', searchTerms, data);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.account));
      const netRanAmountSearch = FilterUtils.createFieldDigitSearch('netRanAmount', searchTerms, data.netRanAmount);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const editBySearch = FilterUtils.searchFunction(searchTerms.editBy, data.lastUpdatedBy);

      const invoiceCreatedDateSearch = FilterUtils.createDateSearch('invoiceCreatedDate', 'addedOn', searchTerms, data);
      const createdDateSearch = FilterUtils.createDateSearch('createdDate', 'createdAt', searchTerms, data);
      const editDateSearch = FilterUtils.createDateSearch('editDate', 'lastUpdatedAt', searchTerms, data);

      const filterFunctions = [
        invoiceNumberSearch(), accountCodeSearch(),
        accountNameSearch(), stationNameSearch(),
        accountTypeSearch(), netRanAmountSearch(),
        ftcOnSearch(), ltcOnSearch(),
        createdBySearch(), editBySearch(),
        invoiceCreatedDateSearch(), createdDateSearch(),
        editDateSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  onExportClicked() {
    this.isReportLoading$.next(true);

    const sortedFilteredData = ReportsUtil.getSortedFilteredData<StationInvoice>(this.dataSource);
    this.reportsService.generateExcelReport(ReportsUtil.createRequestReport(sortedFilteredData, EntityType.StationInvoice))
      .subscribe(
        data => ReportsUtil.downloadFile(data, Filename.StationInvoiceList),
        () =>  this.notificationService.error('Error occurred while trying to download report file.'),
        () => this.isReportLoading$.next(false)
      );
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
