import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';

import { FormMode } from '../../shared/models/form-mode.enum';
import {
  Payment,
  PaymentBatchAssignmentSummaryEntry,
  paymentBatchStatuses,
  PaymentCheckBatch,
  PaymentCheckRequest,
  PaymentMethodAccountantAssignment
} from '../shared/models';
import { User } from '../../shared/models/user';
import { PaymentMethod } from 'src/app/entities/shared/models/payment-method';
import { Role } from '../../shared/models/role.enum';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { EntityDictionaryService } from 'src/app/entities/shared/entity-dictionary.service';
import { PaymentBatchService } from '../shared/payment-batch.service';
import { UserService } from '../../shared/user.service';
import { PaymentCheckRequestService } from '../shared/payment-check-request.service';
import { FormUtils } from '../../shared/utils/form-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { NoteUtil } from '../shared/utils/note-util';
import { ReportsUtil } from '../../shared/utils/reports-util';

import { BrowseCheckDialogComponent } from '../browse-check-dialog/browse-check-dialog.component';
import { BrowseUserDialogComponent } from '../browse-user-dialog/browse-user-dialog.component';
import { ConfirmationDialogResult } from '../../shared/models/confirmation-dialog-result';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-payment-edit',
  templateUrl: './payment-edit.component.html',
  styleUrls: ['./payment-edit.component.scss']
})
export class PaymentEditComponent implements OnInit, OnDestroy {
  UNASSIGNED = 'Unassigned';
  private subscription = new Subscription();
  assignmentSummary: PaymentBatchAssignmentSummaryEntry[];
  assignmentSummaryEntriesGlobal: PaymentBatchAssignmentSummaryEntry[] = [];
  assignmentSummaryEntriesLocal: PaymentBatchAssignmentSummaryEntry[] = [];
  currentAssignmentSummaryEntries: PaymentBatchAssignmentSummaryEntry[] = [];
  form = this.fb.group({
    id: [''],
    code: [''],
    status: [null],
    checkRequest: [null, Validators.required],
    assignedAccountant: [null, Validators.required],
    paymentMethodAccountantAssignments: this.fb.array([]),
  });
  flightsInvalid = [];
  paymentStations = this.fb.array([]);

  filters = new FormGroup({
    paymentMethod: new FormControl(''),
    emptyQB: new FormControl(false)
  });

  checkRequests: PaymentCheckRequest[] = [];
  filteredCheckRequests: Observable<PaymentCheckRequest[]>;
  paymentMethods: PaymentMethod[];
  accountants: User[] = [];
  filteredAccountants: Observable<User[]>;

  paymentsDocuments: Array<{ files: File[], paymentId: number }>;
  checkRequestPaymentsAssignedInitial: Array<{ paymentMethod: PaymentMethod, pmAssignedAccountant: User }>;

  mode: FormMode = FormMode.CREATE;
  loading = true;
  templateLoading = false;
  saving = false;
  declining = false;
  openedDialog = false;
  filteredPayments: Payment[] = [];

  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);
  isExportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private dictionaryService: EntityDictionaryService,
              private paymentBatchService: PaymentBatchService,
              private checkService: PaymentCheckRequestService,
              private authService: AuthService,
              private usersService: UserService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    const checkId = this.route.snapshot.queryParamMap.get('checkId'); // in case to create payment from check request
    this.loadData(id, checkId);

    this.subscription.add(this.form.get('checkRequest').valueChanges.subscribe((value) => {
        if (!this.form.get('checkRequest').hasError('required')
          && typeof value !== 'string') {
          this.form.patchValue({assignedAccountant: value.assignedAccountant});
          this.checkRequestPaymentsAssignedInitial = value.payments?.map(payment => {
            return { paymentMethod: payment.paymentMethod, pmAssignedAccountant:  payment.pmAssignedAccountant };
          });
          this.filterPayments();
          this.buildPaymentBatchStationsForm(value);
          this.buildAccountantAssignmentsForm(value);
          this.changeAssignmentSummary();
        }
      })
    );

    this.subscription.add(this.filters.valueChanges.subscribe((value) => this.filterPayments(value)));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get sortedPaymentBatchStations() {
    if (!this.checkRequest.payments.length) {
      return [];
    }
    const itemsUncompleted = this.checkRequest.payments.filter(item => !item.paid && !item.quickBookReference);
    const itemsQuickBookUncompleted = this.checkRequest.payments.filter(item => !item.paid && item.quickBookReference);
    const itemsCompleted = this.checkRequest.payments.filter(item => item.paid && !item.quickBookReference);
    const itemsQuickBookCompleted = this.checkRequest.payments.filter(item => item.paid && item.quickBookReference);

    return [
      ...itemsUncompleted,
      ...itemsQuickBookUncompleted,
      ...itemsCompleted,
      ...itemsQuickBookCompleted
    ];
  }

  get saveDisabled() {
    return !this.form.valid || this.isRequesting || this.flightsInvalid.find(invalid => invalid) || !this.allStationsHavePaymentMethod();
  }

  get checkRequest() {
    return this.form.get('checkRequest').value;
  }

  get notes() {
    return this.checkRequest?.notes?.sort((item1, item2) => (item1.sortNumber > item2.sortNumber) ? -1 : 1);
  }

  get isEdit() {
    return this.mode === FormMode.EDIT;
  }

  get isPaymentBatchStationReadonly() {
    return !this.hasEditPermission;
  }

  get paymentStationsFormArray(): FormArray {
    return this.paymentStations as FormArray;
  }

  set paymentStationsFormArray(val: FormArray) {
    this.paymentStations = val;
  }

  get paymentPatchStationsFormGroups(): FormGroup[] {
    return this.paymentStationsFormArray?.controls as FormGroup[];
  }

  get accountantAssignmentsFormArray(): FormArray {
    return this.form.get('paymentMethodAccountantAssignments') as FormArray;
  }

  set accountantAssignmentsFormArray(val: FormArray) {
    this.form.removeControl('paymentMethodAccountantAssignments');
    this.form.addControl('paymentMethodAccountantAssignments', val);
  }

  get accountantAssignmentsFormGroup(): FormGroup[] {
    return this.accountantAssignmentsFormArray?.controls as FormGroup[];
  }

  get canDecline() {
    return this.hasEditPermission && this.form.get('status').value?.id === paymentBatchStatuses.PROCESSING_1.id;
  }

  get isRequesting() {
    return this.saving || this.declining;
  }

  get account() {
    const checkAccount = this.checkRequest?.account;
    return checkAccount ? `${checkAccount.code}, ${checkAccount.name}` : '';
  }

  get accountCompany() {
    const cpmCompany = this.checkRequest?.account?.cpmCompany;
    return cpmCompany ? cpmCompany.name : '';
  }

  loadData(id: string, checkId: string) {
    const paymentMethods$ = this.dictionaryService.getPaymentMethods();
    const accountants$ = this.usersService.getAccountants();
    const checks$ = this.isEdit ? of([]) : this.checkService.getReadyToPayCheckRequests();
    const batch$ = this.isEdit ? this.paymentBatchService.getPaymentBatch(id) : of(null);
    const assignmentSummary$ = this.paymentBatchService.getPaymentAssignmentSummary();

    forkJoin([paymentMethods$, accountants$, checks$, batch$, assignmentSummary$])
      .pipe(first())
      .subscribe(([paymentMethods, accountants, checks, batch, assignmentSummary]) => {
        this.paymentMethods = paymentMethods;
        this.accountants = accountants;
        this.filteredAccountants = TypeaheadUtil.manageAccountantFilter(this.form, this.accountants);
        this.checkRequests = checks;
        this.assignmentSummary = assignmentSummary;

        if (this.isEdit) {
          this.paymentsDocuments = batch.checkRequest.payments?.map(payment => ({ files: [], paymentId: payment.id }));
          this.form.patchValue({...batch, assignedAccountant: batch.checkRequest.assignedAccountant});
          this.buildPaymentBatchStationsForm(batch.checkRequest);
          this.buildAccountantAssignmentsForm(batch.checkRequest);
          this.assignmentSummaryEntriesLocal = this.calculateAssignmentSummaryEntriesLocal();
          this.assignmentSummaryEntriesGlobal =
            this.calculateClearSummaryEntriesGlobal(this.assignmentSummary, this.assignmentSummaryEntriesLocal);
        } else {
          let checkRequest = null;
          if (checkId) {
            checkRequest = checks.find(item => item.id === +checkId) || null;
            this.paymentsDocuments = checkRequest.payments?.map(payment => ({ files: [], paymentId: payment.id }));
            this.checkRequestPaymentsAssignedInitial = checkRequest.payments?.map(payment => {
                return { paymentMethod: payment.paymentMethod, pmAssignedAccountant:  payment.pmAssignedAccountant };
              });
          }
          this.form.patchValue({checkRequest, assignedAccountant: checkRequest ? checkRequest.assignedAccountant : null}); // init selected
          this.filteredCheckRequests = TypeaheadUtil.manageCheckRequestFilter(this.form.get('checkRequest'), this.checkRequests);

          this.assignmentSummaryEntriesLocal = this.calculateAssignmentSummaryEntriesLocal();
          this.assignmentSummaryEntriesGlobal = this.assignmentSummary;
        }
        this.changeAssignmentSummary();
        this.loading = false;
      });
  }

  accountantSelectionChanged(control: FormGroup, value) {
    control.get('accountant').patchValue(value);
    this.form.get('paymentMethodAccountantAssignments').updateValueAndValidity();
    this.changeAssignmentSummary();
  }

  onPaymentMethodChanged($event: any, paymentBatchStationsFormGroup: FormGroup) {
    paymentBatchStationsFormGroup.get('paymentMethod').setValue($event, {emitEvent: false});
    this.buildAccountantAssignmentsForm(this.checkRequest);
    this.updateStationPaymentMethod(paymentBatchStationsFormGroup.get('station').value?.id, $event);
    this.changeAssignmentSummary();
  }

  updateStationPaymentMethod(stationId: number, method: PaymentMethod) {
    const payment = this.checkRequest.payments?.find((item: Payment) => item.station.id === stationId);
    if (payment) {
      payment.paymentMethod = method;
    }
  }

  onBlurCheckRequest(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurCheckRequest(this.form.get('checkRequest'), this.checkRequests, inputElement);
    if (this.form.value.checkRequest?.payments?.length) {
      this.paymentsDocuments = this.form.value.checkRequest.payments.map(payment => ({ files: [], paymentId: payment.id }));
    }
  }

  onCheckRequestOptionSelect(option) {
    TypeaheadUtil.onCheckRequestOptionSelect(this.form.get('checkRequest'), option);
  }

  onBlurAccountant(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccountant(this.form, this.accountants, inputElement);
  }

  onAccountantOptionSelect(option) {
    TypeaheadUtil.onAccountantOptionSelect(this.form, option);
  }

  displayAccountant(accountant?: User): string {
    return TypeaheadUtil.displayAccountant(accountant);
  }

  onFlightFormChange(invalid: boolean, i: number) {
    this.flightsInvalid[i] = invalid;
  }

  onBatchDocumentationChange(files: File[], paymentId: number) {
    const paymentDocuments = this.paymentsDocuments.find(paymentDocs => paymentDocs.paymentId === paymentId);
    paymentDocuments.files = files;
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/payments').then();
  }

  submit(form: FormGroup, exportMode = false) {
    this.saving = true;
    this.updateAccountantAssignments(form);

    const batch = form.value;
    batch.checkRequest.assignedAccountant = batch.assignedAccountant;
    this.prepareAccountantAssignments(batch);

    const request: Observable<any> = this.isEdit
      ? this.paymentBatchService.updatePaymentBatch(batch).pipe(
        tap(() => this.notificationService.success('Payment batch was updated!'))
      )
      : this.paymentBatchService.createPaymentBatch(batch).pipe(
        tap(() => this.notificationService.success('New payment batch created!'))
      );

    request.subscribe(
      data => {
        const withDocs = batch.checkRequest.payments?.length && this.paymentsDocuments?.length;
        if (withDocs) {
          this.uploadPaymentsDocuments(data.id, exportMode);
        }
        if (exportMode) {
          this.exportPayment(data.id);
          this.saving = false;
        } else if (!withDocs) {
          this.returnClicked();
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  uploadPaymentsDocuments(batchId: number, exportMode = false) {
    const documents = [];
    const documents$ = [];
    this.paymentsDocuments
    .filter(paymentDocuments => paymentDocuments && paymentDocuments.files.length)
    .forEach(paymentDocument => {
      paymentDocument.files.forEach(file => {
        documents.push({ file, paymentId: paymentDocument.paymentId });
      });
    });

    if (documents.length) {
      documents.forEach(document => {
        documents$.push(this.paymentBatchService.uploadDocument(batchId, document.paymentId, document.file));
      });

      forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
        () => {
          if (!exportMode) {
            this.returnClicked();
          }
        },
        (error) => {
          if (error.status === 400 && error.error?.code === 'DM008') {
            this.notificationService.error('Uploading failed. File contains viruses.');
          } else {
            this.notificationService.error('Error occurred while trying to upload documents.');
          }
        }
      );
    } else {
      if (!exportMode) {
        this.returnClicked();
      }
    }
  }

  exportPayment(batchId: number) {
    this.paymentBatchService.exportBatch(batchId).pipe(finalize(() => this.isExportLoading$.next(false)))
      .subscribe((data: HttpResponse<any>) => {
          const filename = data.headers.get('filename');
          ReportsUtil.downloadFile(data.body as ArrayBuffer, filename);
          if (this.isEdit) {
            this.loadData(this.form.get('id').value, '');
          } else {
            this.router.navigateByUrl(`/orders/payments/edit/${batchId}`).then();
          }
        },
        () => this.notificationService.error('Error occurred while trying to download Payment Batch.')
      );
  }

  updateAccountantAssignments(form: FormGroup) {
    form.get('paymentMethodAccountantAssignments').updateValueAndValidity({emitEvent: false});
  }

  prepareAccountantAssignments(batch: PaymentCheckBatch) {
    const payments = batch.checkRequest.payments;
    batch.checkRequest.payments = payments;
    this.paymentPatchStationsFormGroups.forEach(stationFormGroup => {
      const paymentToChange = payments
        .find(payment => payment.station.id === stationFormGroup.value.station.id);
      paymentToChange.paymentMethod = stationFormGroup.value.paymentMethod;
    });
    batch.checkRequest.payments = payments;
  }

  declineBatch() {
    this.declining = true;
    this.openDeclineConfirmationDialog()
      .afterClosed()
      .subscribe((result: ConfirmationDialogResult) => {
        if (result?.value) {
          this.updateCheckRequestWithNote(result.note);
        } else {
          this.declining = false;
        }
      });
  }

  private allStationsHavePaymentMethod(): boolean {
    return this.paymentPatchStationsFormGroups
      .map(formGroup => formGroup.value.paymentMethod)
      .every(paymentMethod => !!paymentMethod);
  }

  private updateCheckRequestWithNote(noteMessage: string){
    const checkRequest = this.checkRequest;
    checkRequest.noteToAdd = NoteUtil.createNote(noteMessage);
    this.checkService.updateCheckRequest(checkRequest.id, checkRequest).subscribe(
      () => {
        this.paymentBatchService.declinePaymentBatch(this.form.get('id').value)
          .pipe(finalize(() => this.declining = false))
          .subscribe(
            () => {
              this.notificationService.success('Payment Batch declined!');
              this.router.navigateByUrl('/orders/payments');
            },
            () => this.notificationService.error('Error occurred while trying to decline Payment Batch')
          );
      },
      () => this.notificationService.error('Error occurred while trying to add note to the check request.')
    );
  }

  private openDeclineConfirmationDialog(): MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult> {
    const dialogData = {data: {
        message: `Are you sure you want to decline this batch?`,
        isComment: true,
        isCommentRequired: true
      }};
    return this.dialog.open(ConfirmationDialogComponent,  dialogData);
  }

  displayCheckRequest(request?: any): string {
    return request ? request.code : '';
  }

  browseRequest() {
    const dialogRef = this.dialog.open(BrowseCheckDialogComponent,
      {data: {selected: this.form.value.checkRequest?.id}}
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.form.patchValue({checkRequest: result});
      }
    });
  }

  browseUser() {
    const dialogRef = this.dialog.open(BrowseUserDialogComponent,
      {data: {selected: this.form.value.assignedAccountant?.id}}
    );
    dialogRef.afterClosed().subscribe((result: User) => {
      if (result) {
        this.form.patchValue({assignedAccountant: result});
      }
    });
  }

  showExport(accountantAssignmentsGroup: FormGroup) {
    return (accountantAssignmentsGroup.value.paymentMethod?.name || '').toUpperCase().includes('(MPI)');
  }

  onExportClicked() {
    this.isExportLoading$.next(true);
    this.submit(this.form, true);
  }

  private buildPaymentBatchStationsForm(checkRequest: PaymentCheckRequest) {
    this.paymentStationsFormArray = this.fb.array([]);

    checkRequest.payments?.forEach(payment => {
      const stationGroup = this.fb.group({
        station: [payment.station],
        paymentMethod: [payment.paymentMethod ?? null]
      });
      this.paymentStationsFormArray.controls.push(stationGroup);
    });
    this.paymentStationsFormArray.updateValueAndValidity({emitEvent: false});
  }

  private buildAccountantAssignmentsForm(checkRequest: PaymentCheckRequest) {
    const pastAccountantAssignments = this.accountantAssignmentsFormArray
      .controls
      .map(control => control.value);
    this.accountantAssignmentsFormArray = this.fb.array([]);

    const paymentMethods = this.paymentPatchStationsFormGroups
      .map(formGroup => formGroup.get('paymentMethod').value)
      .filter(paymentMethod => !!paymentMethod);

    const uniquePaymentMethods = [...new Set(paymentMethods.map(item => item.id))]
      .map(id => {
        return paymentMethods.find(item => item.id === id);
      });

    uniquePaymentMethods?.forEach(paymentMethod => {
      const pastAccountantAssignment = pastAccountantAssignments.find(item => item.paymentMethod.id === paymentMethod.id);
      const foundAccountantObject = pastAccountantAssignment?.accountant
        ? this.accountants.find(acc => acc.id === pastAccountantAssignment.accountant.id)
        : !this.isEdit ? this.checkRequestPaymentsAssignedInitial
          .find(item => item.paymentMethod?.id === paymentMethod.id)?.pmAssignedAccountant : null;
      let accountant = null;
      if (foundAccountantObject) {
        accountant = this.accountants.find(v => v.id === foundAccountantObject.id);
      }
      const accountantAssignmentsGroup = this.fb.group({
        paymentMethod: [paymentMethod],
        accountant: [accountant ?? null, Validators.required]
      });
      this.accountantAssignmentsFormArray.controls.push(accountantAssignmentsGroup);
    });
    this.accountantAssignmentsFormArray.updateValueAndValidity({emitEvent: false});
  }

  private calculateAssignmentSummaryEntries(assignmentSummaryEntriesLocal: PaymentBatchAssignmentSummaryEntry[],
                                            assignmentSummaryEntriesGlobal: PaymentBatchAssignmentSummaryEntry[]):
    PaymentBatchAssignmentSummaryEntry[] {

    const assignmentGlobalAccountants = assignmentSummaryEntriesGlobal.map(entry => entry.accountant?.id);
    const allEntries = [...assignmentSummaryEntriesGlobal.map(entry => entry.accountant),
      ...assignmentSummaryEntriesLocal.map(entry => entry.accountant).filter(item => !assignmentGlobalAccountants.includes(item?.id))];

    return allEntries.map(accountant => {
      let globalEntry = assignmentSummaryEntriesGlobal
        .find(item => item.accountant?.id === accountant?.id);
      if (!globalEntry) {
        globalEntry = this.createClearAssignmentSummaryEntry(accountant);
      }

      let localEntry = assignmentSummaryEntriesLocal
        .find(item => item.accountant?.id === accountant?.id);
      if (!localEntry) {
        localEntry = this.createClearAssignmentSummaryEntry(accountant);
      }

      const assignedForPaymentsPerMethod = this.calcAssignedForPaymentsPerMethodObject(globalEntry, localEntry);
      const paymentsTotal = globalEntry.assignedForPaymentsTotal + localEntry.assignedForPaymentsTotal;
      const batchesTotal = globalEntry.assignedForPaymentBatchesTotal + localEntry.assignedForPaymentBatchesTotal;
      return this.buildAssignmentSummaryEntry(accountant, paymentsTotal, batchesTotal, assignedForPaymentsPerMethod);
    });
  }

  private changeAssignmentSummary() {
    this.assignmentSummaryEntriesLocal = this.calculateAssignmentSummaryEntriesLocal();
    this.currentAssignmentSummaryEntries =
      this.calculateAssignmentSummaryEntries(this.assignmentSummaryEntriesLocal, this.assignmentSummaryEntriesGlobal);
  }

  private calculateAssignmentSummaryEntriesLocal(): PaymentBatchAssignmentSummaryEntry[] {
    const entries = [] as PaymentBatchAssignmentSummaryEntry[];
    this.accountantAssignmentsFormGroup
      .map(formGroup => formGroup.value as PaymentMethodAccountantAssignment)
      .forEach(assignment => {

        const paymentBatchStationsCount = this.paymentPatchStationsFormGroups
          .map(formGroup => formGroup.value)
          .filter(formGroup => {
            return !this.filteredPayments.find(payment => payment.station.id === formGroup.station.id).paid;
          })
          .reduce((summ, v) => v.paymentMethod?.id === assignment.paymentMethod.id ? summ + 1 : summ, 0);

        const accountant = assignment.accountant;
        const paymentsTotal =  paymentBatchStationsCount;
        const batchesTotal = paymentBatchStationsCount === 0 ? 0 : 1;
        const assignedPerMethod = this.buildAssignedForPaymentsPerMethodObject();
        assignedPerMethod[assignment.paymentMethod.name] = paymentBatchStationsCount;
        const index = entries.findIndex(entry => entry.accountant?.id === assignment.accountant?.id);
        if (index >= 0) {
          entries[index].assignedForPaymentBatchesTotal = (entries[index].assignedForPaymentBatchesTotal || 0) + batchesTotal;
          entries[index].assignedForPaymentsTotal = (entries[index].assignedForPaymentsTotal || 0) + paymentBatchStationsCount;
          entries[index].assignedForPaymentsPerMethod[assignment.paymentMethod.name] = paymentBatchStationsCount;
        } else {
          entries.push(this.buildAssignmentSummaryEntry(accountant, paymentsTotal, batchesTotal, assignedPerMethod ));
        }
      });

    const unassignedBatchStationsCount = this.paymentPatchStationsFormGroups.filter(formGroup => !formGroup.value.paymentMethod);
    if (unassignedBatchStationsCount?.length) {

      const accountant = null;
      const paymentsTotal =  unassignedBatchStationsCount.length;
      const batchesTotal = 1;
      const assignedPerMethod = this.buildAssignedForPaymentsPerMethodObject();
      assignedPerMethod[this.UNASSIGNED] = unassignedBatchStationsCount.length;
      const index = entries.findIndex(entry => entry.accountant === null);
      if (index >= 0) {
        entries[index].assignedForPaymentBatchesTotal = batchesTotal;
        entries[index].assignedForPaymentsTotal = (entries[index].assignedForPaymentsTotal || 0) + unassignedBatchStationsCount.length;
        entries[index].assignedForPaymentsPerMethod[this.UNASSIGNED] = (entries[index].assignedForPaymentsPerMethod[this.UNASSIGNED] || 0)
          + unassignedBatchStationsCount.length;
      } else {
        entries.push(this.buildAssignmentSummaryEntry(accountant, paymentsTotal, batchesTotal, assignedPerMethod ));
      }
    }
    return entries;
  }

  /**
   * Creates new assignment summary entries and returns the index of this entry in local array.
   * @param assignedAccount Accountant of new entry.
   */
  private createLocalAssignmentSummaryEntry(assignedAccount: User): number {
    const localEntry = this.createClearAssignmentSummaryEntry(assignedAccount);
    return this.assignmentSummaryEntriesLocal.push(localEntry) - 1;
  }

  private createClearAssignmentSummaryEntry(assignedAccount: User): PaymentBatchAssignmentSummaryEntry {
    const assignedForPaymentsPerMethod = {};
    this.paymentMethods.forEach(item => assignedForPaymentsPerMethod[item.name] = 0);
    return {
      accountant: assignedAccount,
      assignedForPaymentsTotal: 0,
      assignedForPaymentBatchesTotal: 0,
      assignedForPaymentsPerMethod
    } as PaymentBatchAssignmentSummaryEntry;
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} payment batch.`);
  }

  /**
   * Calculates assignment summary entries global without saved payments form current batch.
   */
  private calculateClearSummaryEntriesGlobal(assignmentSummaryFormServer: PaymentBatchAssignmentSummaryEntry[],
                                             assignmentSummaryEntriesLocal: PaymentBatchAssignmentSummaryEntry[])
                                                : PaymentBatchAssignmentSummaryEntry[] {
    return assignmentSummaryFormServer.map((globalEntry, index) => {
      let localEntry = assignmentSummaryEntriesLocal
        .find(item => item.accountant?.id === globalEntry.accountant?.id);
      if (!localEntry) {
        localEntry = this.createClearAssignmentSummaryEntry(globalEntry.accountant);
      }

      const calcPaymentsPerMethodAsSum = false;
      const assignedForPaymentsPerMethod = this.calcAssignedForPaymentsPerMethodObject(globalEntry, localEntry, calcPaymentsPerMethodAsSum);

      const accountant = globalEntry.accountant;
      const paymentsTotal =  globalEntry.assignedForPaymentsTotal - localEntry.assignedForPaymentsTotal;
      const batchesTotal = globalEntry.assignedForPaymentBatchesTotal - localEntry.assignedForPaymentBatchesTotal;
      return this.buildAssignmentSummaryEntry(accountant, paymentsTotal,
        batchesTotal, assignedForPaymentsPerMethod);
    });
  }

  private buildAssignedForPaymentsPerMethodObject(): Map<string, number> {
    const assignedForPaymentsPerMethod = {} as Map<string, number>;
    this.paymentMethods.forEach(paymentMethod => {
      assignedForPaymentsPerMethod[paymentMethod.name] = 0;
    });
    return assignedForPaymentsPerMethod;
  }

  private calcAssignedForPaymentsPerMethodObject(globalEntry: PaymentBatchAssignmentSummaryEntry,
                                                 localEntry: PaymentBatchAssignmentSummaryEntry,
                                                 isSumCalculate = true): Map<string, number> {
    const assignedForPaymentsPerMethod = this.buildAssignedForPaymentsPerMethodObject();
    this.paymentMethods.forEach(paymentMethod => {
      const methodName = paymentMethod.name;
      assignedForPaymentsPerMethod[paymentMethod.name] = isSumCalculate
        ? globalEntry.assignedForPaymentsPerMethod[methodName] + localEntry.assignedForPaymentsPerMethod[methodName]
        : globalEntry.assignedForPaymentsPerMethod[methodName] - localEntry.assignedForPaymentsPerMethod[methodName];
    });

    if (globalEntry.assignedForPaymentsPerMethod[this.UNASSIGNED]) {
      assignedForPaymentsPerMethod[this.UNASSIGNED] = globalEntry.assignedForPaymentsPerMethod[this.UNASSIGNED];
    }
    if (localEntry.assignedForPaymentsPerMethod[this.UNASSIGNED]) {
      assignedForPaymentsPerMethod[this.UNASSIGNED] = localEntry.assignedForPaymentsPerMethod[this.UNASSIGNED];
    }
    return assignedForPaymentsPerMethod;
  }

  private buildAssignmentSummaryEntry(accountant: User, paymentsTotal: number, batchesTotal: number,
                                      assignedPerMethod: object): PaymentBatchAssignmentSummaryEntry {
    return {
      accountant,
      assignedForPaymentsTotal: paymentsTotal,
      assignedForPaymentBatchesTotal: batchesTotal,
      assignedForPaymentsPerMethod: assignedPerMethod
    } as PaymentBatchAssignmentSummaryEntry;
  }

  private filterPayments(value?: any) {
    const emptyQB = value ? value.emptyQB : this.filters.get('emptyQB').value;
    const paymentMethod = value ? value.paymentMethod : this.filters.get('paymentMethod').value;
    let filteredPayments = this.sortedPaymentBatchStations;

    if (filteredPayments?.length) {
      if (emptyQB) {
        filteredPayments = filteredPayments.filter(item => !item.quickBookReference);
      }
      if (paymentMethod) {
        filteredPayments = filteredPayments.filter(item => item.paymentMethod && item.paymentMethod.id === paymentMethod.id);
      }
    }
    this.filteredPayments = filteredPayments;
  }
}
