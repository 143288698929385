<div class="subheader mat-title">Commission Tiers</div>
<mat-card class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="tierAmount">
      <th mat-header-cell *matHeaderCellDef>Tier Amount</th>
      <td class="tier-cell" mat-cell *matCellDef="let row">{{row.tierAmount}}</td>
    </ng-container>
    <ng-container *ngFor="let consultantRate of commissionTiers[0].consultantRates; let i = index;" [matColumnDef]="'consultant'+i">
      <th mat-header-cell *matHeaderCellDef>{{commissionTiers[0].consultantRates[i].consultantCode}}</th>
      <td class="company-cell" mat-cell *matCellDef="let row">{{row.consultantRate[i]}}</td>
    </ng-container>
    <ng-container matColumnDef="canal">
      <th mat-header-cell *matHeaderCellDef>Canal</th>
      <td class="canal-cell" mat-cell *matCellDef="let row">{{row.canal}}</td>
    </ng-container>
    <ng-container matColumnDef="roll">
      <th mat-header-cell *matHeaderCellDef>Roll</th>
      <td class="roll-cell" mat-cell *matCellDef="let row">{{row.roll}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>
