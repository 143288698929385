<mat-dialog-content class="browse-dialog">
  <div class="content" role="main">
    <div class="container">
      <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let row">{{row.versionLabel}}</td>
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
            <td mat-cell *matCellDef="let row">{{row.referenceNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="netRanAmount">
            <th mat-header-cell *matHeaderCellDef>Net Invoice</th>
            <td mat-cell *matCellDef="let row">{{row.netRanAmount | currency}}</td>
          </ng-container>

          <ng-container matColumnDef="invoiceCreatedDate">
            <th mat-header-cell *matHeaderCellDef>Invoice Created Date</th>
            <td mat-cell *matCellDef="let row">{{row.addedOn || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="ftcOn">
            <th mat-header-cell *matHeaderCellDef>Invoice Date Span FTC</th>
            <td mat-cell *matCellDef="let row">{{row.ftcOn || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="ltcOn">
            <th mat-header-cell *matHeaderCellDef>Invoice Date Span LTC</th>
            <td mat-cell *matCellDef="let row">{{row.ltcOn || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionAuthor">
            <th mat-header-cell *matHeaderCellDef>Edited By</th>
            <td mat-cell *matCellDef="let row">{{row.versionAuthor || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="versionCreatedAt">
            <th mat-header-cell *matHeaderCellDef>Last Edit</th>
            <td mat-cell *matCellDef="let row">{{(row.versionCreatedAt | date:'short') || 'N/A'}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="!dataSource.filteredData.length">
          <p class="no-data">No data</p>
        </ng-container>
      </div>


      <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
</mat-dialog-actions>
