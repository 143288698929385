import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import {
  Account,
  Station,
  TransactionsReportFilter,
  TransactionsReportSection,
  TransactionsReportSectionEntry,
  TransactionsReportType
} from '../shared/models';
import { NotificationService } from '../../shared/notification.service';
import { StationService } from '../shared/station.service';
import { DateUtils } from '../../shared/utils/date-utils';
import { FormUtils } from '../../shared/utils/form-utils';
import { ReportsUtil } from '../../shared/utils/reports-util';

@Component({
  selector: 'app-station-transaction-list',
  templateUrl: './station-transaction-list.component.html',
  styleUrls: ['./station-transaction-list.component.scss']
})
export class StationTransactionListComponent implements OnInit, AfterViewChecked {
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  title: string;
  stationId: string;
  station: Station;

  accounts: Account[];

  filterForm = this.fb.group({
    view: [TransactionsReportType.STATION_ORDER],
    year: [null],
    since: [null],
    to: [null],
    quickBookReference: [''],
    transactionId: [''],
  });
  selectedAccounts: number[] = [];

  loading = true;
  isLoadingReport = false;
  isDateRange = true;
  isTransactionId = true;

  TransactionsReportType = TransactionsReportType;

  collapseAllTable = true;
  sections: TransactionsReportSection[] = [];
  total: TransactionsReportSectionEntry;
  currentFilter = {view: TransactionsReportType.STATION_ORDER};
  appliedFilter: TransactionsReportFilter;
  currentBalance = 0;

  @ViewChild('header') header: ElementRef;
  @ViewChild('filterPanel') filterPanel: ElementRef;
  @ViewChild('tableNameRow') tableNameRow: ElementRef;
  @ViewChild('collapseRow') collapseRow: ElementRef;
  @ViewChild('tableContainer') tableContainer: ElementRef;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private stationService: StationService) { }

  ngOnInit(): void {
    this.stationId = this.route.snapshot.paramMap.get('id') || undefined;
    this.title = this.route.snapshot.queryParamMap.get('title');
    this.loadData();
  }


  ngAfterViewChecked() {
    this.calcTableContainerHeight();
  }

  calcTableContainerHeight() {
    const innerHeight = window.innerHeight;
    const headerHeight = this.header?.nativeElement.clientHeight || 0;
    const filterPanelHeight = this.filterPanel?.nativeElement.clientHeight || 0;
    const tableNameRowHeight = this.tableNameRow?.nativeElement.clientHeight || 0;
    const collapseRowHeight = this.collapseRow?.nativeElement.clientHeight || 0;
    const paddingsHeight = 41;
    const preTableHeight = headerHeight + filterPanelHeight + tableNameRowHeight + collapseRowHeight + paddingsHeight;
    if (this.tableContainer) {
      this.tableContainer.nativeElement.style.maxHeight = innerHeight - preTableHeight + 'px';
    }
  }

  loadData = () => {
    const station$ = this.stationService.getStation(this.stationId);
    const accounts$ = this.stationService.getStationAccounts(this.stationId);

    forkJoin([accounts$, station$])
      .pipe(first())
      .subscribe(([accounts, station]) => {
        this.accounts = accounts;
        this.station = station;
        this.loading = false;
      });
  }

  get isOrderReport() {
    return this.viewType === TransactionsReportType.STATION_ORDER;
  }

  get viewType() {
    return this.currentFilter.view;
  }

  searchTransactions(formValue) {
    this.isLoadingReport = true;
    const {since, to, year} = formValue;
    let filter = {...formValue};
    filter.since =  DateUtils.dateFormatToShort(since);
    filter.to =  DateUtils.dateFormatToShort(to);
    filter.year =  DateUtils.dateFormatToYear(year);
    filter = FormUtils.filterEmptyFields(filter as TransactionsReportFilter);
    filter.accountsFilter = {
      accountIds: this.selectedAccounts
    };

    this.currentFilter = filter;
    this.stationService
      .getStationTransactionsReport(this.stationId, filter)
      .subscribe(report => {
        this.collapseAllTable = true;
        this.sections = report.sections;
        this.currentBalance = report.balance;
        this.appliedFilter = report.filter;
        this.total = report.total;
        this.isLoadingReport = false;
      });
  }

  onBackClicked() {
    this.router.navigate(['/entities/stations/detail', this.stationId]);
  }

  handleSelectDateType(value: boolean) {
    this.isDateRange = value;
    this.filterForm.patchValue({since: null, to: null, year: null});
  }

  handleSelectTransactionType(value: boolean) {
    this.isTransactionId = value;
    this.filterForm.patchValue({quickBookReference: '', transactionId: ''});
  }

  chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
    const ctrlValue = new Date(this.filterForm.get('year').value) || new Date();
    ctrlValue.setFullYear(normalizedYear.getFullYear());
    this.filterForm.get('year').setValue(ctrlValue);
  }

  toggleAccount(value: boolean, id: number) {
    if (value) {
      this.selectedAccounts.push(id);
    } else {
      this.selectedAccounts = this.selectedAccounts.filter(item => item !== id);
    }
  }

  onExportClicked() {
    this.isReportLoading$.next(true);

    const request =
            this.stationService.generateExcelStationTransactionsReport(this.stationId, this.appliedFilter);

    request.pipe(finalize(() => this.isReportLoading$.next(false)))
      .subscribe(data => {
          const filename = data.headers.get('filename');
          ReportsUtil.downloadFile(data.body as ArrayBuffer, filename);
        },
        () => this.notificationService.error('Error occurred while trying to export list.')
      );
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    const appHeaderFilterHeight = 350; // menu height + submenu height + filter height
    if (window.pageYOffset >= appHeaderFilterHeight) {
      window.scrollTo(0, appHeaderFilterHeight);
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.calcTableContainerHeight();
  }
}
