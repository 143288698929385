import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { FormMode } from 'src/app/entities/shared/models';
import { ConfirmationDialogResult } from '../models/confirmation-dialog-result';
import { ComponentType } from '../models/component-type.enum';
import { DocumentMetadata } from '../models/document-metadata';
import { TransactionService } from '../../orders/shared/transaction.service';
import { PaymentBatchService } from '../../orders/shared/payment-batch.service';
import { StationInvoiceService } from '../../orders/shared/station-invoice.service';
import { ExpensesService } from '../../orders/shared/expenses.service';
import { NotificationService } from '../notification.service';
import { ConsultantCompanyCommissionService } from '../../orders/shared/consultant-company-commission.service';
import { AcceptPaymentService } from '../../orders/shared/accept-payment.service';
import { ParentCompanyService } from '../../entities/shared/parent-company.service';
import { StationService } from '../../entities/shared/station.service';
import { DocumentService } from '../document.service';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

interface DocumentMetadataExt extends DocumentMetadata {
  url?: any;
  size?: number;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy {
  private subscription = new Subscription();
  @Input() mode = FormMode.VIEW;
  @Input() maxSizeMB = 10;
  @Input() filesUploadedBefore: DocumentMetadataExt[] = [];
  @Input() component;
  @Input() id;
  @Input() propId;
  @Input() canUpload = true;
  @Input() readonly = false;

  @Output() filesChanges = new EventEmitter<{files: File[]}>();
  files: Array<File> = [];
  isLoading = false;

  constructor(private transactionService: TransactionService,
              private paymentBatchService: PaymentBatchService,
              private consultantCompanyCommissionService: ConsultantCompanyCommissionService,
              private stationInvoiceService: StationInvoiceService,
              private expensesService: ExpensesService,
              private acceptPaymentService: AcceptPaymentService,
              private parentCompanyService: ParentCompanyService,
              private stationService: StationService,
              private notificationService: NotificationService,
              private documentService: DocumentService,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.filesUploadedBefore?.length
        && !(this.component === ComponentType.ConsultantCommission)) {
      this.setDocumentUrlAndSize();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component === ComponentType.ConsultantCommission
        && changes.propId
        && this.filesUploadedBefore?.length) {
      this.setDocumentUrlAndSize();
    }
  }

  get isLoadingFiles() {
    return this.filesUploadedBefore.some(file => !file.size);
  }

  setDocumentUrlAndSize() {
    this.filesUploadedBefore.forEach(fileUploadedBefore => {
      let obs$: Observable<any>;

      switch (this.component) {
        case ComponentType.Transaction:
          obs$ = this.transactionService.getTransactionDocument(this.id, fileUploadedBefore.id);
          break;
        case ComponentType.PaymentBatch:
          obs$ = this.paymentBatchService.getPaymentBatchDocument(this.id, this.propId, fileUploadedBefore.id);
          break;
        case ComponentType.StationInvoice:
          obs$ = this.stationInvoiceService.getStationInvoiceDocument(this.id, fileUploadedBefore.id);
          break;
        case ComponentType.PayExpense:
          obs$ = this.expensesService.getExpenseDocument(this.id, fileUploadedBefore.id);
          break;
        case ComponentType.ConsultantCommission:
          obs$ = this.consultantCompanyCommissionService
            .geConsultantCompanyTransactionDocument(this.id, this.propId, fileUploadedBefore.id);
          break;
        case ComponentType.AcceptPayment:
          obs$ = this.acceptPaymentService
            .getAcceptPaymentDocument(this.propId, this.id, fileUploadedBefore.id);
          break;
        case ComponentType.ParentCompany:
          obs$ = this.parentCompanyService.getDocument(this.id, fileUploadedBefore.id);
          break;
        case ComponentType.Station:
          obs$ = this.stationService.getDocument(this.id, fileUploadedBefore.id);
          break;
      }

      this.subscription.add(obs$
        .subscribe(data => {
          const blob = new Blob([data.body], { type: data.body.type });
          const url = window.URL.createObjectURL(blob);
          const size = blob.size;

          fileUploadedBefore.url = url;
          fileUploadedBefore.size = size;
        }, () => this.notificationService.error('Error occurred while trying to get document download')));
    });
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onSelect(event) {
    const selectedFiles = [...event.addedFiles];

    if (!selectedFiles.length) {
      this.notificationService.error('Unsupported file format');
      return;
    }

    if (selectedFiles.some(file => !file.size)) {
      this.notificationService.error('You can\'t upload a file with 0 MB');
      return;
    }

    if (this.isAnyFileExceededMaxSize(selectedFiles)) {
      this.notificationService.error('Max upload files size exceeded');
      return;
    }

    const checkDocuments$ = [];
    if (selectedFiles.length) {
      selectedFiles.forEach(file => {
        checkDocuments$.push(this.documentService.validateDocument(file));
      });

      forkJoin(checkDocuments$).subscribe(
        () => {
          if (this.mode === FormMode.VIEW) {
            this.isLoading = true;
            let obs$: Observable<any>;
            const documents$ = [];

            selectedFiles.forEach(document => {
              switch (this.component) {
                case ComponentType.Transaction:
                  obs$ = this.transactionService.uploadDocument(this.id, document);
                  break;
                case ComponentType.PaymentBatch:
                  obs$ = this.paymentBatchService.uploadDocument(this.id, this.propId, document);
                  break;
                case ComponentType.StationInvoice:
                  obs$ = this.stationInvoiceService.uploadDocument(this.id, document);
                  break;
                case ComponentType.PayExpense:
                  obs$ = this.expensesService.uploadExpenseDocument(this.id, document);
                  break;
                case ComponentType.ConsultantCommission:
                  obs$ = this.consultantCompanyCommissionService.uploadDocument(this.id, this.propId, document);
                  break;
                case ComponentType.AcceptPayment:
                  obs$ = this.acceptPaymentService.uploadDocument(this.propId, this.id, document);
                  break;
                case ComponentType.ParentCompany:
                  obs$ = this.parentCompanyService.uploadDocument(this.id, document);
                  break;
                case ComponentType.Station:
                  obs$ = this.stationService.uploadDocument(this.id, document);
                  break;
              }

              documents$.push(obs$);
            });

            this.subscription.add(forkJoin(documents$)
              .pipe(mergeMap(() => {
                switch (this.component) {
                  case ComponentType.Transaction:
                    return this.transactionService.getTransaction(this.id);
                  case ComponentType.PaymentBatch:
                    return this.paymentBatchService.getPaymentBatch(this.id);
                  case ComponentType.StationInvoice:
                    return this.stationInvoiceService.getInvoice(this.id);
                  case ComponentType.PayExpense:
                    return this.expensesService.getExpense(this.id);
                  case ComponentType.ConsultantCommission:
                    return this.consultantCompanyCommissionService.getConsultantCompanyCommissionTemplate(this.propId, this.id);
                  case ComponentType.AcceptPayment:
                    return this.acceptPaymentService.getAcceptPayment(this.propId, this.id);
                  case ComponentType.ParentCompany:
                    return this.parentCompanyService.getParentCompany(this.id);
                  case ComponentType.Station:
                    return this.stationService.getStation(this.id);
                }
              }))
              .subscribe((data: any) => {
                if (this.component === ComponentType.PaymentBatch) {
                  data = data.checkRequest.payments.find(payment => payment.id === this.propId);
                }
                this.filesUploadedBefore = data.documents;
                this.setDocumentUrlAndSize();
                this.notificationService.success('File(s) was uploaded');
                this.isLoading = false;
              }, (error) => {
                if (error.status === 400 && error.error?.code === 'DM008') {
                  this.notificationService.error('Uploading failed. File contains viruses.');
                } else {
                  this.notificationService.error('Error occurred while trying to upload files');
                }
                this.isLoading = false;
              })
            );
          } else {
            this.files = this.files.concat(selectedFiles);
            this.emitFiles();
          }
        },
        (error) => {
          if (error.status === 400 && error.error?.code === 'DM008') {
            this.notificationService.error('File contains virus. Data not saved');
          } else {
            this.notificationService.error('Error occurred while documents verification.');
          }
        }
      );
    }
  }

  removeDocument(document: DocumentMetadataExt): void {
    this.isLoading = true;
    this.filesUploadedBefore = this.filesUploadedBefore.filter(file => file.id !== document.id);

    let obs$: Observable<any>;

    switch (this.component) {
      case ComponentType.Transaction:
        obs$ = this.transactionService.deleteTransactionDocument(this.id, document.id);
        break;
      case ComponentType.PaymentBatch:
        obs$ = this.paymentBatchService.deletePaymentBatchDocument(this.id, this.propId, document.id);
        break;
      case ComponentType.StationInvoice:
        obs$ = this.stationInvoiceService.deleteStationInvoiceDocument(this.id, document.id);
        break;
      case ComponentType.PayExpense:
        obs$ = this.expensesService.deleteExpenseDocument(this.id, document.id);
        break;
      case ComponentType.ConsultantCommission:
        obs$ = this.consultantCompanyCommissionService.deleteConsultantCompanyTransactionDocument(this.id, this.propId, document.id); break;
      case ComponentType.AcceptPayment:
        obs$ = this.acceptPaymentService.deleteAcceptPaymentDocument(this.propId, this.id, document.id);
        break;
      case ComponentType.ParentCompany:
        obs$ = this.parentCompanyService.deleteDocument(this.id, document.id);
        break;
      case ComponentType.Station:
        obs$ = this.stationService.deleteDocument(this.id, document.id);
        break;
    }
    this.subscription.add(obs$
      .subscribe(() => {
        this.isLoading = false;

        this.notificationService.success('File was successfully deleted.');
      }, () => this.notificationService.error('Error occurred while trying to delete file')));
  }

  removeFileFromArray(file: File) {
      const index = this.files.indexOf(file);
      if (index > -1) {
            this.files.splice(index, 1);
      }

      this.emitFiles();
  }


  confirmDeletionAndRemove(isLocalFile, file) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      { data: { message: 'Are you sure you want to delete this file?' } }
    );

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result?.value) {
        isLocalFile ? this.removeFileFromArray(file) : this.removeDocument(file);
      }
    });
  }

  emitFiles() {
    this.filesChanges.emit({ files: this.files });
  }

  getMaxUploadSize() {
    return isFinite(this.maxSizeMB) ? this.maxSizeMB + 'MB' : 'N/A';
  }

  getUploadSizeMB(bytes: number) {
    return Math.ceil(bytes / 100000) / 10;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private isAnyFileExceededMaxSize(files: Array<File | DocumentMetadataExt>) {
    return files.map((file) => this.getUploadSizeMB(file.size) > this.maxSizeMB).some(item => !!item);
  }

}
