import { startWith, map } from 'rxjs/operators';

import { ParentCompany, Station } from '../../entities/shared/models';
import { PaymentCheckRequest } from '../../orders/shared/models';
import { Title } from '../../entities/shared/models';
import { User } from '../models/user';

export class TypeaheadUtil {

  static filterByPropName(value, options, propName) {
    const filterValue = value.toLowerCase();
    return options.filter(option => option[propName].toLowerCase().includes(filterValue));
  }

  static onBlurParentCompany(form, companies, inputElement) {
    const current = form.get('parentCompany').value;
    if (typeof current === 'string') {
      const newCompany = companies.find(item => item.name === current);
      if (newCompany) {
        inputElement.focus();
      }
      form.patchValue({parentCompany: newCompany || null});
    }
  }

  static onParentCompanyOptionSelect(form, option) {
    form.patchValue({parentCompany: option}, {emitEvent: false});
  }

  static manageAccountFilter(form, accounts) {
    return form.get('account').valueChanges
      .pipe(
        startWith(''),
        map(value => value ? TypeaheadUtil.filterByCodeName(value, accounts) : accounts?.slice())
      );
  }

  static filterByCodeName(value, options) {
    if (typeof value === 'string') {
      value = value?.toLowerCase();
      return options?.filter(option =>
        `${option.code.toLowerCase()} - ${option.name.toLowerCase()}`.includes(value)
      );
    }
  }

  static onBlurAccount(form, accounts, inputElement) {
    const current = form.get('account').value;

    if (typeof current === 'string') {
      const newAccount = accounts.find(item => `${item.code} - ${item.name}` === current);
      if (newAccount) {
        inputElement.focus();
      }
      form.patchValue({account: newAccount || null});
    }
  }

  static onAccountOptionSelect(form, option) {
    form.patchValue({account: `${option.code} - ${option.name}`});
  }

  static manageAccountantFilter(form, accountants) {
    return form.get('assignedAccountant').valueChanges
    .pipe(
      startWith(''),
      map(value => value ? TypeaheadUtil.filterAccountant(value, accountants) : accountants?.slice())
    );
  }

  static filterAccountant(value, accountants) {
    if (typeof value === 'string') {
      value = value.toLowerCase();
      return accountants?.filter(option =>
        `${option.name?.toLowerCase()} ${option.lastName?.toLowerCase()}`.includes(value));
    }
  }

  static onBlurAccountant(form, accountants, inputElement) {
    const current = form.get('assignedAccountant').value;

    if (typeof current === 'string') {
      const newAccountant = accountants.find(item => `${item.name} ${item.lastName}` === current);
      if (newAccountant) {
        inputElement.focus();
      }
      form.patchValue({assignedAccountant: newAccountant || null});
    }
  }

  static onAccountantOptionSelect(form, option) {
    form.patchValue({assignedAccountant: `${option.name} ${option.lastName}`});
  }

  static manageConsultantFilter(consultantControl, consultantCompanies) {
    return consultantControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? TypeaheadUtil.filterByCodeName(value, consultantCompanies) : consultantCompanies?.slice())
      );
  }

  static onBlurConsultant(consultantControl, consultantCompanies, inputElement) {
    const current = consultantControl.value;

    if (typeof current === 'string') {
      const newConsultant = consultantCompanies.find(item => `${item.code} - ${item.name}` === current);
      if (newConsultant) {
        inputElement.focus();
      }
      consultantControl.patchValue(newConsultant || null);
    }
  }

  static onConsultantOptionSelect(consultantControl, option) {
    consultantControl.patchValue(`${option.code} - ${option.name}`);
  }

  static manageStationFilter(stationControl, stations) {
    return stationControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' || !value ? value : (value as Station).name),
        map(name => name ? TypeaheadUtil.filterByPropName(name, stations, 'name') : stations?.slice())
      );
  }

  static manageCompanyFilter(companyControl, companies) {
    return companyControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' || !value ? value : (value as ParentCompany).name),
        map(name => name ? TypeaheadUtil.filterByPropName(name, companies, 'name') : companies?.slice())
      );
  }

  static onBlurStation(stationControl, stations, inputElement) {
    const current = stationControl.value;

    if (typeof current === 'string') {
      const newStation = stations.find(item => item.name === current);
      if (newStation) {
        inputElement.focus();
      }
      stationControl.patchValue(newStation || null);
    }
  }

  static onStationOptionSelect(stationControl, option) {
    stationControl.patchValue(option);
  }

  static manageCheckRequestFilter(checkRequestControl, checkRequests) {
    return checkRequestControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' || !value ? value : (value as PaymentCheckRequest).code),
        map(code => code ? TypeaheadUtil.filterByCode(code, checkRequests) : checkRequests?.slice())
      );
  }

  static filterByCode(code, options) {
    const filterValue = code.toLowerCase();
    return options.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  static onBlurCheckRequest(checkRequestControl, checkRequests, inputElement) {
    const current = checkRequestControl.value;

    if (typeof current === 'string') {
      const newCheckRequest = checkRequests.find(item => item.code === current);
      if (newCheckRequest) {
        inputElement.focus();
      }
      checkRequestControl.patchValue(newCheckRequest || null);
    }
  }

  static onCheckRequestOptionSelect(checkRequestControl, option) {
    checkRequestControl.patchValue(option.code);
  }

  static manageTitleFilter(addressControl, titles) {
    return addressControl.get('contactTitle').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' || !value ? value : (value as Title).title),
        map(title => title ? TypeaheadUtil.filterByPropName(title, titles, 'title') : titles?.slice())
      );
  }

  static onBlurTitle(titleControl, titles, inputElement) {
    const current = titleControl.value;

    if (typeof current === 'string') {
      const titleId = titles.find(item => current.includes(item.title))?.id;
      if (titleId) {
        inputElement.focus();
      }
      titleControl.patchValue({ id: titleId, title: current } || null);
    }
  }

  static onTitleOptionSelect(titleControl, option) {
    titleControl.patchValue(option.title);
  }

  static displayAccountant(accountant?: User): string {
    if (!accountant) {
      return '';
    }

    return accountant.name && accountant.lastName
      ? `${accountant.name} ${accountant.lastName}`
      : (accountant.name ? accountant.name : accountant.lastName);
  }

}

