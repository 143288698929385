import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ACCOUNTS } from '../../core/apiUrls';
import { Account, ConsultantCompany, OrdersExistInYear, TransactionsReport, TransactionsReportFilter } from './models';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  getAccountList(calculateBalance: boolean = false, archived: boolean = false): Observable<Account[]> {
    const params = new HttpParams()
      .set('calculateBalance', calculateBalance.toString())
      .set('archived', archived.toString());
    return this.http.get<Account[]>(ACCOUNTS, {params});
  }

  getAccount(id: string): Observable<Account> {
    return this.http.get<Account>(`${ACCOUNTS}/${id}`);
  }

  createAccount(account: Account): Observable<Account> {
    return this.http.post<Account>(ACCOUNTS, account);
  }

  updateAccount(account: Account): Observable<Account> {
    return this.http.put<Account>(`${ACCOUNTS}/${account.id}`, account);
  }

  getYears(firmOfficeId: number): Observable<OrdersExistInYear[]> {
    return this.http.get<OrdersExistInYear[]>(`${ACCOUNTS}/${firmOfficeId}/years`);
  }

  getConsultantCompanies(id: string): Observable<ConsultantCompany[]> {
    return this.http.get<ConsultantCompany[]>(`${ACCOUNTS}/${id}/worksWith`);
  }

  getAccountTransactionsReport(id: string, filter: TransactionsReportFilter): Observable<TransactionsReport> {
    return this.http.post<TransactionsReport>(`${ACCOUNTS}/${id}/transactions/preview`, filter);
  }

  generateExcelAccountTransactionsReport(id: string, filter: TransactionsReportFilter): Observable<HttpResponse<any>> {
    // @ts-ignore
    return this.http.post(`${ACCOUNTS}/${id}/transactions/preview/export`, filter,
    { observe: 'response' as 'body', responseType: 'blob'});
  }
}
