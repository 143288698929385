import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OrderFlightStation, Payment } from '../shared/models';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-station-flight-checks',
  templateUrl: './station-flight-checks.component.html',
  styleUrls: ['./station-flight-checks.component.scss']
})
export class StationFlightChecksComponent implements OnInit {
  @Input() payment: Payment;
  @Input() readonly: boolean;
  @Input() detailView = false;
  @Input() showBalance: boolean;
  @Input() index;
  @Output() payAmountChanged = new EventEmitter();
  payAmountDebouncer: Subject<any> = new Subject<any>();
  displayedColumns = ['flight', 'date', 'type', 'id', 'grossPlaced', 'netOrdered'];
  dataSource: any = new MatTableDataSource<OrderFlightStation>();
  controls: FormArray;
  payInFullAll = new FormControl(false);
  payAmount = new FormControl();

  ngOnInit(): void {
    if (!this.detailView) {
      this.displayedColumns.push('paymentOptions');
    }
    const controlGroups = [];
    this.payment?.stationFlights?.forEach((flight: OrderFlightStation) => controlGroups.push(new FormControl(flight.includedInPayment)));
    this.dataSource.data = this.payment.stationFlights;
    this.controls = new FormArray(controlGroups);

    const includedInPayment = this.payment?.stationFlights?.filter((flight: OrderFlightStation) => flight.includedInPayment) || [];
    this.payInFullAll.setValue(includedInPayment.length === this.payment?.stationFlights?.length);
    this.payAmount.setValue(this.payment.payAmount || 0);
    this.payInFullAll.valueChanges.subscribe(value => this.setPayInFullAll(value, false));
    this.handlePayAmountDebouncer();
    this.handlePayAmountChanges();
  }

  get stationPaymentMethod() {
    return this.payment.station.overwriteParentCompanyPaymentMethod
      ? this.payment.station.paymentMethod
      : this.payment.station.parentCompany?.paymentMethod;
  }

  updatePaymentOption(index) {
    const oldValue = this.payment.stationFlights[index].includedInPayment;
    this.payment.stationFlights[index].includedInPayment = !oldValue;
    this.dataSource.data[index].includedInPayment = !oldValue;
    if (!this.payAmount.value && this.totalPaid) {
      this.payAmount.setValue(this.totalPaid);
    }
  }

  setPayInFullAll(value: boolean, needSet: boolean = true) {
    if (!this.payment.editable) {
      return;
    }
    if (needSet) {
      this.payInFullAll.setValue(value);
    }
    this.payment.stationFlights?.forEach((flight: OrderFlightStation, index: number) => {
      flight.includedInPayment = value;
      this.dataSource.data[index].includedInPayment = value;
      this.controls.at(index).setValue(value);
    });
    if (!this.payAmount.value && this.payment.currentStationBalance && this.payment.currentStationBalance > 0) {
      this.payAmount.setValue(this.payment.currentStationBalance);
    }
  }

  getControl(index) {
    return this.controls.at(index) as FormControl;
  }

  get station() {
    return this.payment?.station;
  }

  getTotal(fieldName: string): number {
    return this.dataSource.data
      .map((item: any) => item[fieldName])
      .filter(item => item)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get totalPaid(): number {
    if (!this.payment?.stationFlights) {
      return 0;
    }
    return this.payment?.stationFlights
      .filter((flight: OrderFlightStation) => flight.includedInPayment && flight.netAmount)
      .map((flight: OrderFlightStation) => flight.netAmount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get balanceOwned(): number {
    return (this.payment?.currentStationBalance || 0) - (this.payAmount.value || 0);
  }

  private handlePayAmountDebouncer() {
    this.payAmountDebouncer
      .pipe(debounceTime(500))
      .subscribe((value) => this.payAmountChanged.emit(value));
  }

  private handlePayAmountChanges() {
    this.payAmount.valueChanges.subscribe(value => {
      this.payment.payAmount = value;
      this.payAmountDebouncer.next(value);
    });
  }
}
