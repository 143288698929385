import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { AccountListType, StationInvoiceExpense } from '../shared/models';
import { Account, Station } from '../../entities/shared/models';
import { PaymentMethod } from '../../dashboard/shared/models/payment-method';
import { User } from '../../shared/models/user';
import { FormMode } from '../../shared/models/form-mode.enum';
import { Role } from '../../shared/models/role.enum';
import { ComponentType } from '../../shared/models/component-type.enum';
import { NotificationService } from '../../shared/notification.service';
import { TransactionService } from '../shared/transaction.service';
import { PaymentService } from '../../dashboard/shared/payment.service';
import { UserService } from '../../shared/user.service';
import { AuthService } from '../../shared/auth.service';
import { ExpensesService } from '../shared/expenses.service';
import { StationService } from '../../entities/shared/station.service';
import { DateUtils } from '../../shared/utils/date-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { FormUtils } from '../../shared/utils/form-utils';
import { NoteUtil } from '../shared/utils/note-util';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { BrowseUserDialogComponent } from '../browse-user-dialog/browse-user-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { BrowseOrderedStationDialogComponent } from '../browse-ordered-station-dialog/browse-ordered-station-dialog.component';


@Component({
  selector: 'app-pay-expense',
  templateUrl: './pay-expense.component.html',
  styleUrls: ['./pay-expense.component.scss']
})
export class PayExpenseComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  expense: StationInvoiceExpense = {} as any;
  form = this.fb.group({
    account: [ null, Validators.required ],
    station: [ null ],
    id: [ '' ],
    assignedAccountant: [ null ],
    paymentMethod: [ null ],
    code: [ '' ],
    whoDidGoTo: [ '' ],
    amount: [ '', Validators.required ],
    date: [ new Date(), Validators.required ],
    commissionable: [ false ],
    noteToAdd: [ null ],
    quickBookReference: [ '' ],
    completed: [ false ]
  });
  dateControl = this.fb.control(['']);
  exposeModeControl = this.fb.control([true]);
  loading = true;
  saving = false;
  mode: FormMode = FormMode.CREATE;
  isPaid = false;
  openedDialog = false;

  paymentMethods: PaymentMethod[] = [];
  accountants: User[];
  filteredAccountants: Observable<User[]>;
  accounts: Account[];
  stations: Station[];
  filteredAccounts: Observable<Account[]>;
  filteredStations: Observable<Station[]>;

  documents: File[] = [];
  documentsUploadedBefore = [];
  component = ComponentType.PayExpense;
  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);
  hasPayPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private transactionService: TransactionService,
              private expensesService: ExpensesService,
              private paymentService: PaymentService,
              private stationService: StationService,
              private authService: AuthService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    this.setDynamicValidation();
    this.loadData(id);
    this.subscription.add(this.dateControl.valueChanges.subscribe(this.changeDateFormat(this.form.get('date') as FormControl)));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  loadData(id: string) {
    const accountId = this.route.snapshot.queryParamMap.get('accountId');
    const accounts$ = this.isEditMode ? of([]) : this.transactionService.getAccountList();
    const stations$ = !this.isEditMode && accountId ? this.transactionService.getStationList(Number(accountId)) : of([]);
    const paymentMethods$ = this.paymentService.getPaymentMethodList();
    const accountants$ = this.userService.getAccountants();
    const expense$ = this.isEditMode ? this.expensesService.getExpense(id) : of(null);

    forkJoin([accounts$, stations$, paymentMethods$, accountants$, expense$])
      .pipe(first())
      .subscribe(([accounts, stations, paymentMethods, accountants, expense]) => {
        this.accounts = accounts || [];
        this.stations = stations || [];
        this.paymentMethods = paymentMethods || [];
        this.accountants = accountants || [];
        this.filteredAccountants = TypeaheadUtil.manageAccountantFilter(this.form, this.accountants);

        if (expense) {
          this.expense = expense;
          this.setExpenseForm(expense);
          this.documentsUploadedBefore = expense.documents;
          if (!this.expense.completed) {
            this.loadStationList(this.expense.account);
          }
        } else {
          if (accountId) {
            const selectedAccount = this.accounts.find(account => account.id === Number(accountId));
            this.form.patchValue({account: selectedAccount});
          }

          this.dateControl.setValue(new Date());

          this.manageAccountFilters();
          this.manageStationFilters();
          this.subscription.add(this.stationControl.valueChanges.subscribe(value => {
            if (!value) {
              this.loadAccountList();
            }
          }));
          this.subscription.add(this.accountControl.valueChanges.subscribe(value => {
            if (!value) {
              this.stations = [];
              this.manageAccountFilters();
              this.manageStationFilters();
            }
          }));
        }
        if (!this.hasEditPermission) {
          FormUtils.disableFormControls(this.form as FormGroup);
          this.dateControl.disable();
        }

        if (!this.hasPayPermission) {
          this.form.get('completed').disable();
          if (this.isPaid) {
            this.form.get('amount').disable();
            this.dateControl.disable();
          }
        }
        this.loading = false;
      });
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get expenseId() {
    return this.form.get('id').value;
  }

  get isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  get isCompleted() {
    return !!this.form.get('completed').value;
  }

  get stationControl() {
    return this.form.get('station');
  }

  get accountControl() {
    return this.form.get('account');
  }

  manageStationFilters() {
    const accountId = this.accountControl.value?.id;
    this.filteredStations = accountId ? TypeaheadUtil.manageStationFilter(this.stationControl, this.stations)
      : this.stationControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => this.stationService.getTypeaheadStationList(term))
      ).pipe(map(results => {
        this.stations = results.map(item => ({id: Number(item.id), name: item.text}) as any);
        return this.stations;
      }));
  }

  onBlurStation(inputElement) {
    TypeaheadUtil.onBlurStation(this.stationControl, this.stations, inputElement);
  }

  onStationOptionSelect(option) {
    TypeaheadUtil.onStationOptionSelect(this.stationControl, option);
    this.loadAccountList(option);
  }

  loadAccountList(station?: Station) {
    const accounts$ = this.transactionService.getAccountList(+station?.id);
    this.subscription.add(accounts$.subscribe(accounts => {
      this.accounts = accounts;
      this.filteredAccounts = of(accounts);
      this.manageAccountFilters();
    }));
  }

  manageAccountFilters() {
    this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);
  }

  onBlurAccount(inputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
    this.loadStationList(option);
  }

  loadStationList(account?: Account) {
    const stations$ = this.transactionService.getStationList(account?.id);
    this.subscription.add(stations$.subscribe(stations => {
      this.stations = stations;
      this.filteredStations = of(stations);
      this.manageStationFilters();
    }));
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.expense.notes);
  }

  setDynamicValidation() {
    this.subscription.add(this.exposeModeControl.valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('station').setValidators(Validators.required);
        this.form.get('whoDidGoTo').clearValidators();
      } else {
        this.form.get('whoDidGoTo').setValidators(Validators.required);
        this.form.get('station').clearValidators();
      }
      this.form.get('whoDidGoTo').updateValueAndValidity();
      this.form.get('station').updateValueAndValidity();
      this.form.updateValueAndValidity();
    }));
    this.exposeModeControl.setValue(true);
  }

  submit(form: FormGroup): void {
    this.saving = true;
    const expense = this.prepareExpense(form);

    const request: any = this.mode === FormMode.EDIT
      ? this.expensesService.updateExpense(expense).pipe(
        tap(() => this.notificationService.success('Expense was updated!'))
      )
      : this.expensesService.createExpense(expense).pipe(
        tap(() => this.notificationService.success('New expense created!'))
      );

    request.subscribe(
      data => {
        if (this.documents.length) {
          this.uploadExpenseDocuments(data.id);
        } else {
          this.returnClicked();
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  private prepareExpense(form: FormGroup): StationInvoiceExpense {
    const expense = {
      ...this.expense,
      ...form.value
    };

    if (this.exposeModeControl.value) {
      expense.whoDidGoTo = '';
    } else {
      expense.station = null;
    }

    NoteUtil.processNotes(expense, form);

    return expense;
  }

  uploadExpenseDocuments = (expenseId) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.expensesService.uploadExpenseDocument(expenseId, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
      () => this.returnClicked(),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  private setExpenseForm(expense: StationInvoiceExpense) {
    this.isPaid = expense.completed;
    expense.paymentMethod = this.paymentMethods.find(item => item.name === expense.paymentMethod?.name ?? false);

    this.form.patchValue(expense);
    this.dateControl.setValue(new Date(expense.date));
    this.exposeModeControl.setValue(!!expense.station);
  }

  changeDateFormat = (stringControl: FormControl) => (newDate: Date) => {
    stringControl.patchValue(DateUtils.dateFormatToShort(newDate), {emitEvent: false});
  }

  displayAccount(account?: Account): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  displayStation(station?: Station): string {
    return station ? ` ${station.name}` : '';
  }

  openAccountDialog() {
    if (this.accounts.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Accounts with ordered Station', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
        {
          data: {
            selected: this.form.value.account?.id,
            type: AccountListType.STATION_TRANSACTION,
            id: this.form.get('station').value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Account) => {
        if (result) {
          this.accountControl.setValue(result);
          this.loadStationList(this.accountControl.value);
        }
      });
    }
  }

  openStationDialog() {
    if (!this.accountControl.value) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'First select an account', cancelDisabled: true }});
    } else if (this.stations.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No ordered Stations for Account', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseOrderedStationDialogComponent,
        {
          data: {
            selected: this.form.value.station?.id,
            accountId: this.accountControl.value?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Station) => {
        if (result) {
          this.stationControl.setValue(result);
          this.loadAccountList(this.stationControl.value);
        }
      });
    }
  }

  browseUser() {
    const dialogRef = this.dialog.open(BrowseUserDialogComponent,
      {data: {selected: this.form.value.assignedAccountant?.id}}
    );
    dialogRef.afterClosed().subscribe((result: User) => {
      if (result) {
        this.form.patchValue({assignedAccountant: result});
      }
    });
  }

  onBlurAccountant(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccountant(this.form, this.accountants, inputElement);
  }

  onAccountantOptionSelect(option) {
    TypeaheadUtil.onAccountantOptionSelect(this.form, option);
  }

  displayAccountant(accountant?: User): string {
    return TypeaheadUtil.displayAccountant(accountant);
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} expense.`);
  }

  setExpenseDocumentation(data: any) {
    this.documents = data.files;
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/expenses');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
