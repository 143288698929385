import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { finalize, first, take } from 'rxjs/operators';

import { Account, CPMCompany, UserConfiguration } from '../../entities/shared/models';
import { Role } from '../../shared/models/role.enum';

import { AuthService } from '../../shared/auth.service';
import { CPMCompanyService } from '../../entities/shared/cpm-company.service';
import { UsersService } from '../../entities/shared/users.service';
import { NotificationService } from '../../shared/notification.service';
import { customSortingDataAccessor, getClientCandidateType, SortingDataTypes, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {
  loading = false;
  saving = false;
  companies: CPMCompany[];
  userConfigurations: UserConfiguration[];

  filterForm = this.fb.group({
    cpmCompany: [null, Validators.required],
    user: [null, Validators.required]
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  selection = new SelectionModel<Account>(true, []);
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'select',
    'name',
    'code',
    'leadConsultant',
    'accountType',
    'menu'
  ];

  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    code: new FormControl(''),
    leadConsultant: new FormControl(''),
    accountType: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private companyService: CPMCompanyService,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UsersService) { }

  ngOnInit(): void {
    if (!this.authService.hasPermission([Role.SUPER_ADMIN, Role.ADMIN])) {
      this.router.navigateByUrl('/reports');
    }
    this.loading = true;
    this.loadData();
    this.buildFilterData();
  }

  get isSuperAdmin(): boolean {
    return this.authService.hasPermission([Role.SUPER_ADMIN]);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item)).filter(item => !!item))
    ];
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  loadData = () => {
    const companies$ = this.companyService.getCompanyList(this.isSuperAdmin);
    const accounts$ = this.isSuperAdmin ? of([]) : this.userService.getUserAccountList();
    const userConfigurations$ = this.isSuperAdmin ? of([]) : this.userService.getUserConfigurationsList();

    forkJoin([companies$, accounts$, userConfigurations$])
      .pipe(first())
      .subscribe(([companies, accounts, userConfigurations]) => {
        this.companies = companies || [];
        this.setTableData(accounts);
        this.userConfigurations = userConfigurations || [];
        if (!this.isSuperAdmin) {
          this.filterForm.get('cpmCompany').setValue(companies[0]);
        }
        this.loading = false;
      });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = customSortingDataAccessor(SortingDataTypes.Account);
    this.dataSource.sort = this.sort;
    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });
    this.dataSource.filterPredicate = this.createFilter();
  }

  loadAccounts(shortName: string) {
    this.loading = true;
    this.userService.getUserAccountList(shortName).pipe(take(1)).subscribe(data => this.setTableData(data));
  }

  loadUserConfigurations(shortName: string) {
    this.userService.getUserConfigurationsList(shortName).pipe(take(1)).subscribe(data => {
      this.userConfigurations = data;
    });
  }

  setTableData(data) {
    this.dataSource.data = sortListByLastEdit(data);
    this.dataSource.data.forEach(dataItem =>
      dataItem.leadConsultant = dataItem.consultantCompanies?.filter(consultant =>
        consultant.code === dataItem.leadConsultantCompanyCode)[0]?.name
    );

    this.loading = false;
  }

  initSelected(configuration: UserConfiguration) {
    const ids = configuration.allowedAccounts.map(item => item.id);
    this.dataSource.data.forEach((item: Account) => {
      if (ids.includes(item.id)) {
        this.selection.toggle(item);
      }
    });
  }

  save() {
    const configuration = this.filterForm.get('user').value;
    const newConfiguration = {...configuration, allowedAccounts: this.selection.selected};
    this.userService.updateUserConfiguration(newConfiguration).pipe(finalize(() => this.saving = false)).subscribe(
      () => {
        configuration.allowedAccounts = this.selection.selected;
        this.notificationService.success('User configuration updated!');
      },
      (e) => this.notificationService.error('User configuration can not be updated!'),
    );
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const leadConsultantSearch = FilterUtils.searchFunction(searchTerms.leadConsultant, data.leadConsultant);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data));

      const filterFunctions = [
        nameSearch(), codeSearch(),
        leadConsultantSearch(), accountTypeSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  private buildFilterData() {
    this.filterForm.get('cpmCompany').valueChanges.subscribe((value: CPMCompany) => {
      if (!this.isSuperAdmin) {
        return;
      }
      this.filterForm.get('user').setValue(null);
      this.loadAccounts(value.shortName);
      this.loadUserConfigurations(value.shortName);
    });
    this.filterForm.get('user').valueChanges.subscribe((value) => {
      this.selection.clear();
      if (value) {
        this.initSelected(value);
      }
    });
  }
}
