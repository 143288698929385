<mat-dialog-content class="browse-dialog">
  <div>{{data.message}}</div>
  <mat-form-field *ngIf="data.isComment" class="note-input">
    <mat-label>Note</mat-label>
    <textarea rows="4" [formControl]="noteControl" matInput [maxLength]="255" [required]="data.isCommentRequired || false"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial *ngIf="!data.cancelDisabled">Cancel</button>
  <button mat-raised-button color="primary"
          [mat-dialog-close]="confirmationDialogResult"
          [disabled]="noteControl?.invalid">Apply</button>
</mat-dialog-actions>
