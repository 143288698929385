import { FormGroup } from '@angular/forms';
import { MediaType } from '../../entities/shared/models';

export class FilterUtils {
  static processUndefinedValues = (object) => {
    const processedObject = {...object};
    Object.keys(processedObject).map(key => {
      if (!processedObject[key]) {
        processedObject[key] = '';
      } else if (processedObject[key] instanceof Object) {
        processedObject[key] = FilterUtils.processUndefinedValues(processedObject[key]);
      }
    });

    return processedObject;
  }

  static searchFunction = (searchTerm: string, dataField: string) => () => {
    let found = false;

    if (searchTerm.length !== 0 && !dataField) {
      return found;
    }

    searchTerm.trim().toLowerCase().split(' ').forEach(word => {
      if (word.length === 0 || !dataField) {
        found = true;
      }
      if (dataField?.toLowerCase().indexOf(word) !== -1) {
        found = true;
      }
    });
    return found;
  }

  static createSelectSearch = (searchTerm: string, dataField: string) => () => {
    if (!searchTerm) {
      return true;
    }

    if (searchTerm.length !== 0 && !dataField) {
      return false;
    }

    return (searchTerm === dataField);
  }

  static createMultiSelectSearch = (searchTerm: object, dataField: string) => () => {
    if (!searchTerm) {
      return true;
    }

    const values = Object.values(searchTerm);
    if (!values.length) {
      return true;
    }
    if (values.length !== 0 && !dataField) {
      return false;
    }

    return (values.includes(dataField));
  }

  static createDigitSearch = (searchTermName: string, dataName: string, searchTerms: any, data: any) => () => {
    if (!searchTerms[searchTermName].symbol || !searchTerms[searchTermName].value) {
      return true;
    }

    return FilterUtils.compare(searchTermName, searchTerms, data[dataName]);
  }

  static createFieldDigitSearch = (searchTermName: string, searchTerms: any, fieldData: any) => () => {
    if (!searchTerms[searchTermName].symbol || !searchTerms[searchTermName].value) {
      return true;
    }

    return FilterUtils.compare(searchTermName, searchTerms, fieldData);
  }

  static createDateSearch = (searchTermName: string, dataName: string, searchTerms: any, data: any) => () => {
    if (searchTerms[searchTermName].value.length !== 0 && (!data || !data[dataName])) {
      return false;
    }

    if (!searchTerms[searchTermName].symbol || !searchTerms[searchTermName].value) {
      return true;
    }

    searchTerms[searchTermName].value = (new Date(searchTerms[searchTermName].value)).setHours(0, 0, 0, 0);
    const compareData = (new Date(data[dataName])).setHours(0, 0, 0, 0);

    return FilterUtils.compare(searchTermName, searchTerms, compareData, true);
  }

  static createMediaTypeSearch = (searchTermType: string, dataMediaTypes: MediaType[]) => () => {
    if (!searchTermType) {
      return true;
    }

    if (!dataMediaTypes) {
      return false;
    }

    return dataMediaTypes.map(dataMediaType => dataMediaType.type).includes(searchTermType);
  }

  static compare(searchTermName: string, searchTerms: any, compareData: any, isDate = false) {
    const value = isDate ? searchTerms[searchTermName].value : Number(searchTerms[searchTermName].value);
    switch (searchTerms[searchTermName].symbol) {
      case '=': {
        if (compareData === value) {
          return true;
        }
        break;
      }
      case '!': {
        if (compareData !== value) {
          return true;
        }
        break;
      }
      case '<': {
        if (compareData < value) {
          return true;
        }
        break;
      }
      case '>': {
        if (compareData > value) {
          return true;
        }
        break;
      }
    }
    return false;
  }

  static createDateRangeSearch = (searchTermsStartString: string, searchTermsEndString: string, dataDateString: string) => () => {
    if (!searchTermsStartString || !searchTermsEndString) {
      return true;
    }

    const searchTermsStartDate = new Date(searchTermsStartString);
    const searchTermsEndDate = new Date(searchTermsEndString);
    const dataDate = new Date(new Date(dataDateString).setHours(0, 0, 0, 0));

    const returnRes = dataDate >= searchTermsStartDate && dataDate <= searchTermsEndDate;
    return returnRes;
  }

  static createQuickBooksSearch = (searchTerm: string, assigned: number, total: number) => () => {
    if (!searchTerm) {
      return true;
    }

    if (!assigned && !total) {
      return false;
    }

    if (searchTerm === 'missing') {
      return assigned < total;
    } else if (searchTerm === 'completed') {
      return assigned === total;
    }

    return false;
  }

  static specificQuickBooksSearch = (searchTerm: string, dataField: string) => () => {
    if (searchTerm === '-' ) {
      return !dataField;
    }

    if (searchTerm.length !== 0 && !dataField) {
      return false;
    }

    let found = false;
    searchTerm.trim().toLowerCase().split(' ').forEach(word => {
      if (word.length === 0 || !dataField) {
        found = true;
      }
      if (dataField?.toLowerCase().indexOf(word) !== -1) {
        found = true;
      }
    });
    return found;
  }

  static clearFilterForm(tableFilterForm: FormGroup, excludedObjectKey: string[] = []) {
    Object.keys(tableFilterForm.controls).forEach((key) => {
      if (typeof tableFilterForm.get(key).value === 'object' && !excludedObjectKey.includes(key)) {
        tableFilterForm.get(key).patchValue({
          symbol: '=',
          value: ''
        });
      } else {
        tableFilterForm.get(key).patchValue('');
      }
    });
  }
}
