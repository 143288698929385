import { Component, HostListener, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

import { Role } from '../models/role.enum';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent implements OnInit {
  title = 'Forty Four';
  navList = [
    {
      navTitle: 'Orders',
      subnavTitles: this.orderNav,
      routerLink: '/orders'},
    {
      navTitle: 'Entities',
      subnavTitles: [
        { subnavTitle: 'Account', subnavRouterLink: '/entities/accounts' },
        { subnavTitle: 'Consultant', subnavRouterLink: '/entities/consultants' },
        { subnavTitle: 'Station', subnavRouterLink: '/entities/stations' },
        { subnavTitle: 'Parent Company', subnavRouterLink: '/entities/parentCompany' }
      ],
      routerLink: '/entities'
    },
    {
      navTitle: 'Reports',
      subnavTitles: [],
      routerLink: '/reports'
    }
  ];

  currentUser = '';
  companyName = '';
  selectedSubnavTitles = [];
  isOnMenu = false;
  showSubmenu = false;
  isSuperAdmin = this.authService.hasPermission([Role.SUPER_ADMIN]);
  keycloakUrl = this.authService.keycloakUrl;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    this.selectedSubnavTitles = [];
  }

  constructor(protected keycloakAngular: KeycloakService, private authService: AuthService, protected user: UserService) {
  }

  ngOnInit(): void {
    if (this.authService.hasEditPermission) {
      this.navList.unshift({ navTitle: 'Dashboard', subnavTitles: [], routerLink: '/dashboard' });
    }
    if (this.authService.hasPermission([Role.ADMIN, Role.SUPER_ADMIN])) {
      this.navList.push({ navTitle: 'User Access', subnavTitles: [], routerLink: '/user-access' });
    }
    this.user.getCurrentUser().pipe(take(1))
      .subscribe(data => {
        this.currentUser = `${data.name ? data.name : ''} ${data.lastName ? data.lastName : ''}`;
        this.companyName = data.companyName;
      });
  }

  over(subnavTitles){
    this.isOnMenu = true;
    this.showSubmenu = true;
    this.selectedSubnavTitles = subnavTitles;
  }

  out(){
    this.isOnMenu = false;
    if (!this.showSubmenu) {
      this.selectedSubnavTitles = [];
    }
  }

  outSub() {
    this.showSubmenu = false;
    if (!this.isOnMenu) {
      this.selectedSubnavTitles = [];
    }
  }

  logout() {
    this.keycloakAngular.logout();
  }

  get orderNav() {
    return [{ subnavTitle: 'Account Invoices', subnavRouterLink: '/orders/cinvoices' },
      { subnavTitle: 'Orders', subnavRouterLink: '/orders/orders' },
      { subnavTitle: 'Check Requests', subnavRouterLink: '/orders/checks' },
      { subnavTitle: 'Payment Batches', subnavRouterLink: '/orders/payments' },
      { subnavTitle: 'Station Invoices', subnavRouterLink: '/orders/transactions/invoice' },
      { subnavTitle: 'Expenses', subnavRouterLink: '/orders/expenses' },
      { subnavTitle: 'Accept Payment', subnavRouterLink: '/orders/accept-payment' },
      { subnavTitle: 'Memo/Refund', subnavRouterLink: '/orders/memo-refund' },
      { subnavTitle: 'Commission', subnavRouterLink: '/orders/commissions' }
    ];
  }
}
