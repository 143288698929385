import { Note } from '../models';
import { FormGroup } from '@angular/forms';
import { NotedEntity } from '../../../shared/models/noted-entity';

export class NoteUtil {
  static notesReverse(notes: Note[]): Note[] {
    return notes ? notes.reverse() : [];
  }

  static sortByPostedAtEarliest(notes: Note[]): Note[] {
    const compareFn = (a: Note, b: Note): number => {
      const dateA =  new Date(a.postedAtTime);
      const dateB = new Date(b.postedAtTime);
      return dateA > dateB ? -1 : 1;
    };
    return notes ? notes.sort(compareFn) : [];
  }

  static processNotes<T extends NotedEntity>(entity: T, form: FormGroup): T {
    if (NoteUtil.getNoteToAddMessage(form).length > 0) {
      entity.noteToAdd = NoteUtil.createNote(NoteUtil.getNoteToAddMessage(form));
    } else {
      entity.noteToAdd = null;
    }
    return entity;
  }

  static getNoteToAddMessage(form: FormGroup): string {
    return form.get('noteToAdd').value as string ?? '';
  }

  static createNote(message: string): Note {
    return { message };
  }

}
