import { VersionedEntity } from '../../../shared/models/versioned-entity';
import { DocumentMetadata } from '../../../shared/models/document-metadata';
import { Account } from './account';
import { Note } from '../../../orders/shared/models';
import { User } from '../../../shared/models/user';
import { CommissionTier } from './commission-tier';
import { ConsultantCompany } from './consultant-company';
import { PaymentMethod } from './payment-method';
import { Commissionable } from './commissionable';

export interface ConsultantCompanyCommissionTransaction extends VersionedEntity {
  id: number;
  code: string;
  account: Account;
  consultantCompany: ConsultantCompany;
  owedBeforeCommission: number;
  owedAfterCommission: number;
  commissionableTransactions: Commissionable[];
  grossPaymentTotal: number;
  commissionTotal: number;
  toPayAmount: number;
  assignedAccountant: User;
  notes: Note[];
  noteToAdd: Note;
  paymentMethod:	PaymentMethod;
  versionLabel: string;
  quickBookReference: string;
  completed: boolean;
  completedAt: Date;
  completedBy: User;
  commissionTiers: CommissionTier[];
  documents: DocumentMetadata[];
  status: ConsultantCompanyCommissionTransactionStatus;
  type: ConsultantCompanyCommissionTransactionType;
  permissions?: string[];
}

export enum ConsultantCompanyCommissionTransactionType {
  ORDER      = 'ORDER',
  EXPENSE    = 'EXPENSE',
  IN_ADVANCE = 'IN_ADVANCE'
}

export enum ConsultantCompanyCommissionTransactionStatus {
  COMPLETED = 'COMPLETED',
  PAID      = 'PAID',
  DRAFT     = 'DRAFT',
  SUBMITTED = 'SUBMITTED'
}
