export interface AccountInvoiceStatus {
  id: number;
  status: string;
  convertedStatus: ConvertedStatus;
}

export enum ConvertedStatus {
  DRAFT = 'DRAFT',
  DECLINED = 'DECLINED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  PAID  = 'PAID',
}


export const accountInvoiceStatuses = {
  DRAFT: {id: 1, status: 'Draft'},
  DECLINED: {id: 2, status: 'Declined'},
  SUBMITTED: {id: 3, status: 'Submitted'},
  APPROVED: {id: 4, status: 'Approved'},
  PAID: {id: 5, status: 'Paid'},
};
