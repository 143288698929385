import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmationDialogResult } from '../models/confirmation-dialog-result';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: [ './confirmation-dialog.component.scss' ]
})
export class ConfirmationDialogComponent implements OnInit {
  noteControl: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, isComment: boolean,
    isCommentRequired: boolean, cancelDisabled: boolean }) {
  }

  ngOnInit(): void {
    if (this.data.isComment) {
      this.noteControl = new FormControl(
        '',  this.data.isCommentRequired ? Validators.required : null);
    }
  }

  get confirmationDialogResult(): ConfirmationDialogResult {
    return {value: true, note: this.noteControl?.value};
  }

}
