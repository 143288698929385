import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { Station } from '../../entities/shared/models';
import { StationService } from '../../entities/shared/station.service';
import { DateUtils } from '../../shared/utils/date-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { OrderFlightStation } from '../shared/models';
import { BrowseStationDialogComponent } from '../browse-station-dialog/browse-station-dialog.component';
import { StationSavedResult } from '../../entities/station-edit/station-edit.component';
import ValidatorsExtension from '../../shared/utils/validators-extension';
import { StationCreateDialogComponent } from '../station-create-dialog/station-create-dialog.component';

@Component({
  selector: 'app-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss']
})
export class FlightCardComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Output() duplicateFlight = new EventEmitter();
  @Output() deleteFlight = new EventEmitter();
  @Output() stationCreated = new EventEmitter();
  @Input() expanded: boolean;
  @Input() stations: Station[];
  @Input() flightNumber = 0;
  @Input() totalFlights = 0;
  @Input() disabled = false;
  @Input() canDeleteFlights = true;
  @Input() flightForm = this.fb.group({
    id: [''],
    estimateNumber: [''],
    ftcOn: ['', Validators.required],
    ltcOn: ['', Validators.required],
    flightStations: this.fb.array([]),
    sortNumber: null,
    couldBeDeleted: ['']
  });
  datesForm = this.fb.group({
      ftcDateControl: [''],
      ltcDateControl: ['']
  }, {validators: ValidatorsExtension.flightDatesValidator});
  filteredStation: Observable<Station[]>[] = [];

  constructor(private dialog: MatDialog,
              private fb: FormBuilder,
              private stationService: StationService) { }

  ngOnInit(): void {
    this.bindToDateControl(this.ftcDateControl, this.flightForm.get('ftcOn') as FormControl);
    this.bindToDateControl(this.ltcDateControl, this.flightForm.get('ltcOn') as FormControl);

    this.flightStations.controls.forEach((item: FormGroup, index) => {
      this.manageStationFilters(index);
    });

    if (!this.flightStations.length) {
      this.addStation();
    }

    this.flightStations.valueChanges.subscribe(() => {
      if (this.ftcDateControl.invalid || this.ltcDateControl.invalid) {
        this.flightForm.get('ltcOn').setErrors({invalid: true});
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get flightStations() {
    return this.flightForm.get('flightStations') as FormArray;
  }

  get totalNetAmount(): number {
    return this.flightStations.controls
      .map(station => station.value.netAmount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get canDeleteFlight() {
    return this.canDeleteFlights && this.flightForm.value.couldBeDeleted;
  }

  get ftcDateControl(): FormControl {
    return this.datesForm.get('ftcDateControl') as FormControl;
  }

  get ltcDateControl(): FormControl {
    return this.datesForm.get('ltcDateControl') as FormControl;
  }

  canDeleteStation(flightStation: OrderFlightStation) {
    return this.flightStations.length > 1 && !this.disabled && flightStation.couldBeDeleted;
  }

  bindToDateControl(dateControl: FormControl, stringControl: FormControl) {
    dateControl.setValue(new Date(stringControl.value));
    this.subscription.add(dateControl.valueChanges.subscribe(this.changeDateFormat(stringControl)));
  }

  changeDateFormat = (stringControl: FormControl) => (newDate: Date) => {
    stringControl.patchValue(DateUtils.dateFormatToShort(newDate), {emitEvent: false} );
    this.datesForm.updateValueAndValidity({emitEvent: false});
    if (this.ftcDateControl.invalid || this.ltcDateControl.invalid || this.datesForm.invalid) {
      this.flightForm.setErrors({invalid: true});
    }
  }

  displayStationName(station: Station): string {
    return station?.name ?? '';
  }

  getMediaType(station: Station): string {
    return station?.mediaType?.type ?? '';
  }

  getState(station: Station): string {
    return station?.address?.state?.name ?? '';
  }

  getCity(station: Station): string {
    return station?.address?.city ?? '';
  }

  getMarketsListString(station: Station): string {
    return  station?.mediaMarkets ? station.mediaMarkets.map(mediaMarket => mediaMarket.name).join() : '';
  }

  manageStationFilters(index: number) {
    this.filteredStation[index] = this.manageStationFilter(this.flightStations.at(index).get('station'));
  }

  onBlurStation(index: number) {
    const current = this.flightStations.at(index).get('station').value;
    if (typeof current === 'string') {
      this.flightStations.at(index).get('station').patchValue(null);
    }
  }

  onStationOptionSelect(option, i) {
    if (this.disabled) {
      return;
    }
    TypeaheadUtil.onStationOptionSelect(this.flightStations.at(i).get('station'), option);

    this.stationService.getStation(option.id)
    .pipe(first())
    .subscribe((station) => this.flightStations.at(i).patchValue({station}));
  }

  openDialog(index: number) {
    const selected = this.flightStations.at(index)?.get('station').value?.id || '0';
    const flights = [...this.flightStations.value];
    flights.splice(index, 1);
    const exclude = flights.map(item => item.station?.id) || [];
    const dialogRef = this.dialog.open(BrowseStationDialogComponent,
      { data: { selected, exclude } }
    );

    dialogRef.afterClosed().subscribe((station: OrderFlightStation) => {
      if (station) {
        this.flightStations.controls[index].patchValue({station});
      }
    });
  }

  addStation(station?: Station) {
    this.flightStations.push(this.fb.group({
      station: [station ?? '', Validators.required],
      netAmount: ['', Validators.required],
      couldBeDeleted: [true]
    }));

    this.manageStationFilters(this.flightStations.length - 1);
  }

  createStation() {
    const dialogRef = this.dialog.open(StationCreateDialogComponent, { panelClass: 'no-padding-dialog' });

    dialogRef.afterClosed().subscribe((result: StationSavedResult) => {
      if (result?.isSaved) {
        if (this.flightStations.length === 1 && this.flightStations.at(0).value.station === '') {
          this.flightStations.at(0).get('station').setValue(result.station);
        } else {
          this.addStation(result.station);
        }
        this.stationCreated.emit(result.isSaved);
      }
    });
  }

  deleteStation(index: number) {
    this.flightStations.controls.splice(index, 1);
    this.flightStations.updateValueAndValidity();

    for (let i = 0; i < this.flightStations.controls.length; i++) {
      this.manageStationFilters(i);
    }
  }

  manageStationFilter(stationControl) {
    return stationControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: any) => term?.length >= 2 ? this.stationService.getTypeaheadStationList(term) : of([]))
    ).pipe(map((results: any) => {
      this.stations = results
        .map(item => ({id: Number(item.id), name: item.text}) as any)
        .filter(station => { // cannot add one station multiple times in one flight
          return !this.flightStations.controls.map(c => c.value.station?.id).includes(station.id);
        });
      return this.stations;
    }));
  }
}
