import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';

export function initializer(keycloak: KeycloakService, http: HttpClient): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        http.get('assets/keycloak.json').subscribe(async (data: any) => {
          resolve(await keycloak.init({
            config: {
              url: data.url,
              realm: data.realm,
              clientId: data.clientId
            },
            loadUserProfileAtStartUp: false,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: true
            },
            bearerExcludedUrls: []
          }));
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
}
