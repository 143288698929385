import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { NotificationService } from '../../shared/notification.service';
import { AccountService } from '../shared/account.service';
import { Account } from '../shared/models';
import { AuthService } from '../../shared/auth.service';
import { sortByNumber } from '../../shared/utils/sort-list-util';
import { Role } from '../../shared/models/role.enum';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  account: Observable<Account>;
  accountData: Account;
  loading = true;
  hasCreateInvoicePermission = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER]);
  hasCreatePaymentPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.account = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.accountService.getAccount(params.get('id')))
    );

    this.account.subscribe(
      account => {
        this.accountData = account;
        sortByNumber(this.accountData.consultantCompanies, 'sortNumber');
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get account data')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.router.navigateByUrl('/entities/accounts').then();
    }
  }

  get years() {
    return this.accountData.ordersExistInYears
      .filter(ordersExistInYears => ordersExistInYears.checked)
      .sort((item1, item2) => (+item2.year) - (+item1.year))
      .reduce((years, ordersExistInYears) => years + ordersExistInYears.year + ', ', '')
      .slice(0, -2);
  }

  onViewAllTransactionClicked() {
    this.router.navigate([ 'entities/accounts', this.accountData.id, 'transactions' ], this.routeParams);
  }

  onAddPaymentClicked() {
    this.router.navigate([ '/orders/accept-payment/edit/', '' ], this.routeParams);
  }

  onAddInvoiceClicked() {
    this.router.navigate([ '/orders/cinvoices/edit/', '' ], this.routeParams);
  }

  get routeParams() {
    return {queryParams: {accountId: this.accountData.id, title: this.accountData.name}};
  }

  getNabField(key: string) {
    return this.accountData.nabFormFields ? this.accountData.nabFormFields[key] : '';
  }

  get hasEditPermission() {
    if (this.accountData?.archived) {
      return this.authService.hasPermission([Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
    }
    return this.authService.hasEditPermission;
  }
}
