import { Account, ConsultantCompany, Station } from '../../entities/shared/models';

export const sortListByLastEdit = list => {
  list.sort((listItem1, listItem2) => {
    const lastEditListItem1 = listItem1.lastUpdatedAt ? listItem1.lastUpdatedAt : listItem1.createdAt;
    const lastEditListItem2 = listItem2.lastUpdatedAt ? listItem2.lastUpdatedAt : listItem2.createdAt;

    return lastEditListItem1 > lastEditListItem2 ? -1 : 1;
  });

  return list;
};

export enum SortingDataTypes {
  Station,
  Consultant,
  Account
}

export const customSortingDataAccessor = (sortingDataType?: SortingDataTypes) => (item: any, property: string) => {
  switch (sortingDataType) {
    case SortingDataTypes.Station:
      return stationSortingDataAccessor((item as Station), property);
    case SortingDataTypes.Consultant:
      return consultantSortingDataAccessor((item as ConsultantCompany), property);
    case SortingDataTypes.Account:
      return accountSortingDataAccessor((item as Account), property);
    default:
      return item[property];
  }
};

const consultantSortingDataAccessor = (item: ConsultantCompany, property: string) => {
  switch (property) {
    case 'address':
      return item.address?.addressLine1;
    case 'city':
      return item.address?.city;
    case 'state':
      return item.address?.state?.name;
    case 'zipCode':
      return item.address?.zipCode;
    case 'cpmCompany':
      return item.cpmCompany?.name;
    default:
      return item[property];
  }
};

const accountSortingDataAccessor = (item: Account, property: string) => {
  switch (property) {
    case 'accountType':
      return getClientCandidateType(item);
    default: return item[property];
  }
};

const stationSortingDataAccessor = (item: Station, property: string) => {
  switch (property) {
    case 'mediaType':
      return item.mediaType?.type;
    case 'state':
      return item.address?.state?.name;
    case 'parentCompany':
      return item.parentCompany?.name;
    default: return item[property];
  }
};

export const getClientCandidateType = (account: Account) => {
  if (account.candidate) {
    return 'Candidate';
  } else if (account.advocacy) {
    return 'Client (Political)';
  } else {
    return 'Client';
  }
};

export const getLeadConsultantName = (account: Account) => {
  return account.consultantCompanies?.find(item => item.code === account.leadConsultantCompanyCode).name;
};

export const sortByNumber = (items: any[], sortField: string) =>
  items.sort((a, b) => a[sortField] > b[sortField] ? 1 : -1);
