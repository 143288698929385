import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceptPaymentService } from '../shared/accept-payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

import { AcceptPayment } from '../shared/models';

@Component({
  selector: 'app-accept-payment-versions-dialog',
  templateUrl: './accept-payment-versions-dialog.component.html',
  styleUrls: ['./accept-payment-versions-dialog.component.scss']
})
export class AcceptPaymentVersionsDialogComponent implements OnInit {
  dataSource = new MatTableDataSource<AcceptPayment>();
  displayedColumns: string[] = ['version', 'paidAmount', 'quickBookReference', 'paidOn', 'paidBy', 'versionCreatedAt', 'versionAuthor'];
  isLoadingResults = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number, accountId: number}, private service: AcceptPaymentService) { }

  ngOnInit(): void {
    this.service.getAcceptPaymentVersions(this.data.accountId, this.data.id).pipe(take(1))
      .subscribe(data => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
      });
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }
}
