import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountStationTransaction } from '../shared/models';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionService } from '../shared/transaction.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-memo-refund-versions-dialog',
  templateUrl: './memo-refund-versions-dialog.component.html',
  styleUrls: ['./memo-refund-versions-dialog.component.scss']
})
export class MemoRefundVersionsDialogComponent implements OnInit {
  isLoadingResults = true;
  displayedColumns: string[] = ['version', 'amount', 'enteredOn', 'referenceNumber', 'versionCreatedAt', 'versionAuthor'];
  dataSource = new MatTableDataSource<AccountStationTransaction>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number}, private service: TransactionService) { }

  ngOnInit(): void {
    this.service.getTransactionVersions(this.data.id).pipe(take(1))
      .subscribe(data => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
      });
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }
}
