import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PARENT_COMPANIES, TYPEAHEAD } from '../../core/apiUrls';
import { ParentCompany } from './models';
import { TypeaheadItem } from '../../shared/models/typeahead-item';

@Injectable({
  providedIn: 'root'
})
export class ParentCompanyService {

  constructor(private http: HttpClient) { }

  getParentCompanyList(): Observable<ParentCompany[]> {
    return this.http.get<ParentCompany[]>(PARENT_COMPANIES);
  }

  getParentCompany(id: string): Observable<ParentCompany> {
    return this.http.get<ParentCompany>(`${PARENT_COMPANIES}/${id}`);
  }

  createParentCompany(company: ParentCompany): Observable<ParentCompany> {
    return this.http.post<ParentCompany>(PARENT_COMPANIES, company);
  }

  updateParentCompany(company: ParentCompany): Observable<ParentCompany> {
    return this.http.put<ParentCompany>(`${PARENT_COMPANIES}/${company.id}`, company);
  }

  getDocument(id: number, documentId: number): Observable<any> {
    return this.http.get(
      `${PARENT_COMPANIES}/${id}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(id: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post(`${PARENT_COMPANIES}/${id}/documents`, fd);
  }

  deleteDocument(id: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${PARENT_COMPANIES}/${id}/documents/${documentId}`);
  }

  getTypeaheadParentCompanyList(term?: string): Observable<TypeaheadItem[]> {
    let httpParams = new HttpParams();
    if (term) {
      httpParams = httpParams.set('nameStartsWith', term);
    }
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}/parentCompanies`, { params: httpParams});
  }
}
