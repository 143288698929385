import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StationSavedResult } from '../../entities/station-edit/station-edit.component';

@Component({
  selector: 'app-station-create-dialog-component',
  templateUrl: './station-create-dialog.component.html',
  styleUrls: ['./station-create-dialog.component.scss']
})
export class StationCreateDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<StationCreateDialogComponent>) {}

  ngOnInit(): void {
  }

  closeDialog(stationSavedResult: StationSavedResult) {
    this.dialogRef.close(stationSavedResult);
  }
}
