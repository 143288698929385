import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ACCOUNTS } from '../../core/apiUrls';
import { AccountInvoice, Order, StationInvoiceExpense } from './models';
import { Account } from '../../entities/shared/models';

@Injectable({
  providedIn: 'root'
})
export class AccountInvoiceService {

  constructor(private http: HttpClient) { }

  createInvoice(invoice: AccountInvoice): Observable<AccountInvoice> {
    return this.http.post<AccountInvoice>(`${ACCOUNTS}/${invoice.account.id}/transactions/invoices`, invoice);
  }

  getInvoice(id: string): Observable<AccountInvoice> {
    return this.http.get<AccountInvoice>(`${ACCOUNTS}/transactions/invoices/${id}`);
  }

  getInvoiceList(since?: string, to?: string): Observable<AccountInvoice[]> {
    let httpParams = new HttpParams();
    if (since) {
      httpParams = httpParams.set('since', since);
    }
    if (to) {
      httpParams =  httpParams.set('to', to);
    }

    return this.http.get<AccountInvoice[]>(`${ACCOUNTS}/transactions/invoices`, { params: httpParams});
  }

  updateInvoice(invoice: AccountInvoice): Observable<AccountInvoice> {
    return this.http.put<AccountInvoice>(`${ACCOUNTS}/transactions/invoices/${invoice.id}`, invoice);
  }

  disableInvoice(invoiceId: string): Observable<AccountInvoice> {
    return this.http.put<AccountInvoice>(`${ACCOUNTS}/transactions/invoices/${invoiceId}/disable`, {});
  }

  getAccountForInvoice(): Observable<Account[]> {
    return this.http.get<Account[]>(`${ACCOUNTS}/transactions/toInvoice`);
  }

  getOrderForInvoice(id: string): Observable<Order[]> {
    return this.http.get<Order[]>(`${ACCOUNTS}/${id}/transactions/invoices/orders`);
  }

  addOrders(accountInvoice: AccountInvoice, overwrite: boolean): Observable<AccountInvoice> {
    return this.http.put<AccountInvoice>(`${ACCOUNTS}/transactions/invoices/addOrders`, {accountInvoice, overwrite});
  }

  getStationInvoiceExpenses(accountId: string): Observable<StationInvoiceExpense[]> {
    return this.http.get<StationInvoiceExpense[]>(`${ACCOUNTS}/${accountId}/transactions/invoices/stationInvoiceExpenses`);
  }


}
