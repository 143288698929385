import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from './models/user';
import { USERS } from '../core/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser: User;
  accountants: User[];

  constructor(private http: HttpClient) {
    this.getCurrentUser().subscribe();
  }

  getCurrentUser() {
    if (this.currentUser) {
      return of(this.currentUser);
    }
    return this.http.get<User>(`${USERS}/current`).pipe(
      tap(data => this.currentUser = data)
    );
  }

  getAccountants() {
    if (this.accountants) {
      return of(this.accountants);
    }
    return this.http.get<User[]>(`${USERS}/ACCOUNTANT`).pipe(
      tap(data => this.accountants = data)
    );
  }

  get userRoles() {
    return this.currentUser?.roles;
  }

  get user() {
    return this.currentUser;
  }
}
