import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DICTIONARIES } from '../../core/apiUrls';
import { ElectionType, OrderStatus } from './models';
import { PaymentType } from '../../entities/shared/models';

@Injectable({
  providedIn: 'root'
})
export class OrderDictionaryService {
  orderStatus: OrderStatus[];
  electionTypes: ElectionType[];
  paymentTypes: PaymentType[];

  constructor(private http: HttpClient) { }

  getElectionTypes(): Observable<ElectionType[]> {
    if (this.electionTypes) {
      return of(this.electionTypes);
    }
    return this.http.get<ElectionType[]>(`${DICTIONARIES}/electionTypes`).pipe(
      tap(data => this.electionTypes = data)
    );
  }

  getOrderStatuses(): Observable<OrderStatus[]> {
    if (this.orderStatus) {
      return of(this.orderStatus);
    }
    return this.http.get<OrderStatus[]>(`${DICTIONARIES}/orderStatuses`).pipe(
      tap(data => this.orderStatus = data)
    );
  }

  getPaymentTypes(): Observable<PaymentType[]> {
    if (this.paymentTypes) {
      return of(this.paymentTypes);
    }
    return this.http.get<PaymentType[]>(`${DICTIONARIES}/paymentTypes`).pipe(
      tap(data => this.paymentTypes = data)
    );
  }
}
