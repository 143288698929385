import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsultantCompanyCommissionReport } from '../shared/models/consultant-company-commission-report';

@Component({
  selector: 'app-transaction-report-dialog',
  templateUrl: './transaction-report-dialog.component.html',
  styleUrls: ['./transaction-report-dialog.component.scss']
})
export class TransactionReportDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public  data: {consultantCommissionReport: ConsultantCompanyCommissionReport}) {}

  ngOnInit(): void {
  }

}
