import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { STATION_TRANSACTIONS } from '../../core/apiUrls';
import { Account, Station } from '../../entities/shared/models';
import { AccountStationTransaction } from './models';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  getTransactions(accountId?: string, stationId?: string): Observable<AccountStationTransaction[]> {
    let httpParams = new HttpParams();
    if (accountId) {
      httpParams = httpParams.append('accountId', accountId);
    }
    if (stationId) {
      httpParams = httpParams.append('stationId', stationId);
    }

    return this.http.get<AccountStationTransaction[]>(`${STATION_TRANSACTIONS}`, { params: httpParams});
  }

  getTransactionsInRange(createdAfter?: string, createdBefore?: string): Observable<AccountStationTransaction[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }

    return this.http.get<AccountStationTransaction[]>(`${STATION_TRANSACTIONS}`, { params: httpParams});
  }

  getTransactionVersions(id: number): Observable<AccountStationTransaction[]> {
    return this.http.get<AccountStationTransaction[]>(`${STATION_TRANSACTIONS}/${id}/versions`);
  }

  getTransaction(id: string): Observable<AccountStationTransaction> {
    return this.http.get<AccountStationTransaction>(`${STATION_TRANSACTIONS}/${id}`);
  }

  getTransactionDocument(transactionId: number, documentId: number): Observable<any> {
    return this.http.get(
      `${STATION_TRANSACTIONS}/${transactionId}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(transactionId: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<AccountStationTransaction>(`${STATION_TRANSACTIONS}/${transactionId}/documents`, fd);
  }

  deleteTransactionDocument(transactionId: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${STATION_TRANSACTIONS}/${transactionId}/documents/${documentId}`);
  }

  createTransaction(transaction: AccountStationTransaction): Observable<AccountStationTransaction> {
    return this.http.post<AccountStationTransaction>(`${STATION_TRANSACTIONS}`, transaction);
  }

  updateTransaction(transaction: AccountStationTransaction): Observable<AccountStationTransaction> {
    return this.http.put<AccountStationTransaction>(`${STATION_TRANSACTIONS}/${transaction.id}`, transaction);
  }

  getAccountList(stationId?: number): Observable<Account[]> {
    let httpParams = new HttpParams();
    if (stationId) {
      httpParams = httpParams.append('stationId', String(stationId));
    }
    return this.http.get<Account[]>(`${STATION_TRANSACTIONS}/accounts`, { params: httpParams});
  }

  getStationList(accountId?: number): Observable<Station[]> {
    let httpParams = new HttpParams();
    if (accountId) {
      httpParams = httpParams.append('accountId', String(accountId));
    }
    return this.http.get<Station[]>(`${STATION_TRANSACTIONS}/stations`, { params: httpParams});
  }
}
