import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { ParentCompanyService } from '../shared/parent-company.service';
import { EntityDictionaryService } from '../shared/entity-dictionary.service';
import { State, ParentCompany, Station, MediaType, FormMode, PaymentMethod, Title } from '../shared/models';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { FormUtils } from '../../shared/utils/form-utils';
import { PhoneUtils } from '../shared/utils/phone-utils';
import { EmailUtils } from '../shared/utils/email-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { ComponentType } from '../../shared/models/component-type.enum';

@Component({
  selector: 'app-parent-company-edit',
  templateUrl: './parent-company-edit.component.html',
  styleUrls: ['./parent-company-edit.component.scss']
})
export class ParentCompanyEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  parentCompanyId: Observable<string|null>;
  parentCompanyForm = this.fb.group({
    id: [''],
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      city: [''],
      contactEmail: ['', Validators.pattern(EmailUtils.EMAIL_REGEXP_PATTERN)],
      contactName: [''],
      contactTitle: [null],
      fax1: [''],
      fax2: [''],
      phone1: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone1Ext: [''],
      phone2: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone2Ext: [''],
      state: [''],
      zipCode: ['']
    }),
    name: ['', Validators.required],
    mediaTypes: [null],
    disabled: [false],
    paymentMethod: [null],
    paymentGuidelinesUrl: ['', Validators.pattern(EmailUtils.URL_REGEXP_PATTERN)]
  });

  dataSource = new MatTableDataSource<Station>();
  displayedColumns: string[] = ['name', 'stationType', 'menu'];
  selection = new SelectionModel<ParentCompany>(false, []);
  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    stationType: new FormControl('')
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChildren(MatSort) sort: QueryList<any>;

  states: State[];
  mediaTypes: MediaType[];
  paymentMethods: PaymentMethod[];
  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  hasEditPermission = this.authService.hasEditPermission;

  titles: Title[];
  filteredTitles: Observable<Title[]>;

  documents: File[] = [];
  documentsUploadedBefore = [];
  component = ComponentType.ParentCompany;

  constructor(private parentCompanyService: ParentCompanyService,
              private notificationService: NotificationService,
              private dictionaryService: EntityDictionaryService,
              private fb: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.parentCompanyId = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.mode = params.get('id') ? FormMode.EDIT : FormMode.CREATE;
        return of(params.get('id')) || of(null);
      })
    );

    this.subscription.add(this.parentCompanyId.subscribe(this.loadData,
      () => this.notificationService.error('Error occurred while trying to get parent company info.')));

    this.dataSource.paginator = this.paginator;

    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();

    if (!this.hasEditPermission) {
      FormUtils.disableFormControls(this.parentCompanyForm);
    }
  }

  loadData = (id: string) => {
    const states$ = this.dictionaryService.getStates();
    const types$ = this.dictionaryService.getMediaTypes();
    const titles$ = this.dictionaryService.getTitles();
    const paymentMethods$ = this.dictionaryService.getPaymentMethods();
    const parentCompany$ = (this.mode === FormMode.EDIT) ? this.parentCompanyService.getParentCompany(id) : of(null);
    forkJoin([ states$, types$, titles$, paymentMethods$, parentCompany$ ])
      .pipe(first())
      .subscribe(([ states, types, titles, paymentMethods, parentCompany ]) => {
        this.states = states;
        this.mediaTypes = types;
        this.paymentMethods = paymentMethods;
        this.titles = titles;
        this.manageTitleFilters();

        if (parentCompany) {
          if (!parentCompany.address) {
            delete parentCompany.address;
          } else {
            parentCompany.address.state = this.states.find(item => item.code === parentCompany.address?.state?.code);
          }
          parentCompany.paymentMethod = this.paymentMethods.find(item => item.id === parentCompany.paymentMethod?.id);
          this.parentCompanyForm.patchValue(parentCompany);
          this.dataSource.data = parentCompany.associatedStations || [];
          this.documentsUploadedBefore = parentCompany.documents;
        }
        this.loading = false;

        this.sort.changes.subscribe(sort => {
          this.dataSource.sort = sort.first;
          this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        });
      });
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  sortingDataAccessor = (item: Station, property: string) => {
    switch (property) {
      case 'stationType':
        return item.mediaType?.type;
      default:
        return item[property];
    }
  }

  get addressFormGroup(): FormGroup {
    return this.parentCompanyForm.get('address') as FormGroup;
  }

  get parentCompanyID() {
    return this.parentCompanyForm.get('id').value;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit(parentCompany: ParentCompany, addNew?: boolean) {
    this.saving = true;

    const request = this.mode === FormMode.EDIT
      ? this.parentCompanyService.updateParentCompany(parentCompany).pipe(
        tap(() => this.notificationService.success('Parent company was successfully updated'))
      )
      : this.parentCompanyService.createParentCompany(parentCompany).pipe(
        tap(() => this.notificationService.success('Parent company was successfully created'))
      );

    request.subscribe(
      (data) => {
        if (this.documents.length) {
          this.uploadDocuments(data.id, addNew);
        } else {
          addNew ? this.createNew() : this.returnClicked();
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  uploadDocuments = (id: number, addNew?: boolean) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.parentCompanyService.uploadDocument(id, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
      () => addNew ? this.createNew() : this.returnClicked(),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  createNew() {
    this.router.navigate(['/entities/parentCompany/', '']);
  }

  get stationTypes() {
    return [...new Set(this.dataSource.data.filter(item => !!item).map(item => item.mediaType?.type))];
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  manageTitleFilters() {
    this.filteredTitles = TypeaheadUtil.manageTitleFilter(this.addressFormGroup, this.titles);
  }

  displayTitle(item?: Title): string | undefined {
    return item ? item.title : undefined;
  }

  onBlurTitle(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurTitle(this.addressFormGroup.get('contactTitle'), this.titles, inputElement);
  }

  onTitleOptionSelect(option) {
    TypeaheadUtil.onTitleOptionSelect(this.addressFormGroup.get('contactTitle'), option);
  }

  private returnClicked() {
    this.router.navigateByUrl('/entities/parentCompany');
  }

  compareMediaTypes(mediaType1, mediaType2) {
    return mediaType1?.id === mediaType2?.id;
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.parentCompanyForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} parent company.`);
  }

  private createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);
      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const stationTypeSearch = FilterUtils.createSelectSearch(searchTerms.stationType, data.mediaType?.type);

      return nameSearch() && stationTypeSearch();
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  setDocumentation(data: any) {
    this.documents = data.files;
  }
}
