import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { VersionedEntity } from '../models/versioned-entity';

@Component({
  selector: 'app-table-version-history',
  templateUrl: './table-version-history.component.html',
  styleUrls: ['./table-version-history.component.scss']
})
export class TableVersionHistoryComponent implements OnInit {
  versionHistoryTableData = [];
  displayedColumns = ['version', 'versionCreatedAt', 'versionAuthor'];
  dataSource = new MatTableDataSource<VersionedEntity[]>();
  isShowAllVersions = false;

  @Input() set versionData(data) {
    this.versionHistoryTableData = data;
    this.sortVersionHistoryByDate();
    this.showLatestVersions();
  }

  constructor() { }

  ngOnInit(): void {
  }

  sortVersionHistoryByDate() {
    this.versionHistoryTableData = this.versionHistoryTableData
      ?.sort((item1, item2) => (!item2.versionCreatedAt || item1.versionCreatedAt > item2.versionCreatedAt) ? -1 : 1) || [];
  }

  showAllVersions() {
    this.dataSource.data = this.versionHistoryTableData;
    this.isShowAllVersions = true;
  }

  showLatestVersions() {
    this.dataSource.data = this.versionHistoryTableData.slice(0, 3);
    this.isShowAllVersions = false;
  }
}
