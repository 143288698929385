<h2>Commission Tiers</h2>

<div class="flex-container">

  <div class="left-column">
    <div class="exist-breakPoint flex-container">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let breakPoint of shownBreakPoints"
          [removable]="true"
          (removed)="deleteBreakPoint(breakPoint)">
          {{breakPoint}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="flex-container">
      <div class="boundary-value_left">
        <h3 class="mat-h3">$0</h3>
      </div>
      <app-slider
        class="multi-slider"
        *ngIf="!reloading"
                  (loadNext)="onLoadNext()"
                  [handles]="sliderHandles">
      </app-slider>
      <div class="boundary-value_right">
        <h3 class="mat-h3">$∞</h3>
      </div>
    </div>
  </div>


  <div >
    <div class="breakPoint">
      <span>Add New Commission BreakPoint:</span>
      <div class="add-form flex-container">
        <mat-form-field>
          <input [formControl]="newBreakPointControl" matInput type="number"
                 oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                 min="0"
                 maxlength="25">
          <mat-icon matSuffix>attach_money</mat-icon>
        </mat-form-field>

        <mat-card-actions class="add-form-button">
          <button type="button"
                  mat-raised-button color="primary"
                  (click)="addBreakPoint()"
                  [disabled]="isAddDisabled() || !newBreakPointControl.valid">+ Add
          </button>
        </mat-card-actions>
      </div>
    </div>
  </div>
</div>
