<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Check Request</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content>
        <div class="form-content">
          <div class="form-content_column">
            <app-table-version-history *ngIf="checkRequest.versions?.length"
                                       [versionData]="checkRequest.versions"></app-table-version-history>
            <mat-form-field>
              <mat-label>Account</mat-label>
              <input type="text"
                matInput
                required
                formControlName="account"
                #accountInput
                (blur)="onBlurAccount(accountInput)"
                [matAutocomplete]="autoAccount"
                [readonly]="isEditMode || order"
              >
              <mat-autocomplete #autoAccount="matAutocomplete" [displayWith]="displayAccount">
                <mat-option *ngFor="let option of filteredAccounts | async" [value]="option" (mousedown)="onAccountOptionSelect(option)">
                  {{option.code}} - {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="browse-link" (click)="openAccountDialog()" *ngIf="!isEditMode && !order">Browse Accounts ></div>

            <div *ngIf="order">
              <mat-form-field>
                <mat-label>Order#</mat-label>
                <input type="text" matInput [value]="order.code" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Election type</mat-label>
                <input type="text" matInput [value]="order.electionType?.type" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Election Date</mat-label>
                <input type="text" matInput [value]="order.electionDate" readonly>
              </mat-form-field>
            </div>

            <mat-form-field *ngIf="isEditMode">
              <mat-label>Request #</mat-label>
              <input formControlName="code" matInput readonly>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Assigned Accountant</mat-label>
              <input type="text"
                matInput required
                formControlName="assignedAccountant"
                #accountantInput
                (blur)="onBlurAccountant(accountantInput)"
                [matAutocomplete]="autoAccountant"
                [readonly]="!isCanEditAccountant"
              >
              <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayAccountant">
                <mat-option *ngFor="let option of filteredAccountants | async" [value]="option"
                            (mousedown)="onAccountantOptionSelect(option)">
                  {{option.name}} {{option.lastName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="browse-link" (click)="browseUser()" *ngIf="isCanEditAccountant">Browse Accountants ></div>

          </div>
          <div class="form-content_column">
            <h2>Notes</h2>
            <div *ngFor="let note of noteReverse" class="note">
              <div><b>{{note | noteHeader}}</b></div>
              <div><span>{{note.message}}</span></div>
            </div>
            <mat-form-field appearance="fill">
              <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="225"></textarea>
            </mat-form-field>
          </div>
        </div>
        <h2 *ngIf="!order && isCanEditFilter">Filter Flights</h2>
        <div class="filter-row" [formGroup]="filterForm">
          <div class="filter-row_left" *ngIf="!order && isCanEditFilter">
            <div class="filter-row-item">
              <mat-form-field>
                <mat-label>Date Range</mat-label>
                <mat-date-range-input formGroupName="range" [rangePicker]="picker">
                  <input matInput matStartDate formControlName="flightStartOn" placeholder="Start date">
                  <input matInput matEndDate formControlName="flightFinishOn" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="dateRangeGroup.hasError('dateRangeRequired')">
                  {{ dateRangeGroup.errors.dateRangeRequired }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="filter-row-item">
              <mat-form-field>
                <mat-label>Media Type</mat-label>
                <mat-select formControlName="stationMediaType">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let option of mediaTypes" aria-selected="true" [value]="option">
                    {{option.type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="filter-row-item">
              <mat-form-field>
                <mat-label>Parent Company</mat-label>
                <input type="text"
                  matInput
                  formControlName="parentCompany"
                  #parentCompany
                  (blur)="onBlurParentCompany(parentCompany)"
                  [matAutocomplete]="autoParentCompany"
                >
                <mat-autocomplete #autoParentCompany="matAutocomplete" [displayWith]="displayParentCompany">
                  <mat-option *ngFor="let option of filteredCompanies | async" [value]="option"
                              [matTooltip]="option.name" (mousedown)="onParentCompanyOptionSelect(option)">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div class="browse-link" (click)="browseCompanies()">Browse Companies ></div>
            </div>
            <button mat-raised-button class="filter-row-item filter-button" type="button" color="accent" (click)="getStationFlightChecks()">
              Search...
            </button>
          </div>

          <div class="filter-row_right" *ngIf="!isStationFlightChecksReadonly">
            <button mat-raised-button class="filter-row-item filter-button" type="button"
                    [disabled]="!checkRequest?.payments?.length" (click)="markPayIsFull(true)">
              Mark All as "Pay In Full"
            </button>
            <button mat-raised-button class="filter-row-item filter-button" type="button"
                    [disabled]="!checkRequest?.payments?.length" (click)="markPayIsFull(false)">
              Mark None as "Pay In Full"
            </button>
          </div>
        </div>
        <div *ngIf="checkRequest.payments && checkRequest.payments.length && !order && isCanEditFilter" class="stations-match">
          <span class="stations-match_data">
            {{checkRequest.summary?.numberOfStationsOrderedForAccount  + ' / ' + checkRequest.summary?.totalNumberOfStationsOrderedForAccount}}
          </span>Stations match the current Client, Station Type, and Parent Company
        </div>
        <div *ngFor="let data of checkRequest.payments; let i=index;" class="note">
          <app-station-flight-checks
            [payment]="data" [index]="i" [readonly]="isStationFlightChecksReadonly"
            (payAmountChanged)="onPayAmountChanged()" [showBalance]="isShowBalance"
          ></app-station-flight-checks>
        </div>
      </mat-card-content>

      <mat-card class="summary-container">
        <div class="subheader subheader-summary mat-title">Payment Summary</div>
        <div *ngFor="let data of checkRequest.payments" class="row">
          <div class="note summary-field-name">
            <h3>
              <span class="bolder">{{data.station.name}}</span>&nbsp;
              <span class="smaller">{{data.station.mediaType.type}}</span>:
            </h3>
          </div>
          <div class="note summary-field-value">
            <h3 class="bolder">{{data.payAmount | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row">
          <h3 class="large summary-field-name bolder">Total Requested:</h3>
          <div class="row summary-field-value" [ngClass]="{'marked': totalRequested < 0}">
            <h3 class="bolder">{{totalRequested | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Current Account Balance:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': currentAccountBalance < 0}">
            <h3 class="bolder">{{currentAccountBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Total of outstanding requests:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': totalOfOutstandingRequestsAmount < 0}">
            <h3 class="bolder">{{totalOfOutstandingRequestsAmount | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row" *ngIf="isShowBalance">
          <h3 class="summary-field-name">
            Balance After This Request:</h3>
          <div class="note marked summary-field-value" [ngClass]="{'marked': afterThisRequestBalance < 0}">
            <h3 class="bolder">{{afterThisRequestBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row margin-top" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Outstanding Invoices:</h3>
          <div class="notes summary-field-value" [ngClass]="{'marked': outstandingInvoicesBalance < 0}">
            <h3 class="bolder">{{outstandingInvoicesBalance | negativeCurrency}}</h3>
          </div>
        </div>
        <div class="row" *ngIf="isShowBalance">
          <h3 class="summary-field-name">Balance After This Request and Invoices:</h3>
          <div class="note summary-field-value" [ngClass]="{'marked': afterThisRequestAndInvoicesBalance < 0}">
            <h3 class="bolder">{{afterThisRequestAndInvoicesBalance | negativeCurrency}}</h3>
          </div>
        </div>
      </mat-card>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading color="primary"
        [loading]="saving" [disabled]="!form.valid || isRequesting"
        (click)="saving=true; save(form);"
      >Save</button>
      <button mat-raised-button appLoading color="primary"
        [loading]="submitting" [disabled]="!form.valid || isRequesting"
        type="button"
        (click)="saveAndSubmit()"
        *ngIf="isAllowSubmit"
      >Save and Submit
      </button>
      <button mat-raised-button [disabled]="isRequesting" type="button" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

