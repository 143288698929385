<div role="main">
  <h1>
    <button mat-icon-button routerLink="/orders/transactions/invoice">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <span>Station Invoice Detail</span>
    <div class="buttons-container">
      <button mat-mini-fab color="primary" aria-label="Edit Station Invoice" *ngIf="hasEditPermission"
              [routerLink]="['/orders/transactions/invoice/edit', stationInvoiceData?.id]" class="edit-button">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading" class="container">
    <mat-card-content class="content">
      <div class="content-left">
        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="column-item">
            <div class="title">Station Invoice ID#</div>
            {{stationInvoiceData.code || 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Account Name</div>
            {{stationInvoiceData.account?.name || 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Account ID#</div>
            {{stationInvoiceData.account?.code || 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Account Type</div>
            {{stationInvoiceData.account ? getAccountType() : 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Station</div>
            {{stationInvoiceData.station?.name || 'N/A'}}
          </div>
        </mat-card>
        <div class="subheader mat-title">Invoice</div>
        <mat-card class="container">
          <div class="column-item">
            <div class="title">Invoice Number</div>
            {{stationInvoiceData.referenceNumber || 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Invoice Created Date</div>
            {{(stationInvoiceData.addedOn | date: 'shortDate') || 'N/A'}}
          </div>
          <div class="column-item">
            <div class="title">Invoice Date Span</div>
            {{
            stationInvoiceData.ftcOn && stationInvoiceData.ltcOn
              ? (stationInvoiceData.ftcOn | date: 'shortDate') + ' - ' + (stationInvoiceData.ltcOn | date: 'shortDate')
              : 'N/A'
            }}
          </div>
          <div class="column-item">
            <div class="title">Amount</div>
            {{stationInvoiceData.netRanAmount ? (stationInvoiceData.netRanAmount | currency) : 'N/A'}}
          </div>
        </mat-card>
      </div>

      <div class="content-right">
        <div class="subheader mat-title">Invoice Documentation</div>
        <mat-card class="documents-container">
          <mat-card-content>
            <app-file-upload *ngIf="documentsUploadedBefore.length"
              [id]="stationInvoiceData.id"
              [filesUploadedBefore]="documentsUploadedBefore"
              [mode]="mode"
              [component]="component"
              [canUpload]="false"
            ></app-file-upload>
            <div *ngIf="!documentsUploadedBefore.length">No Uploaded files.</div>
          </mat-card-content>
        </mat-card>
        <div class="subheader mat-title">Notes</div>
        <mat-card class="notes-container">
          <mat-card-content>
            <ng-container *ngIf="stationInvoiceData.notes?.length">
              <div class="note" *ngFor="let note of stationInvoiceData.notes.reverse()">
                <div><b>{{note | noteHeader}}</b></div>
                <div><span>{{note.message}}</span></div>
              </div>
            </ng-container>
            <ng-container *ngIf="!stationInvoiceData.notes?.length">
              No created Notes.
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
