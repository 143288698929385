import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { StationInvoiceExpense } from '../shared/models';
import { ExpensesService } from '../shared/expenses.service';

@Component({
  selector: 'app-expense-versions-dialog',
  templateUrl: './expense-versions-dialog.component.html',
  styleUrls: ['./expense-versions-dialog.component.scss']
})
export class ExpenseVersionsDialogComponent implements OnInit {
  isLoadingResults = true;
  displayedColumns: string[] = ['version', 'amount', 'date', 'quickBookReference', 'versionCreatedAt', 'versionAuthor'];
  dataSource = new MatTableDataSource<StationInvoiceExpense>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number}, private service: ExpensesService) { }

  ngOnInit(): void {
    this.service.getExpenseVersions(this.data.id).pipe(take(1))
      .subscribe(data => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
      });
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

}
