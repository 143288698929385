export const API_URL = '/api';
export const DICTIONARIES = `${API_URL}/dictionaries`;
export const USERS = `${API_URL}/users`;
export const USER = `${API_URL}/user`;
export const TYPEAHEAD = `${API_URL}/typeahead`;
export const ACCOUNTS = `${API_URL}/accounts`;
export const CONSULTANT_COMPANIES = `${API_URL}/consultantCompanies`;
export const STATIONS = `${API_URL}/stations`;
export const PARENT_COMPANIES = `${API_URL}/parentCompanies`;
export const CPM_COMPANIES = `${API_URL}/cpmcompanies`;
export const PAYMENT_METHODS = `${API_URL}/paymentMethods`;
export const ORDERS = `/orders`;
export const REPORTS = `${API_URL}/reports`;
export const TRANSACTIONS = `${API_URL}/transactions`;
export const STATION_TRANSACTIONS = `${API_URL}/stations/transactions`;
export const EXPENSES = `${API_URL}/expenses`;
export const PAYMENT_BATCHES = `${API_URL}/transactions/paymentCheckBatches`;
export const VERSION = `${API_URL}/version`;
export const DASHBOARD = `${API_URL}/dashboard`;
export const DOCUMENTS = `${API_URL}/documents`;
