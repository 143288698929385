import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Payment } from '../shared/models';
import { ComponentType } from '../../shared/models/component-type.enum';
import { DateUtils } from '../../shared/utils/date-utils';

import { PaymentVersionsDialogComponent } from '../payment-versions-dialog/payment-versions-dialog.component';

@Component({
  selector: 'app-payment-batch-station',
  templateUrl: './payment-batch-station.component.html',
  styleUrls: ['./payment-batch-station.component.scss']
})
export class PaymentBatchStationComponent implements OnInit {
  @Input() payment: Payment;
  @Input() paymentBatchId;
  @Input() readonly = false;
  @Input() index;
  @Input() mode;
  @Output() flightFormChange = new EventEmitter();
  @Output() batchDocumentationChange = new EventEmitter();
  isCompleted = false;
  form: FormGroup;
  ComponentType = ComponentType;
  documentsUploadedBefore = [];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isCompleted = this.payment?.paid;
    this.documentsUploadedBefore = this.payment.documents;
    this.buildForm();
    this.processFormChanges();
    (!this.payment?.payAmount && this.payment?.payAmount !== 0) ? this.flightFormChange.emit(true) : this.flightFormChange.emit(false);
  }

  showVersions() {
    this.dialog.open(PaymentVersionsDialogComponent, { data: { versions: this.payment.versions } });
  }

  get station() {
    return this.payment?.station;
  }

  get paidInfo(): string {
    const pipe = new DatePipe('en-US');
    const formattedDate = pipe.transform(this.payment?.paidAt, 'short');
    return `by ${this.payment?.paidBy?.name} ${this.payment?.paidBy?.lastName} ${formattedDate} `;
  }

  get paymentGuidelinesUrl() {
    return this.station?.paymentGuidelinesUrl ?
      (this.station?.paymentGuidelinesUrl.startsWith('http') ? this.station?.paymentGuidelinesUrl :
        `http://${this.station?.paymentGuidelinesUrl}`)
      : '';
  }

  get markets() {
    const markets = this.station?.mediaMarkets || [];
    return markets.map(item => item.name).join(', ');
  }

  private buildForm() {
    this.form = new FormGroup({
      payAmount: new FormControl(this.payment?.payAmount || 0, [Validators.required]),
      quickBookReference: new FormControl(this.payment?.quickBookReference || ''),
      completed: new FormControl(this.payment?.paid || false),
      paidOn: new FormControl(this.payment?.paidOn ? new Date(this.payment?.paidOn) : new Date (), [Validators.required]),
    });
  }

  private processFormChanges() {
    this.form.valueChanges.subscribe(val => {
      this.payment.paid = val.completed;
      this.payment.quickBookReference = val.quickBookReference;
      this.payment.payAmount = val.payAmount;
      this.payment.paidOn = DateUtils.dateFormatToShort(val.paidOn);
      this.flightFormChange.emit(this.form.status === 'INVALID');
    });
  }

  setBatchDocumentation(data: any) {
    this.batchDocumentationChange.emit(data.files);
  }
}
