<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="flex-container flex-wrap panel-title">
        <div class="title">
          <h2>Flight {{flightNumber}}/{{totalFlights}}</h2>
        </div>
        <div class="flight-name">
          <h3>{{flightStations.length}} Stations</h3>
        </div>
        <div class="flight-buttons">
          <button type="button"
                  class="delete-flight"
                  mat-icon-button
                  color="accent"
                  matTooltip="Delete flight"
                  [disabled]="!canDeleteFlights"
                  (click)="this.deleteFlight.emit()">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <button type="button"
                  class="duplicate-flight"
                  mat-icon-button
                  color="accent"
                  matTooltip="Duplicate Flight"
                  [disabled]="disabled"
                  (click)="this.duplicateFlight.emit()">
            <mat-icon>file_copy</mat-icon>
          </button>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>
  <div [formGroup]="flightForm">
    <div class="flex-container flex-wrap">
      <mat-form-field class="data">
        <mat-label>FTC</mat-label>
        <input matInput [formControl]="ftcDateControl" [matDatepicker]="pickerFTC" required>
        <mat-datepicker-toggle matSuffix [for]="pickerFTC"></mat-datepicker-toggle>
        <mat-datepicker #pickerFTC ></mat-datepicker>
        <mat-error *ngIf="ftcDateControl.hasError('required')">
          FTC is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="ftcDateControl.hasError('flightDatesError')">
        </mat-error>
      </mat-form-field>

      <mat-form-field class="data">
        <mat-label>LTC</mat-label>
        <input matInput [formControl]="ltcDateControl" [matDatepicker]="pickerLTC" required>
        <mat-datepicker-toggle matSuffix [for]="pickerLTC"></mat-datepicker-toggle>
        <mat-datepicker #pickerLTC ></mat-datepicker>
        <mat-error *ngIf="ltcDateControl.hasError('required')">
          LTC is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="ltcDateControl.hasError('flightDatesError')">
        </mat-error>
      </mat-form-field>

      <mat-form-field class="data">
        <mat-label>Estimate Number</mat-label>
        <input formControlName="estimateNumber" matInput maxlength="50">
      </mat-form-field>
    </div>

    <span *ngIf="datesForm.hasError('flightDatesError')" class="mat-error error-span">
      {{datesForm.getError('flightDatesError')}}
    </span>

    <div *ngFor="let flightStation of flightStations.controls; let i=index" [formGroup]="flightStation" class="flex-container flex-wrap station-data-container">

      <div class="station-data-item_account">

        <mat-form-field>
          <mat-label>Station</mat-label>
          <input type="text"
                 matInput
                 required
                 formControlName="station"
                 (blur)="onBlurStation(i)"
                 [disabled]="disabled"
                 [readonly]="disabled"
                 [matAutocomplete]="autoStation"
          >
          <mat-autocomplete #autoStation="matAutocomplete" [displayWith]="displayStationName">
            <mat-option *ngFor="let option of filteredStation[i] | async" [value]="option" [disabled]="disabled"
                        (click)="onStationOptionSelect(option, i)">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="flightStation.get('station').hasError('required')">
            Station is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <div *ngIf="!disabled" class="browse-link"  (click)="openDialog(i)">Browse Stations ></div>
      </div>

      <mat-form-field class="station-data-item">
        <mat-label>Net Amount</mat-label>
        <input matInput type="number" formControlName="netAmount" maxlength="50" required>
        <mat-icon matSuffix>attach_money</mat-icon>
        <mat-error *ngIf="flightStation.get('netAmount').hasError('required')">
          Net Amount is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="station-data-item">
        <mat-label>Type</mat-label>
        <input matInput
               [value]="getMediaType(flightStation.value.station)"
               [readonly]="true"
               [disabled]="disabled">
      </mat-form-field>

      <button type="button" *ngIf="canDeleteStation(flightStation.value)"
              class="station-delete-button"
              mat-icon-button color="accent"
              aria-label="Delete Station"
              (click)="deleteStation(i)">
        <mat-icon>close</mat-icon>
      </button>

      <mat-form-field class="station-data-item">
        <mat-label>State</mat-label>
        <input matInput [value]="getState(flightStation.value.station)" [readonly]="true"  [disabled]="disabled">
      </mat-form-field>

      <mat-form-field class="station-data-item">
        <mat-label>Market</mat-label>
        <input matInput
               [matTooltip]="getMarketsListString(flightStation.value.station)"
               [value]="getMarketsListString(flightStation.value.station)"
               [readonly]="true"
               [disabled]="disabled">
      </mat-form-field>

      <mat-form-field class="station-data-item">
        <mat-label>City</mat-label>
        <input matInput [value]="getCity(flightStation.value.station)" [readonly]="true" [disabled]="disabled">
      </mat-form-field>

    </div>

    <div class="flex-container">
      <button [disabled]="disabled" type="button" class="add-button" (click)="addStation()" mat-raised-button color="accent">Add station</button>
      <button [disabled]="disabled" type="button" class="add-button" (click)="createStation()" mat-raised-button color="accent">Create new Station</button>
      <div class="total-netAmount">
        <h3>{{totalNetAmount.toFixed(2) | currency }}</h3>
        <h5>Flight {{flightNumber}} Subtotal</h5>
      </div>
    </div>
  </div>
</mat-expansion-panel>
