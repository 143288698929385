import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  Commissionable,
  ConsultantCompany,
  ConsultantCompanyAccountBalance,
} from './models';
import { CONSULTANT_COMPANIES } from '../../core/apiUrls';
import { ConsultantCompanyCommission } from '../../orders/shared/models';


@Injectable({
  providedIn: 'root'
})
export class ConsultantCompanyService {

  constructor(private http: HttpClient) { }

  getConsultantCompanyList(): Observable<ConsultantCompany[]> {
    return this.http.get<ConsultantCompany[]>(CONSULTANT_COMPANIES);
  }

  getConsultantCompany(id: string): Observable<ConsultantCompany> {
    return this.http.get<ConsultantCompany>(`${CONSULTANT_COMPANIES}/${id}`);
  }

  createConsultantCompany(consultantCompany: ConsultantCompany): Observable<ConsultantCompany> {
    return this.http.post<ConsultantCompany>(CONSULTANT_COMPANIES, consultantCompany);
  }

  updateConsultantCompany(consultantCompany: ConsultantCompany): Observable<ConsultantCompany> {
    return this.http.put<ConsultantCompany>(`${CONSULTANT_COMPANIES}/${consultantCompany.id}`, consultantCompany);
  }

  getConsultantCompanyLeads(): Observable<ConsultantCompany[]> {
    return this.http.get<ConsultantCompany[]>(`${CONSULTANT_COMPANIES}/allLead`);
  }

  getConsultantCompanyTransactionList(consultantCompanyId: string, createdAfter?: string, createdBefore?: string):
    Observable<ConsultantCompanyCommission[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }
    return this.http.get<ConsultantCompanyCommission[]>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/transactions`,
      { params: httpParams});
  }

  getConsultantCompanyTransaction(consultantCompanyId: string): Observable<ConsultantCompanyCommission> {
    return this.http.get<ConsultantCompanyCommission>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/transactions`);
  }

  createConsultantCompanyTransaction(consultantCompanyId: string, transaction: ConsultantCompanyCommission):
    Observable<ConsultantCompanyCommission> {
    return this.http.post<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/transactions/${transaction.account.id}`, transaction);
  }

  updateConsultantCompanyTransaction(consultantCompanyId: string, transaction: ConsultantCompanyCommission):
    Observable<ConsultantCompanyCommission> {
    return this.http.put<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/transactions/${transaction.id}`, transaction);
  }

  getConsultantCompanyTransactionsBalance(consultantCompanyId: string): Observable<ConsultantCompanyAccountBalance> {
    return this.http.get<ConsultantCompanyAccountBalance>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/transactions/balance`);
  }

  getCommissionableTransactionList(consultantCompanyId: string, accountId: string): Observable<Commissionable[]> {
    return this.http.get<Commissionable[]>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissionable/${accountId}`);
  }
}
