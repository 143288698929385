<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Accept Payment</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" (ngSubmit)="save(form)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content>
        <div class="form-content">
          <div class="form-content_column">
            <mat-form-field>
              <mat-label>Account</mat-label>
              <input type="text"
                     matInput
                     required
                     formControlName="account"
                     #accountInput
                     (blur)="onBlurAccount(accountInput)"
                     [matAutocomplete]="autoAccount"
                     [readonly]="isEditMode || acceptPayment"
              >
              <mat-autocomplete #autoAccount="matAutocomplete" [displayWith]="displayAccount">
                <mat-option *ngFor="let option of filteredAccounts | async" [value]="option"
                            (mousedown)="onAccountOptionSelect(option)">
                  {{option.code}} - {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="browse-link" (click)="openAccountDialog()" *ngIf="!isEditMode && !acceptPayment">Browse Accounts
              >
            </div>

            <div *ngIf="acceptPayment">
              <mat-form-field>
                <mat-label>Accept Payment ID#</mat-label>
                <input type="text" matInput [value]="acceptPayment.code" readonly>
              </mat-form-field>
            </div>

            <mat-form-field>
              <mat-label>Paid By</mat-label>
              <input formControlName="paidBy" matInput maxlength="25" required>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Payment Received Date</mat-label>
              <input matInput [matDatepicker]="pickerElectionDate" formControlName="paidOn" required>
              <mat-datepicker-toggle matSuffix [for]="pickerElectionDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerElectionDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <mat-label>QBref#</mat-label>
              <input formControlName="quickBookReference" matInput maxlength="25">
            </mat-form-field>

            <h2>Upload Proof of Payment</h2>
            <app-file-upload
              [filesUploadedBefore]="documentsUploadedBefore"
              (filesChanges)="setCreditDocumentation($event)"
              [mode]="mode"
              [propId]="accountId"
              [id]="acceptPaymentId"
              [component]="component"
              [readonly]="!hasEditPermission"
            >
            </app-file-upload>

            <h2>Notes</h2>
            <div *ngFor="let note of noteReverse" class="note">
              <div><b>{{note | noteHeader}}</b></div>
              <div><span>{{note.message}}</span></div>
            </div>
            <mat-form-field appearance="fill">
              <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
            </mat-form-field>

          </div>
          <div class="form-content_column">

            <div *ngFor="let item of acceptPaymentInvoices; let i = index;">
              <div class="invoice-row" [formGroup]="item">
                <mat-form-field class="invoice-row-item" disabled>
                  <mat-label>Invoice#</mat-label>
                  <input matInput [value]="getInvoiceCode(item)" readonly disabled>
                </mat-form-field>
                <mat-form-field class="invoice-row-item" disabled>
                  <mat-label>Amount Requested</mat-label>
                  <input matInput [value]="getInvoiceAmountRequested(item)" readonly disabled>
                </mat-form-field>
                <mat-form-field class="invoice-row-item">
                  <input type="number"
                         [placeholder]="'Amount'"
                         matInput
                         form
                         required
                         (change)="onAmountInput($event, i)"
                         [value]="getAmountValue(item)"
                         oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="25"
                  >
                  <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
                <button mat-icon-button color="primary"
                        type="button" (click)="removeInvoice(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <div class="total-currency-row">
              <div class="total-amount-field">
                <h2>Total Amount</h2>
              </div>
              <div class="total-amount-field hidden">
              </div>
              <div class="total-amount-field">
                <mat-form-field>
                  <input formControlName="paidAmount"
                         [readonly]="acceptPaymentInvoices.length > 0"
                         matInput
                         required
                         type="number"
                         oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         maxlength="25">
                  <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
              </div>
              <button class="hidden" mat-icon-button color="primary"
                      type="button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div class="actions-row button-row">
              <button type="button" mat-raised-button (click)="addPaymentClicked()" [disabled]="!canAddPaymentInvoice">
                Add payment
              </button>
            </div>

          </div>
        </div>

      </mat-card-content>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button type="submit" mat-raised-button appLoading color="primary" [loading]="saving" [disabled]="isSaveDisabled">Save</button>
      <button mat-raised-button type="button" [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>
