import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StationListComponent } from '../../entities/station-list/station-list.component';
import { Station } from 'src/app/entities/shared/models';

@Component({
  selector: 'app-browse-station-dialog',
  templateUrl: './browse-station-dialog.component.html',
  styleUrls: ['./browse-station-dialog.component.scss']
})
export class BrowseStationDialogComponent implements OnInit {
  @ViewChild(StationListComponent, {static: true}) stationList: StationListComponent;
  selectedStation: Station;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number, exclude: number[]}) {}

  ngOnInit(): void {
    if (!this.stationList) {
      return;
    }

    this.stationList.selection.changed.subscribe(station => {
      this.selectedStation = station.source.selected[0];
    });

    if (this.data.selected) {
      this.stationList.initSelected(this.data.selected);
    }
  }

}
