import { FormGroup } from '@angular/forms';
import { ValidationError } from '../models/validation-error';
import { NotificationService } from '../notification.service';

export class FormUtils {
  static filterEmptyFields<T>(object: T): T {
    return Object.fromEntries(Object.entries(object).filter(([key, value]) => {
      return value && value !== '';
    })) as T;
  }

  static lowerFirstLetter(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  static formValidationHandler(err: any, form: FormGroup, service: NotificationService, defaultMessage: string) {
    if (err.status === 400 && (err.error as ValidationError).errors) {
      const errors = (err.error as ValidationError).errors;
      const messages = [];
      Object.keys(errors).forEach(key => {
        const field = FormUtils.formFieldByName(form, key);
        if (field) {
          field.setErrors({validation: errors[key]});
        }
        messages.push(errors[key]);
      });
      service.warn(messages.join(' '));
    } else {
      service.error(defaultMessage);
    }
  }

  static formFieldByName(form: FormGroup, fieldName: string) {
    if (!form) {
      return null;
    }
    const groupSeparator = fieldName.indexOf('.');
    if (groupSeparator > -1) {
      const formName = fieldName.substring(0, groupSeparator);
      return FormUtils.formFieldByName(form.get(formName) as any, fieldName.substring(groupSeparator + 1));
    } else {
      return form.get(fieldName);
    }
  }

  static disableFormControls(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).disable();
    });
  }
}
