<div class="content" role="main">
  <div class="header">
    <h1>Station List</h1>
    <div class="header-action" *ngIf="!isSelectMode">
      <button mat-raised-button appLoading
              [loading]="isReportLoading$ | async"
              class="export-button" type="button"
              (click)="onExportClicked()"
      >Export...
      </button>
      <button mat-mini-fab color="primary" aria-label="New Station" [routerLink]="['/entities/stations/edit', '']"
              [disabled]="isReportLoading$ | async" *ngIf="hasEditPermission">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="searchStation(form.value)">
    <div class="filter-container">
      <mat-form-field [ngClass]="{'filter-column-w15': !isSelectMode, 'filter-column-w18': isSelectMode}">
        <mat-label>Station</mat-label>
        <input type="text" matInput
               maxlength="50"
               #stationInput
               (blur)="onBlurStation(stationInput)"
               [matAutocomplete]="autoStations"
               formControlName="station"
        >
        <mat-autocomplete #autoStations="matAutocomplete" [displayWith]="displayRelatedItem">
          <mat-option *ngIf="stations.length === 0" [value]="null">No Stations</mat-option>
          <mat-option *ngFor="let option of filteredStations | async"
                      [value]="option"
                      (mousedown)="onStationOptionSelect(option)"
                      [matTooltip]="option.name"
          >
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


      <mat-form-field [ngClass]="{'filter-column-w15': !isSelectMode, 'filter-column-w18': isSelectMode}">
        <mat-label>Parent Company</mat-label>
        <input type="text"
               matInput
               formControlName="parentCompany"
               #parentCompanyInput
               (blur)="onBlurParentCompany(parentCompanyInput)"
               [matAutocomplete]="auto"
        >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRelatedItem">
          <mat-option *ngFor="let option of filteredCompanies | async" [value]="option"
                      (mousedown)="onParentCompanyOptionSelect(option)" [matTooltip]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field [ngClass]="{'filter-column-w15': !isSelectMode, 'filter-column-w18': isSelectMode}">
        <mat-label>Media Type</mat-label>
        <mat-select formControlName="mediaTypes" multiple>
          <mat-option *ngFor="let option of mediaTypes" aria-selected="true" [value]="option">
            {{option.type}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="{'filter-column-w15': !isSelectMode, 'filter-column-w18': isSelectMode}">
        <mat-label>Sort by...</mat-label>
        <mat-select formControlName="stationSortField">
          <mat-option *ngIf="stations.length === 0" [value]="null">No Sort</mat-option>
          <mat-option *ngFor="let option of stationSortFields" [value]="option">
            {{ConvertedStationSortField[option]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div [ngClass]="{'filter-column-w15': !isSelectMode, 'filter-column-w18': isSelectMode}" *ngIf="!isSelectMode">
        <mat-checkbox formControlName="onlyUnbalancedRunningOrder" class="year-item">Unbalanced total (Order)
        </mat-checkbox>

        <mat-checkbox formControlName="onlyUnbalancedRunningInvoce" class="year-item">Unbalanced total (Invoice)
        </mat-checkbox>
      </div>

      <div class="filter-column">
        <button class="search-btn"
                mat-raised-button appLoading
                [loading]="loading$ | async"
                color="primary" type="submit"
        >Search Stations
        </button>
      </div>

    </div>

  </form>


  <div class="container" [ngClass]="{'mat-elevation-z8': !isSelectMode}">
    <div class="loading-shade" *ngIf="loading$ | async; else table">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
    <ng-template #table>
      <div class="table-container"
           [ngClass]="{'no-data-table-container': !dataSource.data.length}">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select" sticky>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [disabled]="exclude.includes(row.id)"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Station Name</th>
            <td mat-cell *matCellDef="let row">
              <a [routerLink]="['/entities/stations/detail', row.id]" *ngIf="!isSelectMode">{{row.name}}</a>
              <span *ngIf="isSelectMode">{{row.name}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="mediaType">
            <th mat-header-cell *matHeaderCellDef>Media Type</th>
            <td mat-cell *matCellDef="let row">{{row.mediaType?.type || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="parentCompany">
            <th mat-header-cell *matHeaderCellDef>Parent Company</th>
            <td mat-cell *matCellDef="let row">{{row.parentCompany?.name || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="runningOrderBalance">
            <th mat-header-cell *matHeaderCellDef>Running Balance (Order)</th>
            <td mat-cell *matCellDef="let row">{{(row.runningOrderBalance | negativeCurrency) || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="runningInvoiceBalance">
            <th mat-header-cell *matHeaderCellDef>Running Balance (Invoice)</th>
            <td mat-cell *matCellDef="let row">{{(row.runningInvoiceBalance | negativeCurrency) || 'N/A'}}</td>
          </ng-container>

          <ng-container matColumnDef="menu" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button class="button pull-right"
                      *ngIf="hasEditPermission"
                      [matMenuTriggerFor]="appMenu"
                      [matMenuTriggerData]="{ id: row.id}">
              <span class="material-icons">
                more_vert
              </span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="!dataSource.data.length">
          <p class="no-data">No data..</p>
        </ng-container>
      </div>

      <mat-menu #appMenu>
        <ng-template matMenuContent let-id="id">
          <button mat-menu-item (click)="onEditClicked(id)">Edit</button>
        </ng-template>
      </mat-menu>

      <mat-paginator *ngIf="!!stationListPage" [length]="stationListPage.totalElements"
                     [pageSize]="stationListPage.elementsPerPage"
                     [pageIndex]="stationListPage.page - 1"
                     (page)="onPaginateChange($event)"></mat-paginator>
    </ng-template>

  </div>
</div>
