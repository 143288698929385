export interface ReportType {
  id: number;
  type:	string;
  filterDataClassName:	string;
  convertedType: ConvertedReportType;
}

export enum ConvertedReportType {
  ACCOUNT_GLOBAL_BALANCE = 'ACCOUNT_GLOBAL_BALANCE',
  ACCOUNT_CONSULTANT_COMPANY = 'ACCOUNT_CONSULTANT_COMPANY',
  ACCOUNT_GLOBAL = 'ACCOUNT_GLOBAL',
  ACCOUNT_CHECK = 'ACCOUNT_CHECK',
  ACCOUNT_ORDER = 'ACCOUNT_ORDER',
  ACCOUNT_INVOICE = 'ACCOUNT_INVOICE',
  STATION_ORDER = 'STATION_ORDER',
  STATION_INVOICE = 'STATION_INVOICE'
}

export interface ReportFilter {
  reportType?: ConvertedReportType;
  since?: string;
  to?: string;
  year?: number;
  consultantCompanyId?: number;
  accountId?: number;
  cpmCompanyIds?: number[];
  includeAll?: boolean;
}

export interface Report<T> {
  filter: ReportFilter;
  entries: T[];
}

export interface ConsultantCompanyCommissionReport extends Report<ConsultantCompanyCommissionReportEntry> {
  filter: ReportFilter;
  entries: ConsultantCompanyCommissionReportEntry[];
  consultantSplitColumnName: string;
  consultantPaidColumnName: string;
  consultantBalanceColumnName: string;
  cpmName: string;
  accountName: string;
  consultantCompanyName: string;
  reportCreatedBy: string;
}

export interface ConsultantCompanyCommissionReportEntry {
  type: ConsultantCompanyCommissionReportEntryType;
  sortNumber: number;
  tierNumber: number;
  transactionTypeId: number;
  title: string;
  transactionId: string;
  transactionDate: string;
  ftc: string;
  ltc: string;
  quickBookReference: string;
  grossAmount: number;
  netAmount: number;
  cpmSplitAmount: number;
  consultantCompanySplitAmount: number;
  paidToConsultantCompanyAmount: number;
  balanceWithConsultantAmount: number;
  tierRow: boolean;
  tierColumn1: string;
  tierColumn2: string;
  tierColumn3: string;
  totalRow: boolean;
  transactionDateHeader: boolean;
  ConsultantCompanyCommissionReportEntry: boolean;
}

enum ConsultantCompanyCommissionReportEntryType {
  PAYMENT,
  COMMISSION
}

export interface AccountCheckReport extends Report<AccountCheckReportEntry> {
  filter: ReportFilter;
  entries: AccountCheckReportEntry[];
  cpmName: string;
  accountName: string;
  consultantCompanyName: string;
  reportCreatedBy: string;
}

export interface AccountCheckReportEntry {
  transactionTypeId: number;
  typeId: number;
  type: AccountCheckReportEntryType;
  title: string;
  transactionType: string;
  transactionDate: string;
  transactionId: string;
  grossAmount: number;
  adjustedGrossAmount: number;
  netAmount: number;
  expenseAmount: number;
  receivedAmount: number;
  balanceAmount: number;
  outstandingAccountInvoiceAmount: number;
  total: boolean;
  sortNumber: number;
}

export enum AccountCheckReportEntryType {
  PAYMENT = 'PAYMENT',
  EXPENSE = 'EXPENSE',
  REFUND = 'REFUND',
  ACCOUNT_INVOICE = 'ACCOUNT_INVOICE',
  ACCEPT_PAYMENT = 'ACCEPT_PAYMENT',
  TOTAL = 'TOTAL'
}

export interface GlobalAccountBalanceReport  extends Report<GlobalAccountBalanceReportEntry> {
  filter: ReportFilter;
  entries: GlobalAccountBalanceReportEntry[];
  cpmCompanyName: string;
}

export interface GlobalAccountBalanceReportEntry {
  id: number;
  accountName: string;
  consultantCompanyName: string;
  grossAmount: number;
  adjustedGrossAmount: number;
  netAmount: number;
  expenseAmount: number;
  receivedAmount: number;
  balanceAmount: number;
  outstandingAccountInvoiceAmount: number;
  commissionOwedAmount: number;
  cpmCommissionAmount: number;
  total: boolean;
}

export enum Operator {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LESS = 'LESS',
  GREATER = 'GREATER'
}
