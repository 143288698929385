<mat-card>
  <ng-container *ngIf="commissionableData?.length; else noCommissionableData">
    <table
           mat-table
           [dataSource]="tableData">

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Transaction ID#</th>
        <td mat-cell *matCellDef="let element"> {{element.code || ''}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="stationType">
        <th mat-header-cell *matHeaderCellDef> Station - Media Type</th>
        <td mat-cell *matCellDef="let element"> {{ getStationMediaType(element)}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="ftc">
        <th mat-header-cell *matHeaderCellDef> FTC - LTC</th>
        <td mat-cell *matCellDef="let element"> {{ getFtcLtc(element) || '' }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="quickBookReference">
        <th mat-header-cell *matHeaderCellDef> QuickBook #</th>
        <td mat-cell *matCellDef="let element"> {{element.quickBookReference || '-' }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="grossPaymentTotal">
        <th mat-header-cell class="small-column" *matHeaderCellDef> Gross Payment Total</th>
        <td mat-cell *matCellDef="let element"> {{(element.grossPaymentTotal | currency) || ''}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="netPaymentTotal">
        <th mat-header-cell class="small-column" *matHeaderCellDef> Net Payment Total</th>
        <td mat-cell *matCellDef="let element"> {{(element.netPaymentTotal | currency) || '' }} </td>
        <td mat-footer-cell class="large-cell" *matFooterCellDef>
          <span>Total = <br>COMMISSION BALANCE OWED =</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="consultantSplit">
        <th mat-header-cell class="small-column" *matHeaderCellDef> Consultant Split</th>
        <td mat-cell
            *matCellDef="let element"> {{(element.consultantSplit[0]?.consultantRates[0]?.amount | currency) || ''}} </td>
        <td mat-footer-cell *matFooterCellDef>{{total | currency}} <br>{{commissionBalanceOwed | currency }}</td>
      </ng-container>

      <ng-container matColumnDef="tierInfo">
        <th mat-header-cell class="small-column" *matHeaderCellDef> Tier Info</th>
        <td mat-cell *matCellDef="let element"> {{getTierInfo(element.consultantSplit[0]) || ''}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="footer-row-group">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="3" class="footer-row-group">
          Total = <br>Commission balance owed =
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedFooterColumns"></tr>

    </table>
  </ng-container>


  <ng-template #noCommissionableData>
    <p class="no-data">No Commissionable Transactions</p>
  </ng-template>
</mat-card>
