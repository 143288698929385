import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '../../shared/notification.service';
import { StationService } from '../shared/station.service';
import { AuthService } from '../../shared/auth.service';
import { MediaMarket, Station } from '../shared/models';
import { Role } from '../../shared/models/role.enum';
import { ComponentType } from '../../shared/models/component-type.enum';

@Component({
  selector: 'app-station-detail',
  templateUrl: './station-detail.component.html',
  styleUrls: ['./station-detail.component.scss']
})
export class StationDetailComponent implements OnInit {
  station: Observable<Station>;
  stationData: Station;
  loading = true;
  ComponentType = ComponentType;
  hasEditPermission = this.authService.hasEditPermission;
  hasCreateTransaction = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private route: ActivatedRoute,
              private stationService: StationService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.station = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.stationService.getStation(params.get('id')))
    );

    this.station.subscribe(
      station => {
        this.stationData = station;
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get station data')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.router.navigateByUrl('/entities/stations').then();
    }
  }

  onAddTransactionClicked() {
    this.router.navigate(
      ['/orders/memo-refund/edit/', ''], this.routeParams);
  }

  onAddInvoiceClicked() {
    this.router.navigate(
      ['/orders/transactions/invoice/edit/', ''], this.routeParams);
  }

  onViewAllTransactionClicked() {
    this.router.navigate([ 'entities/stations', this.stationData.id, 'transactions' ], this.routeParams);
  }

  get routeParams() {
    return {queryParams: {stationId: this.stationData.id, title: this.stationData.name}};
  }

  get paymentGuidelinesUrl() {
    const guidelinesUrl = this.stationData.overwriteParentCompanyPaymentMethod ? this.stationData.paymentGuidelinesUrl
      : this.stationData.parentCompany?.paymentGuidelinesUrl;
    return guidelinesUrl ? (guidelinesUrl.startsWith('http') ? guidelinesUrl : `http://${guidelinesUrl}`) : '';
  }

}
