import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AccountInvoiceService } from '../shared/account-invoice.service';
import { ReportsService } from '../../entities/shared/reports.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { getClientCandidateType } from 'src/app/shared/utils/sort-list-util';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { AccountInvoice, ConvertedStatus } from '../shared/models';
import { Filename } from '../../shared/models/filename.enum';
import { Role } from '../../shared/models/role.enum';
import { AfterSaveAction } from '../shared/models/after-save-action.enum';
import { Action } from '../../shared/models/action.enum';


@Component({
  selector: 'app-account-invoice-detail',
  templateUrl: './account-invoice-detail.component.html',
  styleUrls: ['./account-invoice-detail.component.scss']
})
export class AccountInvoiceDetailComponent implements OnInit {
  accountInvoice: Observable<AccountInvoice>;
  accountInvoiceData: AccountInvoice;
  loading = true;
  submitting = false;
  reporting = false;

  hasEditPermission = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: AccountInvoiceService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.accountInvoice = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.service.getInvoice(params.get('id')))
    );

    this.accountInvoice.subscribe(
      data => {
        this.accountInvoiceData = data;
        this.hasEditPermission = data.permissions?.includes(Action.EDIT) || data.permissions?.includes(Action.DISABLE);
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get account invoice data')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/cinvoices');
  }

  get isRequesting() {
    return this.submitting || this.reporting;
  }

  getAccountType() {
    return getClientCandidateType(this.accountInvoiceData.account);
  }

  getConsultantNameByCode(code) {
    return this.accountInvoiceData.boundConsultantCompanies.find(consultant => consultant.code === code)?.name;
  }

  getPaymentAmountTotal() {
    return this.accountInvoiceData.invoicedExpenses
      .map(invoicedExpense => invoicedExpense.amount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get canSubmit(): boolean {
    const hasPermission = this.authService.hasPermission([Role.SENIOR_BUYER, Role.BUYER])
      && this.accountInvoiceData?.permissions?.includes(Action.SUBMIT);
    const allowedStatuses = [
      ConvertedStatus.DRAFT,
      ConvertedStatus.DECLINED
    ];
    return hasPermission && this.accountInvoiceData && this.accountInvoiceData.accountInvoiceStatus &&
      allowedStatuses.includes(this.accountInvoiceData.accountInvoiceStatus.convertedStatus);
  }

  get versions() {
    return this.accountInvoiceData?.versions || [];
  }

  save(nextStatus?: AfterSaveAction) {
    if (nextStatus) {
      this.accountInvoiceData.afterSaveAction = nextStatus;
    }

    this.service.updateInvoice(this.accountInvoiceData)
      .pipe(finalize(() => this.resetRequesting()))
      .subscribe(
      (accountInvoice) => {
        this.accountInvoiceData = accountInvoice;
        this.notificationService.success('Account invoice was updated!');
      },
      () => this.notificationService.error(`Error occurred while trying to update account invoice.`)
    );
  }

  resetRequesting() {
    this.submitting = false;
  }

  saveAndSubmit() {
    this.submitting = true;
    this.save(AfterSaveAction.APPROVE_NEXT_STATUS);
  }

  onExportClicked() {
    this.reporting = true;

    this.reportsService.generateExcelReportForAccountInvoice(String(this.accountInvoiceData.id))
      .pipe(finalize(() => this.reporting = false))
      .subscribe(
        data => {
          const filename = data.headers.get('filename');
          ReportsUtil.downloadFile(data.body as ArrayBuffer, filename);
        },
        () => this.notificationService.error('Error occurred while trying to download report file.')
      );
  }
}
