import { ConsultantCompanyCommission } from '../models';

export class CommissionUtil {
  static getAssignedAccountant = (commission: ConsultantCompanyCommission) => {
    const name = commission.assignedAccountant?.name;
    const lastName = commission.assignedAccountant?.lastName;
    return (name && lastName) ? `${name} ${lastName}`
      : name ? name : lastName ? lastName : null;
  }

  static getStatus = (commission: ConsultantCompanyCommission) => {
    return commission.completed ? 'Paid' : 'Submitted';
  }

  static getConsultantLabel(index: number) {
    return index === 0 ? 'Lead Consultant' : `Consultant ${index + 1}`;
  }
}
