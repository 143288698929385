<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Vendor Memo or Station Refund</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit(form)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <h2>General Info</h2>
          <mat-form-field *ngIf="!isCreateMode">
            <mat-label>Memo/Refund ID#</mat-label>
            <input formControlName="code" matInput readonly>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Account</mat-label>
            <input type="text"
                   matInput
                   required
                   maxlength="50"
                   formControlName="account"
                   #accountInput
                   (blur)="onBlurAccount(accountInput)"
                   [matAutocomplete]="autoAccounts"
                   [readonly]="!isCreateMode"
            >
            <mat-autocomplete #autoAccounts="matAutocomplete" [displayWith]="displayAccount">
              <mat-option *ngIf="accounts.length === 0" [value]="null">No Accounts with ordered Station</mat-option>
              <mat-option *ngFor="let option of filteredAccounts | async"
                          [value]="option"
                          (mousedown)="onAccountOptionSelect(option)"
              >
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openAccountDialog()" *ngIf="isCreateMode">Browse Accounts ></div>

          <mat-form-field>
            <mat-label>Station</mat-label>
            <input type="text"
              matInput
              required
              maxlength="50"
              formControlName="station"
              [matAutocomplete]="autoStation"
              #stationInput
              (blur)="onBlurStation(stationInput)"
              [readonly]="!isCreateMode"
            >
            <mat-autocomplete #autoStation="matAutocomplete" [displayWith]="displayStation">
              <mat-option *ngIf="accountControl.value && stations.length === 0" [value]="null">No ordered Stations for Account</mat-option>
              <mat-option *ngIf="!accountControl.value && stations.length === 0" [value]="null">Enter the first two letters of the name to start filtering</mat-option>
              <mat-option *ngFor="let option of filteredStations | async"
                [value]="option"
                (mousedown)="onStationOptionSelect(option)"
              >
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openStationDialog()" *ngIf="isCreateMode">Browse Stations ></div>

          <mat-form-field>
            <mat-label>Date of Entry</mat-label>
            <input matInput formControlName="enteredOn" [matDatepicker]="pickerEnteredOn" required>
            <mat-datepicker-toggle matSuffix [for]="pickerEnteredOn"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnteredOn></mat-datepicker>
            <mat-error *ngIf="form.get('enteredOn').invalid">
              Date of Entry is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <h4 class="select-option_header" *ngIf="isCreateMode">Select One Option *</h4>
          <section class="form-element select-option">
            <mat-radio-group aria-label="Select Transaction Type" formControlName="type" class="margin-element" required
                             [disabled]="!isCreateMode || (hasBuyerPermission && !hasAccountantPermission)">
              <mat-radio-button class="radio-button" [value]="BalanceTransactionType.VENDOR_MEMO_CREDIT">Vendor MEMO / CREDIT</mat-radio-button>
              <mat-radio-button class="radio-button" [value]="BalanceTransactionType.REFUND">Station REFUND</mat-radio-button>
            </mat-radio-group>
          </section>

          <h2>Vendor Memo or Refund Amount</h2>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field>
                <mat-label>Reason</mat-label>
                <input matInput formControlName="reason" maxlength="100">
              </mat-form-field>
            </div>

            <div class="inner-form-content_column">
              <mat-form-field>
                <mat-label>Amount</mat-label>
                <input matInput type="number" formControlName="amount" required (keypress)="checkAmountLength($event.target.value)">
                <mat-icon matSuffix>attach_money</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field>
            <mat-label>QBref#</mat-label>
            <input formControlName="referenceNumber" matInput maxlength="25">
          </mat-form-field>
        </div>

        <div class="form-content_column">
          <h2>Vendor Memo or Refund Documentation</h2>
          <app-file-upload
            [filesUploadedBefore]="documentsUploadedBefore"
            (filesChanges)="setCreditDocumentation($event)"
            [mode]="mode"
            [id]="transactionId"
            [component]="component"
            [readonly]="!hasEditPermission"
          >
          </app-file-upload>
        </div>
      </mat-card-content>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading color="primary"
        [loading]="saving"
        [disabled]="!form.valid || saving"
        *ngIf="hasEditPermission"
      >Save</button>
      <button mat-raised-button [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

