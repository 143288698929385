import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { StationInvoiceExpense } from '../shared/models';
import { StationInvoiceService } from '../shared/station-invoice.service';
import { AccountInvoiceService } from '../shared/account-invoice.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-invoice-expense-list',
  templateUrl: './invoice-expense-list.component.html',
  styleUrls: ['./invoice-expense-list.component.scss']
})
export class InvoiceExpenseListComponent implements OnInit, OnDestroy {
  @Input() isAccountInvoices: boolean;
  @Input() invoicedExpenses = null;
  @Input() paymentAmountTotal = null;
  @Input() invoicedExpensesCommissionableTotalAmount = null;
  @Input() accountId: string = null;
  @Input() stationId: string = null;
  @Input() isMultiselect: boolean;
  private subscription = new Subscription();
  dataSource = new MatTableDataSource<StationInvoiceExpense>();
  displayedColumns: string[] = ['select', 'code', 'amount', 'createdAt', 'whoDidGoTo', 'paymentMethod', 'assignedAccountant', 'menu'];
  selection: SelectionModel<StationInvoiceExpense>;
  isLoadingResults = true;
  selectedIds: number[];
  isSelectMode = true;

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    amount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    station: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    whoDidGoTo: new FormControl(''),
    paymentMethod: new FormControl(''),
    assignedAccountant: new FormControl(''),
    commissionable: new FormControl('')
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: StationInvoiceService,
              private accountInvoiceService: AccountInvoiceService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    if (this.invoicedExpenses) {
      this.displayedColumns = ['station', 'code', 'whoDidGoTo', 'amount', 'commission'];
      this.dataSource.data = this.invoicedExpenses;
      this.isLoadingResults = false;
    } else {
      this.selection = new SelectionModel<StationInvoiceExpense>(this.isMultiselect, []);

      if (this.isAccountInvoices) {
        this.displayedColumns = ['select', 'station', 'code', 'whoDidGoTo', 'amount', 'commissionable'];
      }

      const expenses$ = this.isAccountInvoices
      ? this.accountInvoiceService.getStationInvoiceExpenses(this.accountId)
      : this.service.getListOfStationInvoiceExpenses(this.accountId, this.stationId);

      this.subscription.add(expenses$.subscribe(
          (data) => {
          this.dataSource.data = sortListByLastEdit(data);
          this.isLoadingResults = false;
          if (this.selectedIds) {
            this.initSelected(this.selectedIds);
          }
        },
        () => this.notificationService.error('Error occurred while trying to get expense list.')
      ));

      this.dataSource.paginator = this.paginator;

      this.tableFilterForm.valueChanges.subscribe(value => {
        value.createdAt.value = value.createdAt?.value?.toString();
        this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
      });

      this.dataSource.filterPredicate = this.createFilter();
    }

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'station':
        return item.station?.name;
      case 'paymentMethod':
        return item.paymentMethod?.name;
      case 'assignedAccountant':
        return this.getAssignedAccountant(item);
      default: return item[property];
    }
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  getAssignedAccountant(expense) {
    const name = expense.assignedAccountant?.name;
    const lastName = expense.assignedAccountant?.lastName;
    return (name && lastName) ? `${name} ${lastName}`
          : name ? name : lastName ? lastName : null;
  }

  initSelected(ids: number[]) {
    if (this.isLoadingResults) {
      this.selectedIds = ids;
      return;
    }
    const rows = this.dataSource.data.filter((item: StationInvoiceExpense) => ids.includes(item.id));
    if (rows) {
      rows.forEach(row => {
        this.selection.toggle(row as StationInvoiceExpense);
      });
    }
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const stationSearch = FilterUtils.searchFunction(searchTerms.station, data.station?.name);
      const commissionableSearch = FilterUtils.searchFunction(searchTerms.commissionable, String(data.commissionable));
      const whoDidGoToSearch = FilterUtils.searchFunction(searchTerms.whoDidGoTo, data.whoDidGoTo);
      const paymentMethodSearch = FilterUtils.searchFunction(searchTerms.paymentMethod, data.paymentMethod?.name);
      const assignedAccountantSearch = FilterUtils.searchFunction(searchTerms.assignedAccountant, this.getAssignedAccountant(data));
      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const amountSearch = FilterUtils.createDigitSearch('amount', 'amount', searchTerms, data);

      const filterFunctions = [
        codeSearch(),
        whoDidGoToSearch(),
        paymentMethodSearch(),
        assignedAccountantSearch(),
        createdAtSearch(),
        amountSearch(),
        commissionableSearch(),
        stationSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
