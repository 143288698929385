import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, first, take } from 'rxjs/operators';

import { PaymentService } from '../shared/payment.service';
import { PaymentMethod } from '../shared/models/payment-method';
import { PaymentType } from '../shared/models/payment-type';
import { FormUtils } from '../../shared/utils/form-utils';
import { NotificationService } from '../../shared/notification.service';
import { EntityDictionaryService } from '../../entities/shared/entity-dictionary.service';
import { State } from '../shared/models/state';
import { FormMode } from '../shared/models/form-mode.enum';
import { PaymentTypeEnum } from '../shared/models/payment-type.enum';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { AuthService } from '../../shared/auth.service';
import { Role } from '../../shared/models/role.enum';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const month = ('0' + (date.getMonth() + 1)).substr(-2);
      const year = date.getFullYear().toString().substr(-2);
      // Return the format as per your requirement
      return `${month}/${year}`;
    } else {
      return date.toDateString();
    }
  }
}

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YY'
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: CustomDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
    }
  ]
})
export class PaymentInfoComponent implements OnInit {
  loading = true;
  saving = false;
  mode = FormMode.CREATE;

  paymentMethods: PaymentMethod[];
  paymentTypes: PaymentType[];
  states: State[];
  hasEditPermission = false;

  paymentMethodForm = this.fb.group({
    id: [''],
    name: [''],
    type: [null],
    details: this.createPaymentDetailsForm()
  });

  constructor(private service: PaymentService,
              private fb: FormBuilder,
              private dictionaryService: EntityDictionaryService,
              private authService: AuthService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.hasEditPermission = this.authService.hasPermission([Role.SENIOR_ACCOUNTANT]);
    this.loadPaymentTypes();
    this.loadPaymentMethods();
    this.loadStates();

    if (!this.hasEditPermission) {
      FormUtils.disableFormControls(this.paymentMethodForm);
    }
  }

  loadPaymentMethods() {
    this.service.getPaymentMethodList().pipe(take(1)).subscribe(methods => {
      this.paymentMethods = methods;
    });
  }

  loadPaymentTypes() {
    this.service.getPaymentTypes().pipe(take(1)).subscribe(types => {
      this.paymentTypes = types;
      this.loading = false;
    });
  }

  loadStates() {
    this.dictionaryService.getStates().pipe(first()).subscribe(states => {
      this.states = states;
    });
  }

  onExpirationDateChange(value) {
    if (value.length === 5) {
      let [month, year] = value.split('/');
      month = Number(month) - 1;
      year = Number((new Date()).getFullYear().toString().substring(0, 2) + year);

      if (month && year && month > 0 && month < 13) {
        this.paymentMethodForm.get('details').get('expirationDate').patchValue(new Date(year, month));
        this.paymentMethodForm.updateValueAndValidity();
      }
    }
  }

  updatePaymentMethodForm(selectedType: PaymentType) {
    this.initPaymentMethodForm(selectedType);

    const selectedMethod = this.findExistsMethod(selectedType);
    this.mode = selectedMethod ? FormMode.EDIT : FormMode.CREATE;

    if (selectedMethod) {
      selectedMethod.details.addressState = this.states
        .find(item => item.code === selectedMethod?.details?.addressState?.code);
      this.paymentMethodForm.get('id').patchValue(selectedMethod.id);
      this.paymentMethodForm.get('details').patchValue(selectedMethod.details);
      this.paymentMethodForm.get('name').patchValue(selectedMethod.name);
      this.paymentMethodForm.get('name').patchValue(selectedMethod.name);
      this.initExpirationDate();
    }
  }

  initExpirationDate() {
    const expirationDate = this.paymentMethodForm.get('details').get('expirationDate').value;
    if (expirationDate?.length > 0) {
      const expirationDateArray = expirationDate.split('/');
      const month = expirationDateArray[0];
      const year = expirationDateArray[expirationDateArray.length - 1];
      this.paymentMethodForm.get('details').get('expirationDate')
        .patchValue(expirationDateArray.length > 0 ? new Date(year, Number(month) - 1) : null);
    }
  }

  savePaymentMethod(paymentMethod: PaymentMethod) {
    this.saving = true;

    if (paymentMethod.details.expirationDate) {
      paymentMethod.details.expirationDate = this.transformExpirationDateToString(paymentMethod.details.expirationDate);
    }
    switch (this.mode) {
      case FormMode.CREATE: {
        this.createPaymentMethod(paymentMethod);
        break;
      }
      case FormMode.EDIT: {
        this.editPaymentMethod(paymentMethod);
        break;
      }
    }
  }

  cancel() {
    this.updatePaymentMethodForm(this.paymentMethodForm.get('type').value as PaymentType);
  }

  transformExpirationDateToString(expirationDate: any): string {
    return `${expirationDate.getMonth() + 1}/${expirationDate.getFullYear()}`;
  }

  chosenYearHandler(normalizedYear: any) {
    const ctrlValue = this.paymentMethodForm.get('details').get('expirationDate').value || new Date();
    ctrlValue.setFullYear(normalizedYear.getFullYear());
    this.paymentMethodForm.get('details').get('expirationDate').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
    const ctrlValue = this.paymentMethodForm.get('details').get('expirationDate').value;
    ctrlValue.setMonth(normalizedMonth.getMonth());
    this.paymentMethodForm.get('details').get('expirationDate').setValue(ctrlValue);
  }

  private findExistsMethod(selectedType: PaymentType): PaymentMethod {
    return this.paymentMethods.find(method => method.name === selectedType.name);
  }

  private createPaymentMethod(paymentMethod: PaymentMethod) {
    this.service.createPaymentMethod(paymentMethod)
    .pipe(finalize(() => this.saving = false))
    .subscribe(
      () => {
        this.notificationService.success('New payment method created!');
        this.loadPaymentMethods();
      },
      (err) => this.actionErrorHandler(err, FormMode.CREATE)
    );
  }

  private editPaymentMethod(paymentMethod: PaymentMethod) {
    this.service.updatePaymentMethod(paymentMethod.id, paymentMethod)
    .pipe(finalize(() => this.saving = false))
    .subscribe(
      () => {
        this.notificationService.success('New payment method updated!');
        this.loadPaymentMethods();
      },
      (err) => this.actionErrorHandler(err, FormMode.EDIT)
    );
  }

  private createPaymentDetailsForm(): FormGroup {
    return this.fb.group({
      portalUrl: [''], // max 2048
      userName: [''], // max 255
      userPassword: [''], // max 255
      cardNumber: ['', Validators.minLength(16)], // max 20
      expirationDate: [null], // format 11/20
      ccv: [''], // max 5
      nameOnCard: [''], // max 50
      addressLine1: [''], // max 255
      addressLine2: [''], // max 255
      addressCity: [''], // max 100
      addressState: [null],
      addressZipCode: [''], // max 10
      wireTransferAccountNumber: [''], // max 255
      wireTransferRoutingNumber: [''], // max 255
      checkPayableTo: [''], // max 512
      checkNote: [''], // max 512,
      paymentsGuidelines: [''],  // max 512
      paymentGuidelines: [''],  // max 512
      paymentPortalGuidelines: [''] // max 1024
    });
  }

  get cardNumberInvalid() {
    return this.paymentMethodForm.get('details').get('cardNumber').invalid
      && this.paymentMethodForm.get('details').get('cardNumber').touched;
  }

  private initPaymentMethodForm(selectedType: PaymentType) {
    this.paymentMethodForm.patchValue({
      name: selectedType.name,
      type: selectedType,
      details: this.createPaymentDetailsForm().value
    });
    if (selectedType.name === PaymentTypeEnum.AmEx || selectedType.name === PaymentTypeEnum.Visa) {
      this.paymentMethodForm.get('details').get('cardNumber').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('expirationDate').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('ccv').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('addressLine1').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('addressCity').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('addressState').setValidators(Validators.required);
      this.paymentMethodForm.get('details').get('addressZipCode')
        .setValidators(Validators.pattern(new RegExp(/\b(\d{5}(\-\d{4})?)(?!-)\b/)));
    } else {
      this.paymentMethodForm.get('details').get('cardNumber').setValidators(null);
      this.paymentMethodForm.get('details').get('expirationDate').setValidators(null);
      this.paymentMethodForm.get('details').get('ccv').setValidators(null);
      this.paymentMethodForm.get('details').get('addressLine1').setValidators(null);
      this.paymentMethodForm.get('details').get('addressCity').setValidators(null);
      this.paymentMethodForm.get('details').get('addressState').setValidators(null);
      this.paymentMethodForm.get('details').get('addressZipCode').setValidators(null);
    }
    this.paymentMethodForm.updateValueAndValidity();
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.paymentMethodForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} payment method.`);
  }

}
