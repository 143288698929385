import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return `${value.substr(0, 1)}-${value.substr(1, 3)}-${value.substr(4, 3)}-${value.substr(7, 4)}`;
  }

}
