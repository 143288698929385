import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserListComponent } from '../user-list/user-list.component';
import { User } from '../../shared/models/user';

@Component({
  selector: 'app-browse-user-dialog',
  templateUrl: './browse-user-dialog.component.html',
  styleUrls: ['./browse-user-dialog.component.scss']
})
export class BrowseUserDialogComponent implements OnInit {
  @ViewChild(UserListComponent, {static: true}) userList: UserListComponent;
  selectedUser: User;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: string}) {}

  ngOnInit(): void {
    if (!this.userList) {
      return;
    }

    this.userList.selection.changed.subscribe(user => {
      this.selectedUser = user.source.selected[0];
    });

    if (this.data.selected) {
      this.userList.initSelected(this.data.selected);
    }
  }

}
