import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ACCOUNTS, CONSULTANT_COMPANIES } from '../../core/apiUrls';
import { ConsultantCompanyCommission } from './models';
import { Account, ConsultantCompanyCommissionTransaction } from '../../entities/shared/models';
import { ConsultantCompanyCommissionReport } from './models/consultant-company-commission-report';

@Injectable({
  providedIn: 'root'
})
export class ConsultantCompanyCommissionService {

  constructor(private http: HttpClient) { }

  getCommissionList(since?: string, to?: string): Observable<ConsultantCompanyCommission[]> {
    let httpParams = new HttpParams();
    if (since) {
      httpParams = httpParams.set('since', since);
    }
    if (to) {
      httpParams =  httpParams.set('to', to);
    }

    return this.http.get<ConsultantCompanyCommission[]>(`${CONSULTANT_COMPANIES}/commissions`, { params: httpParams});
  }

  getAccountsWithCommissionBalance(): Observable<Account[]> {
    return this.http.get<Account[]>(`${ACCOUNTS}/withCommissionBalance`);
  }

  getConsultantCompanyCommissionTemplate(consultantCompanyId: string,
                                         accountId: string):
    Observable<ConsultantCompanyCommission> {
    return this.http.get<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/accounts/${accountId}/commission`);
  }

  getAccountList(consultantCompanyId: string): Observable<Account[]> {
    return this.http.get<Account[]>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/worksFor`);
  }

  createTransaction(consultantCompanyId: string, transaction: ConsultantCompanyCommission)
    : Observable<ConsultantCompanyCommission> {
    return this.http.post<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions`, transaction);
  }

  editTransaction(consultantCompanyId: string, id: string, transaction: ConsultantCompanyCommission)
    : Observable<ConsultantCompanyCommission> {
    return this.http.put<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}`, transaction);
  }

  getTransaction(consultantCompanyId: string, id: string): Observable<ConsultantCompanyCommission> {
    return this.http.get<ConsultantCompanyCommission>(
      `${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}`);
  }

  getCommissionVersions(consultantCompanyId: number, id: number): Observable<ConsultantCompanyCommission[]> {
    return this.http.get<ConsultantCompanyCommission[]>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}/versions`);
  }

  geConsultantCompanyTransactionDocument(consultantCompanyId: number, id: number, documentId: number): Observable<any> {
    return this.http.get(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(consultantCompanyId: number, id: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<ConsultantCompanyCommissionTransaction>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}/documents`, fd);
  }

  deleteConsultantCompanyTransactionDocument(consultantCompanyId: number, id: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/commissions/${id}/documents/${documentId}`);
  }

  getCommissionReport(consultantCompanyId: string, accountId: string): Observable<ConsultantCompanyCommissionReport> {
    return this.http.get<ConsultantCompanyCommissionReport>(`${CONSULTANT_COMPANIES}/${consultantCompanyId}/accounts/${accountId}/commissionReport`);
  }
}
