import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { SharedComponentsModule } from '../shared-components.module';
import { EntitiesRoutingModule } from './entities-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { EntitiesComponent } from './entities.component';
import { StationDetailComponent } from './station-detail/station-detail.component';
import { ParentCompanyListComponent } from './parent-company-list/parent-company-list.component';
import { ParentCompanyEditComponent } from './parent-company-edit/parent-company-edit.component';
import { BrowseCompanyDialogComponent } from './browse-company-dialog/browse-company-dialog.component';
import { ConsultantListComponent } from './consultant-list/consultant-list.component';
import { ConsultantEditComponent } from './consultant-edit/consultant-edit.component';
import { ConsultantDetailComponent } from './consultant-detail/consultant-detail.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { TableCommissionTiersComponent } from './table-commission-tiers/table-commission-tiers.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { BrowseConsultantDialogComponent } from './browse-consultant-dialog/browse-consultant-dialog.component';
import { CommissionTierComponent } from './commission-tier/commission-tier.component';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { CommissionRatesComponent } from './commission-rates/commission-rates.component';
import { SliderComponent } from './slider/slider.component';
import { TwoDigitDecimalNumberDirective } from '../shared/directives/two-digit-decimal-number.directive';
import { CommissionableTransactionListComponent } from './commissionable-transaction-list/commissionable-transaction-list.component';
import { CommissionableInfoTableComponent } from './commissionable-info-table/commissionable-info-table.component';
import { AccountTransactionListComponent } from './account-transaction-list/account-transaction-list.component';
import { AccountTransactionReportOrderComponent } from './account-transaction-report-order/account-transaction-report-order.component';
import { AccountTransactionReportTableComponent } from './account-transaction-report-table/account-transaction-report-table.component';
import { StationTransactionListComponent } from './station-transaction-list/station-transaction-list.component';
import { TransactionCollapsableTableComponent } from './transaction-collapsable-table/transaction-collapsable-table.component';

@NgModule({
  declarations: [
    EntitiesComponent,
    ConsultantListComponent,
    StationDetailComponent,
    ParentCompanyListComponent,
    ParentCompanyEditComponent,
    BrowseCompanyDialogComponent,
    ConsultantEditComponent,
    ConsultantDetailComponent,
    AccountDetailComponent,
    TableCommissionTiersComponent,
    AccountEditComponent,
    BrowseConsultantDialogComponent,
    CommissionTierComponent,
    PhonePipe,
    CommissionRatesComponent,
    SliderComponent,
    TwoDigitDecimalNumberDirective,
    CommissionableTransactionListComponent,
    CommissionableInfoTableComponent,
    AccountTransactionListComponent,
    AccountTransactionReportOrderComponent,
    AccountTransactionReportTableComponent,
    StationTransactionListComponent,
    TransactionCollapsableTableComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedComponentsModule,
    EntitiesRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSortModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    SharedModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    MatListModule,
    MatSlideToggleModule
  ],
  exports: [
    CommissionRatesComponent,
    BrowseCompanyDialogComponent,
    TwoDigitDecimalNumberDirective,
    TransactionCollapsableTableComponent
  ]
})
export class EntitiesModule {
}
