import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DICTIONARIES, PAYMENT_METHODS } from '../../core/apiUrls';
import {
  CandidateAccountParty,
  MediaMarket,
  MediaType,
  State,
  PaymentMethod, Title
} from './models';

@Injectable({
  providedIn: 'root'
})
export class EntityDictionaryService {
  states: State[];
  markets: MediaMarket[];
  mediaTypes: MediaType[];
  candidateParties: CandidateAccountParty[];
  titles: Title[];

  constructor(private http: HttpClient) { }

  getStates(): Observable<State[]> {
    if (this.states) {
      return of(this.states);
    }
    return this.http.get<State[]>(`${DICTIONARIES}/states`).pipe(
      tap(data => this.states = data)
    );
  }

  getMarkets(): Observable<MediaMarket[]> {
    if (this.markets) {
      return of(this.markets);
    }
    return this.http.get<MediaMarket[]>(`${DICTIONARIES}/mediaMarkets`).pipe(
      tap(data => this.markets = data)
    );
  }

  getMediaTypes(): Observable<MediaType[]> {
    if (this.mediaTypes) {
      return of(this.mediaTypes);
    }
    return this.http.get<MediaType[]>(`${DICTIONARIES}/mediaTypes`).pipe(
      tap(data => this.mediaTypes = data)
    );
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(PAYMENT_METHODS);
  }

  getCandidateParties(): Observable<CandidateAccountParty[]> {
    if (this.candidateParties) {
      return of(this.candidateParties);
    }
    return this.http.get<CandidateAccountParty[]>(`${DICTIONARIES}/candidateAccountParties`).pipe(
      tap(data => this.candidateParties = data)
    );
  }

  getTitles(): Observable<Title[]> {
    if (this.titles) {
      return of(this.titles);
    }

    return this.http.get<Title[]>(`${DICTIONARIES}/titles`).pipe(
      tap(data => this.titles = data)
    );
  }
}
