import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';

import { FormMode } from '../../shared/models/form-mode.enum';
import { AcceptPayment, AcceptPaymentInvoice, AccountInvoice, AccountLight } from '../shared/models';
import { AcceptPaymentService } from '../shared/accept-payment.service';
import { UserService } from '../../shared/user.service';
import { NotificationService } from '../../shared/notification.service';
import { AccountInvoiceService } from '../shared/account-invoice.service';
import { FormUtils } from '../../shared/utils/form-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { NoteUtil } from '../shared/utils/note-util';
import { AccountInvoicesDialogComponent } from '../account-invoices-dialog/account-invoices-dialog.component';
import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { ComponentType } from '../../shared/models/component-type.enum';
import { Role } from '../../shared/models/role.enum';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-accept-payment-edit',
  templateUrl: './accept-payment-edit.component.html',
  styleUrls: ['./accept-payment-edit.component.scss']
})
export class AcceptPaymentEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  acceptPayment: AcceptPayment = null;
  accountId: string;
  form = this.fb.group({
    account: [null, Validators.required],
    id: [''],
    code: [''],
    paidBy: ['', Validators.required],
    paidOn: [new Date(), Validators.required],
    acceptPaymentInvoices: this.fb.array([]),
    noteToAdd: [null],
    quickBookReference: [''],
    paidAmount: ['']
  });

  accounts: AccountLight[];
  filteredAccounts: Observable<AccountLight[]>;

  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  openedDialog = false;

  acceptedInvoices = [];

  documents: File[] = [];
  documentsUploadedBefore = [];
  component = ComponentType.AcceptPayment;

  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private service: AcceptPaymentService,
              private accountInvoiceService: AccountInvoiceService,
              private userService: UserService,
              private notificationService: NotificationService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    const accountId = this.route.snapshot.queryParamMap.get('accountId'); // in case to create from account and account invoice
    this.accountId = id?.split('-')[0] || this.accountId;
    const accountInvoiceId = this.route.snapshot.queryParamMap.get('accountInvoiceId'); // in case to create from account invoice
    this.loadData(id?.split('-')[1], id?.split('-')[0] || accountId, accountInvoiceId);
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  get acceptPaymentInvoices(): FormGroup[] {
    return (this.form.get('acceptPaymentInvoices') as FormArray).controls as FormGroup[];
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.acceptPayment?.notes);
  }

  get canAddPaymentInvoice() {
    return !!this.form.get('account').value;
  }

  get totalAmount() {
    return this.acceptPaymentInvoices
      .map(formGroup => Number(formGroup.get('amount').value))
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get isInvoicesValid() {
    return this.acceptPaymentInvoices
      .every(control => control.status === 'VALID');
  }

  get isSaveDisabled() {
    return !this.form.valid || !this.isInvoicesValid;
  }

  get acceptPaymentId() {
    return this.acceptPayment?.id;
  }

  loadData = (id: string, accountId: string, accountInvoiceId: string) => {
    const payment$ = this.isEditMode ? this.service.getAcceptPayment(accountId, id) : of(null);
    const accounts$ = this.isEditMode ? of([]) : this.service.getAccountList();
    const accountInvoice$ = !accountInvoiceId ? of(null) : this.accountInvoiceService.getInvoice(accountInvoiceId);

    forkJoin([payment$, accounts$, accountInvoice$])
      .pipe(first())
      .subscribe(([payment, accounts, accountInvoice]) => {
        this.accounts = accounts ? accounts.map(item => ({id: Number(item.id), code: item.data, name: item.text} as AccountLight)) : [];
        this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);
        this.loading = false;

        if (this.isEditMode) {
          this.form.patchValue({...payment,
            account: payment.accountBalance.account, paidOn: new Date(payment?.paidOn)});
          payment.acceptPaymentInvoices.forEach(paymentInvoice => {
            this.acceptPaymentInvoices.push(this.fb.group({
              accountInvoice: [paymentInvoice.accountInvoice],
              amount: [paymentInvoice.amount, Validators.required]
            }));
          });
          this.acceptPayment = payment;
          this.documentsUploadedBefore = payment.documents;
          this.form.updateValueAndValidity();
          this.acceptPaymentInvoices.forEach(formGroup => {
            this.subscription.add(formGroup.valueChanges.subscribe(val => this.updatePaidAmountValue()));
          });
        } else {
          if (accountId) {
            const selectedAccount = this.accounts.find(account => account.id === +accountId);
            if (selectedAccount) {
              this.form.patchValue({account: selectedAccount});
            }
          }

          if (accountInvoice) {
            this.patchAcceptPaymentInvoices([accountInvoice]);
          }

          this.subscription.add(this.form.valueChanges.subscribe(value => {
            if (!this.form.value.account?.id) {
              const acceptPaymentInvoices = this.form.get('acceptPaymentInvoices') as FormArray;

              while (acceptPaymentInvoices.length !== 0) {
                acceptPaymentInvoices.removeAt(0);
              }
            }
          }));
        }
      });
  }

  onAmountInput = (value, acceptInvoiceIndex) => {
    this.acceptPaymentInvoices[acceptInvoiceIndex].get('amount').setValue(value.target.value);
    this.form.updateValueAndValidity();
  }

  isAcceptedAmountValue = (accountInvoiceId) => {
    return this.acceptedInvoices.includes(accountInvoiceId);
  }

  getAmountValue(item: FormGroup): string {
    const amount = Number(item.get('amount').value);
    const fixedAmount = parseFloat(amount.toFixed(2));
    return String(fixedAmount);
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/accept-payment');
  }

  save(form: FormGroup) {
    this.saving = true;

    const acceptPayment = {
      ...this.acceptPayment,
      ...form.value
    };
    acceptPayment.paidOn = DateUtils.dateFormatToShort(acceptPayment.paidOn);
    NoteUtil.processNotes(acceptPayment, form);

    const account = {...acceptPayment.account};
    delete acceptPayment.account;
    acceptPayment.acceptPaymentInvoices = [];

    if (this.acceptPaymentInvoices.length > 0) {
      this.acceptPaymentInvoices.forEach(formGroup => {
        acceptPayment.acceptPaymentInvoices.push({
          accountInvoice: formGroup.get('accountInvoice').value,
          amount: formGroup.get('amount').value
        });
      });
    }

    const request: any = this.isEditMode
      ? this.service.updateAcceptPayment(account.id, acceptPayment).pipe(
        tap(() => this.notificationService.success('Accept payment was updated!'))
      )
      : this.service.createAcceptPayment(account.id, acceptPayment).pipe(
        tap(() => this.notificationService.success('New accept payment created!'))
      );

    request.subscribe(
      (data) => {
        if (this.documents.length) {
          this.uploadDocuments(account.id, data.id);
        } else {
          this.returnClicked();
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  onBlurAccount(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
  }

  displayAccount(account?: AccountLight): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  openAccountDialog() {
    const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
      {data: {selected: this.form.value.account?.id}}
    );
    dialogRef.afterClosed().subscribe((result: AccountLight) => {
      if (result) {
        this.form.get('account').patchValue(result);
      }
    });
  }

  addPaymentClicked() {
    const dialogRef = this.dialog.open(AccountInvoicesDialogComponent,
      {
        data: {
          accountId: this.form.value.account?.id,
          selected: this.getSelectedInvoicesIds(),
          isAccountInvoiceDialog: true
        }
      }
    );
    dialogRef.afterClosed().subscribe((result: AccountInvoice[]) => {
      if (result) {
        this.patchAcceptPaymentInvoices(result);
      }
    });
  }

  patchAcceptPaymentInvoices(invoices: AccountInvoice[]) {
    const filteredInvoices = invoices.filter(invoice => {
      return !this.acceptPaymentInvoices
        .map(item => item.get('accountInvoice').value.id)
        .includes(invoice.id);
    });

    filteredInvoices.forEach(invoice => {
      const formGroup = this.fb.group({
        accountInvoice: [invoice],
        amount: [this.getInvoiceRemainingAmount(invoice), Validators.required]
      });
      this.acceptPaymentInvoices.push(formGroup);
      this.subscription.add(formGroup.valueChanges.subscribe(() => {
        this.updatePaidAmountValue();
      }));
    });
    this.form.updateValueAndValidity();
    this.updatePaidAmountValue();
  }

  getInvoiceCode(formGroup: FormGroup) {
    return formGroup.controls?.accountInvoice?.value?.code || '-';
  }

  getInvoiceAmountRequested(formGroup: FormGroup) {
    return formGroup.controls?.accountInvoice?.value?.requestedAmount || '-';
  }

  removeInvoice(invoiceControlIndex: number) {
    const invoiceAccountId = this.acceptPaymentInvoices[invoiceControlIndex].get('accountInvoice').value.id;
    this.acceptPaymentInvoices.splice(invoiceControlIndex, 1);
    this.acceptedInvoices = this.acceptedInvoices.filter(id => id !== invoiceAccountId);
    this.form.updateValueAndValidity();
    this.updatePaidAmountValue();
  }

  getInvoiceRemainingAmount = (accountInvoice: AccountInvoice) => {
    return Number((accountInvoice.requestedAmount - accountInvoice.paidAmount).toFixed(2)) || 0;
  }


  uploadDocuments = (accountId: number, id: number) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.service.uploadDocument(accountId, id, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
      () => this.returnClicked(),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  setCreditDocumentation(data: any) { // @todo: check
    this.documents = data.files;
  }

  private getSelectedInvoicesIds(): number[] {
    return this.acceptPaymentInvoices.length > 0 ?
      this.acceptPaymentInvoices
        .map((control: FormGroup) => control.controls.accountInvoice.value as AccountInvoice)
        .map(accountInvoice => accountInvoice.id)
      : [];
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} accept payment.`);
  }

  private updatePaidAmountValue() {
    const paidAmountValue = this.acceptPaymentInvoices.length > 0
      ? this.acceptPaymentInvoices
        .map(fg => fg.value as AcceptPaymentInvoice)
        .map(value => Number(value.amount))
        .reduce((a, b) => b ? a + b : a, 0)
      : this.acceptPayment.paidAmount;
    const fixedAmount = parseFloat(paidAmountValue.toFixed(2));
    this.form.get('paidAmount').setValue(fixedAmount);
  }
}
