import { Component, Input, OnInit } from '@angular/core';
import {
  AccountCheckReportEntry,
  ConsultantCompanyCommissionReportEntry,
  ConvertedReportType,
  GlobalAccountBalanceReportEntry,
  Report
} from '../shared/models/report-type';
import { TransactionsReport } from '../../entities/shared/models';

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss']
})
export class ReportPreviewComponent implements OnInit {
  @Input() reportType = ConvertedReportType;
  @Input() data: Report<ConsultantCompanyCommissionReportEntry|AccountCheckReportEntry|GlobalAccountBalanceReportEntry>|TransactionsReport;

  ConvertedReportType = ConvertedReportType;
  collapseAllTable = true;

  constructor() { }

  ngOnInit(): void {
  }

  get viewType() {
    return (this.data as TransactionsReport).filter.view;
  }

  get sections() {
    return (this.data as TransactionsReport).sections;
  }

  get total() {
    return (this.data as TransactionsReport).total;
  }

  get entries() {
    return (this.data as Report<GlobalAccountBalanceReportEntry>).entries;
  }

  get accountId() {
    return (this.data as TransactionsReport).filter.stationsFilter.accountId.toString();
  }

}
