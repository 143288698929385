import { CommissionTier } from './commission-tier';
import { Station } from './station';

export interface Commissionable {
  id: number;
  code: string;
  ftc: string;
  ltc: string;
  grossPaymentTotal: number;
  netPaymentTotal: number;
  consultantSplit: CommissionTier[];
  quickBookReference: string;
  expenseId: number;
  orderId: number;
  station: Station;
}

export enum CommissionableTransactionType {
  ORDER   = 'ORDER',
  EXPENSE = 'EXPENSE',
  IN_ADVANCE = 'IN_ADVANCE '
}

export enum CommissionableTransactionStatus {
  COMPLETED = 'COMPLETED',
  PAID      = 'PAID'
}
