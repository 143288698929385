import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import { AuthService } from '../../shared/auth.service';
import { StationService } from '../shared/station.service';
import { NotificationService } from '../../shared/notification.service';
import { TransactionService } from '../../orders/shared/transaction.service';
import { ReportsService } from '../shared/reports.service';
import { Filename } from '../../shared/models/filename.enum';
import { MediaType, ParentCompany, Station } from '../shared/models';
import { ReportsUtil } from '../../shared/utils/reports-util';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConvertedStationSortField, StationsBrowsingFilter } from '../shared/models/station-paged';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap } from 'rxjs/operators';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { EntityDictionaryService } from '../shared/entity-dictionary.service';
import { RelatedItemOption } from '../../shared/models/related-item-option';
import { ParentCompanyService } from '../shared/parent-company.service';

interface PaginatorData {
  length?: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex?: number;
}

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit {
  @Input() isSelectMode: boolean;
  @Input() exclude: number[] = [];

  dataSource = new MatTableDataSource<Station>();
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  displayedColumns: string[] = [
    'name', 'mediaType', 'parentCompany', 'runningOrderBalance', 'runningInvoiceBalance', 'menu'];
  selection = new SelectionModel<Station>(false, []);
  selectedId: number;
  hasEditPermission = this.authService.hasEditPermission;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  preLoading = true;

  stations: Station[] = [];
  filteredStations: Observable<Station[]>;
  companies: ParentCompany[];
  filteredCompanies: Observable<ParentCompany[]>;

  mediaTypes: MediaType[];

  form = this.fb.group({
    station: [null],
    mediaTypes: [null],
    parentCompany: [null],
    pageNumber: 0,
    pageSize: 30,
    stationSortField: [null],
    onlyUnbalancedRunningOrder: [false],
    onlyUnbalancedRunningInvoce: [false],
  });

  ConvertedStationSortField = ConvertedStationSortField;

  stationListPage = null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: StationService,
              private transactionService: TransactionService,
              private router: Router,
              private route: ActivatedRoute,
              private reportsService: ReportsService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private fb: FormBuilder,
              private dictionaryService: EntityDictionaryService,
              private companyService: ParentCompanyService) {
  }

  ngOnInit(): void {
    this.displayedColumns = this.isSelectMode
      ? ['select', 'name', 'mediaType', 'parentCompany']
      : this.displayedColumns;

    const types$ = this.dictionaryService.getMediaTypes();
    forkJoin([types$])
      .pipe(first())
      .subscribe(([types]) => {
        this.mediaTypes = types || [];
        this.preLoading = false;

        this.manageStationFilters();
        this.manageCompanyFilters();
      });

    this.form.get('station').valueChanges.subscribe(value => {
      if (value && typeof value === 'object') {
        this.form.get('mediaTypes').setValue([]);
        this.form.get('parentCompany').setValue(null);
        this.form.get('stationSortField').setValue('');
        this.form.get('onlyUnbalancedRunningOrder').setValue(false);
        this.form.get('onlyUnbalancedRunningInvoce').setValue(false);

        this.form.get('mediaTypes').disable();
        this.form.get('parentCompany').disable();
        this.form.get('stationSortField').disable();
        this.form.get('onlyUnbalancedRunningOrder').disable();
        this.form.get('onlyUnbalancedRunningInvoce').disable();
      } else {
        this.form.get('mediaTypes').enable();
        this.form.get('parentCompany').enable();
        this.form.get('stationSortField').enable();
        this.form.get('onlyUnbalancedRunningOrder').enable();
        this.form.get('onlyUnbalancedRunningInvoce').enable();
      }
    });

    const runningOrderBalance = this.route.snapshot.queryParamMap.get('runningOrderBalance');  // Filters in case to go from dashboard
    if (runningOrderBalance) {
      this.form.patchValue({onlyUnbalancedRunningOrder: true});
      this.searchStation(this.form.value);
    }

    const runningInvoiceBalance = this.route.snapshot.queryParamMap.get('runningInvoiceBalance');  // Filters in case to go from dashboard
    if (runningInvoiceBalance) {
      this.form.patchValue({onlyUnbalancedRunningInvoce: true});
      this.searchStation(this.form.value);
    }
  }

  get parentCompanyControl() {
    return this.form.get('parentCompany');
  }

  get stationControl() {
    return this.form.get('station');
  }

  get stationSortFields() {
    return Object.keys(ConvertedStationSortField);
  }

  searchStation(formValue, paginatorData?: PaginatorData) {
    this.loading$.next(true);
    const filter = this.prepareFilter(formValue, paginatorData);
    const calculateBalance = !this.isSelectMode;
    this.service.getStationPaged(filter, calculateBalance)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(data => {
        this.stationListPage = data;
        this.setTableData(data);
      });
  }

  setTableData(data) {
    this.dataSource.data = data.content;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  onBlurStation(inputElement) {
    TypeaheadUtil.onBlurStation(this.form.get('station'), this.stations, inputElement);
  }

  onStationOptionSelect(option) {
    TypeaheadUtil.onStationOptionSelect(this.form.get('station'), option);

    this.searchStation(this.form.value);
  }

  displayRelatedItem(item: RelatedItemOption): string {
    return item && item.name ? item.name : '';
  }

  manageCompanyFilters() {
    this.filteredCompanies = this.parentCompanyControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => term?.length >= 2 ? this.companyService.getTypeaheadParentCompanyList(term) : of([]))
    ).pipe(map(results => {
      this.companies = results.map(item => ({id: Number(item.id), name: item.text}) as any);
      return this.companies;
    }));
  }

  manageStationFilters() {
    this.filteredStations = this.stationControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => term?.length >= 2 ? this.service.getTypeaheadStationList(term) : of([]))
    ).pipe(map(results => {
      this.stations = results.map(item => ({id: Number(item.id), name: item.text}) as any);
      return this.stations;
    }));
  }

  onBlurParentCompany(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurParentCompany(this.form, this.companies, inputElement);
  }

  onParentCompanyOptionSelect(option) {
    TypeaheadUtil.onParentCompanyOptionSelect(this.form, option);
  }

  get isSticky() {
    if (!this.selectedId) {
      return true;
    }

    return !this.dataSource.data.filter(station => {
      let hasLongWord = false;
      station.name.split(' ').forEach(word => {
        if (word.length > 34) {
          hasLongWord = true;
        }
      });
      return hasLongWord;
    }).length;
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get parentCompany() {
    return [...new Set(this.dataSource.data.filter(item => !!item.parentCompany).map(item => item.parentCompany?.name))];
  }

  get states() {
    return [...new Set(this.dataSource.data.filter(item => !!item.address?.state).map(item => item.address?.state?.name))];
  }

  onEditClicked(stationId: number) {
    this.router.navigate(['/entities/stations/edit', stationId]).then();
  }

  onExportClicked() {
    this.isReportLoading$.next(true);

    const filter = this.prepareFilter(this.form.value);

    this.service.exportsStations(filter)
      .subscribe(
        data => ReportsUtil.downloadFile(data, Filename.StationList),
        () => this.notificationService.error('Error occurred while trying to download report file.'),
        () => this.isReportLoading$.next(false)
      );
  }

  onPaginateChange($event) {
    this.searchStation(this.form.value, $event as PaginatorData);
  }

  initSelected(id: number) {
    if (this.loading$) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: Station) => +item.id === id);
    if (row) {
      this.selection.toggle(row as Station);
    }
  }

  private prepareFilter = (formValue, paginatorData?: PaginatorData): StationsBrowsingFilter => {
    if (!paginatorData) {
      paginatorData = {
        pageIndex: 0,
        pageSize: 30
      };
    }

    return {
      stationId: formValue.station?.id || null,
      mediaTypeIds: formValue.mediaTypes?.map(mediaType => mediaType.id) || null,
      parentCompanyId: formValue.parentCompany?.id,
      pageNumber: paginatorData.pageIndex + 1,
      pageSize: paginatorData.pageSize,
      stationSortFields: formValue.stationSortField ? [formValue.stationSortField] : [],
      onlyUnbalancedRunningOrder: formValue.onlyUnbalancedRunningOrder,
      onlyUnbalancedRunningInvoce: formValue.onlyUnbalancedRunningInvoce
    } as StationsBrowsingFilter;
  }
}
