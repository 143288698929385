import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DICTIONARIES, PAYMENT_METHODS } from '../../core/apiUrls';
import { PaymentMethod } from './models/payment-method';
import { PaymentType } from './models/payment-type';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  getPaymentMethodList(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(PAYMENT_METHODS);
  }

  createPaymentMethod(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this.http.post<PaymentMethod>(PAYMENT_METHODS, paymentMethod);
  }

  updatePaymentMethod(id: number, paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this.http.put<PaymentMethod>(`${PAYMENT_METHODS}/${id}`, paymentMethod);
  }

  getPaymentTypes(): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(`${DICTIONARIES}/paymentTypes`);
  }
}
