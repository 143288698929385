<div class="main">
  <h1>
    <button mat-icon-button>
      <span routerLink="/orders/accept-payment" class="back-arrow material-icons">keyboard_backspace</span>
    </button>
    <span>Accept Payment Detail</span>
    <div class="buttons-container edit-button">
      <button mat-mini-fab color="primary" aria-label="Edit Accept Payment"
              *ngIf="hasEditPermission"
              [routerLink]="['/orders/accept-payment/edit', acceptPaymentData?.accountBalance?.account?.id + '-' + acceptPaymentData?.id]">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading && acceptPaymentData" class="container">
    <mat-card-content class="content">
      <div class="content_right">
        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Account</div>
                {{acceptPaymentData.accountBalance?.account?.name || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Account ID#</div>
                {{acceptPaymentData.accountBalance?.account?.code || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Accept Payment ID#</div>
                {{acceptPaymentData.code}}
              </div>
            </div>
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Paid By</div>
                {{acceptPaymentData.paidBy}}
              </div>
              <div class="column_item">
                <div class="title">Payment Received Date</div>
                {{acceptPaymentData.paidOn | date: 'shortDate'}}
              </div>
              <div class="column_item">
                <div class="title">QBref#</div>
                {{acceptPaymentData.quickBookReference || '-'}}
              </div>
            </div>
          </div>
        </mat-card>

        <ng-container *ngIf="acceptPaymentData.notes">
          <div class="subheader mat-title">Notes</div>
          <mat-card class="notes-container">
            <mat-card-content>
              <div class="note" *ngFor="let note of acceptPaymentData.notes.reverse()">
                <div><b>{{note | noteHeader}}</b></div>
                <div><span>{{note.message}}</span></div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>


      <div class="content_left">
        <div class="subheader mat-title">Invoices</div>
        <mat-card class="container">
          <mat-card-content>
            <div class="content_left_info-container">
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Invoice#</div>
                </div>
                <div *ngFor="let item of acceptPaymentData.acceptPaymentInvoices"  class="column_item">
                  #{{item.accountInvoice.code}}
                </div>
              </div>
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Amount Applied</div>
                </div>
                <div *ngFor="let item of acceptPaymentData.acceptPaymentInvoices" class="column_item">
                  {{item.amount | currency}}
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content>
            <div class="content_left_info-container">
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title total-amount">Total Amount = {{ acceptPaymentData.paidAmount | currency}}</div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="subheader mat-title">Upload Proof of Payment</div>
        <mat-card class="container">
          <app-file-upload *ngIf="acceptPaymentData.documents.length > 0; else noDocumentsBlock"
            [filesUploadedBefore]="acceptPaymentData.documents"
            [id]="acceptPaymentData?.id"
            [propId]="acceptPaymentData?.accountBalance?.account?.id"
            [component]="component"
            [canUpload]="false"
          >
          </app-file-upload>
          <ng-template #noDocumentsBlock>
            <span class="no-documents">The list of uploaded files is empty.</span>
          </ng-template>
        </mat-card>
        </div>
    </mat-card-content>
  </mat-card>
</div>
