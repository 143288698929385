import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { PaymentBatchService } from '../shared/payment-batch.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { getClientCandidateType, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { TypeaheadUtil } from 'src/app/shared/utils/typeahead-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { PaymentBatchAssignmentSummaryEntry, paymentBatchStatuses, PaymentCheckBatch } from '../shared/models';
import { Role } from '../../shared/models/role.enum';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() isSelectMode = false;
  assignmentSummary: PaymentBatchAssignmentSummaryEntry[];

  dataSource = new MatTableDataSource<PaymentCheckBatch>();
  displayedColumns: string[] = [
    'code', 'requestCode', 'orderCode',
    'status',
    'accountName', 'accountId', 'accountType',
    'quickBooks', 'paidFlights',
    'flightDate', 'flightDays',
    'assignedAccountant',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'lastUpdatedBy',
    'menu'
  ];

  digitFilters = [
    {name: 'flightDays', filterDef: 'flightDays-filter'},
  ];

  dateFilters = [
    {name: 'flightDate', filterDef: 'flightDate-filter'},
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
  ];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    requestCode: new FormControl(''),
    orderCode: new FormControl(''),
    status: new FormControl(''),
    accountName: new FormControl(''),
    accountId: new FormControl(''),
    accountType: new FormControl(''),
    quickBooks: new FormControl(''),
    flightDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    flightDays: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    assignedAccountant: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl('')
  });

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  isLoadingResults = true;
  selection = new SelectionModel<PaymentCheckBatch>(false, []);
  selectedId: number;
  start: Date | null;
  end: Date | null;

  hasCreatePermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private notificationService: NotificationService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private paymentService: PaymentBatchService) {
  }

  ngOnInit(): void {
    const checkRequests$ = this.paymentService.getPaymentBatchList();
    this.subscription.add(checkRequests$.subscribe((data) => {
        this.paymentBatch(data);
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get payment batches list.')
    ));

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.flightDate.value = value.flightDate?.value?.toString();
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();

      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));

    this.manageDateFilter();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();
  }

  paymentBatch = (data) => {
    if (data) {
      this.dataSource.data = sortListByLastEdit(data.batches || []);
      this.assignmentSummary = data.assignmentSummary;
    }
    this.isLoadingResults = false;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  applyDashboardFilter = () => {
    // Filters in case to go from dashboard
    const status = this.route.snapshot.queryParamMap.get('status')?.split(',');
    const assignedAccountant = this.route.snapshot.queryParamMap.get('accountant');
    const quickBooks = this.route.snapshot.queryParamMap.get('quickBooks');
    if (status && assignedAccountant) {
      this.tableFilterForm.patchValue({status, assignedAccountant});
    }

    if (status && quickBooks) {
      this.tableFilterForm.patchValue({status, quickBooks});
    }
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.paymentService.getPaymentBatchList(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe(this.paymentBatch,
        () => this.notificationService.error('Error occurred while trying to get payment batches list.'));
  }

  sortingDataAccessor = (item: PaymentCheckBatch, property: string) => {
    switch (property) {
      case 'requestCode':
        return item.checkRequest?.code;
      case 'orderCode':
        return this.getPaymentBatchOrders(item);
      case 'status':
        return item.status?.status;
      case 'accountName':
        return item.checkRequest?.account?.name;
      case 'accountId':
        return item.checkRequest?.account?.code;
      case 'accountType':
        return this.getAccountType(item.checkRequest?.account);
      case 'quickBooks':
        return this.getQuickBooksRefNumbers(item);
      case 'paidFlights':
        return this.getAssignedFlightsNumber(item);
      case 'flightDate':
        return item.summary?.earliestUnpaidFlightOn;
      case 'flightDays':
        return item.summary?.earliestUnpaidFlightDaysFromNow;
      case 'assignedAccountant':
        return this.getAssignedAccountant(item);
      default:
        return item[property];
    }
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  getAssignedAccountant(paymentBatch) {
    return TypeaheadUtil.displayAccountant(paymentBatch.checkRequest.assignedAccountant);
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find(item => item.id === id);
    if (row) {
      this.selection.toggle(row as any);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  getPaymentBatchOrders(paymentBatch) {
    const paymentBatchOrders = paymentBatch.summary?.boundOrders;
    if (!paymentBatchOrders?.length) {
      return;
    }

    return ([...new Set(paymentBatchOrders.map(item => item.code))] as string[]).reduce((codes, currentCode) => {
      return codes + currentCode + ', ';
    }, '').slice(0, -2);
  }

  getQuickBooksRefNumbers(paymentBatch) {
    const quickBookRefTotalNumber = paymentBatch.summary?.quickBookReferenceTotalNumber || 0;
    const quickBookRefAssignedNumber = paymentBatch.summary?.quickBookReferenceAssignedNumber || 0;

    return `${quickBookRefAssignedNumber} / ${quickBookRefTotalNumber}`;
  }

  getAssignedFlightsNumber(paymentBatch) {
    const assignedFlightsPaidNumber = paymentBatch.summary?.assignedFlightsPaidNumber || 0;
    const assignedFlightsTotalNumber = paymentBatch.summary?.assignedFlightsTotalNumber || 0;

    return `${assignedFlightsPaidNumber} / ${assignedFlightsTotalNumber}`;
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.checkRequest?.account)).filter(item => !!item))
    ];
  }

  get statuses() {
    return Object.keys(paymentBatchStatuses).map(name => paymentBatchStatuses[name].status);
  }

  createFilter() {
    return (data: PaymentCheckBatch, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const requestCodeSearch = FilterUtils.searchFunction(searchTerms.requestCode, data.checkRequest?.code);
      const orderCodeSearch = FilterUtils.searchFunction(searchTerms.orderCode, this.getPaymentBatchOrders(data));
      const statusSearch = FilterUtils.createMultiSelectSearch(searchTerms.status, data.status?.status);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.checkRequest?.account?.name);
      const accountIdSearch = FilterUtils.searchFunction(searchTerms.accountId, data.checkRequest?.account?.code);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.checkRequest?.account));
      const quickBooksSearch = FilterUtils.createQuickBooksSearch(searchTerms.quickBooks,
        data.summary?.quickBookReferenceAssignedNumber, data.summary?.quickBookReferenceTotalNumber);

      const flightDateSearch = FilterUtils.createDateSearch('flightDate', 'earliestUnpaidFlightOn', searchTerms, data.summary);
      const flightDaysSearch = FilterUtils.createFieldDigitSearch('flightDays', searchTerms, data.summary?.earliestUnpaidFlightDaysFromNow);
      const assignedAccountantSearch = FilterUtils.searchFunction(searchTerms.assignedAccountant, this.getAssignedAccountant(data));
      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);

      const filterFunctions = [
        codeSearch(),
        requestCodeSearch(),
        orderCodeSearch(),
        statusSearch(),
        accountNameSearch(),
        accountIdSearch(),
        accountTypeSearch(),
        quickBooksSearch(),

        flightDateSearch(),
        flightDaysSearch(),
        assignedAccountantSearch(),
        createdAtSearch(),
        createdBySearch(),
        lastUpdatedAtSearch(),
        lastUpdatedBySearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm, ['status']);
  }
}
