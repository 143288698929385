import { VersionedEntity } from '../../../shared/models/versioned-entity';
import { Account, MediaType, ParentCompany } from '../../../entities/shared/models';
import { User } from '../../../shared/models/user';
import { Order } from './order';
import { AfterSaveAction } from './after-save-action.enum';
import { Payment } from './payment';
import { NotedEntity } from '../../../shared/models/noted-entity';

export interface PaymentCheckRequest extends VersionedEntity, NotedEntity {
  id: number;
  code: string;
  status: CheckRequestStatus;
  account: Account;
  assignedAccountant: User;
  payments: Payment[];
  summary: CheckRequestSummary;
  filter: CheckRequestCreationFiler;
  versions?: PaymentCheckRequest[];
  versionLabel: string;
  afterSaveAction?: AfterSaveAction;
}

export interface CheckRequestStatus {
  id: number;
  status: string;
}

export interface CheckRequestSummary {
  totalNumberOfStationsOrderedForAccount: number;
  numberOfStationsOrderedForAccount: number;
  currentAccountBalance: number;
  earliestUpcomingFlightOn:	string;
  daysTillStartOfEarliestUpcomingFlight: number;
  daysTillFinishOfEarliestUpcomingFlight: number;
  requestedAmount: number;
  paidAmount: number;
  remainingToPayAmount: number;
  totalOfOutstandingRequestsAmount: number;
  outstandingInvoicesTotal: number;
  afterThisRequestBalance: number;
  outstandingInvoicesBalance: number;
  afterThisRequestAndOutstandingInvoicesBalance: number;
}

export interface CheckRequestCreationFiler {
  order: Order;
  account: Account;
  flightStartOn: string;
  flightFinishOn: string;
  stationMediaType: MediaType;
  parentCompany: ParentCompany;
}

export const checkRequestStatuses = {
  DRAFT: {id: 1, status: 'Draft'},
  SUBMITTED: {id: 2, status: 'Submitted'},
  RETURNED_TO_BUYER: {id: 3, status: 'Returned To Buyer'},
  STARTED: {id: 4, status: 'Started'},
  PROCESSING_1: {id: 5, status: 'Processing 1'},
  PROCESSING_2: {id: 6, status: 'Processing 2'},
  PAID: {id: 7, status: 'Paid'},
};
