<div class="subheader mat-title">Version History</div>
<mat-card class="table-container">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Ver#</th>
      <td mat-cell *matCellDef="let row">{{row.versionLabel}}</td>
    </ng-container>
    <ng-container matColumnDef="versionCreatedAt">
      <th mat-header-cell *matHeaderCellDef>Date / Time</th>
      <td mat-cell *matCellDef="let row">{{row.versionCreatedAt ? (row.versionCreatedAt | date:'short') : 'N/A'}}</td>
    </ng-container>
    <ng-container matColumnDef="versionAuthor">
      <th mat-header-cell *matHeaderCellDef>Edited By</th>
      <td mat-cell *matCellDef="let row">{{row.versionAuthor || 'N/A'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="button-container">
    <ng-container *ngIf="!isShowAllVersions; else allVersions">
      <button mat-raised-button *ngIf="versionHistoryTableData &&
      versionHistoryTableData.length > 3 && !isShowAllVersions" (click)="showAllVersions()">
        Show all {{versionHistoryTableData.length}} versions
      </button>
    </ng-container>
    <ng-template #allVersions>
      <button mat-raised-button *ngIf="versionHistoryTableData &&
      versionHistoryTableData.length > 3 && isShowAllVersions" (click)="showLatestVersions()">
        Сollapse
      </button>
    </ng-template>
  </div>
</mat-card>
