<input matInput class="mat-select-search-input mat-select-search-hidden" autocomplete="off"/>

<div class="mat-select-search-inner"
     [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
  <input matInput
         class="mat-select-search-input"
         #searchSelectInput
         (keydown)="handleKeydown($event)"
         (input)="onInputChange($event.target)"
         (blur)="onBlur($event.target)"
         placeholder="Search"
         autocomplete="off"
  />
  <button mat-button *ngIf="value"
          mat-icon-button
          aria-label="Clear"
          (click)="resetSearch(true)"
          class="mat-select-search-clear">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="showNoEntriesFound$ | async" class="mat-select-search-no-entries-found">
  No option found
</div>
