<div class="content-toolbar mat-toolbar mat-accent">
  <nav class="module-nav">
    <ul role="navigation">
      <li *ngFor="let subnavTitle of subnavTitles">
        <a class="nav-link" [routerLink]="subnavTitle.subnavRouterLink" routerLinkActive="active">
          <span class="nav-link-inner">{{subnavTitle.subnavTitle}}</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
