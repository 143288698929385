import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import {
  TransactionsReportSection, TransactionsReportSectionEntry,
  TransactionsReportSectionEntryType,
  TransactionsReportType
} from '../shared/models';

@Component({
  selector: 'app-transaction-collapsable-table',
  templateUrl: './transaction-collapsable-table.component.html',
  styleUrls: ['./transaction-collapsable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TransactionCollapsableTableComponent implements OnInit, OnChanges {
  @Input() collapseAll: boolean;
  @Input() viewType: TransactionsReportType;
  @Input() sections: TransactionsReportSection[];
  @Input() total: TransactionsReportSectionEntry;
  @Input() accountId: string;

  dataSource = new MatTableDataSource<TransactionsReportSection>();

  orderTypeDisplayedColumns: string[] = [
    'actions',
    'name',
    'flights',
    'transaction-type',
    'transaction-date',
    'transaction-id',
    'quickBookReference-id',
    'grossOrderedAmount',
    'netOrderedAmount',
    'expenseAmount',
    'paidAmount',
    'vendorMemoInputAmount',
    'refundAmount',
    'balanceAmount'
  ];
  orderTypeSubDisplayedColumns: string[] = [
    'sub-actions',
    'sub-name',
    'sub-flights',
    'sub-transaction-type',
    'sub-transaction-date',
    'sub-transaction-id',
    'sub-quickBookReference-id',
    'sub-grossOrderedAmount',
    'sub-netOrderedAmount',
    'sub-expenseAmount',
    'sub-paidAmount',
    'sub-vendorMemoInputAmount',
    'sub-refundAmount',
    'sub-balanceAmount'
  ];
  invoiceTypeDisplayedColumns: string[] = [
    'actions',
    'name',
    'flights',
    'transaction-type',
    'transaction-date',
    'transaction-id',
    'quickBookReference-id',
    'grossOrderedAmount',
    'netOrderedAmount',
/*    'expenseAmount',*/
    'paidAmount',
    'vendorMemoInputAmount',
    'refundAmount',
    'invoicedAmount',
    'balanceAmount'
  ];
  invoiceTypeSubDisplayedColumns: string[] = [
    'sub-actions',
    'sub-name',
    'sub-flights',
    'sub-transaction-type',
    'sub-transaction-date',
    'sub-transaction-id',
    'sub-quickBookReference-id',
    'sub-grossOrderedAmount',
    'sub-netOrderedAmount',
/*    'sub-expenseAmount',*/
    'sub-paidAmount',
    'sub-vendorMemoInputAmount',
    'sub-refundAmount',
    'sub-invoicedAmount',
    'sub-balanceAmount'
  ];

  transactionType = new Map([
    [TransactionsReportSectionEntryType.VENDOR_MEMO_INPUT, 'Vendor Memo Input'],
    [TransactionsReportSectionEntryType.FLIGHT, 'Flight'],
    [TransactionsReportSectionEntryType.PAYMENT, 'Payment'],
    [TransactionsReportSectionEntryType.REFUND, 'Refund'],
    [TransactionsReportSectionEntryType.EXPENSE_COMMISSIONABLE, 'Expense commissionable'],
    [TransactionsReportSectionEntryType.EXPENSE_NON_COMMISSIONABLE, 'Expense non-commissionable'],
    [TransactionsReportSectionEntryType.STATION_INVOICE, 'Station Invoice'],
  ]);

  ngOnInit() {
    this.dataSource.data = this.sections;
  }

  get isOrderViewType(): boolean {
    return this.viewType === TransactionsReportType.ACCOUNT_ORDER || this.viewType === TransactionsReportType.STATION_ORDER;
  }

  get displayedColumns(): string[] {
    return this.isOrderViewType ? this.orderTypeDisplayedColumns : this.invoiceTypeDisplayedColumns;
  }

  get subDisplayedColumns(): string[] {
    return this.isOrderViewType ? this.orderTypeSubDisplayedColumns : this.invoiceTypeSubDisplayedColumns;
  }

  get nameColumnTitle() {
    return (this.viewType === TransactionsReportType.STATION_INVOICE || this.viewType === TransactionsReportType.STATION_ORDER)
      ? 'Account name' : 'Station name + Type';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.collapseAll && changes.collapseAll.currentValue !== changes.collapseAll.previousValue) {
      this.dataSource.data.forEach(element => {
        // @ts-ignore
        element.isExpanded = !changes.collapseAll.currentValue;
      });
    }

    if (changes.sections) {
      this.dataSource.data = changes.sections.currentValue;
    }
  }

  getRouteLink(element: TransactionsReportSectionEntry, sectionId: string) {
    const accountId = this.accountId || sectionId;
    switch (element.type) {
      case TransactionsReportSectionEntryType.FLIGHT:
        return `/orders/orders/detail/${accountId}-${element.transactionTypeId}`;
      case TransactionsReportSectionEntryType.VENDOR_MEMO_INPUT:
      case TransactionsReportSectionEntryType.REFUND:
        return `/orders/memo-refund/edit/${element.transactionTypeId}`;
      case TransactionsReportSectionEntryType.EXPENSE_COMMISSIONABLE:
      case TransactionsReportSectionEntryType.EXPENSE_NON_COMMISSIONABLE:
        return `/orders/expenses/edit/${element.transactionTypeId}`;
      case TransactionsReportSectionEntryType.PAYMENT:
        return `/orders/payments/edit/${element.transactionTypeId}`;
      case TransactionsReportSectionEntryType.STATION_INVOICE:
        return `/orders/transactions/invoice/detail/${element.transactionTypeId}`;
    }
  }

  hideBalance(type) {
    return !this.isOrderViewType && type === TransactionsReportSectionEntryType.FLIGHT;
  }
}
