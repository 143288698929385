export interface ConsultantCompanyCommissionReport {
  filter: ConsultantCompanyCommissionReportFilter;
  entries: ConsultantCompanyCommissionReportEntry[];
  consultantSplitColumnName: string;
  consultantPaidColumnName: string;
  consultantBalanceColumnName: string;
  cpmName: string;
  accountName: string;
  consultantCompanyName: string;
  reportCreatedBy: string;
}

export interface ConsultantCompanyCommissionReportEntry {
  sortNumber: number;
  tierNumber: number;
  transactionTypeId: number;
  consultantCompanyId: number;
  title: string;
  transactionId: string;
  transactionDate: string;
  ftc: string;
  ltc: string;
  quickBookReference: string;
  grossAmount: number;
  netAmount: number;
  cpmSplitAmount: number;
  consultantCompanySplitAmount: number;
  paidToConsultantCompanyAmount: number;
  balanceWithConsultantAmount: number;
  tierRow: boolean;
  tierColumn1: string;
  tierColumn2: string;
  tierColumn3: string;
  totalRow:	boolean;
  type: ConsultantCompanyCommissionReportEntryType;
  transactionDateHeader: boolean;
}

export interface ConsultantCompanyCommissionReportFilter {
  reportType: ConsultantCompanyCommissionReportType;
  year: number;
  since: string;
  to: string;
  accountId: number;
  consultantCompanyId: number;
}

export enum ConsultantCompanyCommissionReportType {
  ACCOUNT_CONSULTANT_COMPANY = 'ACCOUNT_CONSULTANT_COMPANY',
  ACCOUNT_GLOBAL = 'ACCOUNT_GLOBAL'
}

export enum ConsultantCompanyCommissionReportEntryType {
  PAYMENT = 'PAYMENT',
  COMMISSION = 'COMMISSION'
}
