import { Component, OnInit } from '@angular/core';
import { VersionService } from '../version.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-build-version',
  templateUrl: './build-version.component.html',
  styleUrls: ['./build-version.component.scss']
})
export class BuildVersionComponent implements OnInit {
  buildVersion: string;

  constructor(private versionService: VersionService) { }

  ngOnInit(): void {
    const backendVersion$ = this.versionService.getBackEndVersion();
    const uiPipelineId$ = this.versionService.getUIPipelineId();
    const uiDate$ = this.versionService.getUIDate();

    forkJoin([backendVersion$, uiPipelineId$, uiDate$])
      .pipe(first())
      .subscribe(([backendVersion, uiPipelineId, uiDate]) => {
        this.buildVersion = `${uiDate.date}.${uiPipelineId.pipelineId}|${backendVersion.version}`;
      },
        () => {});
  }

}
