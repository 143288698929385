import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StationInvoiceExpense } from '../shared/models';
import { InvoiceExpenseListComponent } from '../invoice-expense-list/invoice-expense-list.component';

@Component({
  selector: 'app-browse-expense-dialog',
  templateUrl: './browse-expense-dialog.component.html',
  styleUrls: ['./browse-expense-dialog.component.scss']
})
export class BrowseExpenseDialogComponent implements AfterViewInit {
  @ViewChild(InvoiceExpenseListComponent, {static: true}) expenseList: InvoiceExpenseListComponent;
  selectedExpenses: StationInvoiceExpense | StationInvoiceExpense[];

  constructor(@Inject(MAT_DIALOG_DATA) public data:
                {selectedIds: number[], isAccountInvoices: boolean, accountId: string, stationId: string, isMultiselect: boolean}) {}

  ngAfterViewInit(): void {
    if (!this.expenseList) {
      return;
    }

    this.expenseList.selection.changed.subscribe(selection => {
      this.selectedExpenses = this.data.isMultiselect ? selection.source.selected : selection.source.selected[0];
    });

    if (this.data.selectedIds) {
      this.expenseList.initSelected(this.data.selectedIds);
    }
  }

}
