import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { PaymentCheckRequestService } from '../shared/payment-check-request.service';
import { PaymentCheckRequest, checkRequestStatuses } from '../shared/models';
import { CheckRequestTotalUtil } from '../shared/utils/check-request-total-util';
import { AuthService } from '../../shared/auth.service';
import { AfterSaveAction } from '../shared/models/after-save-action.enum';
import { NoteUtil } from '../shared/utils/note-util';
import { Role } from '../../shared/models/role.enum';
import { Action } from '../../shared/models/action.enum';
import { NotificationService } from '../../shared/notification.service';
import { UserService } from '../../shared/user.service';

import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogResult } from '../../shared/models/confirmation-dialog-result';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-check-detail',
  templateUrl: './check-detail.component.html',
  styleUrls: [ './check-detail.component.scss' ]
})
export class CheckDetailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  hasEditPermission = false;
  checkRequestData: PaymentCheckRequest;
  loading = false;
  approving = false;
  declining = false;
  openedDialog = false;

  get id(): string {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private service: PaymentCheckRequestService,
              private notificationService: NotificationService,
              private dialog: MatDialog,
              private userService: UserService) {
  }

  ngOnInit() {
    this.subscription.add(this.service.getCheckRequest(this.id).subscribe(
      data => {
        this.checkRequestData = data;
        this.hasEditPermission = data.permissions?.includes(Action.EDIT);
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get check request data')
    ));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get isRequesting() {
    return this.approving || this.declining;
  }

  get isAllowApprove() {
    const allowedStatuses = [checkRequestStatuses.SUBMITTED.id];
    return this.canApproveDecline && this.checkRequestData?.status && allowedStatuses.includes(this.checkRequestData.status.id);
  }

  get isAllowDecline() {
    const allowedStatuses = [checkRequestStatuses.SUBMITTED.id, checkRequestStatuses.PROCESSING_1.id];
    return this.canApproveDecline && this.checkRequestData?.status && allowedStatuses.includes(this.checkRequestData.status.id);
  }

  get canApproveDecline() {
    return this.authService.hasPermission([Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.ACCOUNTANT]);
  }

  get versions() {
    return this.checkRequestData?.versions || [];
  }

  get isShowBalance() {
    const statuses = [checkRequestStatuses.DRAFT.id, checkRequestStatuses.SUBMITTED.id, checkRequestStatuses.RETURNED_TO_BUYER.id];
    return this.checkRequestData?.status && statuses.includes(this.checkRequestData.status.id);
  }

  get currentAccountBalance() {
    return this.checkRequestData?.summary?.currentAccountBalance ?? 0;
  }

  get totalOfOutstandingRequestsAmount() {
    return this.checkRequestData?.summary?.totalOfOutstandingRequestsAmount ?? 0;
  }

  get afterThisRequestBalance() {
    return this.checkRequestData?.summary?.afterThisRequestBalance ?? 0;
  }

  get outstandingInvoicesBalance() {
    return this.checkRequestData?.summary?.outstandingInvoicesBalance ?? 0;
  }

  get afterThisRequestAndInvoicesBalance() {
    return this.checkRequestData?.summary?.afterThisRequestAndOutstandingInvoicesBalance ?? 0;
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/checks');
  }

  getAssignedAccountant(expense) {
    const name = expense.assignedAccountant?.name;
    const lastName = expense.assignedAccountant?.lastName;
    return (name && lastName) ? `${name} ${lastName}`
          : name ? name : lastName ? lastName : null;
  }

  getTotalRequested(checkRequest: PaymentCheckRequest) {
    return CheckRequestTotalUtil.totalRequested(checkRequest);
  }

  save(nextStatus?: AfterSaveAction, isCreateBatch?: boolean) {
    if (nextStatus) {
      this.checkRequestData.afterSaveAction = nextStatus;
    }

    this.service.updateCheckRequest(this.checkRequestData.id, this.checkRequestData)
      .pipe(finalize(() => this.resetRequesting()))
      .subscribe(
        (checkRequest) => {
          this.checkRequestData = checkRequest;
          this.notificationService.success('Check request was updated!');
          if (isCreateBatch) {
            const queryParams = {checkId: checkRequest.id};
            this.router.navigate(['/orders/payments/edit', ''], {queryParams});
          }
        },
        () => this.notificationService.error(`Error occurred while trying to update check request.`)
      );
  }

  resetRequesting() {
    this.approving = false;
    this.declining = false;
  }

  approve(isCreateBatch?: boolean) {
    this.approving = true;
    this.save(AfterSaveAction.APPROVE_NEXT_STATUS, isCreateBatch);
  }

  decline() {
    this.declining = true;
    const dialogData = {data: {
        message: `Are you sure you want to decline this check request?`,
        isComment: true,
        isCommentRequired: true
    }};

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogData);

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result?.value) {
        this.checkRequestData.noteToAdd = result.note?.length > 0
          ? NoteUtil.createNote(result.note)
          : null;
        this.save(AfterSaveAction.DECLINE_TO_PREVIOUS_STATUS);
      } else {
        this.resetRequesting();
      }
    });
  }
}
