<div class="content" role="main">
  <div class="header">
    <h1>Station List</h1>
  </div>
  <div class="container">
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
    <div class="table-container" [ngClass]="{'no-data-table-container': !dataSource.filteredData.length}">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="tableFilterForm">
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [disabled]="exclude.includes(row.id)"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="select-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-filter">
              <div class="form-field"></div>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.name}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="name">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="mediaType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Media Type</th>
          <td mat-cell *matCellDef="let row">{{row.mediaType?.type || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="mediaType-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="mediaType">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let mediaType of mediaTypes" [value]="mediaType">
                  {{mediaType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columnFilters" class="filter-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <ng-container *ngIf="!dataSource.filteredData.length">
      <p class="no-data">No data</p>
    </ng-container>

    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
