import { ReportRequest } from '../../entities/shared/models/report-request';
import { MatTableDataSource } from '@angular/material/table';

export class ReportsUtil {
  static downloadFile(data: ArrayBuffer, filename?: string) {
    const type = (data as any).type;
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    if (filename) {
      a.download = filename;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static getSortedFilteredData<T extends { id, createdAt }>(dataSource: MatTableDataSource<T>) {
    return dataSource.sortData(dataSource.filteredData, dataSource.sort);
  }

  static createRequestReport(data, entityType) {
    const ids = data.map(item => Number(item.id));
    const createdAtArray =  data.map(item => new Date(item.createdAt));
    const begin = new Date(Math.min.apply(null, createdAtArray));
    const end = new Date(Math.max.apply(null, createdAtArray));

    const requestReport: ReportRequest = {
      entityType,
      ids,
      begin,
      end
    };

    return requestReport;
  }
}
