import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { StationListComponent } from './entities/station-list/station-list.component';
import { AccountListComponent } from './entities/account-list/account-list.component';
import { StationEditComponent } from './entities/station-edit/station-edit.component';
import { EntitiesRoutingModule } from './entities/entities-routing.module';
import { SharedModule } from './shared/shared.module';
import { LoadingDirective } from './shared/directives/loading.directive';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { YearFormatDirective } from './shared/directives/year-format.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    StationListComponent,
    AccountListComponent,
    LoadingDirective,
    StationEditComponent,
    PhoneMaskDirective,
    YearFormatDirective
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    EntitiesRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSortModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    SharedModule,
    MatSlideToggleModule
  ],
  exports: [
    AccountListComponent,
    LoadingDirective,
    StationListComponent,
    StationEditComponent,
    PhoneMaskDirective,
    YearFormatDirective
  ]
})
export class SharedComponentsModule {
}

