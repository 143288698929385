import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { GlobalAccountBalanceReportEntry } from '../shared/models/report-type';

@Component({
  selector: 'app-account-balance-table',
  templateUrl: './account-balance-table.component.html',
  styleUrls: ['./account-balance-table.component.scss']
})
export class AccountBalanceTableComponent implements OnInit, OnChanges {
  @Input() entries: GlobalAccountBalanceReportEntry[];
  total: GlobalAccountBalanceReportEntry;

  dataSource = new MatTableDataSource<GlobalAccountBalanceReportEntry>();
  displayedColumns = ['accountName', 'consultantCompanyName', 'grossAmount', 'adjustedGrossAmount', 'netAmount', 'expenseAmount',
    'receivedAmount', 'balanceAmount', 'outstandingAccountInvoiceAmount', 'commissionOwedAmount', 'cpmCommissionAmount'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor() { }

  ngOnInit(): void {
    this.total = this.entries.find(row => row.total);
    this.dataSource.data = this.entries.filter(row => !row.total);
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entries) {
      this.dataSource.data = changes.entries.currentValue;
    }
  }

}
