import { Component, Input, OnInit } from '@angular/core';
import { Commissionable, CommissionTier } from '../shared/models';
import { CommissionUtils } from '../shared/utils/commission-utils';

@Component({
  selector: 'app-commissionable-info-table',
  templateUrl: './commissionable-info-table.component.html',
  styleUrls: ['./commissionable-info-table.component.scss']
})
export class CommissionableInfoTableComponent implements OnInit {
  @Input() commissionableData: Commissionable[];
  @Input() total: number;
  @Input() commissionBalanceOwed: number;
  displayedColumns: string[] = [
    'code', 'stationType',
    'ftc', 'quickBookReference',
    'grossPaymentTotal', 'netPaymentTotal',
    'consultantSplit', 'tierInfo'
  ];
  displayedFooterColumns: string[] = [
    'code', 'stationType',
    'ftc',
    'footer-row-group',
    'consultantSplit', 'tierInfo'
  ];
  tableData: Commissionable[];

  constructor() {
  }

  ngOnInit(): void {
    if (this.commissionableData?.length > 0) {
      this.constructTableData();
    }
  }

  constructTableData(): void {
    this.tableData = this.commissionableData
      .map(dataItem => (dataItem.consultantSplit.length > 1) ? this.createMultiTierRow(dataItem) : dataItem)
      .flat();
  }

  getTierInfo(split: CommissionTier): string {
    return `T${split.sortNumber + 1} - ${split.consultantRates[0]?.rate}%` ?? ''; // split consists only rate of current consultant
  }

  getFtcLtc(element: Commissionable): string {
    return element.ftc && element.ltc ? `${element.ftc} - ${element.ltc}` : '-';
  }

  getStationMediaType(element: Commissionable): string {
    return `${element.station?.name || ''} - ${element.station?.mediaType?.type || ''}`;
  }

  /**
   * Creates commissionableData rows, every row contains only one consultantSplit entry.
   * @param dataItem Commissionable entry.
   */
  private createMultiTierRow(dataItem: Commissionable): Commissionable[] {
    const mainRow = {...dataItem};
    const firstSortNumber = 0;
    const findFirstPredicate = commissionTier => commissionTier.sortNumber === firstSortNumber;
    const findOthersPredicate = consultantSplit => consultantSplit.sortNumber !== firstSortNumber;

    delete mainRow.consultantSplit;
    mainRow.consultantSplit = [dataItem.consultantSplit.find(findFirstPredicate)];

    const otherRows = dataItem.consultantSplit
      .filter(findOthersPredicate)
      .map(consultantSplit => CommissionUtils.getEmptyCommissionable(consultantSplit));

    return [mainRow, ...otherRows];
  }

}
