import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Payment } from '../shared/models';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-versions-dialog',
  templateUrl: './payment-versions-dialog.component.html',
  styleUrls: ['./payment-versions-dialog.component.scss']
})
export class PaymentVersionsDialogComponent implements OnInit {
  displayedColumns: string[] = ['version', 'payAmount', 'quickBookReference', 'paidOn', 'versionCreatedAt', 'versionAuthor'];
  dataSource = new MatTableDataSource<Payment>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {versions: Payment[]}) { }

  ngOnInit(): void {
    this.dataSource.data = this.data.versions;
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

}
