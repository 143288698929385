<div class="main">
  <h1>
    <button mat-icon-button>
      <span routerLink="/entities/consultants" class="back-arrow material-icons">keyboard_backspace</span>
    </button>
    <span class="page-title">{{consultantData?.name}}</span>
    <button class="edit-button"
            *ngIf="hasEditPermission"
            mat-mini-fab
            color="primary"
            aria-label="Edit Station"
            [routerLink]="['/entities/consultants/edit', consultantData?.id]">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading" class="container">
    <mat-card-content class="content">
      <div class="content_right">
<!--        <div class="summary-container">
          <div class="subheader subheader-summary mat-title">Consultant Account Summary</div>

          <div class="summary-container_inner">
            <div class="summary-container_inner_column">
              <div class="cost">{{ 0 | currency}}</div>
              <div class="cost-caption">Owed<br>Today</div>
&lt;!&ndash;              <a href="" class="view-all">View and Pay</a>&ndash;&gt;
            </div>
            <div class="summary-container_inner_column">
              <div class="cost">{{ 0 | currency}}</div>
              <div class="cost-caption">Pending<br>Commision</div>
&lt;!&ndash;              <a href="" class="view-all-ref">View All</a>&ndash;&gt;
            </div>
          </div>
        </div>-->
        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Consultant Company ID</div>
                {{consultantData.code}}
              </div>
              <div class="column_item">
                <div class="title">Consultant Company Name</div>
                {{consultantData.name}}
              </div>
              <div class="column_item">
                <div class="title">Canal Partners Company</div>
                {{consultantData.cpmCompany?.name}}
              </div>
              <div class="column_item">
                <div class="title">Preferred Payment Method</div>
                {{consultantData.paymentMethod?.name || 'N/A'}}
              </div>
            </div>
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Address</div>
                <div>{{consultantData.address?.addressLine1 || 'N/A'}}</div>
                <div>{{consultantData.address?.addressLine2}}</div>
                <div>{{consultantData.address?.addressLine3}}</div>
              </div>
              <div class="column_item">
                <div class="title">City</div>
                {{consultantData.address?.city}}
              </div>
              <div class="column_item">
                <div class="title">State</div>
                {{consultantData.address?.state?.name}}
              </div>
              <div class="column_item">
                <div class="title">ZIP Code</div>
                {{consultantData.address?.zipCode || 'N/A'}}
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="content_left">
        <div class="buttons-container">
          <button mat-button mat-raised-button class="button-transaction" [matMenuTriggerFor]="transactionMenu" *ngIf="canCreateTransaction">
            Add New Transaction</button>
          <mat-menu #transactionMenu="matMenu">
            <button mat-menu-item type="button" (click)="onAddConsultantCommission()">Add Consultant Commission</button>
          </mat-menu>
        </div>

        <app-table-commission-tiers [commissionTiers]="consultantData.commissionTiers"></app-table-commission-tiers>
      </div>
    </mat-card-content>
  </mat-card>
</div>
