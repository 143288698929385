import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { User } from '../../shared/models/user';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = ['select', 'name', 'lastName', 'emailAddr', 'menu'];
  selection = new SelectionModel<User>(false, []);
  isLoadingResults = true;
  selectedId: string;

  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    lastName: new FormControl(''),
    emailAddr: new FormControl('')
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: UserService) { }

  ngOnInit(): void {
    this.service.getAccountants().subscribe(data => {
      this.dataSource.data = sortListByLastEdit(data);

      this.isLoadingResults = false;
      if (this.selectedId) {
        this.initSelected(this.selectedId);
      }
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  initSelected(id: string) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: User) => item.id === id);
    if (row) {
      this.selection.toggle(row as User);
    }
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const lastNameSearch = FilterUtils.searchFunction(searchTerms.lastName, data.lastName);
      const emailAddrSearch = FilterUtils.searchFunction(searchTerms.emailAddr, data.emailAddr);

      return nameSearch() && lastNameSearch() && emailAddrSearch();
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
