<div class="content" role="main">
  <div class="header">
    <h1>REPORT LISTINGS - Report Selection and Filter Page</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <mat-card class="container-wrapper" *ngIf="!loading">
    <div class="container">
      <form [formGroup]="reportTypeFilterForm" (submit)="showPreview(reportTypeFilterForm.value)">
        <mat-card-content>
          <div class="form-content">
            <div class="report_column">
              <div class="report-filter_column">
                <mat-form-field>
                  <mat-select formControlName="type" [placeholder]="'Select report: '">
                    <mat-option *ngFor="let option of reportTypeList" aria-selected="true" [value]="option">
                      {{reportTypes.get(option)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngIf="showAccount">
                  <ng-container
                    *ngTemplateOutlet="selectAccountTemplate; context: {form: reportTypeFilterForm.get('filterData'), showConsultant: reportType === ConvertedReportType.ACCOUNT_CHECK}">
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="reportType === ConvertedReportType.ACCOUNT_GLOBAL_BALANCE">
                  <ng-container
                    *ngTemplateOutlet="selectCPMCompanyTemplate; context: {form: reportTypeFilterForm.get('filterData')}">
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="reportType === ConvertedReportType.STATION_ORDER || reportType === ConvertedReportType.STATION_INVOICE">
                  <ng-container
                    *ngTemplateOutlet="selectStation; context: {form: reportTypeFilterForm.get('filterData')}">
                  </ng-container>
                </ng-container>
              </div>
              <div class="report-filter_column" *ngIf="!!reportType">
                <ng-container
                  *ngTemplateOutlet="selectDateTemplate; context: {form: reportTypeFilterForm.get('filterData')}">
                </ng-container>
                <ng-container *ngIf="reportType === ConvertedReportType.ACCOUNT_ORDER || reportType === ConvertedReportType.ACCOUNT_INVOICE">
                  <ng-container
                    *ngTemplateOutlet="selectMediaTypeTemplate">
                  </ng-container>
                </ng-container>
              </div>
              <div class="report-filter_column">
                <ng-container [ngSwitch]="reportType">
                  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_GLOBAL_BALANCE">
                    <ng-container
                      *ngTemplateOutlet="ACCOUNT_GLOBAL_BALANCE; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY">
                    <ng-container
                      *ngTemplateOutlet="ACCOUNT_CONSULTANT_COMPANY; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_ORDER">
                    <ng-container
                      *ngTemplateOutlet="ACCOUNT_TRANSACTION; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_INVOICE">
                    <ng-container
                      *ngTemplateOutlet="ACCOUNT_TRANSACTION; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="ConvertedReportType.STATION_ORDER">
                    <ng-container
                      *ngTemplateOutlet="STATION_TRANSACTION; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="ConvertedReportType.STATION_INVOICE">
                    <ng-container
                      *ngTemplateOutlet="STATION_TRANSACTION; context: {form: reportTypeFilterForm.get('filterData')}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

            </div>
            <div class="report_buttons">
                <button mat-raised-button appLoading
                        [loading]="isReportLoading$ | async"
                        [disabled]="!reportTypeFilterForm.valid || (isStationLoading$ | async)"
                        color="primary" type="button"
                        *ngIf="hasExportPermission"
                        (click)="exportToExcel(reportTypeFilterForm.value)"
                >Export...
                </button>
                <button mat-raised-button appLoading
                        [loading]="isPreviewLoading$ | async"
                        [disabled]="!reportTypeFilterForm.valid || (isStationLoading$ | async)"
                        color="primary" type="submit"
                        *ngIf="hasExportPermission"
                >Run Report
                </button>
            </div>
          </div>
          <ng-container class="center" *ngIf="!reportType">
            <p class="no-data">Report type is not selected..</p>
          </ng-container>
          <div *ngIf="appliedReportType && previewData">
            <app-report-preview [reportType]="appliedReportType" [data]="previewData"></app-report-preview>
          </div>
          <ng-container class="center" *ngIf="isPreviewLoading$ | async">
            <p class="no-data">Preview loading..</p>
          </ng-container>
        </mat-card-content>
      </form>
    </div>
  </mat-card>
</div>

<ng-template #ACCOUNT_GLOBAL_BALANCE let-filterData="form">
  <div [formGroup]="filterData" class="filter-padding">
    <section class="form-element">
      <mat-checkbox formControlName="includeAll" class="margin-element">Include archived accounts</mat-checkbox>
    </section>
  </div>
</ng-template>

<ng-template #ACCOUNT_CONSULTANT_COMPANY let-filterData="form">
  <div [formGroup]="filterData">
    <mat-form-field>
      <mat-label>Consultant Company</mat-label>
      <input type="text"
             matInput
             required
             maxlength="50"
             formControlName="consultantCompany"
             [matAutocomplete]="autoConsultantCompany"
             #consultantCompany
             (blur)="onBlurConsultantCompany(consultantCompany)"
      >
      <mat-autocomplete #autoConsultantCompany="matAutocomplete" [displayWith]="displayConsultantCompany">
        <mat-option *ngIf="consultantCompanies.length === 0" [value]="null">No Submitted Orders for chosen Account</mat-option>
        <mat-option *ngFor="let option of filteredConsultantCompanies | async"
                    [value]="option"
                    (mousedown)="onConsultantCompanyOptionSelect(option)"
        >
          {{option.code}} - {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="browse-link" (click)="openConsultantCompanyDialog()">
      Browse Consultant Companies >
    </div>
  </div>
</ng-template>

<ng-template #selectAccountTemplate let-filterData="form" let-showConsultant="showConsultant">
  <div [formGroup]="filterData">
    <mat-form-field>
      <mat-label>Account</mat-label>
      <input type="text"
             matInput
             required
             formControlName="account"
             #accountInput
             (blur)="onBlurAccount(accountInput)"
             [matAutocomplete]="autoAccount"
      >
      <mat-autocomplete #autoAccount="matAutocomplete" [displayWith]="displayAccount">
        <mat-option *ngFor="let option of filteredAccounts | async" [value]="option"
                    (mousedown)="onAccountOptionSelect(option)">
          {{option.code}} - {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="browse-link" (click)="openAccountDialog()">Browse Accounts ></div>
    <span *ngIf="showConsultant">{{leadConsultant ? ('Lead Consultant: ' + leadConsultant) : ''}}</span>
  </div>
</ng-template>

<ng-template #selectDateTemplate let-filterData="form">
  <div [formGroup]="filterData" class="filter-padding">
    <section class="form-element">
      <mat-radio-group aria-label="Select Date Type" [value]="isDateRange" (change)="handleSelectDateType($event.value)">
        <mat-radio-button [value]="true">Date range</mat-radio-button>
        <mat-radio-button [value]="false">Year</mat-radio-button>
      </mat-radio-group>
    </section>

    <mat-form-field *ngIf="isDateRange">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matInput matStartDate formControlName="since" placeholder="Start date">
        <input matInput matEndDate formControlName="to" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field *ngIf="!isDateRange" appYearFormat>
      <mat-label>Year</mat-label>
      <input matInput [matDatepicker]="yearDateDatepicker" formControlName="year">
      <mat-datepicker-toggle matSuffix [for]="yearDateDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #yearDateDatepicker startView="multi-year"
                      (yearSelected)="chosenYearHandler($event, yearDateDatepicker)">
      </mat-datepicker>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #selectCPMCompanyTemplate let-filterData="form">
  <div [formGroup]="filterData">
    <mat-form-field>
      <mat-label>Select office</mat-label>
      <mat-select formControlName="cpmCompanies" multiple [disabled]="!isSuperAdmin">
        <mat-option *ngFor="let company of companies" aria-selected="true"  [value]="company">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #selectMediaTypeTemplate>
  <div>
    <div class="filter-method-container-title">
      Select Media Type
      <button mat-raised-button (click)="toggleAll(true)" type="button" [disabled]="isStationLoading$ | async">All</button>
      <button mat-raised-button (click)="toggleAll(false)" type="button" [disabled]="isStationLoading$ | async">None</button>
    </div>
    <div class="media-types-list">
      <mat-checkbox *ngFor="let mediaType of mediaTypes" (change)="toggleMediaType($event.checked, mediaType.id)"
                    [checked]="selectedMediaTypes.includes(mediaType.id)">{{mediaType.type}}</mat-checkbox>
      <button mat-raised-button (click)="specifyStations()" type="button" [disabled]="isStationLoading$ | async">Specify</button>
    </div>
  </div>
</ng-template>

<ng-template #ACCOUNT_TRANSACTION let-filterData="form">
  <div [formGroup]="filterData">
    <div class="filter-method-container-title">
      Select Stations
    </div>
    <div class="station-list">
      <mat-spinner [diameter]="20" *ngIf="isStationLoading$ | async"></mat-spinner>
      <mat-checkbox *ngFor="let station of stations" (change)="toggleStation($event.checked, station.id)"
                    [checked]="selectedStations.includes(station.id)">
        {{station.name}}- {{station.mediaType?.type}}</mat-checkbox>
    </div>

    <section class="form-element transaction">
      <mat-radio-group aria-label="Select Transaction" [value]="isTransactionId" (change)="handleSelectTransactionType($event.value)">
        <mat-radio-button [value]="true">Transaction ID</mat-radio-button>
        <mat-radio-button [value]="false">QBref#</mat-radio-button>
      </mat-radio-group>
    </section>

    <mat-form-field *ngIf="isTransactionId">
      <input formControlName="transactionId" matInput maxlength="50">
    </mat-form-field>

    <mat-form-field *ngIf="!isTransactionId">
      <input formControlName="quickBookReference" matInput maxlength="50">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #selectStation let-filterData="form">
  <div [formGroup]="filterData">
    <mat-form-field>
      <mat-label>Station</mat-label>
      <input type="text"
             matInput
             required
             formControlName="station"
             #stationInput
             (blur)="onBlurStation(stationInput)"
             [matAutocomplete]="autoAccount"
      >
      <mat-autocomplete #autoAccount="matAutocomplete" [displayWith]="displayStationName">
        <mat-option *ngFor="let option of filteredStations | async" [value]="option"
                    (mousedown)="onStationOptionSelect(option)">
          {{option?.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="browse-link" (click)="openStationDialog()">Browse Stations ></div>
  </div>
</ng-template>

<ng-template #STATION_TRANSACTION let-filterData="form">
  <div [formGroup]="filterData">
    <div class="filter-method-container-title">
      Select Accounts
    </div>
    <div class="station-list">
      <span class="station-list-empty" *ngIf="filterData.get('station').value?.id && stationAccounts?.length === 0">No ordered Accounts for Station</span>
      <mat-checkbox *ngFor="let account of stationAccounts" (change)="toggleAccount($event.checked, account.id)"
                    [checked]="selectedAccounts.includes(account.id)">
        {{account.code}}, {{account.name}}</mat-checkbox>
    </div>

    <section class="form-element transaction">
      <mat-radio-group aria-label="Select Transaction" [value]="true" (change)="handleSelectTransactionType($event.value)">
        <mat-radio-button [value]="true">Transaction ID</mat-radio-button>
        <mat-radio-button [value]="false">QBref#</mat-radio-button>
      </mat-radio-group>
    </section>

    <mat-form-field *ngIf="isTransactionId">
      <input formControlName="transactionId" matInput maxlength="50">
    </mat-form-field>

    <mat-form-field *ngIf="!isTransactionId">
      <input formControlName="quickBookReference" matInput maxlength="50">
    </mat-form-field>
  </div>
</ng-template>
