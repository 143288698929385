import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Station } from '../../entities/shared/models';
import { TransactionService } from '../shared/transaction.service';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { customSortingDataAccessor, SortingDataTypes, sortListByLastEdit } from '../../shared/utils/sort-list-util';

@Component({
  selector: 'app-station-transaction-list',
  templateUrl: './station-transaction-list.component.html',
  styleUrls: ['./station-transaction-list.component.scss']
})
export class StationTransactionListComponent implements OnInit {
  @Input() accountId: number;
  @Input() exclude: number[] = [];

  dataSource = new MatTableDataSource<Station>();
  displayedColumns: string[] = ['select', 'name', 'mediaType'];
  isLoadingResults = true;
  selection = new SelectionModel<Station>(false, []);
  selectedId: number;

  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    mediaType: new FormControl(''),
    state: new FormControl(''),
  });

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private transactionService: TransactionService) {
  }

  ngOnInit(): void {
    const stationList$ = this.transactionService.getStationList(this.accountId);

    stationList$.subscribe(data => {
      this.setTableData(data);
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableFilterForm.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();
    this.dataSource.sortingDataAccessor = customSortingDataAccessor(SortingDataTypes.Station);
  }

  setTableData(data) {
    this.dataSource.data = sortListByLastEdit(data);
    this.isLoadingResults = false;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get mediaTypes() {
    return [...new Set(this.dataSource.data.filter(item => !!item.mediaType).map(item => item.mediaType.type))];
  }

  get states() {
    return [...new Set(this.dataSource.data.filter(item => !!item.address?.state).map(item => item.address?.state?.name))];
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: Station) => +item.id === id);
    if (row) {
      this.selection.toggle(row as Station);
    }
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const mediaTypeSearch = FilterUtils.createSelectSearch(searchTerms.mediaType, data.mediaType?.type);
      const stateSearch = FilterUtils.searchFunction(searchTerms.state, data.address.state?.name);

      const filterFunctions = [ nameSearch(), mediaTypeSearch(), stateSearch() ];
      return filterFunctions.every(searchFunction => searchFunction);
    };
  }
}
