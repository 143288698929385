import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CPMCompany } from './models';
import { CPM_COMPANIES } from '../../core/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class CPMCompanyService {

  constructor(private http: HttpClient) { }

  getCompanyList(showAll: boolean = false): Observable<CPMCompany[]> {
    const params = new HttpParams()
      .set('showAll', showAll.toString());
    return this.http.get<CPMCompany[]>(CPM_COMPANIES, {params});
  }
}
