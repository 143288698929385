import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';

import { Account, ConsultantCompany, CPMCompany, MediaType, Station, TransactionsReport } from '../../entities/shared/models';
import {
  AccountCheckReportEntry,
  ConsultantCompanyCommissionReportEntry,
  ConvertedReportType,
  GlobalAccountBalanceReportEntry, Operator,
  Report
} from '../shared/models/report-type';
import { User } from '../../shared/models/user';
import { Role } from '../../shared/models/role.enum';

import { AccountService } from '../../entities/shared/account.service';
import { AuthService } from '../../shared/auth.service';
import { CPMCompanyService } from '../../entities/shared/cpm-company.service';
import { EntityDictionaryService } from '../../entities/shared/entity-dictionary.service';
import { StationService } from '../../entities/shared/station.service';
import { NotificationService } from '../../shared/notification.service';
import { ReportsService } from '../shared/reports.service';
import { UserService } from '../../shared/user.service';

import { DateUtils } from '../../shared/utils/date-utils';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';

import { BrowseAccountDialogComponent } from '../../orders/browse-account-dialog/browse-account-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { BrowseConsultantDialogComponent } from '../../entities/browse-consultant-dialog/browse-consultant-dialog.component';
import { BrowseStationDialogComponent } from '../../orders/browse-station-dialog/browse-station-dialog.component';

type MyMapLikeType = Record<string, object>;
const mapReportTypeToFilterData: MyMapLikeType = {
  [ConvertedReportType.ACCOUNT_GLOBAL_BALANCE]: {
    since: [null], to: [null], year: [null],
    includeAll: [false], cpmCompanies: [null],
  },
  [ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY]: {
    since: [null], to: [null], year: [null],
    account: [null, Validators.required],
    consultantCompany: [null, Validators.required]
  },
  [ConvertedReportType.ACCOUNT_GLOBAL]: {
    since: [null], to: [null], year: [null],
    account: [null, Validators.required]
  },
  [ConvertedReportType.ACCOUNT_CHECK]: {
    since: [null], to: [null], year: [null],
    account: [null, Validators.required]
  },
  [ConvertedReportType.ACCOUNT_ORDER]: {
    since: [null], to: [null], year: [null],
    account: [null, Validators.required],
    quickBookReference: [''], transactionId: ['']
  },
  [ConvertedReportType.ACCOUNT_INVOICE]: {
    since: [null], to: [null], year: [null],
    account: [null, Validators.required],
    quickBookReference: [''], transactionId: ['']
  },
  [ConvertedReportType.STATION_ORDER]: {
    since: [null], to: [null], year: [null],
    station: [null, Validators.required],
    quickBookReference: [''], transactionId: ['']
  },
  [ConvertedReportType.STATION_INVOICE]: {
    since: [null], to: [null], year: [null],
    station: [null, Validators.required],
    quickBookReference: [''], transactionId: ['']
  }
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  reportTypes = new Map([
    [ConvertedReportType.ACCOUNT_GLOBAL_BALANCE, 'Global Balance Report'],
    [ConvertedReportType.ACCOUNT_CHECK, 'Account Check Report'],
    [ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY, 'Consultant Commission Report'],
    [ConvertedReportType.ACCOUNT_GLOBAL, 'Multi Consultant Commission Report'],
    [ConvertedReportType.STATION_ORDER, 'Station Transaction (Order view)'],
    [ConvertedReportType.STATION_INVOICE, 'Station Transaction (Invoice view)'],
    [ConvertedReportType.ACCOUNT_ORDER, 'Account Transaction (Order view)'],
    [ConvertedReportType.ACCOUNT_INVOICE, 'Account Transaction (Invoice view)'],
  ]);
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isPreviewLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isStationLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  accountSubscription: Subscription;
  stationSubscription: Subscription;
  ConvertedReportType = ConvertedReportType;
  Operator = Operator;
  loading = false;

  isDateRange = true;
  isTransactionId = true;

  accounts: Account[];
  filteredAccounts: Observable<Account[]>;
  consultantCompanies: ConsultantCompany[] = [];
  filteredConsultantCompanies: Observable<ConsultantCompany[]>;
  companies: CPMCompany[];
  stations: Station[];
  filteredStations: Observable<Station[]>;
  mediaTypes: MediaType[];
  stationAccounts: Account[];

  selectedMediaTypes: number[] = [];
  selectedStations: number[] = [];
  selectedAccounts: number[] = [];

  previewData: Report<ConsultantCompanyCommissionReportEntry|AccountCheckReportEntry|GlobalAccountBalanceReportEntry>|TransactionsReport;

  reportTypeFilterForm = this.fb.group({
    type: [null, Validators.required]
  });
  appliedReportType: ConvertedReportType;

  constructor(private fb: FormBuilder,
              private dictionaryService: EntityDictionaryService,
              private reportsService: ReportsService,
              private notificationService: NotificationService,
              private dialog: MatDialog,
              private stationService: StationService,
              private companyService: CPMCompanyService,
              private authService: AuthService,
              protected user: UserService,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadData();
    this.buildFilterData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get isSuperAdmin(): boolean {
    return this.authService.hasPermission([Role.SUPER_ADMIN]);
  }

  get hasExportPermission(): boolean {
    // @todo: change the next line
    return true;
  }

  get reportTypeList(): ConvertedReportType[] {
    return Array.from(this.reportTypes.keys());
  }

  get reportType(): ConvertedReportType {
    return this.reportTypeFilterForm.get('type').value as ConvertedReportType;
  }

  get showAccount() {
    return [ ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY, ConvertedReportType.ACCOUNT_GLOBAL, ConvertedReportType.ACCOUNT_CHECK,
    ConvertedReportType.ACCOUNT_ORDER, ConvertedReportType.ACCOUNT_INVOICE]
      .includes(this.reportType);
  }

  get filterDataFormGroup(): FormGroup {
    return this.reportTypeFilterForm.get('filterData') as FormGroup;
  }

  loadData = () => {
    const accounts$ = this.accountService.getAccountList();
    const companies$ = this.companyService.getCompanyList(true);
    const mediaTypes$ = this.dictionaryService.getMediaTypes();

    forkJoin([accounts$, companies$, mediaTypes$])
      .pipe(first())
      .subscribe(([accounts, companies, mediaTypes]) => {
        this.accounts = accounts || [];
        this.companies = companies || [];
        this.mediaTypes = mediaTypes;
        this.loading = false;
      });
  }

  loadConsultantCompanyList(account: Account) {
    this.reportTypeFilterForm.get('filterData').patchValue({consultantCompany: null});
    const consultantCompanies$ = this.accountService.getConsultantCompanies(String(account.id));
    this.subscription.add(consultantCompanies$.subscribe(consultantCompanies => {
      this.consultantCompanies = consultantCompanies;
      this.filteredConsultantCompanies = of(consultantCompanies);
      if (consultantCompanies.length === 1) {
        this.reportTypeFilterForm.get('filterData').patchValue({consultantCompany: consultantCompanies[0]});
      }
      this.manageConsultantCompanyFilters();
    }));
  }

  get leadConsultant() {
    const account = this.reportTypeFilterForm.get('filterData').get('account')?.value;
    return account ? account.consultantCompanies?.find(item => item.code === account.leadConsultantCompanyCode)?.name : '';
  }

  showPreview(reportTypeFilter: any) {
    this.isPreviewLoading$.next(true);
    this.previewData = null;
    const filter = this.prepareFilter(reportTypeFilter);
    let request = of(null);

    switch (reportTypeFilter.type) {
      case ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY:
      case ConvertedReportType.ACCOUNT_GLOBAL:
        request = this.reportsService.getCommissionPreview(filter);
        break;
      case ConvertedReportType.ACCOUNT_CHECK:
        request = this.reportsService.getAccountCheckPreview(filter);
        break;
      case ConvertedReportType.ACCOUNT_GLOBAL_BALANCE:
        request = this.reportsService.getGlobalAccountBalancePreview(filter);
        break;
      case ConvertedReportType.ACCOUNT_ORDER:
      case ConvertedReportType.ACCOUNT_INVOICE:
        request = this.accountService.getAccountTransactionsReport(reportTypeFilter.filterData.account.id, filter);
        break;
      case ConvertedReportType.STATION_ORDER:
      case ConvertedReportType.STATION_INVOICE:
        request = this.stationService.getStationTransactionsReport(reportTypeFilter.filterData.station.id, filter);
        break;
    }

    this.subscription.add(request.pipe(finalize(() => this.isPreviewLoading$.next(false)))
      .subscribe(data => {
          this.previewData = data;
          this.appliedReportType = reportTypeFilter.type;
        },
        () => this.notificationService.error('Error occurred while trying to generate preview of report.')
      ));
  }

  exportToExcel(reportTypeFilter: any) {
    this.isReportLoading$.next(true);
    const filter = this.prepareFilter(reportTypeFilter);
    let request = this.reportsService.generateReportByType(reportTypeFilter);

    switch (reportTypeFilter.type) {
      case ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY:
      case ConvertedReportType.ACCOUNT_GLOBAL:
        request = this.reportsService.generateCommissionReport(filter);
        break;
      case ConvertedReportType.ACCOUNT_CHECK:
        request = this.reportsService.generateAccountCheckReport(filter);
        break;
      case ConvertedReportType.ACCOUNT_GLOBAL_BALANCE:
        request = this.reportsService.generateGlobalAccountBalanceReport(filter);
        break;
      case ConvertedReportType.ACCOUNT_ORDER:
      case ConvertedReportType.ACCOUNT_INVOICE:
        request = this.accountService.generateExcelAccountTransactionsReport(reportTypeFilter.filterData.account.id, filter);
        break;
      case ConvertedReportType.STATION_ORDER:
      case ConvertedReportType.STATION_INVOICE:
        request = this.stationService.generateExcelStationTransactionsReport(reportTypeFilter.filterData.station.id, filter);
        break;
    }

    this.subscription.add(request.pipe(finalize(() => this.isReportLoading$.next(false)))
      .subscribe((data: HttpResponse<any>) => {
        const filename = data.headers.get('filename');
        ReportsUtil.downloadFile(data.body as ArrayBuffer, filename);
      },
      () => this.notificationService.error('Error occurred while trying to download report file.')
    ));
  }

  prepareFilter(reportTypeFilter: any) {
    const filter: any = {reportType: reportTypeFilter.type};

    if (reportTypeFilter.filterData.since) {
      filter.since = DateUtils.dateFormatToShort(new Date(reportTypeFilter.filterData.since));
    }
    if (reportTypeFilter.filterData.to) {
      filter.to = DateUtils.dateFormatToShort(new Date(reportTypeFilter.filterData.to));
    }
    if (reportTypeFilter.filterData.year) {
      const yearDateObject =  typeof reportTypeFilter.filterData.year === 'object'
        ? new Date(reportTypeFilter.filterData.year)
        : new Date(reportTypeFilter.filterData.year, 1);
      filter.year = yearDateObject.getFullYear();
    }
    if (reportTypeFilter.filterData.account) {
      filter.accountId = reportTypeFilter.filterData.account.id;
    }
    if (reportTypeFilter.filterData.consultantCompany) {
      filter.consultantCompanyId = reportTypeFilter.filterData.consultantCompany.id;
    }
    if (reportTypeFilter.type === ConvertedReportType.ACCOUNT_GLOBAL_BALANCE) {
      filter.includeAll = reportTypeFilter.filterData.includeAll;
      filter.cpmCompanyIds = reportTypeFilter.filterData.cpmCompanies?.map(item => item.id) || null;
    }
    if ([ConvertedReportType.ACCOUNT_ORDER, ConvertedReportType.ACCOUNT_INVOICE].includes(reportTypeFilter.type)) {
      delete filter.accountId;
      delete filter.reportType;
      filter.view = reportTypeFilter.type;
      filter.quickBookReference = reportTypeFilter.filterData.quickBookReference;
      filter.transactionId = reportTypeFilter.filterData.transactionId;
      filter.stationsFilter = {
        accountId: reportTypeFilter.filterData.account.id,
        mediaTypeIds: this.selectedMediaTypes,
        stationIds: this.selectedStations
      };
    }
    if ([ConvertedReportType.STATION_INVOICE, ConvertedReportType.STATION_ORDER].includes(reportTypeFilter.type)) {
      delete filter.reportType;
      filter.view = reportTypeFilter.type;
      filter.quickBookReference = reportTypeFilter.filterData.quickBookReference;
      filter.transactionId = reportTypeFilter.filterData.transactionId;
      filter.accountsFilter = {
        accountIds: this.selectedAccounts
      };
    }
    return filter;
  }

  handleSelectDateType(value: boolean) {
    this.isDateRange = value;
    this.clearDateFilter();
  }

  handleSelectTransactionType(value: boolean) {
    this.isTransactionId = value;
    this.reportTypeFilterForm.get('filterData').patchValue({quickBookReference: '', transactionId: ''});
  }

  toggleMediaType(value: boolean, id: number) {
    if (value) {
      this.selectedMediaTypes.push(id);
    } else {
      this.selectedMediaTypes = this.selectedMediaTypes.filter(item => item !== id);
    }
  }

  toggleStation(value: boolean, id: number) {
    if (value) {
      this.selectedStations.push(id);
    } else {
      this.selectedStations = this.selectedStations.filter(item => item !== id);
    }
  }

  toggleAccount(value: boolean, id: number) {
    if (value) {
      this.selectedAccounts.push(id);
    } else {
      this.selectedAccounts = this.selectedAccounts.filter(item => item !== id);
    }
  }

  toggleAll(isAll: boolean) {
    this.selectedMediaTypes = isAll ? this.mediaTypes.map(item => item.id) : [];
  }

  specifyStations() {
    this.stations = undefined;
    this.selectedStations = [];
    const stationsFilter = {
      accountId: this.filterDataFormGroup.get('account').value?.id,
      mediaTypeIds: this.selectedMediaTypes,
      stationIds: []
    };
    this.isStationLoading$.next(true);
    this.stationService.getFilteredStations(stationsFilter).subscribe(stations => {
      this.stations = stations;
      this.isStationLoading$.next(false);
    });
  }

  displayAccountant(accountant?: User): string {
    return TypeaheadUtil.displayAccountant(accountant);
  }

  displayConsultantCompany(item): string {
    return item ? `${item.code} - ${item.name}` : '';
  }

  manageAccountFilters() {
    if (this.isAccountFilterNeeded()) {
      this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.filterDataFormGroup, this.accounts);
    }
  }

  manageConsultantCompanyFilters() {
    this.filteredConsultantCompanies =
      TypeaheadUtil.manageConsultantFilter(this.filterDataFormGroup.get('consultantCompany'), this.consultantCompanies);
  }

  onBlurAccount(inputElement) {
    TypeaheadUtil.onBlurAccount(this.filterDataFormGroup, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.filterDataFormGroup, option);
  }

  displayAccount(account?: Account): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  openAccountDialog() {
    const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
      {
        data: {
          selected: this.filterDataFormGroup.value.account?.id
        }
      }
    );

    this.subscription.add(dialogRef.afterClosed().subscribe((result: Account) => {
      if (result) {
        this.filterDataFormGroup.get('account').setValue(result);
      }
    }));
  }

  openConsultantCompanyDialog() {
    if (this.consultantCompanies.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Submitted Orders for chosen Account', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseConsultantDialogComponent,
        {
          data: {
            selected: this.filterDataFormGroup.value.consultantCompany?.id,
            transaction: true,
            accountId: this.filterDataFormGroup.value.account?.id
          }
        }
      );

      this.subscription.add(dialogRef.afterClosed().subscribe((result: ConsultantCompany) => {
        if (result) {
          this.filterDataFormGroup.get('consultantCompany').setValue(result);
        }
      }));
    }
  }

  onConsultantCompanyOptionSelect(option) {
    TypeaheadUtil.onConsultantOptionSelect(this.filterDataFormGroup.get('consultantCompany'), option);
  }

  onBlurConsultantCompany(inputElement) {
    TypeaheadUtil.onBlurConsultant(this.filterDataFormGroup.get('consultantCompany'), this.consultantCompanies, inputElement);
  }

  manageStationFilter() {
    if (!this.isStationFilterNeeded) {
      return;
    }
    this.filteredStations = this.filterDataFormGroup.get('station')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: any) => term?.length >= 2 ? this.stationService.getTypeaheadStationList(term) : of([]))
    ).pipe(map((results: any) => {
      this.stations = results.map(item => ({id: Number(item.id), name: item.text}) as any);
      return this.stations;
    }));
  }

  onStationOptionSelect(option) {
    TypeaheadUtil.onStationOptionSelect(this.filterDataFormGroup.get('station'), option);
  }

  onBlurStation(inputElement) {
    TypeaheadUtil.onBlurStation(this.filterDataFormGroup.get('station'), this.stations, inputElement);
  }

  openStationDialog() {
    const dialogRef = this.dialog.open(BrowseStationDialogComponent,
      {
        data: {
          selected: this.filterDataFormGroup.value.station?.id,
        }
      }
    );

    this.subscription.add(dialogRef.afterClosed().subscribe((result: Station) => {
      if (result) {
        this.filterDataFormGroup.get('station').setValue(result);
      }
    }));
  }

  loadStationAccounts(value) {
    if (!value || typeof value === 'string') {
      this.stationAccounts = [];
    } else {
      this.stationService.getStationAccounts(value.id).pipe(take(1)).subscribe(data => this.stationAccounts = data);
    }
  }

  displayStationName(station: Station): string {
    return station?.name ?? '';
  }

  chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
    const ctrlValue = new Date(this.filterDataFormGroup.get('year').value) || new Date();
    ctrlValue.setFullYear(normalizedYear.getFullYear());
    this.filterDataFormGroup.get('year').setValue(ctrlValue);
  }

  private buildFilterData() {
    this.reportTypeFilterForm.get('type').valueChanges.subscribe((value) => {
      if (this.accountSubscription) {
        this.accountSubscription.unsubscribe();
      }
      if (this.stationSubscription) {
        this.stationSubscription.unsubscribe();
      }
      this.reportTypeFilterForm.removeControl('filterData');
      this.reportTypeFilterForm.get('type').setValue(value, {emitEvent: false});
      this.reportTypeFilterForm.addControl('filterData', this.fb.group(mapReportTypeToFilterData[value]));
      this.subscribeAccount();
      this.subscribeStation();
      this.manageAccountFilters();
      this.manageStationFilter();

      this.selectedAccounts = [];
      this.selectedStations = [];
      this.selectedMediaTypes = [];
      this.stations = [];
      this.stationAccounts = [];
      this.isDateRange = true;
      this.isTransactionId = true;

      if (value === ConvertedReportType.ACCOUNT_GLOBAL_BALANCE && !this.isSuperAdmin) {
        const userCompany = this.companies.find(company => company.shortName === this.user.currentUser.companyShortName);
        this.filterDataFormGroup.get('cpmCompanies').setValue([userCompany]);
      }
    });
    this.reportTypeFilterForm.updateValueAndValidity({emitEvent: false});
  }

  private subscribeAccount() {
    this.accountSubscription = this.filterDataFormGroup.get('account')?.valueChanges.subscribe((account) => {
      if (account?.id) {
        if (this.reportType === ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY) {
          this.loadConsultantCompanyList(account);
        }
        this.selectedStations = [];
        this.stations = [];
      }
    });
  }

  private subscribeStation() {
    if (!this.isStationFilterNeeded) {
      return;
    }
    this.stationSubscription = this.filterDataFormGroup.get('station')?.valueChanges.subscribe((station) => {
      if (station?.id) {
        this.loadStationAccounts(station);
        this.selectedAccounts = [];
      }
    });
  }

  private isAccountFilterNeeded(): boolean {
    const filterTypesWithAccountFilter = [ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY, ConvertedReportType.ACCOUNT_GLOBAL,
      ConvertedReportType.ACCOUNT_CHECK, ConvertedReportType.ACCOUNT_ORDER, ConvertedReportType.ACCOUNT_INVOICE];
    return filterTypesWithAccountFilter.includes(this.reportType);
  }

  private isStationFilterNeeded(): boolean {
    const filterTypesWithAccountFilter = [ConvertedReportType.STATION_INVOICE, ConvertedReportType.STATION_ORDER];
    return filterTypesWithAccountFilter.includes(this.reportType);
  }

  private clearDateFilter() {
    this.reportTypeFilterForm.get('filterData').patchValue({since: null, to: null, year: null});
  }
}

