import { Component, Input, OnInit } from '@angular/core';
import {
  ConsultantCompanyCommissionReport,
  ConsultantCompanyCommissionReportEntry,
  ConsultantCompanyCommissionReportEntryType
} from '../../orders/shared/models/consultant-company-commission-report';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-consultant-commission-report-table',
  templateUrl: './consultant-commission-report-table.component.html',
  styleUrls: ['./consultant-commission-report-table.component.scss']
})
export class ConsultantCommissionReportTableComponent implements OnInit {
  @Input() consultantCommissionReport: ConsultantCompanyCommissionReport;
  @Input() isReport = false;

  displayedColumns: string[] = [
    'title', 'transactionId', 'transactionDate', 'ftc', 'quickBookReference', 'grossAmount', 'netAmount',
    'cpmSplitAmount', 'consultantCompanySplitAmount', 'paidToConsultantCompanyAmount', 'balanceWithConsultantAmount'
  ];
  dataSource = new MatTableDataSource<ConsultantCompanyCommissionReportEntry>();
  ConsultantCompanyCommissionReportEntryType = ConsultantCompanyCommissionReportEntryType;

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.consultantCommissionReport.entries;
  }

  getTitle(element: ConsultantCompanyCommissionReportEntry): string {
    return element.tierRow ? element.tierColumn1 : (element.title ? element.title : ' ');
  }

  getTransactionID(element: ConsultantCompanyCommissionReportEntry): string {
    return element.tierRow ? element.tierColumn2 : (element.transactionId ? element.transactionId : ' ');
  }

  getTransactionDate(element: ConsultantCompanyCommissionReportEntry): string {
    return element.tierRow ? element.tierColumn3 : element.transactionDate ? element.transactionDate :  ' ';
  }

  getTransactionLink(element: ConsultantCompanyCommissionReportEntry) {
    switch (element.type) {
      case ConsultantCompanyCommissionReportEntryType.COMMISSION:
        return encodeURI(`/orders/commissions/edit/${element.transactionTypeId}`);
      case ConsultantCompanyCommissionReportEntryType.PAYMENT:
        return `/orders/payments/edit/${element.transactionTypeId}`;
    }
  }

  getQueryParams(element: ConsultantCompanyCommissionReportEntry) {
    return element.type === ConsultantCompanyCommissionReportEntryType.COMMISSION
      ? { consultantCompanyId: element.consultantCompanyId }
      : {};
  }

}
