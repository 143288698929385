<div class="content" role="main">
  <div class="header">
    <h1>Account List</h1>
  </div>
  <form [formGroup]="filterForm" class="filter-container">
    <mat-form-field>
      <mat-label>Select office</mat-label>
      <mat-select formControlName="cpmCompany" [disabled]="!isSuperAdmin">
        <mat-option *ngFor="let company of companies" aria-selected="true" [value]="company">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Select user</mat-label>
      <mat-select formControlName="user" [disabled]="!userConfigurations?.length">
        <mat-option *ngFor="let configuration of userConfigurations" aria-selected="true" [value]="configuration">
          {{configuration.user.name}} {{configuration.user.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="container user-access-container mat-elevation-z8">
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <div class="table-container" [ngClass]="{'no-data-table-container': !dataSource.filteredData.length}">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="tableFilterForm">
        <ng-container matColumnDef="select" sticky>
          <th class="select" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="select-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-filter">
              <div class="form-field"></div>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="name" sticky>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Name</th>
          <td mat-cell *matCellDef="let row">
            <span>{{row.name}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="name">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account ID</th>
          <td mat-cell *matCellDef="let row">{{row.code || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="code-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="code">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="leadConsultant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Lead Consultant Company</th>
          <td mat-cell *matCellDef="let row">{{row.leadConsultant || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="leadConsultant-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="leadConsultant">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Type</th>
          <td mat-cell *matCellDef="let row">
            {{getAccountType(row) || 'N/A'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="accountType-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="accountType">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
                  {{accountType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="menu" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"></td>
        </ng-container>

        <ng-container matColumnDef="menu-filter" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <button mat-icon-button class="button pull-right" (click)="clearFilterForm()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                <path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/>
              </svg>
            </button>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columnFilters; sticky: true" class="filter-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-container *ngIf="!dataSource.filteredData.length">
        <p class="no-data">No data</p>
      </ng-container>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
  <div class="footer">
    <button mat-raised-button appLoading
            [disabled]="loading || saving || !filterForm.get('user').value"
            color="primary" type="button"
            (click)="save()"
    >
      Save
    </button>
  </div>
</div>
