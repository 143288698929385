<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Payment Batch</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit(form)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <mat-form-field *ngIf="isEdit">
            <mat-label>Payment Batch ID#</mat-label>
            <input formControlName="code" matInput readonly>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Check Request</mat-label>
            <input type="text"
                   matInput
                   required
                   tabindex="0"
                   formControlName="checkRequest"
                   [matAutocomplete]="autoCheck"
                   [readonly]="isEdit"
                   #checkInput
                   (blur)="onBlurCheckRequest(checkInput)"
            >
            <mat-autocomplete #autoCheck="matAutocomplete" [displayWith]="displayCheckRequest">
              <mat-option *ngFor="let option of filteredCheckRequests | async"
                          [value]="option"
                          (mousedown)="onCheckRequestOptionSelect(option)"
              >
                {{option.code}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="browseRequest()" *ngIf="!isEdit">Browse Check Requests ></div>

          <div class="flex-row">
            <mat-form-field>
              <mat-label>Account</mat-label>
              <input type="text" matInput [value]="account" [readonly]="true">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Company</mat-label>
              <input type="text" matInput [value]="accountCompany" [readonly]="true">
            </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>Assigned Accountant</mat-label>
            <input type="text"
                   matInput
                   formControlName="assignedAccountant"
                   #accountantInput
                   (blur)="onBlurAccountant(accountantInput)"
                   [matAutocomplete]="autoAccountant"
                   [readonly]="!hasEditPermission"
                   required
            >
            <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayAccountant">
              <mat-option *ngFor="let option of filteredAccountants | async" [value]="option"
                          (mousedown)="onAccountantOptionSelect(option)">
                {{option.name}} {{option.lastName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="browseUser()" *ngIf="hasEditPermission">Browse Accountants ></div>


          <div *ngIf="accountantAssignmentsFormGroup.length > 0">
            <mat-grid-list cols="2" rowHeight="7:1">
              <mat-grid-tile><h2 class="full-width full-height">Payment Method</h2></mat-grid-tile>
              <mat-grid-tile><h2 class="full-width full-height">Assigned Accountant</h2></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="6:1" >
              <ng-container *ngFor="let stControl of accountantAssignmentsFormGroup">
                <mat-grid-tile>
                  <div class="full-width full-height">
                    {{stControl.value.paymentMethod?.name}}
                    <button mat-icon-button *ngIf="showExport(stControl)" appLoading color="primary"
                            matTooltip="Save and download" [disabled]="saveDisabled"
                            [loading]="isExportLoading$ | async" (click)="onExportClicked()">
                      <mat-icon>get_app</mat-icon>
                    </button>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <div class="payment-method-cell full-width full-height" >
                    <mat-select required
                                [disabled]="!hasEditPermission"
                                [placeholder]="'Assign Batch To... *'"
                                [value]="stControl.get('accountant').value"
                                (valueChange)="accountantSelectionChanged(stControl, $event)" >
                      <mat-option *ngFor="let option of accountants" [value]="option">
                        {{option.name}} {{option.lastName}}
                      </mat-option>
                    </mat-select>
                  </div>
                </mat-grid-tile>
              </ng-container>
            </mat-grid-list>
          </div>

          <div *ngIf="paymentPatchStationsFormGroups.length > 0">
            <mat-grid-list cols="2" rowHeight="7:1">
              <mat-grid-tile><h2 class="full-width full-height">Station</h2></mat-grid-tile>
              <mat-grid-tile><h2 class="full-width full-height">Payment Method</h2></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="6:1" >
              <ng-container *ngFor="let stControl of paymentPatchStationsFormGroups">
                <mat-grid-tile>
                  <div class="full-width full-height">
                    <div class="bolder">{{stControl.value.station.name}}</div>
                    <div class="station-company">{{stControl.value.station.parentCompany?.name || ''}}</div>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <div class="payment-method-cell full-width full-height">
                    <span *ngIf="stControl.value.paymentMethod">{{stControl.value.paymentMethod.name}}</span>
                    <span *ngIf="!stControl.value.paymentMethod" class="red">Payment method is not selected!</span>
                    <app-payment-selector [options]="paymentMethods"
                                          [inputValue]="stControl.value.paymentMethod ? stControl.value.paymentMethod : null"
                                          (propagateChange)="onPaymentMethodChanged($event, stControl)"
                                          *ngIf="hasEditPermission"
                    ></app-payment-selector>
                  </div>
                </mat-grid-tile>
              </ng-container>
            </mat-grid-list>
          </div>

          <h2 *ngIf="checkRequest?.notes">Notes</h2>
          <div *ngFor="let note of notes" class="note">
            <div><b>{{note | noteHeader}}</b></div>
            <div><span>{{note.message}}</span></div>
          </div>
          <div *ngIf="assignmentSummaryEntriesGlobal" class="summary-container">
            <app-batch-assignment-summary
              [assignmentSummaryEntries]="currentAssignmentSummaryEntries"></app-batch-assignment-summary>
          </div>
        </div>
        <div class="form-content_column">
          <div *ngIf="checkRequest">
            <div class="filter-row" [formGroup]="filters">
              <mat-form-field>
                <mat-label>Payment method</mat-label>
                <mat-select formControlName="paymentMethod">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let option of paymentMethods" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-checkbox class="checkbox-control" formControlName="emptyQB">
                Missing QB number
              </mat-checkbox>
            </div>
            <app-payment-batch-station
              *ngFor="let payment of filteredPayments; let i = index;"
              [index]="i"
              [payment]="payment"
              [mode]="mode"
              [paymentBatchId]="form.get('id').value"
              [readonly]="isPaymentBatchStationReadonly"
              (flightFormChange)="onFlightFormChange($event, i)"
              (batchDocumentationChange)="onBatchDocumentationChange($event, payment.id)"
            ></app-payment-batch-station>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button type="button" mat-raised-button appLoading color="primary"
              [loading]="declining" [disabled]="isRequesting" (click)="declineBatch()"
              *ngIf="canDecline"
      >Decline</button>
      <button mat-raised-button appLoading color="primary" *ngIf="hasEditPermission"
        [loading]="saving" [disabled]="saveDisabled"
      >Save</button>
      <button mat-raised-button [disabled]="isRequesting" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

