<div class="content" role="main">

  <div class="header">
    <h1>Payment Batches</h1>
    <button mat-mini-fab color="primary" aria-label="New Payment Batch" *ngIf="!isSelectMode && hasCreatePermission" [routerLink]="['/orders/payments/edit', '']">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div *ngIf="assignmentSummary" class="summary-container" >
    <app-batch-assignment-summary [assignmentSummaryEntries]="assignmentSummary"></app-batch-assignment-summary>
  </div>

  <div class="dashboard-container" *ngIf="!isSelectMode">
    <div class="table-range-filter">
      <mat-form-field>
        <mat-label>Filter Table by Date</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matInput matStartDate formControlName="start" placeholder="Start date">
          <input matInput matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <div class="container" [ngClass]="{'mat-elevation-z8': !isSelectMode}">
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <div class="table-container" [ngClass]="{'no-data-table-container': !dataSource.filteredData.length}">
      <table mat-table [dataSource]="dataSource" matSort [formGroup]="tableFilterForm">

        <ng-container matColumnDef="select" sticky>
          <th class="select" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="select-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div class="table-filter">
              <div class="form-field"></div>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="code" sticky>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment Batch#</th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="['/orders/payments/edit', row.id]" *ngIf="!isSelectMode">{{row.code}}</a>
            <span *ngIf="isSelectMode">{{row.code}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="code-filter" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="code"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="requestCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Request#</th>
          <td mat-cell *matCellDef="let row">
            <a [routerLink]="['/orders/checks/detail', row.checkRequest?.id]" *ngIf="!isSelectMode">{{row.checkRequest?.code}}</a>
            <span *ngIf="isSelectMode">{{row.checkRequest?.code}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="requestCode-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="requestCode"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="orderCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Orders#</th>
          <td mat-cell *matCellDef="let row">{{getPaymentBatchOrders(row) || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="orderCode-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="orderCode"/>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.status?.status || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="status-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="status" multiple>
                <mat-option *ngFor="let status of statuses" [value]="status">
                  {{status}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Name</th>
          <td mat-cell *matCellDef="let row">{{row.checkRequest?.account?.name || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="accountName-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="accountName">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account ID</th>
          <td mat-cell *matCellDef="let row">{{row.checkRequest?.account?.code || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="accountId-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="accountId">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="accountType">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Type</th>
          <td mat-cell *matCellDef="let row">{{getAccountType(row.checkRequest?.account) || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="accountType-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="accountType">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
                  {{accountType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="quickBooks">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>QuickBooks Reference Numbers</th>
          <td mat-cell *matCellDef="let row">{{getQuickBooksRefNumbers(row)}}</td>
        </ng-container>

        <ng-container matColumnDef="quickBooks-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <mat-select class="form-field" formControlName="quickBooks">
                <mat-option>-- None --</mat-option>
                <mat-option value="missing">Missing QB number</mat-option>
                <mat-option value="completed"> Completed QB number</mat-option>
              </mat-select>
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="paidFlights">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assigned Flights Paid</th>
          <td mat-cell *matCellDef="let row">{{getAssignedFlightsNumber(row)}}</td>
        </ng-container>

        <ng-container matColumnDef="paidFlights-filter">
          <th mat-header-cell *matHeaderCellDef>
          </th>
        </ng-container>

        <ng-container matColumnDef="flightDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Earliest Unpaid Flight (date)</th>
          <td mat-cell *matCellDef="let row">{{row.summary?.earliestUnpaidFlightOn || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="flightDays">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Earliest Unpaid Flight<br>(days ago)</th>
          <td mat-cell *matCellDef="let row">{{row.summary?.earliestUnpaidFlightDaysFromNow || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="balanceAnticipated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Balance Anticipated</th>
          <td mat-cell *matCellDef="let row">{{'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="assignedAccountant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assigned accountant</th>
          <td mat-cell *matCellDef="let row">
            <span>{{getAssignedAccountant(row) || 'N/A'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="assignedAccountant-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="assignedAccountant">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let row">{{(row.createdAt | date:'short') || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Creator</th>
          <td mat-cell *matCellDef="let row">{{row.createdBy || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="createdBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Edit</th>
          <td mat-cell *matCellDef="let row">{{(row.lastUpdatedAt | date:'short') || 'N/A'}}</td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Edited By</th>
          <td mat-cell *matCellDef="let row">
            <span class="pull-left">{{row.lastUpdatedBy || 'N/A'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy-filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="table-filter">
              <input matInput class="form-field" formControlName="lastUpdatedBy">
            </mat-form-field>
          </th>
        </ng-container>

        <ng-container matColumnDef="menu" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"></td>
        </ng-container>

        <ng-container matColumnDef="menu-filter" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <button mat-icon-button class="button pull-right" (click)="clearFilterForm()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                <path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/>
              </svg>
            </button>
          </th>
        </ng-container>

        <ng-container *ngFor="let digitFilter of digitFilters" [matColumnDef]="digitFilter.filterDef">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" [formGroupName]="digitFilter.name">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="digit-filter-input">
                <input matInput
                       type="number"
                       class="form-field"
                       formControlName="value"
                       [readonly]="false">
              </mat-form-field>
            </div>
          </th>
        </ng-container>

        <ng-container *ngFor="let dateFilter of dateFilters; let i = index;" [matColumnDef]="dateFilter.filterDef">
          <th mat-header-cell *matHeaderCellDef>
            <div class="digit-filter-row" [formGroupName]="dateFilter.name">
              <mat-select class="form-field digit-filter-dropdown" [placeholder]="'None'"
                          formControlName="symbol">
                <mat-option value="=">{{'='}}</mat-option>
                <mat-option value=">">{{'>'}}</mat-option>
                <mat-option value="<">{{'<'}}</mat-option>
              </mat-select>
              <mat-form-field class="table-filter digit-filter-input" color="accent">
                <input matInput [matDatepicker]="i" formControlName="value">
                <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
              </mat-form-field>
            </div>
          </th>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="columnFilters; sticky: true" class="filter-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-container *ngIf="!dataSource.filteredData.length">
        <p class="no-data">No data</p>
      </ng-container>
    </div>

    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
