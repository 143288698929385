import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { STATIONS, TYPEAHEAD } from '../../core/apiUrls';
import { Account, Station, StationsFilter, TransactionsReport, TransactionsReportFilter } from './models';
import { TypeaheadItem } from '../../shared/models/typeahead-item';
import { StationListPage, StationsBrowsingFilter } from './models/station-paged';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  constructor(private http: HttpClient) {
  }

  getStationList(): Observable<Station[]> {
    return this.http.get<Station[]>(STATIONS);
  }

  getStationPaged(filter: StationsBrowsingFilter, calculateBalance = false): Observable<StationListPage> {
    let httpParams = new HttpParams();
    if (calculateBalance) {
      httpParams = httpParams.append('calculateBalance', calculateBalance.toString());
    }
    return this.http.post<StationListPage>(`${STATIONS}/paged`, filter, {params: httpParams});
  }

  getStation(id: string): Observable<Station> {
    return this.http.get<Station>(`${STATIONS}/${id}`);
  }

  createStation(station: Station): Observable<Station> {
    return this.http.post<Station>(STATIONS, station);
  }

  updateStation(station: Station): Observable<Station> {
    return this.http.put<Station>(`${STATIONS}/${station.id}`, station);
  }

  exportsStations(filter: StationsBrowsingFilter): Observable<ArrayBuffer> {
    // @ts-ignore
    return this.http.post<ArrayBuffer>(`${STATIONS}/paged/export`, filter, {responseType: 'blob'});
  }

  getDocument(id: number, documentId: number): Observable<any> {
    return this.http.get(
      `${STATIONS}/${id}/documents/${documentId}/download`,
      {observe: 'response', responseType: 'blob'}
    );
  }

  uploadDocument(id: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post(`${STATIONS}/${id}/documents`, fd);
  }

  deleteDocument(id: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${STATIONS}/${id}/documents/${documentId}`);
  }

  getFilteredStations(filter: StationsFilter): Observable<Station[]> {
    return this.http.post<Station[]>(`${STATIONS}/filter`, filter);
  }

  getStationAccounts(id: string): Observable<Account[]> {
    return this.http.get<Account[]>(`${STATIONS}/${id}/accounts`);
  }

  getStationTransactionsReport(id: string, filter: TransactionsReportFilter): Observable<TransactionsReport> {
    return this.http.post<TransactionsReport>(`${STATIONS}/${id}/transactions/preview`, filter);
  }

  generateExcelStationTransactionsReport(id: string, filter: TransactionsReportFilter): Observable<HttpResponse<any>> {
    // @ts-ignore
    return this.http.post(`${STATIONS}/${id}/transactions/preview/export`, filter,
      {observe: 'response' as 'body', responseType: 'blob'});
  }

  getTypeaheadStationList(term?: string): Observable<TypeaheadItem[]> {
    let httpParams = new HttpParams();
    if (term) {
      httpParams = httpParams.set('nameStartsWith', term);
    }
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}/stations`, {params: httpParams});
  }
}
