<div class="content" role="main">
  <div class="header">
    <button mat-icon-button [routerLink]="['/entities/parentCompany']">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Parent Company</h1>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="parentCompanyForm" (ngSubmit)="submit(parentCompanyForm.value)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <mat-form-field>
            <mat-label>Parent Company Name</mat-label>
            <input formControlName="name" matInput required maxlength="50">
            <mat-error *ngIf="parentCompanyForm.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="parentCompanyForm.get('name').hasError('validation')">
              {{parentCompanyForm.get('name').getError('validation')}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Media Type</mat-label>
            <mat-select formControlName="mediaTypes" [compareWith]="compareMediaTypes" multiple>
              <mat-option *ngFor="let option of mediaTypes" aria-selected="true" [value]="option">
                {{option.type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Parent Company Address Line 1</mat-label>
            <input formControlName="addressLine1" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Parent Company Address Line 2</mat-label>
            <input formControlName="addressLine2" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Parent Company Address Line 3</mat-label>
            <input formControlName="addressLine3" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput maxlength="50">
          </mat-form-field>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let option of states" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>ZIP Code</mat-label>
                <input formControlName="zipCode" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 1</mat-label>
                <input matInput formControlName="phone1" appPhoneMask maxlength="14">
                <mat-error *ngIf="addressFormGroup.get('phone1').hasError('pattern')">
                  Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 1 Extension</mat-label>
                <input formControlName="phone1Ext" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 2</mat-label>
                <input matInput formControlName="phone2" appPhoneMask maxlength="14">
                <mat-error *ngIf="addressFormGroup?.get('phone2')?.hasError('pattern')">
                  Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 2 Extension</mat-label>
                <input formControlName="phone2Ext" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Fax 1</mat-label>
                <input formControlName="fax1" matInput maxlength="20">
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Fax 1 Extension</mat-label>
                <input formControlName="fax2" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Contact Name</mat-label>
            <input formControlName="contactName" matInput maxlength="50">
          </mat-form-field>
<!--          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Contact Title</mat-label>
            <input type="text" formControlName="contactTitle" matInput
                   #titleInput
                   (blur)="onBlurTitle(titleInput)"
                   [matAutocomplete]="autoAccountant">
            <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayTitle">
              <mat-option *ngFor="let option of filteredTitles | async" [value]="option"
                          (mousedown)="onTitleOptionSelect(option)">
                {{option.title}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>-->
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Contact Email Address</mat-label>
            <input formControlName="contactEmail" matInput maxlength="50" placeholder="Ex. pat@example.com">
            <mat-error
              *ngIf="addressFormGroup?.get('contactEmail')?.touched && addressFormGroup?.get('contactEmail')?.hasError('pattern')">
              Please enter a valid email address
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-content_column">
<!--          <div class="disable-account-checkbox">
            <mat-checkbox class="checkbox-control" formControlName="disabled">Disable Account</mat-checkbox>
          </div>-->

          <div *ngIf="mode === 1">
            <h2>List of Associated Stations</h2>
            <div class="table-container">
              <table mat-table matSort [dataSource]="dataSource" [formGroup]="tableFilterForm">
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="select-filter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="table-filter">
                      <div class="form-field"></div>
                    </div>
                  </th>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let row">{{row.name}}</td>
                </ng-container>

                <ng-container matColumnDef="name-filter">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field class="table-filter">
                      <input matInput class="form-field" placeholder="Name Filter" formControlName="name">
                    </mat-form-field>
                  </th>
                </ng-container>

                <ng-container matColumnDef="stationType">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>Media Type</th>
                  <td mat-cell *matCellDef="let row">{{row.mediaType?.type}}</td>
                </ng-container>

                <ng-container matColumnDef="stationType-filter">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field class="table-filter">
                      <mat-select class="form-field" placeholder="Station type Filter" formControlName="stationType">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let stationType of stationTypes" [value]="stationType">
                          {{stationType}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </th>
                </ng-container>

                <ng-container matColumnDef="menu">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="menu-filter">
                  <th mat-header-cell *matHeaderCellDef>
                    <button type="button" mat-icon-button class="button pull-right" (click)="clearFilterForm()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                        <path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/>
                      </svg>
                    </button>
                  </th>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
                <tr mat-header-row *matHeaderRowDef="columnFilters" class="filter-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <ng-container *ngIf="!dataSource.data.length">
                <p class="no-data">No data</p>
              </ng-container>
              <mat-paginator *ngIf="dataSource.data.length > 10" [length]="dataSource.data.length"
                             [pageSize]="10"></mat-paginator>
            </div>
          </div>

          <h2>Preferred Payment method</h2>
          <mat-form-field>
            <mat-select formControlName="paymentMethod">
              <mat-option *ngFor="let option of paymentMethods" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Payment URL</mat-label>
            <input formControlName="paymentGuidelinesUrl" matInput maxlength="150">
            <mat-error
              *ngIf="parentCompanyForm.get('paymentGuidelinesUrl')?.touched && parentCompanyForm.get('paymentGuidelinesUrl')?.hasError('pattern')">
              Please enter a valid url
            </mat-error>
          </mat-form-field>

          <h2>Parent Company Documentation</h2>
          <app-file-upload
            [filesUploadedBefore]="documentsUploadedBefore"
            (filesChanges)="setDocumentation($event)"
            [mode]="mode"
            [id]="parentCompanyID"
            [component]="component"
            [readonly]="!hasEditPermission"
          >
          </app-file-upload>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button id="submit_btn" mat-raised-button appLoading [loading]="saving" color="primary" [disabled]="parentCompanyForm.invalid" *ngIf="hasEditPermission">
        Save
      </button>
      <button type="button" mat-raised-button appLoading color="primary"
              [disabled]="parentCompanyForm.invalid || saving" (click)="submit(parentCompanyForm.value, true)"
      >Save and Add New
      </button>
      <button mat-raised-button [disabled]="saving" [routerLink]="['/entities/parentCompany']">Cancel</button>
    </mat-card-actions>
  </form>
</div>
