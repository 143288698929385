import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { Order, OrderStatus, orderStatuses } from '../shared/models';
import { NotificationService } from 'src/app/shared/notification.service';
import { OrderService } from '../shared/order.service';
import { OrderDictionaryService } from '../shared/order-dictionary.service';
import { AuthService } from '../../shared/auth.service';
import { Role } from '../../shared/models/role.enum';
import { Action } from '../../shared/models/action.enum';

import { Filename } from 'src/app/shared/models/filename.enum';
import { ReportsUtil } from 'src/app/shared/utils/reports-util';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  orderData: Order;
  loading = true;
  approving = false;
  declining = false;
  nabLoading = false;

  hasEditPermission = false;

  constructor(private route: ActivatedRoute,
              private notificationService: NotificationService,
              private dictionary: OrderDictionaryService,
              private orderService: OrderService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.router.navigate(['/orders/orders']);
      return;
    }
    const [accountId, orderId] = id?.split('-');
    this.orderService.getOrder(accountId, orderId, true).subscribe(
      order => {
        this.orderData = order;
        this.hasEditPermission = order.permissions?.includes(Action.EDIT);
        this.sortBySortNumber('notes');
        this.sortBySortNumber('flights');
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get order details')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.router.navigate(['/orders/orders']);
    }
  }

  get isRequesting() {
    return this.approving || this.declining || this.nabLoading;
  }

  getOrderStatus(submitted: boolean): OrderStatus {
    return submitted ? orderStatuses.SUBMITTED : orderStatuses.DRAFT;
  }

  sortBySortNumber(prop: string) {
    if (!this.orderData[prop]) {
      return;
    }

    this.orderData[prop] = this.orderData[prop]
      .sort((item1, item2) => (item1.sortNumber < item2.sortNumber) ? -1 : 1);
  }

  getSummaryAmountDue(flightStations) {
    return flightStations.reduce((sum, currentFlightStation) => {
      return sum + currentFlightStation.netAmount;
    }, 0);
  }

  get amountDueTotal() {
    return this.orderData.flights
      .map(flight => this.getSummaryAmountDue(flight.flightStations))
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get versions() {
    return this.orderData?.versions ?? [];
  }

  save(submit?: boolean, navigateToCreateCheckRequest?: boolean) {
    if (submit) {
      this.orderData.submitted = true;
    }

    this.orderService
      .updateOrder(String(this.orderData.account.id), String(this.orderData.id), this.orderData)
      .pipe(finalize(() => this.resetRequesting()))
      .subscribe(
        (order) => {
          this.orderData = order;
          this.notificationService.success('Order was updated.');
          if (navigateToCreateCheckRequest) {
            this.createCheckRequest();
          }
        },
        () => this.notificationService.error(`Error occurred while trying to update order.`)
      );
  }

  resetRequesting() {
    this.approving = false;
    this.declining = false;
  }

  approve() {
    this.approving = true;
    this.save(true);
  }

  approveAndCreateCheckRequest() {
    this.approving = true;
    this.save(true, true);
  }

  createCheckRequest() {
    this.router.navigate(['/orders/checks/edit/', ''],
      { queryParams: {orderId: this.orderData.id, accountId: this.orderData.account.id} });
  }

  get canSubmit() {
    return this.hasEditPermission && this.orderData && !this.orderData.submitted;
  }

  get canCreateCheckRequest() {
    const permissions = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
    return permissions && this.orderData && this.orderData.submitted;
  }

  get canDownloadNABNeeded() {
    const permissions = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT]);
    const allowForType = this.orderData && ((this.orderData.account as any)?.candidate || (this.orderData.account as any)?.advocacy);
    return permissions && allowForType && this.orderData;
  }

  downloadNABForm() {
    this.nabLoading = true;
    this.orderService.downloadOrderNABForm(this.orderData.account.id, this.orderData.id)
      .subscribe(
        data => {
          const filename = data.headers.get('filename');
          ReportsUtil.downloadFile(data.body, filename);
          this.nabLoading = false;
        },
        () => {
          this.notificationService.error('Error occurred while trying to download NAB form.');
          this.nabLoading = false;
        }
      );
  }
}
