import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from '../../shared/models/role.enum';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { TransactionService } from '../shared/transaction.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { getClientCandidateType, getLeadConsultantName, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { ConsultantCompanyCommissionService } from '../shared/consultant-company-commission.service';
import { ConsultantCompanyCommission } from '../shared/models';
import { DateUtils } from '../../shared/utils/date-utils';
import { Account } from '../../entities/shared/models';
import {
  dateFiltersOwed, dateFiltersPaid,
  digitFiltersOwed,
  digitFiltersPaid,
  displayedColumnsOwed, displayedColumnsPaid, sortingDataAccessorOwed, sortingDataAccessorPaid,
  tableFilterFormOwed,
  tableFilterFormPaid
} from './data-source-consts';
import { CommissionUtil } from '../shared/utils/commission-util';

import { CommissionVersionsDialogComponent } from '../commission-versions-dialog/commission-versions-dialog.component';

@Component({
  selector: 'app-commission-list',
  templateUrl: './commission-list.component.html',
  styleUrls: ['./commission-list.component.scss']
})
export class CommissionListComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[];
  digitFilters = [];
  dateFilters = [];
  tableFilterForm = null;

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  start: Date | null;
  end: Date | null;

  tableTypes = {COMMISSION_PAID: 'COMMISSION_PAID', COMMISSION_OWED: 'COMMISSION_OWED'};
  tableTypeControl = new FormControl(this.tableTypes.COMMISSION_OWED);

  isLoadingResults = false;
  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);
  initializing = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private transactionService: TransactionService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private dialog: MatDialog,
              private service: ConsultantCompanyCommissionService) {
  }

  ngOnInit(): void {
    const type = this.route.snapshot.queryParamMap.get('type');  // Filters in case to go from dashboard
    if (type) {
      this.tableTypeControl.setValue(type);
    }

    this.initData(this.tableTypeControl.value, true);
    this.manageDateFilter();

    this.tableTypeControl.valueChanges.subscribe((tableType) => {
      this.tableTypeControl.patchValue(tableType, {emitEvent: false});
      this.initData(tableType);
    });
  }

  applyDashboardFilter = () => {
    const quickBookReference = this.route.snapshot.queryParamMap.get('quickBooks');  // Filters in case to go from dashboard
    const status = this.route.snapshot.queryParamMap.get('status');  // Filters in case to go from dashboard
    if (quickBookReference && status) {
      this.tableFilterForm.patchValue({quickBookReference, status});
    }
    const type = this.route.snapshot.queryParamMap.get('type');  // Filters in case to go from dashboard
    if (type) {
      const unbalanceCommissionOwed = {symbol: '!', value: '0'};
      this.tableFilterForm.patchValue({commissionOwed: unbalanceCommissionOwed});
    }
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          this.isLoadingResults = true;
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.service.getCommissionList(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe((data) => {
          this.dataSource.data = sortListByLastEdit(data);
          this.isLoadingResults = false;
        },
        () => this.notificationService.error('Error occurred while trying to get commission list.'));
  }

  initData(tableType: string, first: boolean = false) {
    this.isLoadingResults = true;
    this.dataSource = new MatTableDataSource<any>();

    if (tableType === this.tableTypes.COMMISSION_PAID) {
      this.service.getCommissionList().subscribe((data: any[]) => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
        if (first) {
          setTimeout(this.applyDashboardFilter);
        }
      }, () => {
      });

      this.displayedColumns = displayedColumnsPaid;
      this.digitFilters = digitFiltersPaid;
      this.dateFilters = dateFiltersPaid;
      this.tableFilterForm = tableFilterFormPaid;
      this.dataSource.sortingDataAccessor = sortingDataAccessorPaid;

    } else {
      this.service.getAccountsWithCommissionBalance().subscribe((data: any[]) => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
        if (first) {
          setTimeout(this.applyDashboardFilter);
        }
      }, () => {
      });

      this.displayedColumns = displayedColumnsOwed;
      this.digitFilters = digitFiltersOwed;
      this.dateFilters = dateFiltersOwed;
      this.tableFilterForm = tableFilterFormOwed;
      this.dataSource.sortingDataAccessor = sortingDataAccessorOwed;
    }

    this.clearFilterForm();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();

    this.tableFilterForm.valueChanges.subscribe(value => {
      if (this.isCommissionPaidTableType) {
        value.createdAt.value = value.createdAt?.value?.toString();
        value.completedOn.value = value.completedOn?.value?.toString();
        value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
        value.amount.value = value.amount?.value?.toString();
      } else {
        value.commissionOwed.value = value.commissionOwed?.value?.toString();
      }

      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });
  }

  get statuses() {
    return ['Paid', 'Submitted'];
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data
      .map(item => this.isCommissionPaidTableType ? item.account : item)
      .filter(item => !!item)
      .map(item => this.getAccountType(item))
    )];
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get isCommissionPaidTableType(): boolean {
    return this.tableTypeControl.value === this.tableTypes.COMMISSION_PAID;
  }

  getAssignedAccountant(commission: ConsultantCompanyCommission) {
    return CommissionUtil.getAssignedAccountant(commission);
  }

  getAccountCode(row) {
    return this.isCommissionPaidTableType ? row.account?.code : row?.code;
  }

  getAccountTypeString(row) {
    return this.isCommissionPaidTableType ? (this.getAccountType(row.account) || 'N/A') : (this.getAccountType(row) || 'N/A');
  }

  getStatus(commission: ConsultantCompanyCommission) {
    return CommissionUtil.getStatus(commission);
  }

  onAddConsultantCommission(row?: Account) {
    if (row) {
      const isSingleConsultantCompany = row.consultantCompanies?.length === 1;
      const queryParams = isSingleConsultantCompany
        ? { accountId: row.id, consultantCompanyId: row.consultantCompanies[0].id }
        : { accountId: row.id };
      this.router.navigate(['/orders/commissions/edit', ''],
         {queryParams}).then();
    } else {
      this.router.navigate(['/orders/commissions/edit', '']).then();
    }
  }

  editCommission(row) {
    this.router.navigate(['/orders/commissions/edit', row.id],
      {queryParams: {consultantCompanyId: row.consultantCompany.id}}).then();
  }

  showVersions(id: number, consultantCompanyId: number) {
    this.dialog.open(CommissionVersionsDialogComponent, { data: { id, consultantCompanyId } });
  }

  getAccountType(account: Account) {
    if (!account) {
      return '';
    }
    return getClientCandidateType(account);
  }

  getLeadConsultantName(account: Account) {
    if (!account) {
      return '';
    }
    return getLeadConsultantName(account);
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const accountCodeSearch = FilterUtils.searchFunction(searchTerms.accountCode, data.account?.code);
      const accountCodeSearchOWED = FilterUtils.searchFunction(searchTerms.accountCode, data.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account?.name);
      const accountNameSearchOWED = FilterUtils.searchFunction(searchTerms.accountName, data.name);
      const consultantNameSearch = FilterUtils.searchFunction(searchTerms.consultantName, data.consultantCompany?.name);
      const consultantNameSearchOWED = FilterUtils.searchFunction(searchTerms.consultantName, this.getLeadConsultantName(data));
      const consultantCodeSearch = FilterUtils.searchFunction(searchTerms.consultantCode, data.leadConsultantCompanyCode);
      const quickBookReferenceSearch = FilterUtils.specificQuickBooksSearch(searchTerms.quickBookReference, data.quickBookReference);
      const assignedAccountantSearch = FilterUtils.searchFunction(searchTerms.assignedAccountant, this.getAssignedAccountant(data));

      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.account));
      const accountTypeSearchOWED = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data));
      const statusSearch = FilterUtils.createSelectSearch(searchTerms.status, this.getStatus(data));

      const amountSearch = FilterUtils.createFieldDigitSearch('amount', searchTerms, data.toPayAmount);
      const commissionOwedSearch = this.createCommissionOwedSearch(searchTerms, data);

      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const editedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);
      const createdSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const completedOnSearch = FilterUtils.createDateSearch('completedOn', 'completedOn', searchTerms, data);

      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);

      const filterFunctions = this.isCommissionPaidTableType
        ? [
          codeSearch(),
          accountCodeSearch(),
          accountNameSearch(),
          consultantNameSearch(),
          quickBookReferenceSearch(),
          assignedAccountantSearch(),
          accountTypeSearch(),
          statusSearch(),
          amountSearch(),
          lastUpdatedAtSearch(),
          editedBySearch(),
          createdSearch(),
          createdBySearch(),
          completedOnSearch()
        ]
        : [
          consultantNameSearchOWED(),
          consultantCodeSearch(),
          accountCodeSearchOWED(),
          accountNameSearchOWED(),
          accountTypeSearchOWED(),
          commissionOwedSearch()
        ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  createCommissionOwedSearch = (searchTerms: any, data: any) => () => {
    const searchTermName = 'commissionOwed';
    if (!searchTerms[searchTermName].symbol || !searchTerms[searchTermName].value) {
      return true;
    }

    const fieldData = data.accountSummary?.commissionOwed;
    return FilterUtils.compare(searchTermName, searchTerms, fieldData);
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
