import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { STATION_TRANSACTIONS } from '../../core/apiUrls';
import { StationInvoice, StationInvoiceExpense } from './models';

const STATION_INVOICES = `${STATION_TRANSACTIONS}/stationInvoices`;

@Injectable({
  providedIn: 'root'
})
export class StationInvoiceService {

  constructor(private http: HttpClient) { }

  createInvoice(invoice: StationInvoice): Observable<StationInvoice> {
    return this.http.post<StationInvoice>(`${STATION_INVOICES}`, invoice);
  }

  updateInvoice(invoice: StationInvoice): Observable<StationInvoice> {
    return this.http.put<StationInvoice>(`${STATION_INVOICES}/${invoice.id}`, invoice);
  }

  getInvoices(createdAfter?: string, createdBefore?: string): Observable<StationInvoice[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }

    return this.http.get<StationInvoice[]>(`${STATION_INVOICES}`, { params: httpParams });
  }

  getInvoiceVersions(invoiceId: number): Observable<StationInvoice[]> {
    return this.http.get<StationInvoice[]>(`${STATION_INVOICES}/${invoiceId}/versions`);
  }

  getInvoice(id: string): Observable<StationInvoice> {
    return this.http.get<StationInvoice>(`${STATION_INVOICES}/${id}`);
  }

  getListOfStationInvoiceExpenses(accountId: string, stationId: string): Observable<StationInvoiceExpense[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('stationId', stationId);
    httpParams = httpParams.set('accountId', accountId);
    return this.http.get<StationInvoiceExpense[]>(`${STATION_INVOICES}/expenses`, { params: httpParams});
  }

  getStationInvoiceDocument(stationInvoiceId: number, documentId: number): Observable<any> {
    return this.http.get(
      `${STATION_INVOICES}/${stationInvoiceId}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(stationInvoiceId: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<StationInvoice>(`${STATION_INVOICES}/${stationInvoiceId}`, fd);
  }

  deleteStationInvoiceDocument(stationInvoiceId: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${STATION_INVOICES}/${stationInvoiceId}/documents/${documentId}`);
  }
}
