import { OrderFlight } from '../models';

export class FlightUtil {
  static flightCompareFn = (a: OrderFlight, b: OrderFlight): number => {
    const ftcOnA = new Date(a.ftcOn);
    const ltcOnA = new Date(a.ltcOn);
    const ftcOnB = new Date(b.ftcOn);
    const ltcOnB = new Date(b.ltcOn);
    return ftcOnA < ftcOnB ? -1 : ftcOnA > ftcOnB ? 1 : (
      ltcOnA < ltcOnB ? -1 : ltcOnA > ltcOnB ? 1 : 0
    );
  }

  static flightSortNumberCompareFn = (a, b) => {
    return a.sortNumber < b.sortNumber ? -1 : a.sortNumber > b.sortNumber ? 1 : 0;
  }
}
