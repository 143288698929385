export class ComposeRouteUtils {
  static SEPARATOR = '-';

  static separateAccountId = (routeParam: string) => {
    return routeParam?.split(ComposeRouteUtils.SEPARATOR)[0];
  }

  static separateEntityId = (routeParam: string) => {
    return routeParam?.split(ComposeRouteUtils.SEPARATOR)[1];
  }
}
