import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { ConsultantCompanyCommission } from '../shared/models';
import { ConsultantCompanyCommissionService } from '../shared/consultant-company-commission.service';

@Component({
  selector: 'app-commission-versions-dialog',
  templateUrl: './commission-versions-dialog.component.html',
  styleUrls: ['./commission-versions-dialog.component.scss']
})
export class CommissionVersionsDialogComponent implements OnInit {
  isLoadingResults = true;
  displayedColumns: string[] = ['version', 'amount', 'completedOn', 'quickBookReference', 'versionCreatedAt', 'versionAuthor'];
  dataSource = new MatTableDataSource<ConsultantCompanyCommission>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {id: number, consultantCompanyId: number},
              private service: ConsultantCompanyCommissionService) { }

  ngOnInit(): void {
    this.service.getCommissionVersions(this.data.consultantCompanyId, this.data.id).pipe(take(1))
      .subscribe(data => {
        this.dataSource.data = data;
        this.isLoadingResults = false;
      });
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

}
