import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() handles = [];
  @ViewChild('handleContainer') handleContainer: ElementRef;
  @ViewChild('rangeSlider') rangeSlider: ElementRef;
  @Output() loadNext = new EventEmitter<void>();
  htmlHandles = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.handles.forEach((handle) => {
      const left = this.calculateLeftShift(handle);
      this.htmlHandles += `<div class="handle" style="left: ${left}px;"></div>`;
    });
    this.handleContainer.nativeElement.innerHTML = this.htmlHandles;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.handles.previousValue) {
      if (this.isValid()) {
        this.handles.forEach((handle) => {
          const left = (handle / 0.15) * this.rangeSlider.nativeElement.offsetWidth / 100;
          this.htmlHandles += `<div class="handle" style="left: ${left}px;"></div>`;
        });
        this.handleContainer.nativeElement.innerHTML = this.htmlHandles;
        this.next();
      }
    }
  }

  isValid(): boolean {
    let result = true;
    if (this.handles.find((el) => el < 0 || el > 15) ) {
      result = false;
    }
    return result;
  }

  calculateLeftShift(handle: number): number {
    return (handle / 0.15) * this.rangeSlider.nativeElement.offsetWidth / 100;
  }

  next() {
    this.loadNext.emit();
  }
}
