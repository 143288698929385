import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-transaction-report-table',
  templateUrl: './account-transaction-report-table.component.html',
  styleUrls: ['./account-transaction-report-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccountTransactionReportTableComponent implements OnInit {
  @Input() reports;
  @Input() isOrderReport = true;

  dataSource;

  displayedColumns: string[] = [
    'stationNameType',
    'ftcOn', 'ltcOn',
    'transactionType', 'transactionDate', 'transactionId',
    'quickBookReference', 'status',
    'grossOrdered', 'netOrdered', 'expense', 'paid', 'vendorMemoApplied', 'vendorMemoAvailable', 'refund', 'invoiced', 'balance'
  ];

  displayedTotalColumns: string[] = [
    'stationNameType', 'grossOrdered', 'netOrdered', 'expense', 'paid',
    'vendorMemoApplied', 'vendorMemoAvailable', 'refund', 'invoiced', 'balance'
  ];

  constructor() { }

  ngOnInit(): void {
    if (this.isOrderReport) {
      this.displayedColumns = this.displayedColumns.filter(columnName => {
        return columnName !== 'expense' && columnName !== 'invoiced';
      });

      this.displayedTotalColumns = this.displayedTotalColumns.filter(columnName => {
        return columnName !== 'expense' && columnName !== 'invoiced';
      });
    }

    const elements = [];

    this.reports.forEach(report => {
      const element = { ...report, rows: [] };

      report.stationFlights.forEach(stationFlight => {
        element.rows.push(stationFlight, ...stationFlight.stationFlightTransactions);

        element.rows.push({
          grossOrdered: stationFlight.grossOrdered,
          netOrdered: stationFlight.netOrdered,
          expense: this.getTotal(stationFlight.stationFlightTransactions, 'expense'),
          paid: this.getTotal(stationFlight.stationFlightTransactions, 'paid'),
          vendorMemoApplied: this.getTotal(stationFlight.stationFlightTransactions, 'vendorMemoApplied'),
          vendorMemoAvailable: this.getTotal(stationFlight.stationFlightTransactions, 'vendorMemoAvailable'),
          refund: this.getTotal(stationFlight.stationFlightTransactions, 'refund'),
          invoiced: this.getTotal(stationFlight.stationFlightTransactions, 'invoiced'),
          balance: this.getTotal(stationFlight.stationFlightTransactions, 'balance'),
          total: true
        });
      });

      elements.push(element);
    });

    this.dataSource = elements;
  }

  getTotal(items, fieldName) {
    return items.reduce((total, item) => total + item[fieldName], 0);
  }
}
