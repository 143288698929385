import { Pipe, PipeTransform } from '@angular/core';
import { Note } from '../../orders/shared/models';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'noteHeader'
})
export class NoteHeaderPipe implements PipeTransform {

  transform(note: Note, ...args: unknown[]): string {
    const pipe = new DatePipe('en-US');
    const formattedDate = pipe.transform(new Date(note.postedAtTime), 'short');
    return `${formattedDate} ${note.postedBy}`;
  }

}
