import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AccountInvoice } from '../shared/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountInvoiceListComponent } from '../account-invoice-list/account-invoice-list.component';

@Component({
  selector: 'app-account-invoices-dialog',
  templateUrl: './account-invoices-dialog.component.html',
  styleUrls: ['./account-invoices-dialog.component.scss']
})
export class AccountInvoicesDialogComponent implements OnInit {
  selected: AccountInvoice[];
  @ViewChild(AccountInvoiceListComponent, {static: true}) list: AccountInvoiceListComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number[], accountId: number, isAccountInvoiceDialog: boolean}) {}

  ngOnInit(): void {
    if (!this.list) {
      return;
    }

    this.list.selection.changed.subscribe(flight => {
      this.selected = flight.source.selected;
    });

    if (this.data.selected) {
      this.list.initSelected(this.data.selected);
    }
  }
}
