import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeaheadItem } from '../../shared/models/typeahead-item';
import { ACCOUNTS, TYPEAHEAD } from '../../core/apiUrls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AcceptPayment, AcceptPaymentInvoice } from './models';
import { ConsultantCompanyCommissionTransaction } from '../../entities/shared/models';

@Injectable({
  providedIn: 'root'
})
export class AcceptPaymentService {
  static ACCOUNTS = '/accounts';

  constructor(private http: HttpClient) { }

  getAcceptPayments(createdAfter?: string, createdBefore?: string): Observable<AcceptPayment[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }

    return this.http.get<AcceptPayment[]>(`${ACCOUNTS}/transactions/acceptPayments`, { params: httpParams });
  }

  getAcceptPaymentVersions(accountId: number, acceptPaymentId: number): Observable<AcceptPayment[]> {
    return this.http.get<AcceptPayment[]>(`${ACCOUNTS}/${accountId}/transactions/acceptPayments/${acceptPaymentId}/versions`);
  }

  getAcceptPayment(accountId: string, acceptPaymentId: string): Observable<AcceptPayment> {
    return this.http.get<AcceptPayment>(`${ACCOUNTS}/${accountId}/transactions/acceptPayments/${acceptPaymentId}`);
  }

  getAccountList(): Observable<TypeaheadItem[]> {
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}${AcceptPaymentService.ACCOUNTS}`);
  }

  getAllAccountInvoices(accountId: string): Observable<AcceptPaymentInvoice[]> {
    return this.http.get<AcceptPaymentInvoice[]>(`${ACCOUNTS}/${accountId}/transactions/invoices`);
  }

  createAcceptPayment(accountId: string, acceptPayment: AcceptPayment): Observable<AcceptPayment> {
    return this.http.post<AcceptPayment>(`${ACCOUNTS}/${accountId}/transactions/acceptPayments`, acceptPayment);
  }

  updateAcceptPayment(accountId: string, acceptPayment: AcceptPayment): Observable<AcceptPayment> {
    return this.http.put<AcceptPayment>(`${ACCOUNTS}/${accountId}/transactions/acceptPayments/${acceptPayment.id}`, acceptPayment);
  }


  getAcceptPaymentDocument(accountId: number, id: number, documentId: number): Observable<any> {
    return this.http.get(`${ACCOUNTS}/${accountId}/transactions/acceptPayments/${id}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(accountId: number, id: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<ConsultantCompanyCommissionTransaction>(
      `${ACCOUNTS}/${accountId}/transactions/acceptPayments/${id}/documents`, fd);
  }

  deleteAcceptPaymentDocument(accountId: number, id: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${ACCOUNTS}/${accountId}/transactions/acceptPayments/${id}/documents/${documentId}`);
  }
}
