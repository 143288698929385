import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, finalize, first, switchMap, take, tap, map } from 'rxjs/operators';
import { forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';

import { FormMode, MediaMarket, MediaType, ParentCompany, PaymentMethod, State, Station } from '../shared/models';
import { StationService } from '../shared/station.service';
import { BrowseCompanyDialogComponent } from '../browse-company-dialog/browse-company-dialog.component';
import { ParentCompanyService } from '../shared/parent-company.service';
import { EntityDictionaryService } from '../shared/entity-dictionary.service';
import { RelatedItemOption } from '../../shared/models/related-item-option';
import { NotificationService } from '../../shared/notification.service';
import { FormUtils } from '../../shared/utils/form-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { PhoneUtils } from '../shared/utils/phone-utils';
import { ComponentType } from '../../shared/models/component-type.enum';
import { EmailUtils } from '../shared/utils/email-utils';

export interface StationSavedResult {
  isSaved: boolean;
  station: Station;
}

@Component({
  selector: 'app-station-edit',
  templateUrl: './station-edit.component.html',
  styleUrls: ['./station-edit.component.scss']
})
export class StationEditComponent implements OnInit, OnDestroy {
  @Input() dialogWindow = false;
  @Output() saved = new EventEmitter<StationSavedResult>();
  private subscription = new Subscription();
  stationId: Observable<string | null>;
  stationForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      city: [''],
      contactName: [''],
      fax1: [''],
      fax2: [''],
      phone1: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone1Ext: [''],
      phone2: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      phone2Ext: [''],
      state: [null],
      zipCode: [''],
      format: ['']
    }),
    mediaType: [null],
    channel: [''],
    parentCompany: [null],
    mediaMarkets: [''],
    overwriteParentCompanyPaymentMethod: [false],
    generalPaymentNotes: [''],
    paymentMethod: [null],
    paymentGuidelinesUrl: ['', Validators.pattern(EmailUtils.URL_REGEXP_PATTERN)]
  });
  parentCompany: ParentCompany;
  companies: ParentCompany[];
  filteredCompanies: Observable<ParentCompany[]>;
  states: State[];
  mediaTypes: MediaType[];
  allMediaMarkets: MediaMarket[] = [];
  paymentMethods: PaymentMethod[];

  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  openedDialog = false;

  documents: File[] = [];
  documentsUploadedBefore = [];
  ComponentType = ComponentType;

  marketFilterCtrl = new FormControl();
  filteredMarkets = new ReplaySubject<MediaMarket[]>(1);
  @ViewChild('mediaMarketsInput') mediaMarketsInput: ElementRef<HTMLInputElement>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: StationService,
              private companyService: ParentCompanyService,
              private dictionaryService: EntityDictionaryService,
              private notificationService: NotificationService,
              private fb: FormBuilder,
              private dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.stationId = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.mode = params.get('id') ? FormMode.EDIT : FormMode.CREATE;
        return of(params.get('id')) || of(null);
      })
    );

    this.subscription.add(this.stationId.subscribe(this.loadData,
      () => this.notificationService.error('Error occurred while trying to get station info.')));

    this.filteredCompanies = this.parentCompanyControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => this.companyService.getTypeaheadParentCompanyList(term))
    ).pipe(map(results => {
      this.companies = results.map(item => ({id: Number(item.id), name: item.text}) as any);
      return this.companies;
    }));

    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
    this.subscription.add(this.marketFilterCtrl.valueChanges.subscribe(this.filterMarkets));
  }

  loadData = (id: string) => {
    const states$ = this.dictionaryService.getStates();
    const markets$ = this.dictionaryService.getMarkets();
    const types$ = this.dictionaryService.getMediaTypes();
    const paymentMethods$ = this.dictionaryService.getPaymentMethods();
    const station$ = (this.mode === FormMode.EDIT) ? this.service.getStation(id) : of(null);

    forkJoin([ states$, markets$, types$, paymentMethods$, station$ ])
      .pipe(first())
      .subscribe(([ states, markets, types, paymentMethods, station ]) => {
        this.states = states;
        this.allMediaMarkets = markets;
        this.filteredMarkets.next(this.allMediaMarkets.slice());
        this.mediaTypes = types;
        this.paymentMethods = paymentMethods;
        if (station) {
          station.mediaType = this.mediaTypes.find(item => item.id === station.mediaType?.id);
          if (station.address) {
            station.address.state = this.states.find(item => item.code === station.address?.state?.code);
          } else {
            delete station.address;
          }
          const paymentMethodId = station.overwriteParentCompanyPaymentMethod ?
                                    station.paymentMethod?.id : station.parentCompany?.paymentMethod?.id;
          station.paymentMethod = this.paymentMethods.find(item => item.id === paymentMethodId);
          const selectedMarkets = station.mediaMarkets?.map(market => market.id);
          station.mediaMarkets = selectedMarkets ? markets.filter(market => selectedMarkets.includes(market.id)) : station.mediaMarkets;
          this.stationForm.patchValue(station);
          this.documentsUploadedBefore = station.documents;
          this.parentCompany = station.parentCompany;
        }
        this.onChangeParentCompany();
        this.onChangeOverwriteCheckbox();
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get addressFormGroup(): FormGroup {
    return this.stationForm.get('address') as FormGroup;
  }

  submit(station: Station, addNew?: boolean): void {
    this.saving = true;

    if (!station.overwriteParentCompanyPaymentMethod) {
      station.paymentMethod = null;
    }
    const request = this.mode === FormMode.EDIT
      ? this.service.updateStation(station).pipe(
        tap(() => this.notificationService.success('Station updated!'))
      )
      : this.service.createStation(FormUtils.filterEmptyFields(station)).pipe(
        tap(() => this.notificationService.success('New station created!'))
      );

    request.subscribe(
      (result) => {
        if (station.overwriteParentCompanyPaymentMethod && this.documents.length) {
          this.uploadDocuments(result, addNew);
        } else {
          addNew ? this.createNew() : this.returnClicked({isSaved: true, station: result});
        }
      },
      (e) => {
        this.actionErrorHandler(e, this.mode);
        this.saving = false;
      }
    );
  }

  uploadDocuments = (result: Station, addNew?: boolean) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.service.uploadDocument(result.id, document));
    });

    forkJoin(documents$).pipe(finalize(() => this.saving = false)).subscribe(
      () => addNew ? this.createNew() : this.returnClicked({isSaved: true, station: result}),
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.notificationService.error('Error occurred while trying to upload documents.');
        }
      }
    );
  }

  createNew() {
    this.router.navigate(['/entities/stations/edit/', '']);
  }

  remove(mediaMarketId: number): void {
    const mediaMarkets = this.selectedMarkets?.filter(market => market.id !== mediaMarketId);
    this.stationForm.patchValue({mediaMarkets});
  }

  get selectedMarkets() {
    return this.stationForm.get('mediaMarkets').value;
  }

  returnClicked(savedResult?: StationSavedResult) {
    if (this.dialogWindow) {
      this.saved.emit(savedResult ?? {isSaved: false, station: null});
    } else {
      this.router.navigateByUrl('/entities/stations');
    }
  }

  displayRelatedItem(item: RelatedItemOption): string {
    return item && item.name ? item.name : '';
  }

  openDialog() {
    const dialogRef = this.dialog.open(BrowseCompanyDialogComponent,
      {data: {selected: this.parentCompanyControl.value?.id}});
    dialogRef.afterClosed().subscribe((result: ParentCompany) => {
      if (result) {
        this.parentCompanyControl.setValue(result);
      }
    });
  }

  onBlurParentCompany(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurParentCompany(this.stationForm, this.companies, inputElement);
  }

  onParentCompanyOptionSelect(option) {
    TypeaheadUtil.onParentCompanyOptionSelect(this.stationForm, option);

    if (!this.stationForm.get('overwriteParentCompanyPaymentMethod').value) {
      this.stationForm.patchValue({paymentMethod: option.paymentMethod || null});
    }
  }

  get stationID() {
    return this.stationForm.get('id').value;
  }

  get parentCompanyControl() {
    return this.stationForm.get('parentCompany');
  }

  onChangeParentCompany() {
    this.parentCompanyControl.valueChanges.subscribe(company => {
      if (company && typeof company !== 'object') {
        return;
      }

      const newValue = {
        overwriteParentCompanyPaymentMethod: false,
        paymentMethod: null,
      };
      this.parentCompany = company || undefined;
      if (company) {
        this.companyService.getParentCompany(company.id).pipe(take(1))
          .subscribe(parentCompany => {
            this.parentCompany = parentCompany;
            newValue.paymentMethod = parentCompany.paymentMethod ?
              this.paymentMethods.find(item => item.id === parentCompany.paymentMethod.id) : null;
            this.stationForm.patchValue(newValue);
          });
      } else {
        this.stationForm.patchValue(newValue);
      }
    });
  }

  onChangeOverwriteCheckbox() {
    this.stationForm.get('overwriteParentCompanyPaymentMethod').valueChanges.subscribe(overwritePaymentMethod => {
      if (!overwritePaymentMethod) {
        const parentCompanyPaymentMethodId = this.parentCompanyControl.value?.paymentMethod?.id;
        this.stationForm.patchValue({ paymentMethod: this.paymentMethods.find(item => item.id === parentCompanyPaymentMethodId)});
      }
    });
  }

  setDocumentation(data: any) {
    this.documents = data.files;
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.stationForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} station.`);
  }

  private filterMarkets = () => {
    if (!this.allMediaMarkets) {
      return;
    }

    let search = this.marketFilterCtrl.value;
    if (!search) {
      this.filteredMarkets.next(this.allMediaMarkets.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredMarkets.next(
      this.allMediaMarkets.filter(market => market.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
