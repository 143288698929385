import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StationInvoiceExpense } from '../orders/shared/models';
import { DOCUMENTS } from '../core/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  validateDocument(file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<StationInvoiceExpense>(`${DOCUMENTS}/check`, fd);
  }
}
