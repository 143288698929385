<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Consultant Company</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="consultantForm" (ngSubmit)="submit(consultantForm.value)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <h2>General Info</h2>
          <mat-form-field *ngIf="this.mode === FormMode.EDIT">
            <mat-label>Consultant Company ID</mat-label>
            <input formControlName="code" matInput readonly>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Consultant Company Name</mat-label>
            <input formControlName="name" matInput required maxlength="75">
            <mat-error *ngIf="consultantForm.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="consultantForm.get('name').hasError('validation')">
              {{consultantForm.get('name').getError('validation')}}</mat-error>
          </mat-form-field>
          <div formGroupName="address">
            <mat-form-field>
              <mat-label>Address 1</mat-label>
              <input formControlName="addressLine1" matInput maxlength="25">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address 2</mat-label>
              <input formControlName="addressLine2" matInput maxlength="25">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address 3</mat-label>
              <input formControlName="addressLine3" matInput maxlength="25">
            </mat-form-field>
            <mat-form-field>
              <mat-label>City</mat-label>
              <input formControlName="city" matInput required maxlength="25">
             <mat-error *ngIf="consultantForm.get('address').get('city').hasError('required')">
                City is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state" required>
                    <mat-option *ngFor="let option of states" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                 <mat-error *ngIf="consultantForm.get('address').get('state').hasError('required')">
                    State is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>ZIP Code</mat-label>
                  <input formControlName="zipCode" matInput maxlength="10">
                </mat-form-field>
              </div>
            </div>

            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Phone 1</mat-label>
                  <input matInput formControlName="phone1" appPhoneMask maxlength="14">
                  <mat-error *ngIf="consultantForm.get('address').get('phone1').hasError('pattern')">
                    Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Phone 1 Extension</mat-label>
                  <input formControlName="phone1Ext" matInput maxlength="10">
                </mat-form-field>
              </div>
            </div>
            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Phone 2</mat-label>
                  <input matInput formControlName="phone2" appPhoneMask maxlength="14">
                  <mat-error *ngIf="consultantForm.get('address').get('phone2').hasError('pattern')">
                    Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Phone 2 Extension</mat-label>
                  <input formControlName="phone2Ext" matInput maxlength="10">
                </mat-form-field>
              </div>
            </div>
            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Fax</mat-label>
                  <input formControlName="fax1" matInput>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input formControlName="contactEmail" matInput maxlength="50" placeholder="Ex. pat@example.com">
                  <mat-error
                    *ngIf="consultantForm.get('address').get('contactEmail').touched && consultantForm.get('address').get('contactEmail').hasError('pattern')">
                    Please enter a valid email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-form-field>
              <mat-label>Contact Name</mat-label>
              <input formControlName="contactName" matInput maxlength="25">
            </mat-form-field>

<!--            <mat-form-field>
              <mat-label>Contact Title</mat-label>
              <input type="text" formControlName="contactTitle" matInput
                     #titleInput
                     (blur)="onBlurTitle(titleInput)"
                     [matAutocomplete]="autoAccountant">
              <mat-autocomplete #autoAccountant="matAutocomplete" [displayWith]="displayTitle">
                <mat-option *ngFor="let option of filteredTitles | async" [value]="option"
                            (mousedown)="onTitleOptionSelect(option)">
                  {{option.title}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>-->

          </div>
          <mat-form-field>
            <mat-label>Preferred Payment Type</mat-label>
            <mat-select formControlName="paymentMethod">
              <mat-option *ngFor="let type of paymentMethods" aria-selected="true"  [value]="type">
                {{type.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-content_column">
          <app-commision-tier [breakPoints]="commissionTiersBreakPoints"
                              (addBreakPointEvent)="addBreakPoint($event)"
                              (deleteBreakPointEvent)="deleteBreakPoint($event)"
          ></app-commision-tier>
          <mat-card class="commission-rates" *ngFor="let commissionTier of commissionTiersArray.controls; let i = index;">
            <app-commission-rates [ratesForm]="commissionTier"
                                  [headerName]="getRateHeaderName(i, commissionTiersArray.controls)"
                                  [consultantNum]="commissionTiersArray.controls.length > 1 ? i + 1 : null"
            ></app-commission-rates>
          </mat-card>

        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading [loading]="saving" color="primary" [disabled]="!consultantForm.valid">Save</button>
      <button type="button" mat-raised-button appLoading color="primary"
              [disabled]="consultantForm.invalid || saving" (click)="submit(consultantForm.value, true)"
      >Save and Add New
      </button>
      <button mat-raised-button [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

