<div class="content" role="main">
  <div class="header">
    <h1>Payment Details</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <mat-card class="container" *ngIf="!loading">
    <mat-card-content class="form-content">
      <div class="form-content_column">
        <mat-selection-list #types [multiple]="false" (selectionChange)="updatePaymentMethodForm($event.option.value)">
          <mat-list-option *ngFor="let type of paymentTypes" [value]="type" >
            {{type.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="form-content_column">
        <form [formGroup]="paymentMethodForm" (ngSubmit)="savePaymentMethod(paymentMethodForm.value)">
          <div [ngSwitch]="paymentMethodForm.get('name').value">
            <ng-container *ngSwitchCase="''" class="center">
              <p class="no-data">Payment type is not selected..</p>
            </ng-container>
            <div *ngSwitchCase="'AmEx'">
              <ng-container
                *ngTemplateOutlet="AmExCard; context: {form: paymentMethodForm.get('details'), header: 'AmEx (Portal Info)'}"></ng-container>
            </div>
            <div *ngSwitchCase="'Visa'">
              <ng-container
                *ngTemplateOutlet="AmExCard; context: {form:  paymentMethodForm.get('details'), header: 'Visa'}"></ng-container>
            </div>
            <div *ngSwitchCase="'Wire Transfer'">
              <ng-container
                *ngTemplateOutlet="WireTransferCard; context: {form:  paymentMethodForm.get('details'), header: 'Wire Transfer'}"></ng-container>
            </div>
            <div *ngSwitchCase="'Direct ACH'">
              <ng-container
                *ngTemplateOutlet="OtherCard; context: {form:  paymentMethodForm.get('details'), header: 'Direct ACH'}"></ng-container>
            </div>
            <div *ngSwitchCase="'Batch ACH (MPI)'">
              <ng-container
                *ngTemplateOutlet="OtherCard; context: {form:  paymentMethodForm.get('details'), header: 'Batch ACH (MPI)'}"></ng-container>
            </div>
            <div *ngSwitchCase="'Check'">
              <ng-container *ngTemplateOutlet="CheckCard; context: {form:  paymentMethodForm.get('details')}"></ng-container>
            </div>
            <div *ngSwitchCase="'Other'">
              <ng-container *ngTemplateOutlet="OtherCard; context: {form:  paymentMethodForm.get('details'),  header: 'Other'}"></ng-container>
            </div>
          </div>
          <mat-card-actions *ngIf="paymentMethodForm.get('name').value.length > 0" class="form-action buttons-container">
            <button mat-raised-button appLoading
              [loading]="saving"
              [disabled]="!paymentMethodForm.valid"
              *ngIf="hasEditPermission"
              type="submit" color="primary"
            >Save</button>
            <button type="button" mat-raised-button [disabled]="saving" (click)="cancel()">Cancel</button>
          </mat-card-actions>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #AmExCard let-paymentDetailsForm="form" let-headerName="header">
  <div [formGroup]="paymentDetailsForm">
    <h2>{{headerName}}</h2>
    <mat-form-field>
      <mat-label>Portal URL</mat-label>
      <input formControlName="portalUrl" matInput maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input formControlName="userName" matInput maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input formControlName="userPassword" matInput maxlength="50">
    </mat-form-field>
    <mat-form-field [ngClass]="{'card-number-error': cardNumberInvalid}">
      <mat-label [ngClass]="{'card-number-error-label': cardNumberInvalid}">
        Credit Card Number *
      </mat-label>
      <app-credit-card-input formControlName="cardNumber" ngDefaultControl></app-credit-card-input>
    </mat-form-field>
    <div class="inner-form-content">
      <div class="inner-form-content_column">
        <mat-form-field>
          <mat-label>Expiration</mat-label>
          <input formControlName="expirationDate" matInput required maxLength="5"
            (input)="onExpirationDateChange($event.target.value)"
            [matDatepicker]="expirationDateDatepicker"
          >
          <mat-datepicker-toggle matSuffix [for]="expirationDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDateDatepicker
                          startView="multi-year"
                          (yearSelected)="chosenYearHandler($event)"
                          (monthSelected)="chosenMonthHandler($event, expirationDateDatepicker)"
                          panelClass="example-month-picker"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="inner-form-content_column">
        <mat-form-field>
          <mat-label>CCV</mat-label>
          <input type="number" formControlName="ccv" matInput
            maxLength="5" required
            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          >
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <mat-label>Credit Card Name</mat-label>
      <input formControlName="nameOnCard" matInput maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Credit Card Address 1</mat-label>
      <input formControlName="addressLine1" matInput maxlength="50" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Credit Card Address 2</mat-label>
      <input formControlName="addressLine2" matInput maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Credit Card City</mat-label>
      <input formControlName="addressCity" matInput maxlength="50" required>
    </mat-form-field>
    <div class="inner-form-content">
      <div class="inner-form-content_column">
        <mat-form-field>
          <mat-label>Credit Card State</mat-label>
          <mat-select formControlName="addressState" required>
            <mat-option *ngFor="let option of states" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="inner-form-content_column">
        <mat-form-field>
          <mat-label>Credit Card ZIP</mat-label>
          <input formControlName="addressZipCode" matInput maxlength="10" appZipCodeMask>
          <mat-error *ngIf="paymentMethodForm.get('details').get('addressZipCode').hasError('pattern')">
            Allowed zip code formats: xxxxx, xxxxx-xxxx.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <mat-label>Payment Portal Guidelines</mat-label>
      <textarea formControlName="paymentPortalGuidelines" type="text" matInput maxlength="255"></textarea>
    </mat-form-field>
  </div>

</ng-template>

<ng-template #WireTransferCard let-paymentDetailsForm="form" let-headerName="header">
  <div [formGroup]="paymentDetailsForm">
    <h2>{{headerName}}</h2>
    <mat-form-field>
      <mat-label>Account Number</mat-label>
      <input formControlName="wireTransferAccountNumber" matInput maxlength="255">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Routing Number</mat-label>
      <input formControlName="wireTransferRoutingNumber" matInput maxlength="255">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Wire Transfer Guidelines</mat-label>
      <textarea formControlName="paymentGuidelines" type="text" matInput  maxlength="512"></textarea>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #CheckCard let-paymentDetailsForm="form">
  <div [formGroup]="paymentDetailsForm">
    <h2>Check Info</h2>
    <mat-form-field>
      <mat-label>Payable to</mat-label>
      <input formControlName="checkPayableTo" matInput maxlength="512">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Note</mat-label>
      <input formControlName="checkNote" matInput maxlength="512">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Check Guidelines</mat-label>
      <textarea formControlName="paymentGuidelines" type="text" matInput maxlength="512"></textarea>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #OtherCard let-paymentDetailsForm="form"  let-headerName="header">
  <div [formGroup]="paymentDetailsForm">
    <h2>{{headerName}}</h2>
    <mat-form-field>
      <mat-label>Payment Guidelines</mat-label>
      <textarea formControlName="paymentsGuidelines" type="text" matInput maxlength="512"></textarea>
    </mat-form-field>
  </div>
</ng-template>
