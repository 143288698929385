import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/auth/auth.guard';
import { Role } from '../shared/models/role.enum';

import { EntitiesComponent } from './entities.component';
import { AccountListComponent } from './account-list/account-list.component';
import { ConsultantListComponent } from './consultant-list/consultant-list.component';
import { StationListComponent } from './station-list/station-list.component';
import { StationEditComponent } from './station-edit/station-edit.component';
import { StationDetailComponent } from './station-detail/station-detail.component';
import { ParentCompanyListComponent } from './parent-company-list/parent-company-list.component';
import { ParentCompanyEditComponent } from './parent-company-edit/parent-company-edit.component';
import { ConsultantEditComponent } from './consultant-edit/consultant-edit.component';
import { ConsultantDetailComponent } from './consultant-detail/consultant-detail.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { AccountTransactionListComponent } from './account-transaction-list/account-transaction-list.component';
import { StationTransactionListComponent } from './station-transaction-list/station-transaction-list.component';

const routes: Routes = [
  {
    path: 'entities', component: EntitiesComponent, children: [
      { path: '', redirectTo: 'accounts', pathMatch: 'full' },
      { path: 'stations', component: StationListComponent },
      { path: 'stations/detail/:id', component: StationDetailComponent },
      { path: 'stations/edit/:id', component: StationEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.ACCOUNTANT]
        }
      },
      { path: 'stations/:id/transactions', component: StationTransactionListComponent },
      { path: 'accounts', component: AccountListComponent },
      { path: 'accounts/edit/:id', component: AccountEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.ACCOUNTANT]
        }
      },
      { path: 'accounts/detail/:id', component: AccountDetailComponent },
      { path: 'accounts/:id/transactions', component: AccountTransactionListComponent },
      { path: 'consultants', component: ConsultantListComponent },
      { path: 'consultants/detail/:id', component: ConsultantDetailComponent },
      { path: 'consultants/edit/:id', component: ConsultantEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT, Role.ACCOUNTANT]
        }
      },
      { path: 'parentCompany', component: ParentCompanyListComponent },
      { path: 'parentCompany/:id', component: ParentCompanyEditComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntitiesRoutingModule { }
