import { Component, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { delay, expand, last, take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/auth.service';
import { UserService } from '../../shared/user.service';
import { Role } from '../../shared/models/role.enum';
import {
  accountantDashboardColumns,
  buyerDashboardColumns,
  dashboardColumns,
  dashboardItems,
  DashboardQuickLinkInfo, missingQBColumns
} from '../shared/models/dashboard';
import { QuickLink } from '../shared/models/quick-link.enum';
import { DashboardService } from '../shared/dashboard.service';

@Component({
  selector: 'app-common-dashboard',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.scss']
})
export class CommonDashboardComponent implements OnInit {
  items = dashboardItems;
  columns = dashboardColumns;
  missingQB = [];
  currentUser = '';
  hasBuyerPermissions = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER]);
  hasAccountantPermissions = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);
  dashboardValues: DashboardQuickLinkInfo[];

  constructor(private authService: AuthService,
              private router: Router,
              private service: DashboardService,
              protected user: UserService) { }

  ngOnInit(): void {
    this.columns = (this.hasBuyerPermissions && this.hasAccountantPermissions) ? this.columns
      : (this.hasBuyerPermissions ? buyerDashboardColumns : accountantDashboardColumns);

    if (this.hasAccountantPermissions) {
      this.missingQB = missingQBColumns;
    }

    this.user.getCurrentUser().pipe(take(1))
      .subscribe(data => this.currentUser = `${data.name ? data.name : ''} ${data.lastName ? data.lastName : ''}`);

    this.getDashboardInfo();
  }

  updateDashboardValues(values: DashboardQuickLinkInfo[]) {
    if (!this.dashboardValues?.length) {
      this.dashboardValues = values;
      return;
    }
    values.forEach(value => {
      const index = this.dashboardValues.findIndex(item => item.quickLink === value.quickLink);
      if (index > -1) {
        this.dashboardValues.splice(index, 1, value);
      } else {
        this.dashboardValues.push(value);
      }
    });
  }

  get roleMarker() {
    return (this.hasBuyerPermissions && this.hasAccountantPermissions) ? ''
      : (this.hasBuyerPermissions ? 'Buyer' : (this.hasAccountantPermissions ? 'Accountant' : ''));
  }

  get columnWidth() {
    return `${100 / this.columns.length}%`;
  }

  get missingColumnWidth() {
    return `${100 / this.missingQB.length}%`;
  }

  onViewClicked(column: QuickLink) {
    const queryParams = this.items.get(column).queryParams as any;
    if (column === QuickLink.OUTSTANDING_PAYMENT_BATCHES_LINK) {
      queryParams.accountant = this.currentUser;
    }
    const url = this.router.serializeUrl(this.router.createUrlTree([this.items.get(column).url], {queryParams}));
    window.open(url, '_blank');
  }

  getQuickLinkValue(column: QuickLink) {
    if (!this.items.get(column).showValue) {
      return null;
    }
    if (!this.dashboardValues) {
      return -1;
    }
    const quickLinkValue = this.dashboardValues.find(item => item.quickLink === column);
    if (!quickLinkValue || quickLinkValue.stillCounting) {
      return -1;
    }
    return quickLinkValue.quantity;
  }

  getCalculationDate() {
    if (!this.dashboardValues) {
      return undefined;
    }
    return this.dashboardValues[0].quantityCountedAt;
  }

  getDashboardInfo() {
    const columns = [...this.columns, ...this.missingQB].filter(item => this.items.get(item).showValue);
    this.service.getDashboardInfo(columns).pipe(
      expand((data) => {
        this.updateDashboardValues(data);
        const stillCountingColumns = data.filter(item => item.stillCounting).map(item => item.quickLink);
        return stillCountingColumns.length ? this.service.getDashboardInfo(stillCountingColumns).pipe(delay(3000)) : EMPTY;
      }),
      last()
    ).subscribe();
  }
}
