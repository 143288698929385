import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {}

  success(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 2000,
      panelClass: ['ams-snackbar', 'success-snackbar']
    });
  }

  error(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 2000,
      panelClass: ['ams-snackbar', 'error-snackbar']
    });
  }

  info(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 200,
      panelClass: ['ams-snackbar', 'info-snackbar']
    });
  }

  warn(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 2000,
      panelClass: ['ams-snackbar', 'warning-snackbar']
    });
  }
}
