import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navbar-submenu',
  templateUrl: './navbar-submenu.component.html',
  styleUrls: ['./navbar-submenu.component.scss']
})
export class NavbarSubmenuComponent implements OnInit {
  @Input() subnavTitles;
  subnavList = [];

  constructor() { }

  ngOnInit(): void {
  }
}
