export enum QuickLink {
  DECLINED_CHECKREQUESTS_LINK = 'DECLINED_CHECKREQUESTS_LINK',
  OUTSTANDING_ACCOUNT_INVOICES_LINK = 'OUTSTANDING_ACCOUNT_INVOICES_LINK',
  UNDER_REVIEW_CHECKREQUESTS_LINK = 'UNDER_REVIEW_CHECKREQUESTS_LINK',
  SUBMITTED_ORDERS_LINK = 'SUBMITTED_ORDERS_LINK',
  GLOBAL_ACCOUNT_VIEW_ORDER_LINK = 'GLOBAL_ACCOUNT_VIEW_ORDER_LINK',
  GLOBAL_ACCOUNT_VIEW_INVOICE_LINK = 'GLOBAL_ACCOUNT_VIEW_INVOICE_LINK',
  GLOBAL_STATION_VIEW_ORDER_LINK = 'GLOBAL_STATION_VIEW_ORDER_LINK',
  GLOBAL_STATION_VIEW_INVOICE_LINK = 'GLOBAL_STATION_VIEW_INVOICE_LINK',
  UNBALANCED_CONSULTANT_COMMISSION_LINK = 'UNBALANCED_CONSULTANT_COMMISSION_LINK',
  OUTSTANDING_PAYMENT_BATCHES_LINK = 'OUTSTANDING_PAYMENT_BATCHES_LINK',
  MISSING_QB_REF_PAYMENT_BATCHES_LINK = 'MISSING_QB_REF_PAYMENT_BATCHES_LINK',
  MISSING_QB_REF_ACCEPT_PAYMENTS_LINK = 'MISSING_QB_REF_ACCEPT_PAYMENTS_LINK',
  MISSING_QB_REF_REFUNDS_LINK = 'MISSING_QB_REF_REFUNDS_LINK',
  MISSING_QB_REF_COMMISSIONS_LINK = 'MISSING_QB_REF_COMMISSIONS_LINK',
  MISSING_QB_REF_EXPENSES_LINK = 'MISSING_QB_REF_EXPENSES_LINK'
}
