import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { REPORTS } from '../../core/apiUrls';
import { ReportRequest } from './models/report-request';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  generateExcelReport(requestReport: ReportRequest): Observable<ArrayBuffer> {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    // @ts-ignore
    return this.http.post<ArrayBuffer>(REPORTS, requestReport, { responseType: 'blob'});
  }

  generateExcelReportForAccountInvoice(accountInvoiceId: string): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    // @ts-ignore
    return this.http.get<ArrayBuffer>(`${REPORTS}/accountInvoices/${accountInvoiceId}`, { observe: 'response' as 'body', responseType: 'blob'});
  }

}
