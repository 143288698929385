import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserConfiguration } from './models';
import { USER } from '../../core/apiUrls';
import { Account } from './models';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUserConfigurationsList(companyShortName?: string): Observable<UserConfiguration[]> {
    const params = new HttpParams()
      .set('companyShortName', companyShortName);
    return this.http.get<UserConfiguration[]>(`${USER}/configurations`, companyShortName ? {params} : undefined);
  }

  getUserAccountList(companyShortName?: string): Observable<Account[]> {
    const params = new HttpParams()
      .set('companyShortName', companyShortName);
    return this.http.get<Account[]>(`${USER}/configurations/accounts`, companyShortName ? {params} : undefined);
  }

  updateUserConfiguration(userConfiguration: UserConfiguration): Observable<UserConfiguration> {
    return this.http.put<UserConfiguration>(`${USER}/configurations/${userConfiguration.id}`, userConfiguration);
  }
}
