<div [formGroup]="parts" class="card-input-container">
  <input matInput class="card-input-element digit-4"
         formControlName="first"
         size="4"
         maxLength="4"
         minlength="4"
         (input)="handleInput(parts.controls.first, second)"
         #first>
  <span class="card-input-spacer">&ndash;</span>
  <input matInput class="card-input-element digit-4"
         formControlName="second"
         size="4"
         maxLength="4"
         minlength="4"
         (input)="handleInput(parts.controls.second, third)"
         (keyup.backspace)="autoFocusPrev(parts.controls.second, first)"
         #second>
  <span class="card-input-spacer">&ndash;</span>
  <input matInput class="card-input-element digit-4"
         formControlName="third"
         maxLength="4"
         size="4"
         minlength="4"
         (input)="handleInput(parts.controls.third, fourth)"
         (keyup.backspace)="autoFocusPrev(parts.controls.third, second)"
         #third>
  <span class="card-input-spacer">&ndash;</span>
  <input matInput class="card-input-element digit-4"
         formControlName="fourth"
         maxLength="4"
         size="4"
         minlength="4"
         (input)="handleInput(parts.controls.fourth)"
         (keyup.backspace)="autoFocusPrev(parts.controls.fourth, third)"
         #fourth>
</div>
