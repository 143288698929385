import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { Directive } from '@angular/core';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    return date.getFullYear().toString();
  }
}

export const FORMAT = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Directive({
  selector: '[appYearFormat]',
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: FORMAT },
  ],
})
export class YearFormatDirective {
}
