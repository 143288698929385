import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CheckListComponent } from '../check-list/check-list.component';

@Component({
  selector: 'app-browse-check-dialog',
  templateUrl: './browse-check-dialog.component.html',
  styleUrls: ['./browse-check-dialog.component.scss']
})
export class BrowseCheckDialogComponent implements OnInit {
  @ViewChild(CheckListComponent, {static: true}) list: CheckListComponent;
  selected: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {accountId: number}) {}

  ngOnInit(): void {
    if (!this.list) {
      return;
    }

    this.list.selection.changed.subscribe(checkRequest => {
      this.selected = checkRequest.source.selected[0];
    });
  }
}
