import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NotificationService } from '../../shared/notification.service';
import { CommissionUtil } from '../../orders/shared/utils/commission-util';
@Component({
  selector: 'app-commission-rates',
  templateUrl: './commission-rates.component.html',
  styleUrls: ['./commission-rates.component.scss']
})
export class CommissionRatesComponent implements OnInit, AfterViewInit {
  readonly COMMON_RATES_PERCENT = 15;
  @Input() headerName = 'Default Commission Rates';
  @Input() consultantNum;
  @Input() ratesForm = this.formBuilder.group({
    consultantRates: this.formBuilder.array([]),
    canal: [0],
    roll:  [{value: {value: this.COMMON_RATES_PERCENT, disabled: true}, disabled: true}]
  });
  reloading = false;

  constructor(private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.ratesForm.markAllAsTouched();
    this.ratesForm.setValidators(this.ratesValidator());
  }

  ngAfterViewInit() {
  }

  ratesValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const consultants = (group.get('consultantRates') as FormArray).controls;
      const canal = group.get('canal');
      const roll = group.get('roll');
      const sum = (consultants.reduce((a, b) => a + b.value, 0) + canal.value + roll.value).toFixed(2);
      if (Number(sum) !== 15) {
        consultants.forEach(control => control.setErrors({wrongSum: true}));
        canal.setErrors({wrongSum: true});
        roll.setErrors({wrongSum: true});
      } else {
        consultants.forEach(control => control.setErrors(null));
        canal.setErrors(null);
        roll.setErrors(null);
      }
      return;
    };
  }


  get consultantsControls(): FormControl[] {
    return (this.ratesForm.get('consultantRates') as FormArray).controls as FormControl[];
  }

  get consultantsValues(): number[] {
    return this.ratesForm.get('consultantRates').value;
  }

  get canalFormValue(): number {
    return this.ratesForm.get('canal').value;
  }

  get rollFormValue() {
    return this.ratesForm.get('roll').value;
  }

  get sliderValues() {
    const consultantsValues = this.ratesForm.get('consultantRates').value.map((acc => val => acc += val)(0));
    return [...consultantsValues, consultantsValues[consultantsValues.length - 1] + this.canalFormValue];
  }

  set sliderValues(sliderValues) {
    const canal = sliderValues[sliderValues.length - 1] - sliderValues[sliderValues.length - 2];

    const consultantsAbsoluteValues = sliderValues.slice(0, sliderValues.length - 1);
    const consultantRates = consultantsAbsoluteValues.map(function(value) {
      const returnValue = value - this.acc;
      this.acc = value;
      return returnValue;
    }, {acc: 0});

    const roll = this.COMMON_RATES_PERCENT - sliderValues[sliderValues.length - 1];

    this.ratesForm.patchValue({
      consultantRates,
      canal,
      roll
    });
  }

  calculateRoll(): string {
    if (!this.canalFormValue || this.consultantsValues.find( value => !value)) {
    }
    const  rollNumber = 15 - this.consultantsValues.reduce((a, b) => a + b, 0) - this.canalFormValue;
    return String(rollNumber < 0 ? 0 : rollNumber);
  }

  getConsultantLabel(index: number) {
    return CommissionUtil.getConsultantLabel(index);
  }

  onLoadNext() {
    this.reloading = true;
    this.cdRef.detectChanges();
    this.reloading = false;
    this.cdRef.detectChanges();
  }

  keyPress(event) {
    return event.charCode >= 48 || event.charCode === 46;
  }

}
