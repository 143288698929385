import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Station } from '../../entities/shared/models';
import { StationTransactionListComponent } from '../station-transaction-list/station-transaction-list.component';

@Component({
  selector: 'app-browse-ordered-station-dialog',
  templateUrl: './browse-ordered-station-dialog.component.html',
  styleUrls: ['./browse-ordered-station-dialog.component.scss']
})
export class BrowseOrderedStationDialogComponent implements OnInit {
  @ViewChild(StationTransactionListComponent, {static: true}) stationList: StationTransactionListComponent;
  selectedStation: Station;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number, accountId: number}) {}

  ngOnInit(): void {
    if (!this.stationList) {
      return;
    }

    this.stationList.selection.changed.subscribe(station => {
      this.selectedStation = station.source.selected[0];
    });

    if (this.data.selected) {
      this.stationList.initSelected(this.data.selected);
    }
  }

}
