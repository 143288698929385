import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { StationInvoiceService } from '../shared/station-invoice.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { getClientCandidateType } from '../../shared/utils/sort-list-util';
import { StationInvoice } from '../shared/models';
import { ComponentType } from '../../shared/models/component-type.enum';
import { FormMode } from '../../shared/models/form-mode.enum';

@Component({
  selector: 'app-station-invoice-detail',
  templateUrl: './station-invoice-detail.component.html',
  styleUrls: ['./station-invoice-detail.component.scss']
})
export class StationInvoiceDetailComponent implements OnInit {
  stationInvoice: Observable<StationInvoice>;
  stationInvoiceData: StationInvoice;
  loading = true;

  mode = FormMode.VIEW;
  component = ComponentType.StationInvoice;
  documentsUploadedBefore = [];

  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasEditPermission = this.authService.hasEditPermission;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private stationInvoiceService: StationInvoiceService,
              private authService: AuthService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.stationInvoice = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.stationInvoiceService.getInvoice(params.get('id')))
    );

    this.stationInvoice.subscribe(
      data => {
        this.stationInvoiceData = data;
        this.documentsUploadedBefore = data.documents;
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get station invoice data')
    );
  }

  getAccountType() {
    return getClientCandidateType(this.stationInvoiceData.account);
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.router.navigateByUrl('/orders/transactions/invoice').then();
    }
  }
}
