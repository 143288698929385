<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container matColumnDef="stationNameType">
    <th mat-header-cell *matHeaderCellDef>Station</th>
    <td mat-cell *matCellDef="let element" class="station-name">{{element.name}} - {{element.type}}</td>
    <td mat-footer-cell *matFooterCellDef colspan="8" class="report-total-title">
      REPORT TOTAL =
    </td>
  </ng-container>
  <ng-container matColumnDef="ftcOn">
    <th mat-header-cell *matHeaderCellDef>FTC</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="ltcOn">
    <th mat-header-cell *matHeaderCellDef>LTC</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="transactionType">
    <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="transactionId">
    <th mat-header-cell *matHeaderCellDef>Transaction ID#</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="quickBookReference">
    <th mat-header-cell *matHeaderCellDef>QuickBooks Reference</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="grossOrdered">
    <th mat-header-cell *matHeaderCellDef>Gross Ordered</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.grossOrdered | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'grossOrdered') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="netOrdered">
    <th mat-header-cell *matHeaderCellDef>Net Ordered</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.netOrdered | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'netOrdered') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="expense">
    <th mat-header-cell *matHeaderCellDef>Expense</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.expense | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'expense') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="paid">
    <th mat-header-cell *matHeaderCellDef>Paid</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.paid | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'paid') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="vendorMemoApplied">
    <th mat-header-cell *matHeaderCellDef>Vendor Memo Applied</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.vendorMemoApplied | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'vendorMemoApplied') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="vendorMemoAvailable">
    <th mat-header-cell *matHeaderCellDef>Vendor Memo Available</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.vendorMemoAvailable | currency}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'vendorMemoAvailable') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="refund">
    <th mat-header-cell *matHeaderCellDef>Refund</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.refund | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'refund') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="invoiced">
    <th mat-header-cell *matHeaderCellDef>Invoiced</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.invoiced | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'invoiced') | currency}}</td>
  </ng-container>
  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef>Balance</th>
    <td mat-cell *matCellDef="let element" class="station-total">{{element.isExpanded ? '' : (element.balance | currency)}}</td>
    <td mat-footer-cell *matFooterCellDef>{{getTotal(reports, 'balance') | currency}}</td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="row station-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
        <table mat-table [dataSource]="element.rows">
          <ng-container matColumnDef="stationNameType">
            <td mat-cell *matCellDef="let row">{{row.estimateNumber}}</td>
            <td mat-footer-cell *matFooterCellDef colspan="8" class="station-flights-subtotal">
              {{(element.name + ' subtotal = ') | uppercase}}
            </td>
          </ng-container>
          <ng-container matColumnDef="ftcOn">
            <td mat-cell *matCellDef="let row">{{row.ftcOn}}</td>
          </ng-container>
          <ng-container matColumnDef="ltcOn">
            <td mat-cell *matCellDef="let row">{{row.ltcOn}}</td>
          </ng-container>
          <ng-container matColumnDef="transactionType">
            <td mat-cell *matCellDef="let row">{{row.transactionType}}</td>
          </ng-container>
          <ng-container matColumnDef="transactionDate">
            <td mat-cell *matCellDef="let row">{{row.transactionDate}}</td>
          </ng-container>
          <ng-container matColumnDef="transactionId">
            <td mat-cell *matCellDef="let row">{{row.transactionId}}</td>
          </ng-container>
          <ng-container matColumnDef="quickBookReference">
            <td mat-cell *matCellDef="let row">{{row.quickBookReference}}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <td mat-cell *matCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container matColumnDef="grossOrdered">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.grossOrdered | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.grossOrdered | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="expense">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{(row.expense || 0) | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.expense | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="netOrdered">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.netOrdered | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.netOrdered | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="paid">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.paid | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.paid | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="vendorMemoApplied">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.vendorMemoApplied | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.vendorMemoApplied | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="vendorMemoAvailable">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.vendorMemoAvailable | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.vendorMemoAvailable | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="refund">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.refund | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.refund | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="invoiced">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{(row.invoiced || 0) | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.invoiced | currency}}</td>
          </ng-container>
          <ng-container matColumnDef="balance">
            <td mat-cell *matCellDef="let row" [ngClass]="{'total': row.total}">{{row.balance | currency}}</td>
            <td mat-footer-cell *matFooterCellDef>{{element.balance | currency}}</td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedTotalColumns" class="total"></tr>
        </table>
      </div>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row
    *matRowDef="let element; columns: displayedColumns;"
    class="station-element-row"
    [class.station-expanded-row]="element.isExpanded"
    (click)="element.isExpanded = !element.isExpanded"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="station-detail-row"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedTotalColumns" class="total"></tr>
</table>
