<mat-dialog-content class="browse-dialog">
  <app-consultant-list [isSelectMode]="true" [isTransactionDialog]="data.transaction"
                       [accountId]="data.accountId"
                       [exclude]="data.exclude || []"
                       [consultantList]="data.consultantList"
  ></app-consultant-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="selectedConsultant" [disabled]="!selectedConsultant">Apply</button>
</mat-dialog-actions>
