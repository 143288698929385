import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { finalize, first, map, startWith, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { BrowseConsultantDialogComponent } from '../browse-consultant-dialog/browse-consultant-dialog.component';
import {
  Account,
  CandidateAccountParty,
  ConsultantCompany,
  FormMode,
  State
} from '../shared/models';
import { CPMCompanyService } from '../shared/cpm-company.service';
import { EntityDictionaryService } from '../shared/entity-dictionary.service';
import { NotificationService } from '../../shared/notification.service';
import { AccountService } from '../shared/account.service';
import { ConsultantCompanyService } from '../shared/consultant-company.service';
import { FormUtils } from '../../shared/utils/form-utils';
import { CommissionUtils } from '../shared/utils/commission-utils';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { EmailUtils } from '../shared/utils/email-utils';
import { PhoneUtils } from '../shared/utils/phone-utils';
import { CommissionUtil } from '../../orders/shared/utils/commission-util';
import { Role } from '../../shared/models/role.enum';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  accountForm = this.fb.group({
    id: [''],
    archived: [false],
    name: ['', Validators.required],
    code: [{ value: null, disabled: true }],
    cpmCompany: [null], // no form-field for edit, need to keep in model for BE
    leadConsultantCompanyCode: ['', Validators.required],
    consultantCompanies: this.fb.array([]),
    candidate: [false],
    advocacy: [false],
    federal: [false],
    state: [false],
    cnd: this.fb.group({
      name: [''],
      committee: [''],
      treasurer: [''],
      sought: [''],
      party: [null],
      represent: [null]
    }),
    cli: this.fb.group({
      importance: [null],
      name: [''],
      contact: [''],
      phone: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      email: ['', Validators.pattern(EmailUtils.EMAIL_REGEXP_PATTERN)],
      officers: [''],
    }),
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      city: [''],
      state: [null],
      zipCode: [''],
    }),
    ordersExistInYears: this.fb.array([]),
  });
  commissionTiersArray = this.fb.array([]);

  filteredConsultantCompanies: Observable<ConsultantCompany[]>[] = [];
  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  openedDialog = false;
  consultantCompanies: ConsultantCompany[];

  states: State[];
  candidateParties: CandidateAccountParty[];
  hasEditArchivedPermission = this.authService.hasPermission([Role.SENIOR_ACCOUNTANT, Role.SENIOR_BUYER]);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: AccountService,
              private authService: AuthService,
              private consultantService: ConsultantCompanyService,
              private companyService: CPMCompanyService,
              private dictionaryService: EntityDictionaryService,
              private notificationService: NotificationService,
              private fb: FormBuilder,
              private dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    this.loadData(id);

    this.subscription.add(this.accountForm.get('candidate').valueChanges
      .subscribe(value => {
        if (value) {
          this.addNabValidator(this.accountForm.get('cnd') as FormGroup);
          if (this.accountForm.get('advocacy').value) {
            this.accountForm.get('advocacy').setValue(false);
            this.removeNabValidator(this.accountForm.get('cli') as FormGroup);
          }
        } else {
          this.removeNabValidator(this.accountForm.get('cnd') as FormGroup);
          if (this.accountForm.get('advocacy').value) {
            this.addNabValidator(this.accountForm.get('cli') as FormGroup);
          }
        }
      }));

    this.subscription.add(this.accountForm.get('advocacy').valueChanges
      .subscribe(value => {
        value ? this.addNabValidator(this.accountForm.get('cli') as FormGroup) :
          this.removeNabValidator(this.accountForm.get('cli') as FormGroup);
      }));

    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  loadData = (id: string) => {
    const states$ = this.dictionaryService.getStates();
    const consultants$ = this.consultantService.getConsultantCompanyList();
    const account$ = (this.mode === FormMode.EDIT) ? this.service.getAccount(id) : of(null);
    const candidateParties$ = this.dictionaryService.getCandidateParties();
    // const years$ = (this.mode === FormMode.CREATE) ? this.service.getYears(-1) : of(null);

    forkJoin([states$, consultants$, account$, candidateParties$])
      .pipe(first())
      .subscribe(([states, consultants, account, candidateParties]) => {
        this.states = states;
        this.consultantCompanies = consultants;
        this.candidateParties = candidateParties;
        if (account) {
          this.initAccountForm(account);
        } else {
/*          years.sort((item1, item2) => (+item2.year) - (+item1.year))
            .forEach((item: OrdersExistInYear) => this.yearsControl.push(this.fb.group({ ...item })));*/
          this.consultantsControl.push(this.fb.control(null, Validators.required));
          this.manageConsultantFilters(0);
          this.commissionTiersArray.push(CommissionUtils.generateBreakPoint(this.fb, null, 1));
        }
        this.loading = false;
      }, () => this.notificationService.error('Error occurred while trying to get account info.'));
  }

  addNabValidator(group: FormGroup) {
    Object.keys(group.controls).forEach(key => {
      const validators = [Validators.required];
      if (key === 'email') {
        validators.push(Validators.pattern(EmailUtils.EMAIL_REGEXP_PATTERN));
      } else if (key === 'phone') {
        validators.push(Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN));
      }
      group.get(key).setValidators(validators);
    });
    this.accountForm.updateValueAndValidity();
  }

  removeNabValidator(group: FormGroup) {
    Object.keys(group.controls).forEach(key => {
      group.get(key).clearValidators();
      group.get(key).updateValueAndValidity();
    });
    this.accountForm.updateValueAndValidity();
  }

  initAccountForm(account: Account) {
    const { ordersExistInYears, consultantCompanies } = account;
    delete account.ordersExistInYears;
    delete account.consultantCompanies;
    account.address.state = this.states.find(item => item.code === account.address?.state?.code);
    this.accountForm.patchValue(account);
    if (account.candidate) {
      const cnd = {
        name: account.nabFormFields['account.cnd.name'],
        committee: account.nabFormFields['account.cnd.committee.name'],
        party: this.candidateParties.find(item => item.name === account.nabFormFields['account.cnd.party']),
        treasurer: account.nabFormFields['account.cnd.treasurer'],
        sought: account.nabFormFields['account.cnd.office.sought'],
        represent: account.nabFormFields['account.cnd.undersigned.represents.candidate'] === 'true'
      };
      this.accountForm.patchValue({ cnd });
    } else if (account.advocacy) {
      const cli = {
        importance: account.nabFormFields['account.cli.ad.national.importance.communicate'] === 'true',
        name: account.nabFormFields['account.cli.advertiser.full_name'],
        contact: account.nabFormFields['account.cli.advertiser.contact.name'],
        phone: account.nabFormFields['account.cli.advertiser.phone'],
        email: account.nabFormFields['account.cli.advertiser.email'],
        officers: account.nabFormFields['account.cli.executive.officers']
      };
      this.accountForm.patchValue({ cli });
    }
/*    ordersExistInYears.sort((item1, item2) => (+item2.year) - (+item1.year))
      .forEach((item: OrdersExistInYear) => this.yearsControl.push(this.fb.group({ ...item })));*/
    this.initConsultants(consultantCompanies, account.leadConsultantCompanyCode);
    CommissionUtils.fillTiersArray(this.fb, account.commissionTiers, this.commissionTiersArray);
  }

  initConsultants(consultantCompanies: ConsultantCompany[], leadConsultantCompanyCode: string) {
    const leadConsultant = consultantCompanies.filter((item) => item.code === leadConsultantCompanyCode)[0];
    const otherConsultants = consultantCompanies.filter((item) => item.code !== leadConsultantCompanyCode);
    this.consultantsControl.push(this.fb.control(leadConsultant, Validators.required));
    otherConsultants.forEach((item) => {
      this.consultantsControl.push(this.fb.control(item, Validators.required));
    });
    for (let i = 0; i < this.consultantsControl.controls.length; i++) {
      this.manageConsultantFilters(i);
    }
  }

  openDialog(index: number) {
    const selected = this.consultantsControl.at(index).value?.id;
    const consultants = [...this.consultantsControl.value];
    consultants.splice(index, 1);
    const exclude = consultants.map(consultant => consultant?.id) || [];

    const dialogRef = this.dialog.open(BrowseConsultantDialogComponent,
      { data: { selected, exclude } }
    );

    dialogRef.afterClosed().subscribe((result: ConsultantCompany) => {
      if (result) {
        this.consultantsControl.controls[index].setValue(result);

        if (index === 0) {
          this.accountForm.patchValue({ leadConsultantCompanyCode: result.code });
        }
      }
    });
  }

  get consultantsControl() {
    return this.accountForm.get('consultantCompanies') as FormArray;
  }

  get yearsControl() {
    return this.accountForm.get('ordersExistInYears') as FormArray;
  }

  get commissionTiersBreakPoints() {
    // @ts-ignore
    return Array.from(this.commissionTiersArray.value, (tier) => tier.breakPoint);
  }

  getConsultantLabel(index: number) {
    return CommissionUtil.getConsultantLabel(index);
  }

  manageConsultantFilters(index: number) {
    this.filteredConsultantCompanies[index] = this.manageConsultantFilter(this.consultantsControl.at(index));
  }

  manageConsultantFilter(control) {
    let consultants = this.getConsultantOptions(control.value?.id);

    return control.valueChanges
      .pipe(
        tap((value: any) => {
          consultants = this.getConsultantOptions(value?.id);
        }),
        startWith(''),
        map(value => value ? TypeaheadUtil.filterByCodeName(value, consultants) : consultants?.slice())
      );
  }

  getConsultantOptions(id) {
    const selectedConsultant = this.consultantsControl.value
      .map(item => item?.id)
      .filter(selectedId => selectedId !== id)
      || [];

    return this.consultantCompanies.filter(consultant => !selectedConsultant.includes(consultant.id));
  }

  onBlurConsultant(inputElement: EventTarget, index: number) {
    TypeaheadUtil.onBlurConsultant(this.consultantsControl.at(index), this.consultantCompanies, inputElement);

    for (let i = 0; i < this.consultantsControl.controls.length; i++) {
      if (i !== index) {
        this.manageConsultantFilters(i);
      }
    }
  }

  onConsultantOptionSelect(option, i) {
    TypeaheadUtil.onConsultantOptionSelect(this.consultantsControl.at(i), option);

    if (i === 0) {
      this.accountForm.patchValue({ leadConsultantCompanyCode: option.code });
    }
  }

  displayConsultant(consultant?: ConsultantCompany): string | undefined {
    return consultant ? `${consultant.code} - ${consultant.name}` : undefined;
  }

  removeConsultant(index: number) {
    const controls = this.consultantsControl;
    controls.removeAt(index);
    const leadConsultant = this.consultantsControl.at(0).value;
    if (index === 0) {
      this.accountForm.patchValue({ leadConsultantCompanyCode: leadConsultant ? leadConsultant.code : '' });
    }
    this.commissionTiersArray.clear();
    this.commissionTiersArray.push(CommissionUtils.generateBreakPoint(this.fb, null, controls.controls.length));

    for (let i = 0; i < this.consultantsControl.controls.length; i++) {
      this.manageConsultantFilters(i);
    }
  }

  addNewConsultant() {
    const controls = this.consultantsControl;
    controls.push(this.fb.control(null, Validators.required));
    this.manageConsultantFilters(controls.length - 1);
    this.commissionTiersArray.controls.forEach((formGroup) => {
      const roll = formGroup.get('roll');
      roll.setValue(roll.value - 1);
      (formGroup.get('consultantRates') as FormArray).push(this.fb.control(1));
    });
  }

  addBreakPoint = (value) => {
    const nextBreakPoint = CommissionUtils.getNextBreakPoint(value, this.commissionTiersArray) as FormGroup;
    this.commissionTiersArray.push(CommissionUtils.generateBreakPoint(this.fb, value,
      this.consultantsControl.controls.length, nextBreakPoint.value));
    this.resortCommissionTiers();
  }

  deleteBreakPoint = (value) => {
    const currentBreakPoint = this.commissionTiersArray.controls
      .find((formGroup) => formGroup.get('breakPoint').value === value) as FormGroup;
    const nextBreakPoint = CommissionUtils.getNextBreakPoint(value, this.commissionTiersArray) as FormGroup;
    nextBreakPoint.patchValue({canal: currentBreakPoint.value.canal, roll: currentBreakPoint.value.roll,
      consultantRates: currentBreakPoint.value.consultantRates});

    const newTiersArray = this.commissionTiersArray.controls.filter((formGroup) => {
      return formGroup.get('breakPoint').value !== value;
    });
    this.commissionTiersArray.clear();

    newTiersArray.forEach((tierControl) => {
      this.commissionTiersArray.push(tierControl);
    });
    this.resortCommissionTiers();
  }

  getRateHeaderName = (index, controls) => CommissionUtils.getRateHeaderName(index, controls);

  returnClicked() {
    this.router.navigateByUrl('/entities/accounts').then();
  }

  submit(account: Account, addNew?: boolean): void {
    this.saving = true;
    this.setNabFormFields(account);
    this.setCommissionTiers(account);
    account.consultantCompanies.forEach((item: ConsultantCompany, index: number) => item.sortNumber = index);

    const request = this.isEditMode()
      ? this.service.updateAccount(account).pipe(
        tap(() => this.notificationService.success('Account updated!'))
      )
      : this.service.createAccount(FormUtils.filterEmptyFields(account)).pipe(
        tap(() => this.notificationService.success('New account created!'))
      );

    request.pipe(finalize(() => this.saving = false)).subscribe(
      () => addNew ? this.createNew() : this.returnClicked(),
      (e) => this.actionErrorHandler(e, this.mode),
    );
  }

  createNew() {
    this.router.navigate(['/entities/accounts/edit/', '']);
  }

  setNabFormFields(account: any) {
    account.nabFormFields = account.candidate ?
      {
        'account.cnd.name': account.cnd.name,
        'account.cnd.committee.name': account.cnd.committee,
        'account.cnd.party': account.cnd.party.name,
        'account.cnd.treasurer': account.cnd.treasurer,
        'account.cnd.office.sought': account.cnd.sought,
        'account.cnd.undersigned.represents.candidate': account.cnd.represent,
        'account.cnd.undersigned.represents.committee': !account.cnd.represent
      } : account.advocacy ? {
        'account.cli.ad.national.importance.communicate': account.cli.importance,
        'account.cli.ad.national.importance.not_communicate': !account.cli.importance,
        'account.cli.advertiser.full_name': account.cli.name,
        'account.cli.advertiser.contact.name': account.cli.contact,
        'account.cli.advertiser.phone': account.cli.phone,
        'account.cli.advertiser.email': account.cli.email,
        'account.cli.executive.officers': account.cli.officers
      } : null;
    delete account.cnd;
    delete account.cli;
  }

  setCommissionTiers(account: Account) {
    account.commissionTiers = [];
    this.commissionTiersArray.controls.forEach((tierControl) => {
      const consultantRates = [];
      tierControl.get('consultantRates').value.forEach((consultantRate, index) => {
        consultantRates.push(
          {
            consultantCode: account.consultantCompanies[index].code,
            rate: consultantRate,
            sortNumber: index
          });
      });

      account.commissionTiers.push(
        {
          breakPoint: tierControl.get('breakPoint').value,
          consultantRates,
          cpmRate: tierControl.get('canal').value,
          rollRate: tierControl.get('roll').value,
          sortNumber: tierControl.get('sortNumber').value
        });
    });
  }

  isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.accountForm, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} account.`);
  }

  private resortCommissionTiers() {
    CommissionUtils.resortCommissionTiers(this.commissionTiersArray);
  }
}
