<div class="content-toolbar mat-toolbar mat-accent">
  <nav class="module-nav">
    <ul role="navigation">
      <li>
        <a class="nav-link" routerLink="/entities/accounts" routerLinkActive="active">
          <span class="nav-link-inner">Account</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/entities/consultants" routerLinkActive="active">
          <span class="nav-link-inner">Consultant</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/entities/stations" routerLinkActive="active">
          <span class="nav-link-inner">Station</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/entities/parentCompany" routerLinkActive="active">
          <span class="nav-link-inner">Parent Company</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
<router-outlet></router-outlet>
