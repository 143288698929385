<div class="content" role="main">
  <div class="header">
    <div class="header_arrow-title">
      <button mat-icon-button routerLink="/orders/cinvoices">
        <span class="material-icons">keyboard_backspace</span>
      </button>
      <h1>Account Invoice Detail</h1>
    </div>

    <div class="header_buttons">
      <button mat-raised-button appLoading
        [loading]="reporting"
        [disabled]="isRequesting"
        class="export-button" type="button"
        (click)="onExportClicked()">Export...
      </button>

      <button mat-raised-button appLoading
        color="primary" type="button" class="header-button"
        [loading]="submitting" [disabled]="isRequesting"
        (click)="saveAndSubmit()"
        *ngIf="canSubmit"
      >Submit
      </button>

      <button mat-mini-fab color="primary" aria-label="Edit Account Invoice"
        *ngIf="hasEditPermission" [disabled]="isRequesting"
        [routerLink]="['/orders/cinvoices/edit', accountInvoiceData?.id]" class="edit-button">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading" class="container container-overflow">
    <mat-card-content>
      <div class="content container-columns">
        <div class="content-left">
          <div class="subheader mat-title">General Info</div>
          <mat-card>
            <div class="column-item">
              <div class="title">Invoice ID#</div>
              {{accountInvoiceData.code || 'N/A'}}
            </div>
            <div class="column-item">
              <div class="title">Account Name</div>
              {{accountInvoiceData.account?.name || 'N/A'}}
            </div>
            <div class="column-item">
              <div class="title">Account Type</div>
              {{accountInvoiceData.account ? getAccountType() : 'N/A'}}
            </div>
            <div class="column-item">
              <div class="title">Election Type</div>
              {{accountInvoiceData.electionType?.type || 'N/A'}}
            </div>
            <div class="column-item">
              <div class="title">Election Date</div>
              {{accountInvoiceData.electionDate ? (accountInvoiceData.electionDate | date: 'shortDate') : 'N/A'}}
            </div>
          </mat-card>
        </div>

        <div class="content-right">
          <app-table-version-history *ngIf="versions" [versionData]="versions"></app-table-version-history>
        </div>
      </div>
      <mat-card class="content-right_order-table"
                *ngIf="accountInvoiceData.invoicedOrdersData?.orderedData?.length">
        <app-account-invoice-orders
          [invoicedOrdersData]="accountInvoiceData.invoicedOrdersData"
          [isDetail]="true"
        ></app-account-invoice-orders>

        <div class="summary-container" *ngIf="accountInvoiceData.invoicedOrdersDataCommissionTier">
          <div class="stub_column"></div>
          <div class="summary_column">
            <div class="summary-row">
              <div class="summary-row-rate">{{accountInvoiceData.invoicedOrdersDataCommissionTier.cpmRate || 0}}%</div>
              <div class="summary-row-label">{{accountInvoiceData.account.cpmCompany?.name || 'CPM Company'}} Commission</div>
              <div class="summary-row-sum">{{accountInvoiceData.invoicedOrdersDataCommissionTier.cpmAmount || '-'}}</div>
            </div>
            <div class="summary-row" *ngFor="let consultant of accountInvoiceData.invoicedOrdersDataCommissionTier.consultantRates">
              <div class="summary-row-rate">{{consultant.rate || '-'}}%</div>
              <div class="summary-row-label">{{getConsultantNameByCode(consultant.consultantCode)}} Commission</div>
              <div class="summary-row-sum">{{consultant.amount | currency}}</div>
            </div>
<!--            <div class="summary-row total">
              <div class="summary-row-label">Total:</div>
              <div class="summary-row-sum">{{accountInvoiceData.invoicedOrdersDataTotalAmount | currency}}</div>
            </div>-->
          </div>
        </div>
      </mat-card>
      <div class="content container-columns">
        <div class="content-left">
          <div class="content-left_notes" *ngIf="accountInvoiceData.notes">
            <div class="subheader mat-title">Notes</div>
            <mat-card class="notes-container">
              <mat-card-content>
                <div class="note" *ngFor="let note of accountInvoiceData.notes.reverse()">
                  <div><b>{{note | noteHeader}}</b></div>
                  <div><span>{{note.message}}</span></div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="content-right">
          <mat-card class="content-right_expense-table"
                    *ngIf="accountInvoiceData.invoicedExpenses?.length">
            <app-invoice-expense-list
              [invoicedExpenses]="accountInvoiceData.invoicedExpenses"
              [paymentAmountTotal]="getPaymentAmountTotal()"
              [invoicedExpensesCommissionableTotalAmount]="accountInvoiceData.invoicedExpensesCommissionableTotalAmount"
            ></app-invoice-expense-list>

            <div class="summary-container summary-expenses" *ngIf="accountInvoiceData.invoicedExpensesCommissionTier">
              <div class="stub_column"></div>
              <div class="summary_column">
                <div class="summary-row">
                  <div class="summary-row-rate">{{accountInvoiceData.invoicedExpensesCommissionTier.cpmRate || '-'}}%</div>
                  <div class="summary-row-label">{{accountInvoiceData.account.cpmCompany?.name || 'CPM Company'}} Commission</div>
                  <div class="summary-row-sum">{{accountInvoiceData.invoicedExpensesCommissionTier.cpmAmount || '-'}}</div>
                </div>
                <div class="summary-row" *ngFor="let consultant of accountInvoiceData.invoicedExpensesCommissionTier.consultantRates">
                  <div class="summary-row-rate">{{consultant.rate || '-'}}%</div>
                  <div class="summary-row-label">{{getConsultantNameByCode(consultant.consultantCode)}} Commission</div>
                  <div class="summary-row-sum">{{consultant.amount | currency}}</div>
                </div>
                <div class="summary-row total">
                  <div class="summary-row-label">Total:</div>
                  <div class="summary-row-sum">{{accountInvoiceData.invoicedExpensesTotalAmount | currency}}</div>
                </div>
              </div>
            </div>
          </mat-card>

          <div class="final-container">
            <h2 class="summary-row">
              <div class="summary-row-label">Final Invoice Balance&nbsp;&nbsp;&nbsp;</div>
              <div class="summary-row-sum">
                {{accountInvoiceData.invoicedOrdersDataTotalAmount + accountInvoiceData.invoicedExpensesTotalAmount | currency}}
              </div>
            </h2>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

