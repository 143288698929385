import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParentCompanyListComponent } from '../parent-company-list/parent-company-list.component';
import { ParentCompany } from '../shared/models';

@Component({
  selector: 'app-browse-company-dialog',
  templateUrl: './browse-company-dialog.component.html',
  styleUrls: ['./browse-company-dialog.component.scss']
})
export class BrowseCompanyDialogComponent implements OnInit {
  @ViewChild(ParentCompanyListComponent, {static: true}) companyList: ParentCompanyListComponent;
  selectedCompany: ParentCompany;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {selected: number}) {}

  ngOnInit(): void {
    if (!this.companyList) {
      return;
    }

    this.companyList.selection.changed.subscribe(company => {
      this.selectedCompany = company.source.selected[0];
    });

    if (this.data.selected) {
      this.companyList.initSelected(this.data.selected);
    }
  }

}
