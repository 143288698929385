import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingService } from '../../shared/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  activeLoadingRequests = 0;
  activeSavingRequests = 0;

  constructor(private loadingScreenService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      if (this.activeLoadingRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeLoadingRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeLoadingRequests--;
          if (this.activeLoadingRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    }

    if (['PUT', 'POST'].includes(request.method)) {
      if (this.activeSavingRequests === 0) {
        this.loadingScreenService.startSaving();
      }

      this.activeSavingRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeSavingRequests--;
          if (this.activeSavingRequests === 0) {
            this.loadingScreenService.stopSaving();
          }
        })
      );
    }

    return next.handle(request);
  }
}
