import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';

import {
  AccountInvoice,
  AccountInvoiceOrderedEntry,
  AccountListType,
  ConvertedStatus,
  ElectionType,
  StationInvoiceExpense
} from '../shared/models';
import { Account, ConsultantCompany, ConsultantRate, State } from '../../entities/shared/models';
import { FormMode } from '../../shared/models/form-mode.enum';
import { Role } from '../../shared/models/role.enum';
import { AfterSaveAction } from '../shared/models/after-save-action.enum';
import { AccountInvoiceService } from '../shared/account-invoice.service';
import { EntityDictionaryService } from '../../entities/shared/entity-dictionary.service';
import { NotificationService } from '../../shared/notification.service';
import { OrderDictionaryService } from '../shared/order-dictionary.service';
import { UserService } from '../../shared/user.service';
import { AuthService } from '../../shared/auth.service';

import { FormUtils } from '../../shared/utils/form-utils';
import { NoteUtil } from '../shared/utils/note-util';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { DateUtils } from '../../shared/utils/date-utils';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { BrowseOrderDialogComponent } from '../browse-order-dialog/browse-order-dialog.component';
import { AccountInvoiceOrdersComponent } from '../account-invoice-orders/account-invoice-orders.component';
import { BrowseExpenseDialogComponent } from '../browse-expense-dialog/browse-expense-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogResult } from '../../shared/models/confirmation-dialog-result';
import { Action } from '../../shared/models/action.enum';
import { PhoneUtils } from '../../entities/shared/utils/phone-utils';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { ReportsService } from '../../entities/shared/reports.service';

@Component({
  selector: 'app-account-invoice-edit',
  templateUrl: './account-invoice-edit.component.html',
  styleUrls: ['./account-invoice-edit.component.scss']
})
export class AccountInvoiceEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  accountInvoice: AccountInvoice = {} as any;
  form = this.fb.group({
    account: [null, Validators.required],
    electionType: [null],
    electionDate: [null],
    associatedConsultants: this.fb.array([]),
    billTo: [''],
    remitToAddress: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      officeName: [''],
      phone1: ['', Validators.pattern(PhoneUtils.PHONE_REGEXP_PATTERN)],
      fax1: [''],
      wireTo: this.fb.group({
        bankInformation: [''],
        abaRoutingNumber: [''],
        accountName: [''],
        fcbAccountNumber: [''],
      })
    }),
    cpmRate: [''],
    noteToAdd: [null],
    invoicedExpenses: this.fb.array([])
  });

  accounts: Account[];
  filteredAccounts: Observable<Account[]>;
  electionTypes: ElectionType[];
  states: State[];

  mode: FormMode = FormMode.CREATE;
  loading = true;
  saving = false;
  submitting = false;
  disabling = false;
  hasAutoRow = false;
  openedDialog = false;
  @ViewChild(AccountInvoiceOrdersComponent, {static: false}) accountInvoiceOrders: AccountInvoiceOrdersComponent;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private dictionary: OrderDictionaryService,
              private service: AccountInvoiceService,
              private notificationService: NotificationService,
              private dictionaryService: EntityDictionaryService,
              private reportsService: ReportsService,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    const accountId = this.route.snapshot.queryParamMap.get('accountId'); // in case to create invoice from account
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    this.loadData(id, accountId);
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  loadData = (invoiceId: string, accountId: string) => {
    const states$ = this.dictionaryService.getStates();
    const accounts$ = this.service.getAccountForInvoice();
    const types$ = this.dictionary.getElectionTypes();
    const invoice$ = (this.mode === FormMode.EDIT) ? this.service.getInvoice(invoiceId) : of(null);

    forkJoin([states$, types$, accounts$, invoice$])
      .pipe(first())
      .subscribe(([states, types, accounts, invoice]) => {
          this.states = states;
          this.electionTypes = types;
          this.accounts = accounts || [];
          this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);

          if (invoice) {
            this.accountInvoice = invoice;
            this.initForm();
          } else if (accountId) {
            const selectedAccount = this.accounts.find(account => account.id === +accountId);
            if (selectedAccount) {
              this.form.patchValue({account: selectedAccount});
              this.applyNewAccount(selectedAccount);
            }
          }

          this.loading = false;

          if (this.mode === FormMode.CREATE) {
            this.hasAutoRow = true;
            this.addRowToInvoicedOrder();
          }
        },
        () => this.notificationService.error('Error occurred while trying to get account invoice info.'));
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  initForm() {
    const {account, billTo, electionType, electionDate, remitToAddress, invoicedExpenses, commissionTierRates} = this.accountInvoice;
    this.form.patchValue({account, billTo, cpmRate: commissionTierRates?.cpmRate});
    if (electionType) {
      this.form.get('electionType').patchValue(this.electionTypes.find(item => item.id = electionType.id));
    }
    this.form.get('remitToAddress').patchValue({
      state: this.states.find(item => item.code === remitToAddress?.state?.code),
      city: remitToAddress.city,
      addressLine1: remitToAddress.addressLine1,
      addressLine2: remitToAddress.addressLine2,
      zipCode: remitToAddress.zipCode,
      officeName: remitToAddress.officeName,
      phone1: remitToAddress.phone1,
      fax1: remitToAddress.fax1
    });

    this.form.get('remitToAddress').get('wireTo').patchValue({
      bankInformation: remitToAddress.wireTo?.bankInformation || '',
      abaRoutingNumber: remitToAddress.wireTo?.abaRoutingNumber || '',
      accountName: remitToAddress.wireTo?.accountName || '',
      fcbAccountNumber: remitToAddress.wireTo?.fcbAccountNumber || '',
    });
    this.form.get('electionDate').setValue(electionDate ? new Date(electionDate) : null);
    invoicedExpenses.forEach(expense => {
      this.invoicedExpenses.controls.push(this.fb.control(expense));
    });

    const boundedConsultantCompanies = this.accountInvoice.boundConsultantCompanies.map(item => item.code);
    this.generateAssociatedConsultants(account, boundedConsultantCompanies, commissionTierRates?.consultantRates);

    if (!this.canChangeInvoiceData) {
      this.form.get('account').disable();
      this.form.get('electionType').disable();
      this.form.get('electionDate').disable();
      this.form.get('billTo').disable();
      this.form.get('remitToAddress').disable();
      this.form.get('cpmRate').disable();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isSaveDisabled(): boolean {
    return !this.form.valid || this.isRequesting;
  }

  get canShowCommission() {
    return this.form.get('account').value &&
      this.accountInvoice?.invoicedOrdersData?.orderedData?.length;
  }

  get isEditMode() {
    return this.mode === FormMode.EDIT;
  }

  get noteReverse() {
    return NoteUtil.notesReverse(this.accountInvoice?.notes);
  }

  get officeNames() {
    const account = this.form.get('account').value;
    return account ? (account as Account).cpmCompany?.addresses?.map(item => item.officeName) : [];
  }

  get canAddExpense() {
    return !!this.form.get('account').value && this.canChangeInvoiceData;
  }

  get totalCommissionable(): number {
    return this.invoicedExpensesData
      .map(item => item.commissionable ? item.amount : 0)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  get canChangeInvoiceData(): boolean {
    return !this.isEditMode || (this.authService.hasPermission([Role.SENIOR_BUYER, Role.BUYER])
      && this.accountInvoice.permissions?.includes(Action.EDIT));
  }

  get canDisable(): boolean {
    const hasPermission = this.authService.hasPermission([Role.SENIOR_BUYER, Role.BUYER, Role.SENIOR_ACCOUNTANT])
      && this.accountInvoice.permissions?.includes(Action.DISABLE);
    const allowedStatuses = [
      ConvertedStatus.DRAFT,
      ConvertedStatus.DECLINED
    ];
    return hasPermission && this.accountInvoice?.accountInvoiceStatus?.convertedStatus &&
      allowedStatuses.includes(this.accountInvoice.accountInvoiceStatus.convertedStatus);
  }

  get account() {
    return this.form.get('account').value;
  }

  onBlurAccount(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
    this.applyNewAccount(this.form.get('account').value);
  }

  onAccountOptionSelect(option) {
    this.form.patchValue({account: option});
    this.applyNewAccount(option);
  }

  displayAccount(account?: Account): string {
    return account ? `${account.code} - ${account.name}` : '';
  }

  isAutoRowEmpty() {
    if (!this.accountInvoiceOrders.invoicedOrder.length) {
      this.hasAutoRow = false;
      return false;
    }

    const autoRow = this.accountInvoiceOrders.invoicedOrder.at(0).value;

    for (const key in autoRow) {
      if (autoRow[key]) {
        return false;
      }
    }

    return true;
  }

  openAccountDialog() {
    const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
      {
        data: {
          selected: this.form.value.account?.id,
          type: AccountListType.INVOICE
        }
      }
    );
    dialogRef.afterClosed().subscribe((result: Account) => {
      if (result) {
        this.form.get('account').patchValue(result);
        this.applyNewAccount(result);
      }
    });
  }

  browseOrders() {
    const dialogRef = this.dialog.open(BrowseOrderDialogComponent,
      {
        data: {
          listMethod: this.service.getOrderForInvoice(this.form.value.account?.id),
          columns: ['select', 'code', 'status', 'earliestFlightStartOn', 'latestFlightFinishOn'],
          isMultiselect: true,
          selected: this.accountInvoice?.basedOnOrders?.map(item => item.id) || null
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (this.hasAutoRow && this.isAutoRowEmpty()) {
        this.accountInvoiceOrders.deleteRow(0);
        this.hasAutoRow = false;
      }

      const accountInvoice = this.prepareInvoice();
      accountInvoice.ordersToAdd = result.items.map(item => ({id: item.id}));
      this.service.addOrders(accountInvoice, result.overwrite).subscribe(invoice => {
        this.accountInvoice = invoice;
        this.calculateGross();
      });
    }, () => this.notificationService.error('Can not add orders to invoice'));
  }

  openExpenseDialog() {
    const dialogRef = this.dialog.open(BrowseExpenseDialogComponent,
      {
        data: {
          selectedIds: this.getSelectedExpensesIds(),
          isAccountInvoices: true,
          accountId: this.form.get('account').value?.id,
          isMultiselect: true
        }
      }
    );

    dialogRef.afterClosed().subscribe((result: StationInvoiceExpense[]) => {
      if (result && result.length) {
        this.invoicedExpenses.clear();
        result.forEach(resultItem => {
          this.invoicedExpenses.push(this.fb.control(resultItem));
        });
      }
    });
  }

  handleDeleteExpense(expenseId: number) {
    const expenseArrayIndex = this.invoicedExpenses.controls.findIndex(item => item.value.id === expenseId);
    this.invoicedExpenses.removeAt(expenseArrayIndex);
    this.form.updateValueAndValidity();
  }

  applyNewAccount(account: Account) {
    this.generateAssociatedConsultants(account);
    this.setAddress(account);
  }

  setAddress(account, index?) {
    const addressIndex = index || 0;
    const remitToAddress = account.cpmCompany.addresses ? account.cpmCompany.addresses[addressIndex] : null;

    if (remitToAddress) {
      this.form.patchValue({remitToAddress});
      this.form.get('remitToAddress').patchValue({
        state: this.states.find(item => item.code === remitToAddress.state?.code)
      });
    }
  }

  generateAssociatedConsultants(account: Account, selected?: string[], consultantRates?: ConsultantRate[]) {
    (this.form.get('associatedConsultants') as FormArray).clear();
    if (!account) {
      return;
    }

    const companies = this.setLeadConsultantCompany(account.consultantCompanies, account.leadConsultantCompanyCode) || [];
    if (!consultantRates) {
      consultantRates = account.commissionTiers[0].consultantRates;
      this.form.get('cpmRate').setValue(account.commissionTiers[0].cpmRate); // taken from the first commission tier
    }

    const isDisabledCommission = !this.canChangeInvoiceData;
    companies.forEach(consultantCompany =>
      this.associatedConsultants.push(
        this.fb.group({
          code: [{value: consultantCompany.code, disabled: true}],
          name: [{value: consultantCompany.name, disabled: true}],
          consultantCompany: [consultantCompany],
          selected: [selected ? selected.includes(consultantCompany.code) : true],
          commission: [{
            value: (consultantRates ? consultantRates.find(item => item.consultantCode === consultantCompany.code)?.rate : ''),
            disabled: isDisabledCommission
            }]
        }))
    );
    this.calculateGross();
  }

  setLeadConsultantCompany(consultantCompanies, leadConsultantCompanyCode): ConsultantCompany[] {
    const leadConsultantCompany = consultantCompanies.find((item) => item.code === leadConsultantCompanyCode);
    consultantCompanies = consultantCompanies.filter((item) => item.code !== leadConsultantCompanyCode);
    consultantCompanies.unshift(leadConsultantCompany);

    return consultantCompanies;
  }

  get associatedConsultants(): FormGroup[] {
    return (this.form.get('associatedConsultants') as FormArray).controls as FormGroup[];
  }

  get invoicedExpenses() {
    return this.form.get('invoicedExpenses') as FormArray;
  }

  get invoicedExpensesData() {
    return this.invoicedExpenses.controls.map(control => control.value);
  }

  get summaryExpenseTotal(): number {
    return this.totalExpensesAmount + this.totalConsultantCommission;
  }

  get totalExpensesAmount() {
    return Number(this.invoicedExpensesData
      .map(item => item.amount)
      .reduce((a, b) => b ? a + b : a, 0)
      .toFixed(2));
  }

  get totalConsultantCommission(): number {
    return Number(this.associatedConsultants
      .map(item => item.get('commission').value)
      .map(commission => this.calculateConsultantCommission(commission))
      .reduce((a, b) => b ? a + b : a, 0)
      .toFixed(2));
  }

  get finalInvoiceBalance(): number {
    // return this.summaryExpenseTotal + this.orderTotal;
    return this.orderTotal;
  }

  get wireToGroupControl(): FormGroup {
    return this.form.get('remitToAddress').get('wireTo') as FormGroup;
  }

  toggleConsultantCompany(formGroup: FormGroup) {
    if (!formGroup.controls.selected.value) {
      formGroup.controls.commission.setValue(0);
      this.calculateGross();
    }
  }

  calculateConsultantCommission(commissionRate: number): number {
    return Number((commissionRate * 0.01 * this.totalCommissionable).toFixed(2));
  }

  calculateGross() {
    const rates = this.associatedConsultants.map(item => +item.get('commission').value).reduce((a, b) => b ? a + b : a, 0);
    if (this.accountInvoice.invoicedOrdersData) {
      this.accountInvoice.invoicedOrdersData.grossPercent = 15 - rates - (this.form.value.cpmRate || 0);
    }
  }

  expenseValue(control: AbstractControl): StationInvoiceExpense {
    return control.value as StationInvoiceExpense;
  }

  prepareInvoice() {
    const formValue = this.form.value;
    const selectedConsultantCompanies = this.associatedConsultants.filter(item => item.get('selected').value);
    const boundConsultantCompanies = selectedConsultantCompanies.map(item => item.get('consultantCompany').value);
    const consultantRates = selectedConsultantCompanies.map((item, index) => {
      return {
        sortNumber: index,
        consultantCode: item.get('consultantCompany').value.code,
        rate: item.get('commission').value
      };
    });
    this.calculateAdjustedGross();
    let accountInvoice: any = {...this.accountInvoice};
    if (this.canChangeInvoiceData) {
      accountInvoice = {
        ...this.accountInvoice,
        ...formValue,
        electionDate: formValue.electionDate ? DateUtils.dateFormatToShort(formValue.electionDate) : null,
        boundConsultantCompanies,
        commissionTierRates: {
          consultantRates,
          cpmRate: formValue.cpmRate
        }
      };
      delete accountInvoice.cpmRate;
      delete accountInvoice.associatedConsultants;
    }
    NoteUtil.processNotes(accountInvoice, this.form);
    return accountInvoice;
  }

  get percent() {
    return this.accountInvoice?.invoicedOrdersData?.grossPercent !== undefined ? this.accountInvoice?.invoicedOrdersData?.grossPercent : 15;
  }

  calculateAdjustedGross() {
    this.accountInvoice?.invoicedOrdersData?.orderedData?.forEach(orderedData => {
      orderedData.adjustedGross = Number(((1 - (this.percent / 100)) * orderedData.gross).toFixed(2));
    });
  }

  get isRequesting() {
    return this.saving || this.disabling || this.submitting;
  }

  save(nextStatus?: AfterSaveAction) {
    if (!nextStatus) {
      this.saving = true;
    }

    const accountInvoice = this.prepareInvoice();

    accountInvoice.afterSaveAction = nextStatus || null;

    const request = this.mode === FormMode.EDIT
      ? this.service.updateInvoice(accountInvoice).pipe(
        tap(() => this.notificationService.success('Account invoice was updated!'))
      )
      : this.service.createInvoice(FormUtils.filterEmptyFields(accountInvoice)).pipe(
        tap(() => this.notificationService.success('New account invoice created!'))
      );

    request.subscribe(
        data => {
          if (nextStatus) {
            this.export(data.id.toString(), data.code);
          } else {
            this.saving = false;
            this.returnClicked();
          }
        },
        (e) => {
          this.actionErrorHandler(e, this.mode);
          this.saving = false;
          this.submitting = false;
        }
      );
  }

  saveAndSubmitAndExport() {
    this.submitting = true;
    this.save(AfterSaveAction.APPROVE_NEXT_STATUS);
  }

  export(id: string, code: string) {
    this.reportsService.generateExcelReportForAccountInvoice(id)
      .subscribe(
        data => {
          const filename = data.headers.get('filename');
          ReportsUtil.downloadFile(data.body as ArrayBuffer, filename);
          this.submitting = false;
          this.returnClicked();
        },
        () => {
          this.notificationService.error('Error occurred while trying to download report file.');
          this.submitting = false;
        }
      );
  }

  disable() {
    if (!this.form.get('noteToAdd').value) {
      this.showNoteRequiredDialog();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {message: 'Are you sure you want to disable this invoice?'}});
    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResult) => {
      if (result.value) {
        this.disabling = true;
        const accountInvoice = this.prepareInvoice();

        this.service.updateInvoice(accountInvoice)
          .subscribe((updatedInvoice) => {
            this.service.disableInvoice(String(updatedInvoice.id))
              .pipe(finalize(() => this.disabling = false))
              .subscribe(
                () => {
                  this.notificationService.success('Account invoice was disabled!');
                  this.returnClicked();
                },
                (e) => this.actionErrorHandler(e, this.mode)
              );
          });
      }
    });
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/cinvoices');
  }

  addRowToInvoicedOrder() {
    let data = this.accountInvoice.invoicedOrdersData;
    if (!data) {
      data = {
        orderedData: [],
        customColumn1Name: '',
        customColumn2Name: '',
        grossPercent: 15
      };
    }
    data.orderedData.push({description: '', mediaType: '', customColumn1Value: '', customColumn2Value: ''});
    this.accountInvoice.invoicedOrdersData = data;
    this.calculateGross();
    this.accountInvoiceOrders?.addRow();
  }

  get orderGross() {
    return this.accountInvoice.invoicedOrdersData?.orderedData?.map(item => item.gross)
      .reduce((a, b) => b ? a + b : a, 0) || 0;
  }

  getAdjustedGross(item: AccountInvoiceOrderedEntry) {
    return Number(((100 - this.percent) / 100) * (item.gross || 0)).toFixed(2);
  }

  get adjustedGrossSumm() {
    return this.accountInvoice.invoicedOrdersData?.orderedData?.map(item => Number(this.getAdjustedGross(item)))
      .reduce((a, b) => b ? a + b : a, 0) || 0;
  }

  getOrderCommission(rate: number) {
    return rate ? Number((rate / 100) * this.orderGross).toFixed(2) : 0;
  }

  get orderTotal(): number {
/*    const gross = this.orderGross;
    const percent = this.accountInvoice.invoicedOrdersData?.grossPercent !== undefined ?
      this.accountInvoice.invoicedOrdersData?.grossPercent : 15;
    return gross ? Number((Number((100 - percent) / 100) * Number(gross)).toFixed(2)) : 0;*/
    return this.adjustedGrossSumm;
  }

  get canSubmit(): boolean {
    const hasPermission = this.authService.hasPermission([Role.SENIOR_BUYER, Role.BUYER])
      && (!this.accountInvoice.id || this.accountInvoice?.permissions?.includes(Action.SUBMIT));
    const allowedStatuses = [
      ConvertedStatus.DRAFT,
      ConvertedStatus.DECLINED
    ];
    return hasPermission && this.accountInvoice && (!this.accountInvoice.id || (this.accountInvoice.accountInvoiceStatus &&
      allowedStatuses.includes(this.accountInvoice.accountInvoiceStatus.convertedStatus)));
  }

  private showNoteRequiredDialog() {
    this.dialog.open(ConfirmationDialogComponent,
      {data: {message: 'Please, fill the note field before disabling account invoice.', cancelDisabled: true}});
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} account invoice.`);
  }

  private getSelectedExpensesIds() {
    return this.invoicedExpenses.controls.length > 0 ?
      this.invoicedExpenses.controls
        .map(control => control.value as StationInvoiceExpense)
        .map(stationInvoiceExpense => stationInvoiceExpense.id)
      : [];
  }

  private selectExpenses(expenses: StationInvoiceExpense[]) {
    this.invoicedExpenses.controls = [];

    expenses.forEach(selectedExpense => {
      this.invoicedExpenses.controls.push(this.fb.control(selectedExpense));
    });
  }
}
