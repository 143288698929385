import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PaymentCheckRequestService } from '../shared/payment-check-request.service';
import { ReportsService } from '../../entities/shared/reports.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { getClientCandidateType, sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { ReportsUtil } from '../../shared/utils/reports-util';
import { EntityType } from '../../entities/shared/models/entity-type.enum';
import { Filename } from '../../shared/models/filename.enum';
import { Role } from '../../shared/models/role.enum';
import { PaymentCheckRequest, checkRequestStatuses } from '../shared/models';

import { TypeaheadUtil } from 'src/app/shared/utils/typeahead-util';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() isSelectMode = false;
  @Input() allReadyToPay = false;
  readyToPayStatus = checkRequestStatuses.STARTED;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'code',
    'orderCode',
    'versionLabel',
    'status',
    'accountId',
    'accountName',
    'accountant',
    'flightDate', 'flightDays',
    'requestedAmount', 'balanceCurrent', 'balanceAnticipated',
    'consultantName',
    'accountType',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'lastUpdatedBy',
    'menu'
  ];

  digitFilters = [
    {name: 'flightDays', filterDef: 'flightDays-filter'},
    {name: 'requestedAmount', filterDef: 'requestedAmount-filter'},
    {name: 'balanceCurrent', filterDef: 'balanceCurrent-filter'},
    {name: 'balanceAnticipated', filterDef: 'balanceAnticipated-filter'},
  ];

  dateFilters = [
    {name: 'flightDate', filterDef: 'flightDate-filter'},
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
  ];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    orderCode: new FormControl(''),
    versionLabel: new FormControl(''),
    status: new FormControl(''),
    accountName: new FormControl(''),
    accountId: new FormControl(''),
    accountType: new FormControl(''),
    flightDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    flightDays: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    requestedAmount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    balanceCurrent: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    balanceAnticipated: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    consultantName: new FormControl(''),
    accountant: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl('')
  });

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  isLoadingResults = true;
  selection = new SelectionModel<any>(false, []);
  selectedId: number;
  start: Date | null;
  end: Date | null;

  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasCreatePermission = this.authService.hasPermission([Role.SENIOR_ACCOUNTANT, Role.SENIOR_BUYER, Role.BUYER]);
  hasCreatePaymentPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private notificationService: NotificationService,
              private route: ActivatedRoute,
              private service: PaymentCheckRequestService,
              private authService: AuthService,
              private reportsService: ReportsService) {
  }

  ngOnInit(): void {
    this.displayedColumns = this.isSelectMode ? ['select', 'code', 'accountName', 'accountId'] : this.displayedColumns;

    const checkRequests$ = this.allReadyToPay ? this.service.getReadyToPayCheckRequests() : this.service.getCheckRequests();
    this.subscription.add(checkRequests$.subscribe((data) => {
        this.checkRequest(data);
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get check request list.')
    ));

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.flightDate.value = value.flightDate?.value?.toString();
      value.balanceCurrent.value = value.balanceCurrent?.value?.toString();
      value.requestedAmount.value = value.requestedAmount?.value?.toString();
      value.balanceAnticipated.value = value.balanceAnticipated?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));

    this.manageDateFilter();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();
  }

  checkRequest = (data) => {
    const checks = sortListByLastEdit(data);
    this.dataSource.data = checks.map(this.transformData);
    this.isLoadingResults = false;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  transformData = (item: any) => {
    const consultantName = (item.account as any).consultantCompanies?.find(consultant =>
      consultant.code === (item.account as any).leadConsultantCompanyCode)?.name;
    return {...item, consultantName};
  }

  applyDashboardFilter = () => {
    const status = this.route.snapshot.queryParamMap.get('status')?.split(','); // in case to go from dashboard
    if (status) {
      this.tableFilterForm.patchValue({status});
    }
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.service.getCheckRequests(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe(this.checkRequest,
        () => this.notificationService.error('Error occurred while trying to get check request list.'));
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'orderCode':
        return item.filter?.order?.code;
      case 'versionLabel':
        return +item.versionLabel?.substr(1);
      case 'status':
        return item.status.status;
      case 'accountName':
        return item.account.name;
      case 'accountId':
        return item.account.code;
      case 'accountType':
        return this.getAccountType(item.account);
      case 'flightDate':
        return new Date(item.summary?.earliestUpcomingFlightOn);
      case 'flightDays':
        return new Date(item.summary?.daysTillStartOfEarliestUpcomingFlight);
      case 'requestedAmount':
        return item.summary?.requestedAmount;
      case 'balanceCurrent':
        return item.summary?.paidAmount;
      case 'balanceAnticipated':
        return item.summary?.remainingToPayAmount;
      case 'accountant':
        return this.getAssignee(item);
      default:
        return item[property];
    }
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  getAssignee(checkRequest) {
    return TypeaheadUtil.displayAccountant(checkRequest.assignedAccountant) || 'N/A';
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find(item => item.id === id);
    if (row) {
      this.selection.toggle(row as any);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.account)).filter(item => !!item))
    ];
  }

  get statuses() {
    return Object.keys(checkRequestStatuses).map(name => checkRequestStatuses[name].status);
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const orderCodeSearch = FilterUtils.searchFunction(searchTerms.orderCode, data.filter?.order?.code);
      const versionLabelSearch = FilterUtils.searchFunction(searchTerms.versionLabel, data.versionLabel);
      const statusSearch = FilterUtils.createMultiSelectSearch(searchTerms.status, data.status.status);
      const accountIdSearch = FilterUtils.searchFunction(searchTerms.accountId, data.account.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account.name);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.account));
      const flightDateSearch = FilterUtils.createDateSearch('flightDate', 'earliestUpcomingFlightOn', searchTerms, data.summary);
      const flightDaysSearch = FilterUtils.createFieldDigitSearch('flightDays', searchTerms,
        data.summary?.daysTillStartOfEarliestUpcomingFlight);

      const requestedAmountSearch = FilterUtils.createFieldDigitSearch('requestedAmount', searchTerms, data.summary?.requestedAmount || 0);
      const balanceCurrentSearch = FilterUtils.createFieldDigitSearch('balanceCurrent', searchTerms, data.summary?.paidAmount || 0);
      const balanceAnticipatedSearch = FilterUtils
        .createFieldDigitSearch('balanceAnticipated', searchTerms, data.summary?.remainingToPayAmount || 0);

      const consultantNameSearch = FilterUtils.searchFunction(searchTerms.consultantName, data.consultantName);
      const accountantSearch = FilterUtils.searchFunction(searchTerms.accountant, this.getAssignee(data));

      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);

      const filterFunctions = [
        codeSearch(),
        orderCodeSearch(),
        versionLabelSearch(),
        statusSearch(),
        accountNameSearch(),
        accountIdSearch(),
        accountTypeSearch(),
        flightDateSearch(), flightDaysSearch(),
        requestedAmountSearch(), balanceCurrentSearch(), balanceAnticipatedSearch(),
        consultantNameSearch(),
        accountantSearch(),
        createdAtSearch(), createdBySearch(),
        lastUpdatedAtSearch(), lastUpdatedBySearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm, ['status']);
  }

  onExportClicked() {
    this.isReportLoading$.next(true);

    const sortedFilteredData = ReportsUtil.getSortedFilteredData<PaymentCheckRequest>(this.dataSource);
    this.reportsService.generateExcelReport(ReportsUtil.createRequestReport(sortedFilteredData, EntityType.CheckRequest))
      .subscribe(
        data => ReportsUtil.downloadFile(data, Filename.CheckRequestList),
        err => this.notificationService.error('Error occurred while trying to download report file.'),
        () => this.isReportLoading$.next(false)
      );
  }

}
