<ng-container [ngSwitch]="reportType">
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_GLOBAL">
    <app-consultant-commission-report-table [isReport]="true"
      [consultantCommissionReport]="data"></app-consultant-commission-report-table>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_GLOBAL_BALANCE">
    <app-account-balance-table [entries]="entries"></app-account-balance-table>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_CHECK">
    <app-account-check-report-table [accountCheckReport]="data"></app-account-check-report-table>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_CONSULTANT_COMPANY">
    <app-consultant-commission-report-table [isReport]="true"
      [consultantCommissionReport]="data"></app-consultant-commission-report-table>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_INVOICE">
    <ng-container *ngTemplateOutlet="ACCOUNT_TRANSACTION"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.ACCOUNT_ORDER">
    <ng-container *ngTemplateOutlet="ACCOUNT_TRANSACTION"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.STATION_INVOICE">
    <ng-container *ngTemplateOutlet="STATION_TRANSACTION"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ConvertedReportType.STATION_ORDER">
    <ng-container *ngTemplateOutlet="STATION_TRANSACTION"></ng-container>
  </ng-container>
</ng-container>

<ng-template #STATION_TRANSACTION>
  <div class="transaction-report"  *ngIf="sections.length">
    <ng-container *ngTemplateOutlet="collapseAll"></ng-container>
    <div class="container mat-elevation-z8 tables-container" *ngIf="sections.length">
      <app-transaction-collapsable-table [collapseAll]="collapseAllTable" [viewType]="viewType" [total]="total"
                                         [sections]="sections"></app-transaction-collapsable-table>
    </div>
  </div>
  <div class="table-container" *ngIf="sections.length === 0">
    <p class="no-data">No data</p>
  </div>
</ng-template>

<ng-template #ACCOUNT_TRANSACTION>
  <div class="transaction-report" *ngIf="sections.length">
    <ng-container *ngTemplateOutlet="collapseAll"></ng-container>
    <div class="container mat-elevation-z8 tables-container">
      <app-transaction-collapsable-table [collapseAll]="collapseAllTable" [viewType]="viewType"
                                         [sections]="sections"
                                         [total]="total"
                                         [accountId]="accountId"></app-transaction-collapsable-table>
    </div>
  </div>
  <div class="table-container" *ngIf="sections.length === 0">
    <p class="no-data">No data</p>
  </div>
</ng-template>

<ng-template #collapseAll>
  <div class="collapse-table-pre-info" *ngIf="sections.length">
    <div class="collapse-container">
      <mat-form-field>
        <mat-label>
          <div>Collapse All</div>
        </mat-label>
        <mat-select class="form-field" [(value)]="collapseAllTable">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>
