<div class="main">
  <h1>
    <button mat-icon-button>
      <span routerLink="/entities/accounts" class="back-arrow material-icons">keyboard_backspace</span>
    </button>
    <span>{{accountData?.name}} (ID {{accountData?.code}})</span>
    <button class="edit-button" mat-mini-fab color="primary" aria-label="Edit Station" [routerLink]="['/entities/accounts/edit', accountData?.id]" *ngIf="hasEditPermission">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading" class="container">
    <mat-card-content class="content">
      <div class="content_right">
<!--        <div class="summary-container">
          <div class="subheader subheader-summary mat-title">
            {{ accountData.candidate ? 'Candidate' : 'Client'}}  Account Summary
          </div>
          <div class="summary-container_inner">
            <div class="summary-container_inner_column">
              <div class="cost">{{ accountData.accountSummary.availableToday | currency}}</div>
              <div class="cost-caption">Available<br>Today</div>
              <div class="cost-subcaption">Previously known as Account Check</div>
            </div>
            <div class="summary-container_inner_column">
              <div class="cost">{{ accountData.accountSummary.pendingInvoices  | currency}}</div>
              <div class="cost-caption">Pending<br>Invoices</div>
              <div class="cost-subcaption">Requests for Clients to pay Canal</div>
&lt;!&ndash;              <a href="" class="view-all-ref">View All</a>&ndash;&gt;
            </div>
            <div class="summary-container_inner_column">
              <div class="cost">{{ accountData.accountSummary.outstandingCheckRequests  | currency}}</div>
              <div class="cost-caption">Outstanding Check Requests</div>
              <div class="cost-subcaption">Requests for Canal to pay Stations</div>
&lt;!&ndash;              <a href="" class="view-all-ref">View All</a>&ndash;&gt;
            </div>
            <div class="summary-container_inner_column">
              <div class="cost cost-after-all">
                ({{ accountData.accountSummary.availableAfterRequestsAndInvoices | currency}})
              </div>
              <div class="cost-caption">Available After All Requests and Invoices</div>
              <div class="cost-subcaption">Previously known as Account NAB</div>
            </div>
          </div>
        </div>-->

        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <div class="info-container_column">
              <div class="column_item">
                <mat-checkbox [checked]="accountData.archived" class="archived-state">Archived</mat-checkbox>
              </div>
              <div class="column_item">
                <div class="title">Consultant(s)</div>
                  <div
                    class="consultant-ref"
                    *ngFor="let consultant of accountData.consultantCompanies"
                    [routerLink]="['/entities/consultants/detail', consultant?.id]"
                  >
                    <a>{{consultant?.code}}, {{consultant?.name}}</a>
                  </div>

                  <div *ngIf="!accountData.consultantCompanies?.length">N/A</div>
              </div>
<!--              <div class="column_item">
                <div class="title">Year(s)</div>
                {{years || 'N/A'}}
              </div>-->
            </div>
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Address</div>
                <div>{{accountData.address?.addressLine1 || 'N/A'}}</div>
                <div>{{accountData.address?.addressLine2}}</div>
                <div>{{accountData.address?.addressLine3}}</div>
              </div>
              <div class="column_item">
                <div class="title">City</div>
                {{accountData.address?.city || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">State</div>
                {{accountData.address?.state?.name || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">ZIP Code</div>
                {{accountData.address?.zipCode || 'N/A'}}
              </div>
            </div>
          </div>
        </mat-card>

        <div class="subheader mat-title">Account Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <ng-container *ngIf="accountData.candidate; else clientInfo">
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Account Type</div>
                  Candidate
                </div>
                <div class="flex-row">
                  <div class="column_item">
                    <div class="title">Federal</div>
                    {{(accountData.federal ? 'Yes' : 'No')}}
                  </div>
                  <div class="column_item">
                    <div class="title">State</div>
                    {{(accountData.state ? 'Yes' : 'No')}}
                  </div>
                </div>
                <div class="column_item">
                  <div class="title">Candidate Name</div>
                  {{getNabField('account.cnd.name') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">Authorized Committee</div>
                  {{getNabField('account.cnd.committee.name') || 'N/A'}}
                </div>
              </div>
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Candidate's Political Party</div>
                  {{getNabField('account.cnd.party') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">Treasurer of candidate's authorized committee</div>
                  {{getNabField('account.cnd.treasurer') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">Office sought (no acronyms or abbreviations)</div>
                  {{getNabField('account.cnd.office.sought') || 'N/A'}}
                </div>
                <div class="column_item">
                  {{getNabField('account.cnd.undersigned.represents.candidate') === 'true' ?
                  'The candidate listed above who is a legally qualified candidate.' :
                  'The authorized committee of the legally qualified candidate listed above.'}}
                </div>
              </div>
            </ng-container>

            <ng-template #clientInfo>
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Account Type</div>
                  Client
                </div>
                <div class="column_item">
                  <div class="title">Advocacy</div>
                  {{(accountData.advocacy ? 'Yes' : 'No')}}
                </div>
                <div class="column_item" *ngIf="!accountData.candidate && accountData.advocacy">
                  {{getNabField('account.cli.ad.national.importance.communicate') === 'true' ?
                  'Ad "communicates a message relating to any political matter of national importance" by referring to (1) a legally qualified candidate for federal office, (2) an election to federal office; (3) a national legislative issue of public importance (e.g., health care legislation, IRS tax code, etc.); or (4) a political issue that is the subject of controversy or discussion at a national level.' :
                  'Ad does NOT communicate a message relating to any political matter of national importance (e.g., relates only to a state or local issue).'}}
                </div>
                <div class="column_item">
                  <div class="title">Name</div>
                  {{getNabField('account.cli.advertiser.full_name') || 'N/A'}}
                </div>
              </div>
              <div class="info-container_column">
                <div class="column_item">
                  <div class="title">Contact</div>
                  {{getNabField('account.cli.advertiser.contact.name') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">Phone Number</div>
                  {{getNabField('account.cli.advertiser.phone') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">Email</div>
                  {{getNabField('account.cli.advertiser.email') || 'N/A'}}
                </div>
                <div class="column_item">
                  <div class="title">List the chief executive officers or members of the executive committee and the board of directors or other governing group(s) of the advertiser/sponsor</div>
                  {{getNabField('account.cli.executive.officers') || 'N/A'}}
                </div>
              </div>
            </ng-template>
          </div>
        </mat-card>
      </div>

      <div class="content_left">
        <div class="buttons-container">
          <button mat-raised-button class="button-transaction" [matMenuTriggerFor]="transactionMenu" *ngIf="hasEditPermission && !accountData?.archived" >
            Add New Transaction
          </button>
          <mat-menu #transactionMenu="matMenu">
            <button mat-menu-item type="button" (click)="onAddInvoiceClicked()" *ngIf="hasCreateInvoicePermission">Add Invoice</button>
            <button mat-menu-item type="button" (click)="onAddPaymentClicked()" *ngIf="hasCreatePaymentPermission">Add Accept Payment</button>
          </mat-menu>
          <button mat-raised-button class="button-transaction" (click)="onViewAllTransactionClicked()">
            View All Transactions
          </button>
        </div>

        <app-table-commission-tiers [commissionTiers]="accountData.commissionTiers"></app-table-commission-tiers>
      </div>
    </mat-card-content>
  </mat-card>
</div>
