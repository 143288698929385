import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';

import { AcceptPaymentService } from '../shared/accept-payment.service';
import { AccountInvoiceService } from '../shared/account-invoice.service';
import { NotificationService } from '../../shared/notification.service';
import { ReportsService } from '../../entities/shared/reports.service';
import { AuthService } from '../../shared/auth.service';

import { sortListByLastEdit, getClientCandidateType } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { AccountInvoice, accountInvoiceStatuses, ConvertedStatus } from '../shared/models';
import { Role } from '../../shared/models/role.enum';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-account-invoice-list',
  templateUrl: './account-invoice-list.component.html',
  styleUrls: ['./account-invoice-list.component.scss']
})
export class AccountInvoiceListComponent implements OnInit, OnDestroy {
  @Input() isSelectMode = false;
  @Input() isAccountInvoiceDialog = false;
  @Input() accountId: number;

  private subscription = new Subscription();
  isReportLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  start: Date | null;
  end: Date | null;
  isLoadingResults = true;
  dataSource = new MatTableDataSource<AccountInvoice>();
  selection = new SelectionModel<AccountInvoice>(true, []);
  selectedIds: number[];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    status: new FormControl(''),
    clientName: new FormControl(''),
    clientCode: new FormControl(''),
    consultant: new FormControl(''),
    type: new FormControl(''),
    requested: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    paid: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    remaining: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    editedBy: new FormControl(''),
    submittedOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ftcOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    ltcOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    electionType: new FormControl(''),
    electionDate: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    })
  });

  displayedColumns: string[] = [
    'code', 'status', 'clientName', 'clientCode',
    'consultant', 'type',
    'requested', 'paid', 'remaining',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'editedBy',
    'submittedOn', 'ftcOn', 'ltcOn', 'electionType', 'electionDate', 'menu'
  ];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  digitFilters = [
    {name: 'requested', filterDef: 'requested-filter'},
    {name: 'paid', filterDef: 'paid-filter'},
    {name: 'remaining', filterDef: 'remaining-filter'}
  ];

  dateFilters = [
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
    {name: 'submittedOn', filterDef: 'submittedOn-filter'},
    {name: 'ftcOn', filterDef: 'ftcOn-filter'},
    {name: 'ltcOn', filterDef: 'ltcOn-filter'},
    {name: 'electionDate', filterDef: 'electionDate-filter'},
  ];

  hasCreatePermission = this.authService.hasPermission([Role.BUYER, Role.SENIOR_BUYER]);
  hasCreatePaymentPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private acceptPaymentService: AcceptPaymentService,
              private service: AccountInvoiceService,
              private notificationService: NotificationService,
              private reportsService: ReportsService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.displayedColumns = this.isSelectMode ? ['select', 'code', 'consultant', 'requested', 'paid', 'remaining'] : this.displayedColumns;
    const invoiceList$: Observable<any> = this.isAccountInvoiceDialog
      ? this.acceptPaymentService.getAllAccountInvoices(String(this.accountId))
      : this.service.getInvoiceList();

    this.subscription.add(invoiceList$.subscribe((data) => {
        this.initInvoices(data);
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get invoice list.')
    ));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();

    this.manageDateFilter();

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.submittedOn.value = value.submittedOn?.value?.toString();
      value.ftcOn.value = value.ftcOn?.value?.toString();
      value.ltcOn.value = value.ltcOn?.value?.toString();
      value.electionDate.value = value.electionDate?.value?.toString();
      value.paid.value = value.paid?.value?.toString();
      value.requested.value = value.requested?.value?.toString();
      value.remaining.value = value.remaining?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));
  }

  applyDashboardFilter = () => {
    const status = this.route.snapshot.queryParamMap.get('status'); // in case to go from dashboard
    if (status) {
      this.tableFilterForm.patchValue({status});
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initInvoices = (data) => {
    this.dataSource.data = sortListByLastEdit(data);
    this.isLoadingResults = false;

    if (this.selectedIds) {
      this.initSelected(this.selectedIds);
    }
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'code':
        return item.code;
      case 'status':
        return item.accountInvoiceStatus.status;
      case 'clientName':
        return item.account.name;
      case 'clientCode':
        return item.account.code;
      case 'consultant':
        return this.getLeadConsultantCompanyName(item);
      case 'type':
        return this.getAccountType(item.account);
      case 'paid':
        return item.paidAmount;
      case 'requested':
        return item.requestedAmount;
      case 'ftcOn':
        return this.getInvoiceFTC(item);
      case 'ltcOn':
        return this.getInvoiceLTC(item);
      case 'remaining':
        return this.getRemainingAmountValue(item);
      case 'electionType':
        return item.electionType?.type;
      case 'electionDate':
        return item.electionDate;
      case 'editedBy':
        return item.lastUpdatedBy;
      case 'submittedOn':
        return item.completedOn;
      default:
        return item[property];
    }
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          FilterUtils.clearFilterForm(this.tableFilterForm);
          return this.service.getInvoiceList(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe(this.initInvoices,
        () => this.notificationService.error('Error occurred while trying to get account invoice list.'));
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get statuses() {
    return [accountInvoiceStatuses.DRAFT.status, accountInvoiceStatuses.SUBMITTED.status, accountInvoiceStatuses.PAID.status];
  }

  get types() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.account)).filter(item => !!item))
    ];
  }

  get electionTypes() {
    return [...new Set(this.dataSource.data.map(item => item.electionType?.type).filter(item => !!item))
    ];
  }

  getLeadConsultantCompanyName(row: AccountInvoice) {
    if (!row.account?.consultantCompanies?.length) {
      return;
    }

    return row.account.consultantCompanies
      .find(consultantCompany => consultantCompany.code === row.account.leadConsultantCompanyCode)
      .name;
  }

  getInvoiceFTC(row: AccountInvoice) {
    if (!row.invoicedOrdersData?.orderedData) {
      return;
    }

    const ordersFTC = row.invoicedOrdersData.orderedData?.map(dataItem => new Date(dataItem.startDate)) ?? null;
    return new Date(Math.min.apply(null, ordersFTC));
  }

  getInvoiceLTC(row: AccountInvoice) {
    if (!row.invoicedOrdersData?.orderedData) {
      return;
    }

    const ordersLTC = row.invoicedOrdersData.orderedData
      .map(dataItem => new Date(dataItem.endDate));

    return new Date(Math.max.apply(null, ordersLTC));
  }

  onViewClicked(invoiceId) {
    this.router.navigate(['/orders/transactions/invoice/detail', invoiceId]);
  }

  onBackClicked() {
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  initSelected(ids: number[]) {
    if (this.isLoadingResults) {
      this.selectedIds = ids;
      return;
    }
    if (this.selectedIds.length > 0) {
      const rows = this.dataSource.data.filter((item: AccountInvoice) => {
        return ids.includes(item.id);
      });
      rows.forEach(row => this.selection.toggle(row as AccountInvoice));
    }
  }

  getRemainingAmountValue(row: AccountInvoice): number {
    return parseFloat((row.requestedAmount - row.paidAmount).toFixed(2)) || 0;
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const invoiceStatusSearch = FilterUtils.createSelectSearch(searchTerms.status, data.accountInvoiceStatus.status);
      const clientNameSearch = FilterUtils.searchFunction(searchTerms.clientName, data.account?.name);
      const clientCodeSearch = FilterUtils.searchFunction(searchTerms.clientCode, data.account?.code);
      const consultantNameSearch = FilterUtils.searchFunction(searchTerms.consultant, this.getLeadConsultantCompanyName(data));
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.type, this.getAccountType(data.account));

      const requestedSearch = FilterUtils.createFieldDigitSearch('requested', searchTerms, data.requestedAmount);
      const paidSearch = FilterUtils.createFieldDigitSearch('paid', searchTerms, data.paidAmount);
      const remainingSearch = FilterUtils.createFieldDigitSearch('remaining', searchTerms, this.getRemainingAmountValue(data));

      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const editedBySearch = FilterUtils.searchFunction(searchTerms.editedBy, data.lastUpdatedBy);
      const createdSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);

      const submittedOnSearch = FilterUtils.createDateSearch('submittedOn', 'completedOn', searchTerms, data);
      const ftcOnSearch = FilterUtils.createDateSearch('ftcOn', 'ftcOn', searchTerms, {ftcOn: this.getInvoiceFTC(data)});
      const ltcOnSearch = FilterUtils.createDateSearch('ltcOn', 'ltcOn', searchTerms, {ltcOn: this.getInvoiceLTC(data)});
      const electionTypeSearch = FilterUtils.createSelectSearch(searchTerms.electionType, data.electionType?.type);
      const electionDateSearch = FilterUtils.createDateSearch('electionDate', 'electionDate', searchTerms, data);


      const filterFunctions = [
        codeSearch(),
        invoiceStatusSearch(),
        clientNameSearch(),
        clientCodeSearch(),
        consultantNameSearch(),
        accountTypeSearch(),

        requestedSearch(),
        paidSearch(),
        remainingSearch(),

        lastUpdatedAtSearch(),
        editedBySearch(),
        createdSearch(),
        createdBySearch(),

        submittedOnSearch(),
        ftcOnSearch(),
        ltcOnSearch(),
        electionTypeSearch(),
        electionDateSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  onExportClicked() {
    // @todo: remove code
  }

  showMenu(row) {
    return row.permissions?.indexOf('EDIT') > -1 || this.canCreatePayment(row);
  }

  canCreatePayment(row) {
    return this.hasCreatePaymentPermission && row.accountInvoiceStatus.convertedStatus === ConvertedStatus.SUBMITTED;
  }
}
