<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Station Invoice</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit(form)" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <h2>General Info</h2>

          <mat-form-field *ngIf="!isCreateMode">
            <mat-label>Station Invoice ID#</mat-label>
            <input formControlName="code" matInput readonly>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Account</mat-label>
            <input type="text"
              matInput
              maxlength="50"
              required
              formControlName="account"
              #accountInput
              (blur)="onBlurAccount(accountInput)"
              [matAutocomplete]="autoAccounts"
              [readonly]="!isCreateMode"
            >
            <button mat-icon-button matSuffix (click)="lockAccount($event)"
                    [attr.aria-label]="'Lock account'" [matTooltip]="accountLocked ? 'Unlock account': 'Lock account'"
                    type="button">
              <mat-icon class="material-icons" color="{{accountLocked ? 'primary' : ''}}">{{accountLocked ?  'lock' : 'lock_open'}}</mat-icon>
            </button>
            <mat-autocomplete #autoAccounts="matAutocomplete" [displayWith]="displayAccount">
              <mat-option *ngIf="accounts.length === 0" [value]="null">No Accounts with ordered Station</mat-option>
              <mat-option *ngFor="let option of filteredAccounts | async"
                [value]="option"
                (mousedown)="onAccountOptionSelect(option)"
              >
                {{option.code}} - {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openAccountDialog()" *ngIf="isCreateMode && !accountDisabled">Browse
            Accounts >
          </div>

          <mat-form-field>
            <mat-label>Station</mat-label>
            <input type="text" matInput
              required
              maxlength="50"
              #stationInput
              (blur)="onBlurStation(stationInput)"
              [matAutocomplete]="autoStations"
              formControlName="station"
              [readonly]="!isCreateMode"
            >
            <button mat-icon-button matSuffix (click)="lockStation($event)"
                    [attr.aria-label]="'Lock station'" [matTooltip]="stationLocked ? 'Unlock station' : 'Lock station'"
                    type="button">
              <mat-icon class="material-icons" color="{{accountLocked ? 'primary' : ''}}">{{stationLocked ?  'lock' : 'lock_open'}}</mat-icon>
            </button>
            <mat-autocomplete #autoStations="matAutocomplete" [displayWith]="displayStation">
              <mat-option *ngIf="accountControl.value && stations.length === 0" [value]="null">No ordered Stations for Account</mat-option>
              <mat-option *ngIf="!accountControl.value && stations.length === 0" [value]="null">Enter the first two letters of the name to start filtering</mat-option>
              <mat-option *ngFor="let option of filteredStations | async"
                [value]="option"
                (mousedown)="onStationOptionSelect(option)"
              >
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openStationDialog()" *ngIf="isCreateMode && !stationDisabled">Browse
            Stations >
          </div>

          <h2>Invoice</h2>
          <mat-form-field>
            <mat-label>Invoice Number</mat-label>
            <input matInput formControlName="referenceNumber" required maxlength="50">
            <mat-error *ngIf="form.get('referenceNumber').hasError('validation')">
              Invoice number should be unique for account and station.</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Invoice Created Date</mat-label>
            <input matInput formControlName="addedOn" [matDatepicker]="pickerAddedOn" required>
            <mat-datepicker-toggle matSuffix [for]="pickerAddedOn"></mat-datepicker-toggle>
            <mat-datepicker #pickerAddedOn></mat-datepicker>
            <mat-error *ngIf="form.get('addedOn').invalid">
              Invoice Created Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Invoice Date Span</mat-label>
            <mat-date-range-input [rangePicker]="picker" required>
              <input matInput required matStartDate formControlName="ftcOn" placeholder="Start date">
              <input matInput required matEndDate formControlName="ltcOn" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="form.get('ftcOn').invalid || form.get('ltcOn').invalid">
              Invoice Date Span is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Amount</mat-label>
            <input matInput type="number" formControlName="netRanAmount" maxlength="17" required>
            <mat-icon matSuffix>attach_money</mat-icon>
          </mat-form-field>
        </div>
        <div class="form-content_column">
          <div class="upload-invoice">
            <h2>Upload Invoice</h2>
            <app-file-upload
              [filesUploadedBefore]="documentsUploadedBefore"
              (filesChanges)="setStationInvoiceDocumentation($event)"
              [id]="stationInvoiceId"
              [mode]="mode"
              [component]="component"
            >
            </app-file-upload>
          </div>

          <h2>Notes</h2>
          <div *ngFor="let note of noteReverse" class="note">
            <div><b>{{note | noteHeader}}</b></div>
            <div><span>{{note.message}}</span></div>
          </div>

          <mat-form-field appearance="fill">
            <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
          </mat-form-field>

        </div>
      </mat-card-content>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button type="submit" mat-raised-button appLoading color="primary"
        [loading]="saving" [disabled]="!form.valid || isRequesting"
      >Save
      </button>
      <button type="button" mat-raised-button appLoading color="primary"
        [loading]="submitting" [disabled]="!form.valid || isRequesting" (click)="submit(form, true)"
      >Save and Add New
      </button>
      <button type="button" mat-raised-button [disabled]="isRequesting" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>

