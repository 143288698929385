<div *ngIf="rows.length">
  <div class="header-row">
    <div class="order-row-item">Description <span *ngIf="!isDetail">*</span></div>
    <div class="order-row-item">Media Type <span *ngIf="!isDetail">*</span></div>
    <mat-form-field class="order-row-item header-item" *ngIf="isCustomColumn1">
      <input matInput [value]="invoicedOrdersData?.customColumn1Name" placeholder="Custom Column 1"
             (change)="updateCustomName($event, 'customColumn1Name')" maxlength="25" [readonly]="isDetail" [disabled]="disabled">
    </mat-form-field>
    <mat-form-field class="order-row-item header-item" *ngIf="isCustomColumn2">
      <input matInput [value]="invoicedOrdersData?.customColumn2Name" placeholder="Custom Column 2"
             (change)="updateCustomName($event, 'customColumn2Name')" maxlength="25" [readonly]="isDetail" [disabled]="disabled">
    </mat-form-field>
    <div class="order-row-item">Start Date<span *ngIf="!isDetail">*</span></div>
    <div class="order-row-item">End Date <span *ngIf="!isDetail">*</span></div>
    <div class="order-row-item">Gross <span *ngIf="!isDetail">*</span> {{percentView}} %</div>
    <div class="order-row-item">Adjusted Gross</div>
    <div class="order-row-item">Net Amount <span *ngIf="!isDetail">*</span></div>
    <div class="delete-row" *ngIf="!isDetail"></div>
  </div>

  <div class="order-row" [ngClass]="{'error': hasError(index)}" *ngFor="let row of rows; let index = index">
    <mat-form-field class="order-row-item">
      <input matInput [formControl]="getControl(index, 'description')" required maxlength="25" [readonly]="isDetail">
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [formControl]="getControl(index, 'mediaType')" required maxlength="25" [readonly]="isDetail">
    </mat-form-field>
    <mat-form-field class="order-row-item" *ngIf="isCustomColumn1">
      <input matInput [formControl]="getControl(index, 'customColumn1Value')" maxlength="25" [readonly]="isDetail">
    </mat-form-field>
    <mat-form-field class="order-row-item" *ngIf="isCustomColumn2">
      <input matInput [formControl]="getControl(index, 'customColumn2Value')" maxlength="25" [readonly]="isDetail">
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [matDatepicker]="pickerStartDate" [formControl]="getControl(index, 'startDate')" required [readonly]="isDetail">
      <mat-datepicker-toggle matSuffix [for]="pickerStartDate" *ngIf="!isDetail"></mat-datepicker-toggle>
      <mat-datepicker #pickerStartDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [matDatepicker]="pickerEndDate" [formControl]="getControl(index, 'endDate')" required [readonly]="isDetail">
      <mat-datepicker-toggle matSuffix [for]="pickerEndDate" *ngIf="!isDetail"></mat-datepicker-toggle>
      <mat-datepicker #pickerEndDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [formControl]="getControl(index, 'gross')"
        (change)="updateGross($event, index)" type="number"
        appPositiveDecimalNumber required
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        min="0"
        maxlength="25"
        [readonly]="isDetail"
      >
      <span matPrefix>$&nbsp;</span>
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [value]="getAdjustedGross(index)" readonly>
      <span matPrefix>$&nbsp;</span>
    </mat-form-field>
    <mat-form-field class="order-row-item">
      <input matInput [formControl]="getControl(index, 'netAmount')"
        (change)="updateNetAmount($event, index)" type="number"
        appPositiveDecimalNumber required
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        min="0"
        maxlength="25"
        [readonly]="isDetail"
      >
      <span matPrefix>$&nbsp;</span>
    </mat-form-field>
    <button type="button"
            class="delete-row"
            mat-icon-button
            color="accent"
            (click)="deleteRow(index)"
            matTooltip="Delete row"
            [disabled]="disabled"
            *ngIf="!isDetail">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
  <div class="footer-row">
    <div class="order-row-item"></div>
    <div class="order-row-item"></div>
    <div class="order-row-item" *ngIf="isCustomColumn1"></div>
    <div class="order-row-item" *ngIf="isCustomColumn2"></div>
    <div class="order-row-item"></div>
    <div class="order-row-item"></div>
    <div class="order-row-item">{{grossSumm | currency}}</div>
    <div class="order-row-item">{{adjustedGrossSumm |currency}}</div>
    <div class="order-row-item">{{netAmountSumm | currency}}</div>
    <div class="delete-row" *ngIf="!isDetail"></div>
  </div>
</div>
