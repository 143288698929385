import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/auth/auth.guard';
import { Role } from '../shared/models/role.enum';

import { PaymentListComponent } from './payment-list/payment-list.component';
import { OrdersComponent } from './orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { CheckListComponent } from './check-list/check-list.component';
import { CheckEditComponent } from './check-edit/check-edit.component';
import { CheckDetailComponent } from './check-detail/check-detail.component';
import { PaymentEditComponent } from './payment-edit/payment-edit.component';
import { TransactionEditComponent } from './transaction-edit/transaction-edit.component';
import { StationInvoiceEditComponent } from './station-invoice-edit/station-invoice-edit.component';
import { StationInvoiceListComponent } from './station-invoice-list/station-invoice-list.component';
import { StationInvoiceDetailComponent } from './station-invoice-detail/station-invoice-detail.component';
import { PayExpenseComponent } from './pay-expense/pay-expense.component';
import { AcceptPaymentListComponent } from './accept-payment-list/accept-payment-list.component';
import { AcceptPaymentEditComponent } from './accept-payment-edit/accept-payment-edit.component';
import { AcceptPaymentDetailComponent } from './accept-payment-detail/accept-payment-detail.component';
import { AccountInvoiceEditComponent } from './account-invoice-edit/account-invoice-edit.component';
import { AccountInvoiceDetailComponent } from './account-invoice-detail/account-invoice-detail.component';
import { AccountInvoiceListComponent } from './account-invoice-list/account-invoice-list.component';
import { ExpensesListComponent } from './expenses-list/expenses-list.component';
import { MemoRefundListComponent } from './memo-refund-list/memo-refund-list.component';
import { CommissionListComponent } from './commission-list/commission-list.component';
import { ConsultantCommissionComponent } from './consultant-commission/consultant-commission.component';

const routes: Routes = [
  {
    path: 'orders', component: OrdersComponent, children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      { path: 'orders', component: OrderListComponent },
      { path: 'orders/edit/:id', component: OrderEditComponent,
        canActivate: [ AuthGuard ],
        data: {
          roles: [ Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT ]
        }
      },
      { path: 'orders/detail/:id', component: OrderDetailComponent },
      { path: 'checks', component: CheckListComponent },
      { path: 'checks/edit/:id', component: CheckEditComponent,
        canActivate: [ AuthGuard ],
        data: {
          roles: [ Role.BUYER, Role.SENIOR_BUYER, Role.SENIOR_ACCOUNTANT ]
        }
      },
      { path: 'checks/detail/:id', component: CheckDetailComponent },
      { path: 'payments', component: PaymentListComponent },
      { path: 'payments/edit/:id', component: PaymentEditComponent },
      { path: 'transactions/invoice/edit/:id', component: StationInvoiceEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [ Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER ]
        }
      },
      { path: 'transactions/invoice/detail/:id', component: StationInvoiceDetailComponent },
      { path: 'transactions/invoice', component: StationInvoiceListComponent },
      { path: 'expenses', component: ExpensesListComponent },
      { path: 'expenses/edit/:id', component: PayExpenseComponent },
      { path: 'accept-payment', component: AcceptPaymentListComponent },
      { path: 'accept-payment/edit/:id', component: AcceptPaymentEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]
        }
      },
      { path: 'accept-payment/edit', component: AcceptPaymentEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]
        }
      },
      { path: 'accept-payment/detail/:id', component: AcceptPaymentDetailComponent },
      { path: 'cinvoices/edit/:id', component:  AccountInvoiceEditComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.SENIOR_ACCOUNTANT, Role.SENIOR_BUYER, Role.BUYER]
        }
      },
      { path: 'cinvoices/detail/:id', component: AccountInvoiceDetailComponent },
      { path: 'cinvoices', component: AccountInvoiceListComponent },
      { path: 'memo-refund', component: MemoRefundListComponent },
      { path: 'memo-refund/edit/:id', component: TransactionEditComponent },
      { path: 'commissions', component: CommissionListComponent },
      { path: 'commissions/edit/:id', component: ConsultantCommissionComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
