import { FormControl, FormGroup } from '@angular/forms';
import { getClientCandidateType, getLeadConsultantName } from '../../shared/utils/sort-list-util';
import { CommissionUtil } from '../shared/utils/commission-util';

export const tableFilterFormPaid = new FormGroup({
  code: new FormControl(''),
  accountCode: new FormControl(''),
  accountName: new FormControl(''),
  accountType: new FormControl(''),
  consultantName: new FormControl(''),
  consultantCode: new FormControl(''),
  status: new FormControl(''),
  quickBookReference: new FormControl(''),
  assignedAccountant: new FormControl(''),
  amount: new FormGroup({
    symbol: new FormControl('='),
    value: new FormControl('')
  }),
  completedOn: new FormGroup({
    symbol: new FormControl('='),
    value: new FormControl('')
  }),
  createdAt: new FormGroup({
    symbol: new FormControl('='),
    value: new FormControl('')
  }),
  createdBy: new FormControl(''),
  lastUpdatedAt: new FormGroup({
    symbol: new FormControl('='),
    value: new FormControl('')
  }),
  lastUpdatedBy: new FormControl('')
});

export const tableFilterFormOwed = new FormGroup({
  accountCode: new FormControl(''),
  accountName: new FormControl(''),
  accountType: new FormControl(''),
  consultantName: new FormControl(''),
  consultantCode: new FormControl(''),
  status: new FormControl(''),
  quickBookReference: new FormControl(''),
  assignedAccountant: new FormControl(''),
  commissionOwed: new FormGroup({
    symbol: new FormControl('='),
    value: new FormControl('')
  })
});

export const displayedColumnsPaid: string[] = [
  'code', 'accountCode', 'accountName',
  'accountType', 'consultantName',
  'status', 'quickBookReference', 'amount', 'assignedAccountant',
  'completedOn', 'createdAt', 'createdBy', 'lastUpdatedAt',
  'lastUpdatedBy', 'menu'
];

export const displayedColumnsOwed: string[] = [
  'consultantName', 'consultantCode', 'accountName', 'accountCode',
  'accountType', 'commissionOwed', 'addCommission', 'menu'
];

export const digitFiltersPaid = [{name: 'amount', filterDef: 'amount-filter'}];
export const digitFiltersOwed = [{name: 'commissionOwed', filterDef: 'commissionOwed-filter'}];

export const dateFiltersPaid = [
  {name: 'createdAt', filterDef: 'createdAt-filter'},
  {name: 'completedOn', filterDef: 'completedOn-filter'},
  {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
];
export const dateFiltersOwed = [];


export const sortingDataAccessorPaid = (item: any, property: string) => {
  switch (property) {
    case 'accountCode':
      return item.account?.code;
    case 'accountName':
      return item.account?.name;
    case 'accountType':
      return getClientCandidateType(item.account);
    case 'consultantName':
      return item.consultantCompany?.name;
    case 'status':
      return CommissionUtil.getStatus(item);
    case 'amount':
      return item.toPayAmount;
    case 'completedOn':
      return new Date(item.completedOn);
    case 'assignedAccountant':
      return CommissionUtil.getAssignedAccountant(item);
    default: return item[property];
  }
};

export const sortingDataAccessorOwed = (item: any, property: string) => {
  switch (property) {
    case 'consultantName':
      return getLeadConsultantName(item);
    case 'consultantCode':
      return item.leadConsultantCompanyCode;
    case 'accountCode':
      return item.code;
    case 'accountName':
      return item.name;
    case 'accountType':
      return getClientCandidateType(item);
    case 'commissionOwed':
      return item.accountSummary?.commissionOwed;
    default: return item[property];
  }
};

