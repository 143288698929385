import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AcceptPayment } from '../shared/models';
import { Role } from '../../shared/models/role.enum';
import { AcceptPaymentService } from '../shared/accept-payment.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { ComponentType } from '../../shared/models/component-type.enum';

@Component({
  selector: 'app-accept-payment-detail',
  templateUrl: './accept-payment-detail.component.html',
  styleUrls: ['./accept-payment-detail.component.scss']
})
export class AcceptPaymentDetailComponent implements OnInit {
  acceptPaymentData: AcceptPayment;
  loading = true;
  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);
  component = ComponentType.AcceptPayment;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private service: AcceptPaymentService,
              private authService: AuthService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!id) {
      this.router.navigate(['/orders/accept-payment']);
      return;
    }
    const [accountId, acceptPaymentId] = id?.split('-');
    this.service.getAcceptPayment(accountId, acceptPaymentId).subscribe(
      order => {
        this.acceptPaymentData = order;
        this.sortBySortNumber('acceptPaymentInvoices');
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get order details')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  get totalAmount() {
    return this.acceptPaymentData.acceptPaymentInvoices
      .map(invoice => invoice.amount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

  sortBySortNumber(prop: string) {
    if (!this.acceptPaymentData[prop]) {
      return;
    }

    this.acceptPaymentData[prop] = this.acceptPaymentData[prop]
      .sort((item1, item2) => (item1.sortNumber < item2.sortNumber) ? -1 : 1);
  }


  returnClicked() {
    this.router.navigateByUrl('/orders/accept-payment');
  }

}
