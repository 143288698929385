import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface UATJson {
  uatMode: string;
}

@Injectable({
  providedIn: 'root'
})
export class UatService {
  constructor(private http: HttpClient) {}

  setUatTrademark(): void {
    this.getUATIdentifier().subscribe(data => {
      if (!!data.uatMode && data.uatMode === 'true') {
        const htmlElement = document.querySelector('html');
        htmlElement.classList.add('uat-mode');
      }
    },
      error => {});
  }

  private  getUATIdentifier(): Observable<UATJson> {
    return this.http.get<UATJson>('assets/config.json');
  }




}
