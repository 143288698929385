import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

function removeError(control: AbstractControl, errorKey) {
  if (!control.hasError(errorKey)) {
    return;
  }

  const errors: ValidationErrors = control.errors;
  delete errors[errorKey];

  control.setErrors(Object.keys(errors).length ? errors : null);
}

export default class ValidatorsExtension extends Validators {
  static dateRangeRequiredValidator(datesGroup: FormGroup): ValidationErrors | null {
    const errorMessage = 'Both of dates are required';
    const areAllControlsHaveValue = Object.values(datesGroup.controls).every(control => !!control.value);
    const validationError = { dateRangeRequired: errorMessage };
    // clear errors for each of child controls to disable error state matching
    const clearError = () => Object.values(datesGroup.controls)
      .forEach(control => removeError(control, 'dateRangeRequired'));

    if (areAllControlsHaveValue) {
      clearError();
      return null;
    }

    const areAllControlsDontHaveValue = Object.values(datesGroup.controls).every(control => !control.value);

    if (areAllControlsDontHaveValue) {
      clearError();
      return null;
    }

    // Manually set errors to each of child controls for triggering show error on the form field
    Object.values(datesGroup.controls).forEach(control => control.setErrors({ dateRangeRequired: errorMessage }));

    return validationError;
  }

  static flightDatesValidator(datesGroup: FormGroup): ValidationErrors | null {
    const errorMessage = 'LTC date should not be less than FTC date.';
    const isLTCLessThanFTC = (ftcDate: Date, ltcDate: Date) => {
      if (!ftcDate || !ltcDate) {
        return false;
      }
      return ltcDate.getTime() < ftcDate.getTime();
    };
    const isError = isLTCLessThanFTC(datesGroup.get('ftcDateControl').value, datesGroup.get('ltcDateControl').value);
    const validationError = { flightDatesError: errorMessage };
    // clear errors for each of child controls to disable error state matching
    const clearError = () => Object.values(datesGroup.controls)
      .forEach(control => removeError(control, 'flightDatesError'));

    if (!isError) {
      clearError();
      return null;
    }

    datesGroup.get('ftcDateControl').setErrors(validationError);
    datesGroup.get('ltcDateControl').setErrors(validationError);

    return validationError;
  }
}
