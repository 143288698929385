import { CommissionTier } from '../shared/models';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input } from '@angular/core';
import { sortByNumber } from '../../shared/utils/sort-list-util';

@Component({
  selector: 'app-table-commission-tiers',
  templateUrl: './table-commission-tiers.component.html',
  styleUrls: ['./table-commission-tiers.component.scss']
})
export class TableCommissionTiersComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @Input() commissionTiers: CommissionTier[] = [];
  displayedColumns;
  constructor() { }

  ngOnInit(): void {
    sortByNumber(this.commissionTiers, 'sortNumber');
    this.sortConsultantsBySortNumber();
    this.setDisplayedTableColumns();
    this.setTableDataSource();
  }

  sortConsultantsBySortNumber() {
    this.commissionTiers.forEach(commissionTier => sortByNumber(commissionTier.consultantRates, 'sortNumber'));
  }

  setDisplayedTableColumns() {
    this.displayedColumns = [ 'tierAmount' ];
    this.commissionTiers[0].consultantRates.forEach((consultantRate, index) => {
      this.displayedColumns.push(`consultant${index}`);
    });

    this.displayedColumns.push('canal');
    this.displayedColumns.push('roll');
  }

  setTableDataSource() {
    let startIntervalPoint = 0;
    let endIntervalPoint;

    this.dataSource.data = this.commissionTiers.map((commissionTier, index) => {
      endIntervalPoint = commissionTier.breakPoint ?
                         commissionTier.breakPoint : '∞';

      const intervalData = {
        tierAmount: `$${startIntervalPoint} - $${endIntervalPoint}`,
        consultantRate: [],
        canal: `${commissionTier.cpmRate}%`,
        roll: `${commissionTier.rollRate}%`
      };

      commissionTier.consultantRates.forEach((consultantRate) => {
        intervalData.consultantRate.push(`${consultantRate.rate}%`);
      });

      startIntervalPoint = endIntervalPoint;
      return intervalData;
    });
  }

}
