import { VersionedEntity } from '../../../shared/models/versioned-entity';
import { PaymentBatchStatus } from './payment-batch-status';
import { PaymentCheckRequest } from './check-request';
import { User } from '../../../shared/models/user';
import { PaymentBatchSummary } from './payment-batch-summary';
import { PaymentBatchAssignmentSummaryEntry } from './payment-batch-assignment-summary-entry';
import { PaymentMethod } from '../../../entities/shared/models';

export interface PaymentCheckBatch extends VersionedEntity {
  id: number;
  code: string;
  status: PaymentBatchStatus;
  checkRequest: PaymentCheckRequest;
  paymentMethodAccountantAssignments: PaymentMethodAccountantAssignment[];
  summary: PaymentBatchSummary;
  assignmentSummary: PaymentBatchAssignmentSummaryEntry[];
}

export interface PaymentMethodAccountantAssignment {
  paymentMethod: PaymentMethod;
  accountant: User;
}

export const  paymentBatchStatuses = {
  PROCESSING_1: {id: 1, status: 'Processing 1'},
  PROCESSING_2: {id: 2, status: 'Processing 2'},
  PAID: {id: 3, status: 'Paid'}
};
