  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <div class="mat-toolbar_inner">
        <div class="mat-toolbar_logo-title">
          <img src="assets/image/logo.png" alt="logo" width="58" height="58">
          <span class="title">{{title}}</span>
        </div>

        <div class="nav-container">
          <nav class="main-nav">
            <ul role="navigation">
              <li (mouseenter) ="over(navListItem.subnavTitles)" (mouseleave) ="out()" *ngFor="let navListItem of navList">
                <a class="nav-link" [routerLink]="navListItem.routerLink" routerLinkActive="active">
                  <span class="nav-link-inner">{{navListItem.navTitle}}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <span class="current-user_company">{{companyName}}</span>
        <span class="current-user">{{currentUser}}</span>
        <a mat-fab class="lock-button" color="primary" target="_blank" [href]="keycloakUrl" *ngIf="isSuperAdmin"><mat-icon>lock</mat-icon></a>
        <button mat-fab color="primary" (click)="logout()"><mat-icon>exit_to_app</mat-icon></button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="subnav-container"
       *ngIf="this.selectedSubnavTitles.length"
       (mouseenter) ="showSubmenu=true"
       (mouseleave) ="outSub()">
    <app-navbar-submenu [subnavTitles]="selectedSubnavTitles"></app-navbar-submenu>
  </div>

