import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { NotificationService } from '../../shared/notification.service';
import { ExpensesService } from '../shared/expenses.service';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { SelectionModel } from '@angular/cdk/collections';
import { sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { AccountLight, StationInvoiceExpense } from '../shared/models';
import { Account } from '../../entities/shared/models';
import { Role } from '../../shared/models/role.enum';
import { AuthService } from '../../shared/auth.service';
import { DateUtils } from 'src/app/shared/utils/date-utils';

import { ExpenseVersionsDialogComponent } from '../expense-versions-dialog/expense-versions-dialog.component';

@Component({
  selector: 'app-expenses-list',
  templateUrl: './expenses-list.component.html',
  styleUrls: ['./expenses-list.component.scss']
})
export class ExpensesListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() isSelectMode = false;
  @Input() accountId: string = null;
  dataSource = new MatTableDataSource<StationInvoiceExpense>();
  selection: SelectionModel<StationInvoiceExpense>;
  displayedColumns: string[] = [
    'code', 'accountId', 'accountName',
    'amount', 'quickBookReference', 'whoDidGoTo', 'station', 'assignedAccountant',
    'completed', 'date', 'createdAt', 'createdBy',
    'lastUpdatedAt', 'lastUpdatedBy',
    'paymentMethod', 'menu'];
  isLoadingResults = true;
  filteredAccounts: Observable<Account[]>;
  accounts: AccountLight[];

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  start: Date | null;
  end: Date | null;


  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    accountId: new FormControl(''),
    accountName: new FormControl(''),
    amount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    quickBookReference: new FormControl(''),
    completed: new FormControl(''),
    date: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    whoDidGoTo: new FormControl(''),
    station: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl(''),
    paymentMethod: new FormControl(''),
    assignedAccountant: new FormControl('')
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);

  constructor(private service: ExpensesService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    const expenses$ = this.service.getExpenses();
    this.subscription.add(expenses$.subscribe(
      (data) => {
        this.dataSource.data = sortListByLastEdit(data);
        setTimeout(this.applyDashboardFilter);
        this.isLoadingResults = false;
      },
      () => this.notificationService.error('Error occurred while trying to get expense list.')
    ));

    this.manageDateFilter();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.createFilter();

    this.tableFilterForm.valueChanges.subscribe(value => {
      value.date.value = value.date?.value?.toString();
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.amount.value = value.amount?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyDashboardFilter = () => {
    const quickBookReference = this.route.snapshot.queryParamMap.get('quickBooks');  // Filters in case to go from dashboard
    const completed = this.route.snapshot.queryParamMap.get('completed');  // Filters in case to go from dashboard
    if (quickBookReference) {
      this.tableFilterForm.patchValue({quickBookReference, completed});
    }
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'accountId':
        return item.account?.code;
      case 'accountName':
        return item.account?.name;
      case 'station':
        return item.station?.name;
      case 'paymentMethod':
        return item.paymentMethod?.name;
      case 'assignedAccountant':
        return this.getAssignedAccountant(item);
      case 'date':
        return new Date(item.date);
      default: return item[property];
    }
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  getAssignedAccountant(expense) {
    return TypeaheadUtil.displayAccountant(expense.assignedAccountant);
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.service.getExpenses(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe(
        (data) => {
          this.dataSource.data = sortListByLastEdit(data);
        },
        () => this.notificationService.error('Error occurred while trying to get expense list.'));
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const accountIdSearch = FilterUtils.searchFunction(searchTerms.accountId, data.account?.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account?.name);
      const amountSearch = FilterUtils.createDigitSearch('amount', 'amount', searchTerms, data);
      const quickBookReferenceSearch = FilterUtils.specificQuickBooksSearch(searchTerms.quickBookReference, data.quickBookReference);
      const completedSearch = FilterUtils.createSelectSearch(searchTerms.completed, data.completed.toString());
      const dateSearch = FilterUtils.createDateSearch('date', 'date', searchTerms, data);
      const whoDidGoToSearch = FilterUtils.searchFunction(searchTerms.whoDidGoTo, data.whoDidGoTo);
      const stationSearch = FilterUtils.searchFunction(searchTerms.station, data.station?.name);
      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);
      const paymentMethodSearch = FilterUtils.searchFunction(searchTerms.paymentMethod, data.paymentMethod?.name);
      const assignedAccountantSearch = FilterUtils.searchFunction(searchTerms.assignedAccountant, this.getAssignedAccountant(data));

      const filterFunctions = [
        codeSearch(),
        accountIdSearch(),
        accountNameSearch(),
        amountSearch(),
        quickBookReferenceSearch(),
        completedSearch(),
        dateSearch(),
        whoDidGoToSearch(),
        stationSearch(),
        createdAtSearch(),
        createdBySearch(),
        lastUpdatedAtSearch(),
        lastUpdatedBySearch(),
        paymentMethodSearch(),
        assignedAccountantSearch(),
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  showVersions(id: number) {
    this.dialog.open(ExpenseVersionsDialogComponent, { data: { id } });
  }
}
