import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConsultantCompanyService } from '../shared/consultant-company.service';
import { NotificationService } from '../../shared/notification.service';
import { ConsultantCompany } from '../shared/models';
import { AuthService } from '../../shared/auth.service';
import { Role } from '../../shared/models/role.enum';

@Component({
  selector: 'app-consultant-detail',
  templateUrl: './consultant-detail.component.html',
  styleUrls: ['./consultant-detail.component.scss']
})
export class ConsultantDetailComponent implements OnInit {
  consultant: Observable<ConsultantCompany>;
  consultantData: ConsultantCompany;
  loading = true;
  hasEditPermission = this.authService.hasEditPermission;
  canCreateTransaction = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  constructor(private route: ActivatedRoute,
              private consultantService: ConsultantCompanyService,
              private notificationService: NotificationService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.consultant = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.consultantService.getConsultantCompany(params.get('id')))
    );

    this.consultant.subscribe(
      consultant => {
        this.consultantData = consultant;
        this.loading = false;
      },
      () => this.notificationService.error('Error occurred while trying to get consultant data')
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.router.navigateByUrl('/entities/consultants').then();
    }
  }

  onAddConsultantCommission() {
    this.router.navigate(
      ['/orders/commissions/edit', ''], { queryParams: {consultantCompanyId: this.consultantData.id} });
  }
}
