import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, mergeMap } from 'rxjs/operators';

import {
  Account,
  BreakpointBounds, Commissionable,
  CommissionTier,
  ConsultantCompany,
  ConsultantRate,
  FormMode,
  PaymentMethod
} from '../../entities/shared/models';
import { AccountListType, ConsultantCompanyCommission } from '../shared/models';
import { User } from '../../shared/models/user';
import { ComponentType } from '../../shared/models/component-type.enum';
import { Role } from '../../shared/models/role.enum';
import { AccountService } from '../../entities/shared/account.service';
import { AuthService } from '../../shared/auth.service';
import { ConsultantCompanyService } from '../../entities/shared/consultant-company.service';
import { ConsultantCompanyCommissionService } from '../shared/consultant-company-commission.service';
import { EntityDictionaryService } from '../../entities/shared/entity-dictionary.service';
import { NotificationService } from '../../shared/notification.service';
import { UserService } from '../../shared/user.service';
import { TypeaheadUtil } from '../../shared/utils/typeahead-util';
import { NoteUtil } from '../shared/utils/note-util';
import { FormUtils } from '../../shared/utils/form-utils';

import { BrowseAccountDialogComponent } from '../browse-account-dialog/browse-account-dialog.component';
import { BrowseConsultantDialogComponent } from '../../entities/browse-consultant-dialog/browse-consultant-dialog.component';
import { BrowseUserDialogComponent } from '../browse-user-dialog/browse-user-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { DateUtils } from '../../shared/utils/date-utils';
import { ConsultantCompanyCommissionReport } from '../shared/models/consultant-company-commission-report';
import { TransactionReportDialogComponent } from '../transaction-report-dialog/transaction-report-dialog.component';

@Component({
  selector: 'app-consultant-commission',
  templateUrl: './consultant-commission.component.html',
  styleUrls: ['./consultant-commission.component.scss']
})
export class ConsultantCommissionComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  commissionTransaction: ConsultantCompanyCommission = {} as any;
  form = this.fb.group({
    account: [null, Validators.required],
    consultantCompany: [null, Validators.required],
    code: [''],
    assignedAccountant: [null],
    toPayAmount: [''],
    currentCommissionOwed: [''],
    noteToAdd: [null],
    paymentMethod: [null],
    quickBookReference: [''],
    completedOn: [new Date (), Validators.required],
    completed: [false]
  });
  dateControl = this.fb.control([new Date (), Validators.required]);
  consultantCompanyId: string;
  accountId: string;
  accounts: Account[];
  consultantCompanies: ConsultantCompany[];
  accountants: User[];
  filteredAccounts: Observable<Account[]>;
  filteredConsultantCompanies: Observable<ConsultantCompany[]>;
  filteredAccountants: Observable<User[]>;
  paymentMethods: PaymentMethod[];

  loading = true;
  saving = false;
  openedDialog = false;
  isPaid = false;
  mode: FormMode = FormMode.CREATE;
  component = ComponentType.ConsultantCommission;
  documents: File[] = [];
  documentsUploadedBefore = [];
  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);
  hasPaidPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);
  hasUploadDocumentPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT, Role.BUYER, Role.SENIOR_BUYER]);

  commissionReport: ConsultantCompanyCommissionReport;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private service: ConsultantCompanyCommissionService,
              private consultantCompanyService: ConsultantCompanyService,
              private accountService: AccountService,
              private usersService: UserService,
              private dictionaryService: EntityDictionaryService,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') || undefined;
    this.mode = id ? FormMode.EDIT : FormMode.CREATE;
    this.consultantCompanyId = this.route.snapshot.queryParamMap.get('consultantCompanyId');
    this.accountId = this.route.snapshot.queryParamMap.get('accountId');
    this.loadData(this.consultantCompanyId, this.accountId, id);

    if (!this.hasEditPermission) {
      FormUtils.disableFormControls(this.form);
    } else if (!this.hasPaidPermission) {
      this.form.get('completed').disable();
    }
    this.subscription.add(this.dateControl.valueChanges.subscribe(this.changeDateFormat(this.form.get('completedOn') as FormControl)));
    this.subscription.add(this.dialog.afterOpened.asObservable().subscribe(() => this.openedDialog = true));
    this.subscription.add(this.dialog.afterAllClosed.subscribe(() => setTimeout(() => this.openedDialog = false, 3000)));
  }

  changeDateFormat = (stringControl: FormControl) => (newDate: Date) => {
    stringControl.patchValue(DateUtils.dateFormatToShort(newDate));
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    if (!this.openedDialog && ev.key === 'Escape') {
      this.returnClicked();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadData = (consultantCompanyId: string, accountId: string, id: string) => {
    const accounts$ = this.isCreateMode ? (consultantCompanyId
      ? this.service.getAccountList(consultantCompanyId)
      : this.accountService.getAccountList()) : of([]); // load Account list depends on consultantCompanyId
    const consultantCompanies$ = this.isCreateMode ? (accountId
      ? this.accountService.getConsultantCompanies(this.accountId)
      : this.consultantCompanyService.getConsultantCompanyList()) : of([]); // load Consultant Companies list depends on accountId
    const transaction$ = (this.mode === FormMode.EDIT) ?
      this.service.getTransaction(consultantCompanyId, id) : of(null);
    const accountants$ = this.usersService.getAccountants();
    const paymentMethods$ = this.dictionaryService.getPaymentMethods();

    forkJoin([accounts$, consultantCompanies$, transaction$, accountants$, paymentMethods$])
      .pipe(first())
      .subscribe(([accounts, consultantCompanies, transaction, accountants, paymentMethods]) => {
        this.accounts = accounts || [];
        this.consultantCompanies = consultantCompanies || [];
        this.accountants = accountants;
        this.paymentMethods = paymentMethods;

        this.manageAccountantsFilter();
        if (transaction) {
          this.isPaid = transaction.completed;
          this.commissionTransaction = transaction;
          this.initEditingForm(transaction);
        } else {
          this.manageAccountFilters();
          this.manageConsultantCompanyFilters();
          this.manageAccountChanges();
          this.manageConsultantChanges();

          const selectedConsultantCompany = this.consultantCompanies.find(company => +company.id === +consultantCompanyId);
          const selectedAccount = this.accounts.find(account => +account.id === +accountId);

          if (selectedConsultantCompany && selectedAccount) {
            this.form.patchValue({account: selectedAccount, consultantCompany: selectedConsultantCompany});
          } else if (selectedConsultantCompany) {
            this.form.patchValue({consultantCompany: selectedConsultantCompany});
            this.loadAccountList(selectedConsultantCompany);
          } else if (selectedAccount) {
            this.form.patchValue({account: selectedAccount});
            this.loadConsultantCompanyList(selectedAccount);
          }

          this.dateControl.setValue(new Date());
        }

        this.loading = false;
      });
  }

  uploadDocuments = (consultantCompanyId: number, consultantCompanyTransactionId: number) => {
    const documents$ = [];
    this.documents.forEach(document => {
      documents$.push(this.service.uploadDocument(consultantCompanyId, consultantCompanyTransactionId, document));
    });

    return forkJoin(documents$);
  }

  get consultantCompany(): ConsultantCompany {
    return this.consultantCompanyControl.value;
  }

  get consultantCompanyControl() {
    return this.form.get('consultantCompany');
  }

  get account(): Account {
    return this.accountControl.value;
  }

  get accountControl() {
    return this.form.get('account');
  }

  get consultantCompanyTransactionId() {
    return this.commissionTransaction.id;
  }

  get isCreateMode() {
    return this.mode === FormMode.CREATE;
  }

  get noteReverse() {
    return NoteUtil.sortByPostedAtEarliest(this.commissionTransaction?.notes);
  }

  get isAccountAndConsultantCompanySelected() {
    return !!this.account && !!this.consultantCompany;
  }

  get isRequesting() {
    return this.saving;
  }

  get commissionTiers(): CommissionTier[] {
    return this.account?.commissionTiers || [];
  }

  get isCompleted() {
    return !!this.form.get('completed').value;
  }

  manageAccountChanges() {
    this.accountControl.valueChanges.subscribe(value => {
      if (!value && !this.consultantCompany) {
        this.loadAccountList();
      }
      if (typeof value === 'string') {
        return;
      }
      this.clearTemplateValues();
      this.loadConsultantCompanyList(value);
    });
  }

  manageConsultantChanges() {
    this.consultantCompanyControl.valueChanges.subscribe(value => {
      if (!value && !this.account) {
        this.loadConsultantCompanyList();
      }
      if (typeof value === 'string') {
        return;
      }
      this.clearTemplateValues(value);
      this.loadAccountList(value);
    });
  }

  loadConsultantCompanyList(account?: Account) {
    const consultantCompanies$ = account && account.id
      ? this.accountService.getConsultantCompanies(String(account.id))
      : this.consultantCompanyService.getConsultantCompanyList(); // load Consultant Companies list depends on accountId
    consultantCompanies$.subscribe(consultantCompanies => {
      this.consultantCompanies = consultantCompanies;
      this.filteredConsultantCompanies = of(consultantCompanies);
      this.manageConsultantCompanyFilters();
    });
  }

  loadAccountList(consultantCompany?: ConsultantCompany) {
    const accounts$ = consultantCompany && consultantCompany.id
      ? this.service.getAccountList(String(consultantCompany.id))
      : this.accountService.getAccountList(); // load Account list depends on consultantCompanyId;
    accounts$.subscribe(res => this.handleAccounts(res));
  }

  getConsultantRate(tier: CommissionTier): string {
    const consultantRate = this.findConsultantRate(tier);
    return String(consultantRate?.rate);
  }

  getTierBreakpoint(tier: CommissionTier, i: number): string {
    const {start, end} = this.findBreakpointBounds(i);
    return `$${start} - $${end ? end : '∞'}`;
  }

  findConsultantRate(tier: CommissionTier): ConsultantRate {
    return tier.consultantRates
      .filter((value) => {
        return value.consultantCode === this.consultantCompany?.code;
      })[0];
  }

  displayItem(item): string {
    return item ? `${item.code} - ${item.name}` : '';
  }

  displayAccountant(accountant?: User): string {
    return TypeaheadUtil.displayAccountant(accountant);
  }

  manageAccountFilters() {
    this.filteredAccounts = TypeaheadUtil.manageAccountFilter(this.form, this.accounts);
  }

  onBlurAccount(inputElement) {
    TypeaheadUtil.onBlurAccount(this.form, this.accounts, inputElement);
  }

  onAccountOptionSelect(option) {
    TypeaheadUtil.onAccountOptionSelect(this.form, option);
  }

  manageConsultantCompanyFilters() {
    this.filteredConsultantCompanies = TypeaheadUtil.manageConsultantFilter(this.consultantCompanyControl, this.consultantCompanies);
  }

  manageAccountantsFilter() {
    this.filteredAccountants = TypeaheadUtil.manageAccountantFilter(this.form, this.accountants);
  }

  onBlurConsultantCompany(inputElement) {
    TypeaheadUtil.onBlurConsultant(this.consultantCompanyControl, this.consultantCompanies, inputElement);
  }

  onConsultantCompanyOptionSelect(option) {
    TypeaheadUtil.onConsultantOptionSelect(this.consultantCompanyControl, option);
    this.consultantCompanyService.getConsultantCompany(option.id)
    .pipe(first())
    .subscribe(consultantCompany => this.form.patchValue({consultantCompany}));
  }

  openAccountDialog() {
    if (this.accounts.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Accounts for chosen Consultant', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseAccountDialogComponent,
        {
          data: {
            selected: this.account?.id,
            type: AccountListType.CONSULTANT_COMPANY,
            id: this.consultantCompany?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: Account) => {
        if (result) {
          this.accountControl.setValue(result);
        }
      });
    }
  }

  openConsultantCompanyDialog() {
    if (this.consultantCompanies.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {data: { message: 'No Submitted Orders for chosen Account', cancelDisabled: true }});
    } else {
      const dialogRef = this.dialog.open(BrowseConsultantDialogComponent,
        {
          data: {
            selected: this.consultantCompany?.id,
            transaction: true,
            accountId: this.account?.id
          }
        }
      );

      dialogRef.afterClosed().subscribe((result: ConsultantCompany) => {
        if (result) {
          this.form.get('consultantCompany').setValue(result);
        }
      });
    }
  }

  onBlurAccountant(inputElement: HTMLInputElement) {
    TypeaheadUtil.onBlurAccountant(this.form, this.accountants, inputElement);
  }

  onAccountantOptionSelect(option) {
    TypeaheadUtil.onAccountantOptionSelect(this.form, option);
  }

  browseUser() {
    const dialogRef = this.dialog.open(BrowseUserDialogComponent,
      {data: {selected: this.form.value.assignedAccountant?.id}}
    );
    dialogRef.afterClosed().subscribe((result: User) => {
      if (result) {
        this.form.patchValue({assignedAccountant: result});
      }
    });
  }

  searchCommission() {
    const consultantId = this.consultantCompany.id;
    const accountId = this.account.id;
    if (!accountId || !consultantId) {
      this.mode = FormMode.CREATE;
      return;
    }
    this.service.getConsultantCompanyCommissionTemplate(String(consultantId), String(accountId))
      .subscribe((transactionTemplate) => {
        this.mode = transactionTemplate.id ? FormMode.EDIT : FormMode.CREATE;
        this.initEditingForm(transactionTemplate);
    });
  }

  returnClicked() {
    this.router.navigateByUrl('/orders/commissions');
  }

  save(form: FormGroup): void {
    const transaction = this.prepareTransaction(form);
    const consultantCompanyId = String(transaction.consultantCompany.id);

    let request$: any = this.mode === FormMode.EDIT
        ? this.service.editTransaction(consultantCompanyId, String(transaction.id), transaction)
        : this.service.createTransaction(consultantCompanyId, transaction);

    if (this.documents.length) {
      request$ = request$.pipe(mergeMap(
        (data: ConsultantCompanyCommission) => {
          return this.uploadDocuments(Number(consultantCompanyId), data.id);
        }
      ));
    }

    request$.pipe(finalize(() => this.resetRequesting())).subscribe(
      () => {
        this.notificationService.success(this.mode === FormMode.EDIT ? 'Transaction was updated!' : 'New transaction created!');
        this.returnClicked();
      },
      (error) => {
        if (error.status === 400 && error.error?.code === 'DM008') {
          this.notificationService.error('Uploading failed. File contains viruses.');
        } else {
          this.actionErrorHandler(error, this.mode);
        }
      }
    );
  }

  resetRequesting() {
    this.saving = false;
  }

  setConsultantCompanyTransactionDocumentation(data: any) {
    this.documents = data.files;
  }

  private prepareTransaction(form: FormGroup): ConsultantCompanyCommission {
    const transaction = {
      ...this.commissionTransaction,
      ...form.value
    };
    NoteUtil.processNotes(transaction, form);

    return transaction;
  }

  showReport(): void {
    if (this.matchesWithCurrentReport()) {
      this.openReportDialog();
    } else {
      this.loadCommissionReportAndOpenDialog();
    }
  }

  private matchesWithCurrentReport(): boolean {
    return this.commissionReport?.filter.consultantCompanyId === this.consultantCompany.id
        && this.commissionReport?.filter.accountId === this.account.id;
  }

  private loadCommissionReportAndOpenDialog() {
    const consultantCompanyId = String(this.consultantCompany?.id);
    const accountId = String(this.account.id);
    this.service.getCommissionReport(consultantCompanyId, accountId).subscribe(report => {
      this.commissionReport = report;
      this.openReportDialog();
    });
  }

  private openReportDialog(): void {
    this.dialog.open(TransactionReportDialogComponent, {
      data: {consultantCommissionReport: this.commissionReport},
      minWidth: '110vh'
    });
  }

  private initEditingForm(transaction: ConsultantCompanyCommission) {
    const selectedAccount = this.isCreateMode ? this.accounts.find(account => +account.id === +transaction.account.id)
      : transaction.account;
    const selectedConsultantCompany = this.isCreateMode ? this.consultantCompanies
      .find(company => +company.id === +transaction.consultantCompany?.id) : transaction.consultantCompany;
    const selectedPaymentMethod = this.paymentMethods.find(this.paymentMethodFindFn(selectedConsultantCompany, transaction));

    this.fillTransactionAccountant(transaction);
    this.fillTransactionAccount(transaction, selectedAccount);

    this.form.patchValue({
      ...transaction,
      consultantCompany: selectedConsultantCompany,
      paymentMethod: selectedPaymentMethod
    }, {emitEvent: false});

    this.patchCommissionOwedValueToPayAmount(transaction.currentCommissionOwed);
    this.initDateControl(transaction);
    this.setDocumentsUploadedBefore(transaction);

    this.commissionTransaction = transaction;
  }

  private paymentMethodFindFn =
    (selectedConsultant: ConsultantCompany, transaction: ConsultantCompanyCommission) => (paymentMethod: PaymentMethod) => {
    return transaction.paymentMethod
      ? paymentMethod.id === transaction.paymentMethod?.id
      : paymentMethod.id === selectedConsultant?.paymentMethod?.id;
  }

  private fillTransactionAccountant(transaction: ConsultantCompanyCommission) {
    transaction.assignedAccountant = this.accountants.find(item => item.id === transaction.assignedAccountant?.id);
  }

  private fillTransactionAccount(transaction: ConsultantCompanyCommission, selectedAccount: Account) {
    if (selectedAccount) {
      transaction.account = selectedAccount;
    }
  }

  private patchCommissionOwedValueToPayAmount(commissionOwed: number) {
    const canPatchValue = this.mode === FormMode.CREATE && commissionOwed > 0;
    if (canPatchValue) {
      this.form.patchValue({toPayAmount: commissionOwed});
    }
  }

  private initDateControl(transaction: ConsultantCompanyCommission) {
    this.dateControl.setValue(transaction.completedOn ? new Date(transaction.completedOn) : new Date());
  }

  setDocumentsUploadedBefore(transaction: ConsultantCompanyCommission) {
    if (!transaction?.documents?.length) {
      return;
    }
    this.consultantCompanyId = String(transaction.consultantCompany.id);
    this.documentsUploadedBefore = transaction.documents;
  }

  private clearTemplateValues(consultantCompany: ConsultantCompany = this.form.value.consultantCompany) {
    const clearTemplateFormValue = {
      code: '',
      assignedAccountant: null,
      toPayAmount: '',
      currentCommissionOwed: '',
      noteToAdd: '',
      paymentMethod: consultantCompany?.paymentMethod || null,
      quickBookReference: '',
      completed: false,
      completedOn: new Date()
    };
    this.form.patchValue(clearTemplateFormValue, {emitEvent: false});
    if (this.isAccountAndConsultantCompanySelected) {
      this.searchCommission();
    }
  }

  private actionErrorHandler(err: any, mode: FormMode) {
    FormUtils.formValidationHandler(err, this.form, this.notificationService,
      `Error occurred while trying to ${mode === FormMode.CREATE ? 'create' : 'update'} transaction.`);
  }

  private findBreakpointBounds(index: number): BreakpointBounds {
    const tier = this.commissionTiers[index];
    const end = tier.breakPoint;
    const start = index === 0 ? 0 : this.commissionTiers
      .find(item => item.sortNumber === tier.sortNumber - 1)
      .breakPoint;

    return {start, end} as BreakpointBounds;
  }

  private handleAccounts = (accounts: Account[]) => {
    this.accounts = accounts;
    this.filteredAccounts = of(accounts);
    this.manageAccountFilters();
  }
}
