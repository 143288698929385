import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { User } from '../../shared/models/user';
import { PaymentBatchAssignmentSummaryEntry } from '../shared/models';
import { TypeaheadUtil } from 'src/app/shared/utils/typeahead-util';

@Component({
  selector: 'app-batch-assignment-summary',
  templateUrl: './batch-assignment-summary.component.html',
  styleUrls: ['./batch-assignment-summary.component.scss']
})
export class BatchAssignmentSummaryComponent implements OnInit, OnChanges {
  @Input() assignmentSummaryEntries: PaymentBatchAssignmentSummaryEntry[] = [];
  dataSource = new MatTableDataSource<PaymentBatchAssignmentSummaryEntry>();
  displayedColumns: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.loadPaymentMethods();
    this.dataSource.data = this.assignmentSummaryEntries?.filter(item => item.assignedForPaymentsTotal !== 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assignmentSummaryEntries) {
      this.loadPaymentMethods();
      this.dataSource.data = changes.assignmentSummaryEntries.currentValue?.filter(item => item.assignedForPaymentsTotal !== 0);
    }
  }

  displayAccountant(accountant: User) {
    if (!accountant) {
      return 'Unassigned';
    }
    return TypeaheadUtil.displayAccountant(accountant) || accountant.id;
  }

  private loadPaymentMethods() {
    this.displayedColumns = ['user', 'total'];
    const methods: any = this.assignmentSummaryEntries.map(item => item.assignedForPaymentsPerMethod)
      .reduce((previousValue, currentValue) => ({...previousValue, ...currentValue}), {});
    this.displayedColumns.push(...Object.keys(methods));
  }

}
