<div class="payment-select-container">
  <span class="label" (click)="payment.open()">Change Method ...</span>

  <mat-form-field>
    <mat-select disableOptionCentering #payment panelClass="payment-select"
                [value]="value?.name ? value.name : null" (openedChange)="openedChange(payment)">
      <mat-option (click)="payment.open()"
                  *ngFor="let option of options" [value]="option.name">{{ option.name }}</mat-option>
      <hr>
      <div class="buttons-container">
        <button type="button" mat-raised-button (click)="payment.close()">Close</button>
        <button type="button" mat-raised-button color="primary" (click)="setSelected(payment)">Apply</button>
      </div>
    </mat-select>
  </mat-form-field>
</div>

