import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DASHBOARD } from '../../core/apiUrls';
import { DashboardQuickLinkInfo } from './models/dashboard';
import { QuickLink } from './models/quick-link.enum';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboardInfo(countQuickLinks: QuickLink[]): Observable<DashboardQuickLinkInfo[]> {
    return this.http.post<DashboardQuickLinkInfo[]>(`${DASHBOARD}/quickLinks`, {countQuickLinks});
  }
}
