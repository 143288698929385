<div class="main">
  <h1>
    <button mat-icon-button>
      <span routerLink="/entities/stations" class="back-arrow material-icons">keyboard_backspace</span>
    </button>
    <span>{{stationData?.name}}</span>
    <button class="edit-button" mat-mini-fab color="primary" aria-label="Edit Station"
            *ngIf="hasEditPermission" [routerLink]="['/entities/stations/edit', stationData?.id]">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>

  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <mat-card *ngIf="!loading" class="container">
    <mat-card-content class="content">
      <div class="content_right">
<!--        <div class="summary-container">
          <div class="subheader subheader-summary mat-title">Station Account Summary</div>

          <div class="summary-container_inner">
            <div class="summary-container_inner_column">
              <div class="cost">{{ 0 | currency}}</div>
              <div class="cost-caption">Outstanding <br> Payments</div>
            </div>
            <div class="summary-container_inner_column">
              <div class="cost">{{ 0 | currency}}</div>
              <div class="cost-caption">Past Due <br> Payments</div>
            </div>
          </div>
        </div>-->
        <div class="subheader mat-title">General Info</div>
        <mat-card class="container">
          <div class="content_left_info-container">
            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Station Name</div>
                {{stationData.name}}
              </div>
              <div class="column_item">
                <div class="title">Media Type</div>
                {{stationData.mediaType?.type || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Address Line 1</div>
                {{stationData.address?.addressLine1 || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Address Line 2</div>
                {{stationData.address?.addressLine2 || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Address Line 3</div>
                {{stationData.address?.addressLine3 || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">City</div>
                {{stationData.address?.city || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">State</div>
                {{stationData.address?.state?.name || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">ZIP Code</div>
                {{stationData.address?.zipCode || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Created At</div>
                {{stationData.createdAt | date: "medium"}}
              </div>
              <div class="column_item">
                <div class="title">Created By</div>
                {{stationData.createdBy || 'N/A'}}
              </div>
            </div>

            <div class="info-container_column">
              <div class="column_item">
                <div class="title">Phone 1</div>
                {{stationData?.address?.phone1 ? stationData.address?.phone1 : 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Phone 1 Ext</div>
                {{stationData?.address?.phone1Ext || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Phone 2</div>
                {{stationData?.address?.phone2 ? stationData.address?.phone2 : 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Phone 2 Ext</div>
                {{stationData?.address?.phone2Ext || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Fax 1</div>
                {{stationData?.address?.fax1 || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Fax 2</div>
                {{stationData?.address?.fax2 || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Contact Name</div>
                {{stationData?.address?.contactName || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Format</div>
                {{stationData?.address?.format || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Channel</div>
                {{stationData?.channel || 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Last Updated At</div>
                {{stationData.lastUpdatedAt ? (stationData.lastUpdatedAt | date: "medium") : 'N/A'}}
              </div>
              <div class="column_item">
                <div class="title">Last Updated By</div>
                {{stationData.lastUpdatedBy || 'N/A'}}
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="content_left">
        <div class="buttons-container">
          <button mat-button mat-raised-button class="button-transaction" [matMenuTriggerFor]="transactionMenu" *ngIf="hasCreateTransaction">
            Add New Transaction
          </button>
          <mat-menu #transactionMenu="matMenu">
            <button mat-menu-item type="button" (click)="onAddTransactionClicked()">Add Memo / Refund</button>
            <button mat-menu-item type="button" (click)="onAddInvoiceClicked()">Add Invoice</button>
          </mat-menu>
          <button mat-raised-button class="button-transaction"
                  (click)="onViewAllTransactionClicked()">
            View All Transactions
          </button>
        </div>
        <div class="parent-company-container">
          <div class="title">Parent Company</div>
          {{stationData.parentCompany?.name || 'N/A'}}
        </div>
        <div class="override-checkbox">
          <mat-checkbox disabled class="checkbox-control" [checked]="stationData.overwriteParentCompanyPaymentMethod">
            Override Parent Company Payment Preferences
          </mat-checkbox>
        </div>
        <div class="column_item">
          <div class="title">Preferred Payment method</div>
          {{(stationData.overwriteParentCompanyPaymentMethod ? stationData?.paymentMethod?.name : stationData?.parentCompany?.paymentMethod?.name) || 'N/A'}}
        </div>
        <div class="notes-container">
          <div class="title">Payment URL</div>
          <a target="_blank" [href]="paymentGuidelinesUrl" *ngIf="paymentGuidelinesUrl">
            {{stationData.overwriteParentCompanyPaymentMethod ? stationData.paymentGuidelinesUrl : stationData.parentCompany?.paymentGuidelinesUrl}}
          </a>
          <span *ngIf="!paymentGuidelinesUrl">N/A</span>
        </div>
        <div class="subheader mt mat-title">Station Documentation</div>
        <app-file-upload
          [filesUploadedBefore]="stationData.overwriteParentCompanyPaymentMethod ? stationData?.documents : stationData?.parentCompany?.documents"
          [id]="stationData.overwriteParentCompanyPaymentMethod ? stationData?.id : stationData?.parentCompany?.id"
          [component]="stationData.overwriteParentCompanyPaymentMethod ? ComponentType.Station : ComponentType.ParentCompany"
          [readonly]="true"
          [canUpload]="false"
        >
        </app-file-upload>
        <div class="notes-container">
          <div class="title">General Payment Notes</div>
          {{stationData.generalPaymentNotes || 'N/A'}}
        </div>

        <div class="subheader mt mat-title">Media Markets</div>
        <mat-card class="container">
          <div class="content_left_markets-container">
            <div *ngFor="let market of stationData.mediaMarkets" class="market-container">
              <div class="market">
                {{market.name}}
              </div>
            </div>
            <div *ngIf="!stationData.mediaMarkets?.length">N/A</div>
          </div>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
