import { Station } from './station';

export interface StationListPage {
  page: number;
  elements: number;
  totalPages: number;
  totalElements: number;
  elementsPerPage: number;
  content: Station[];
  filter:	StationsBrowsingFilter;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
  offset: number;
  sort: Sort;
}

export interface StationsBrowsingFilter {
  createdFrom?: string;
  createdTo?: string;
  stationId: number;
  mediaTypeIds: number[];
  parentCompanyId: number;
  pageNumber: number;
  pageSize: number;
  stationSortFields: StationSortField[];
  onlyUnbalancedRunningOrder: boolean;
  onlyUnbalancedRunningInvoce: boolean;
}

export enum StationSortField {
  NAME = 'NAME',
  MEDIA_TYPE = 'MEDIA_TYPE',
  PARENT_COMPANY = 'PARENT_COMPANY'
}

export enum ConvertedStationSortField {
  NAME = 'Station Name',
  MEDIA_TYPE = 'Media Type',
  PARENT_COMPANY = 'Parent Company'
}
