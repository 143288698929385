import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef, Inject,
  OnDestroy,
  OnInit, QueryList,
  ViewChild, EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSearchComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild('searchSelectInput', {read: ElementRef}) searchSelectInput: ElementRef;
  value: string;

  /** Reference to the MatSelect options */
  public set options(options: QueryList<MatOption>) {
    this.options$.next(options);
  }

  public get options(): QueryList<MatOption> {
    return this.options$.getValue();
  }

  public options$: BehaviorSubject<QueryList<MatOption>> = new BehaviorSubject<QueryList<MatOption>>(null);

  private optionsList$: Observable<MatOption[]> = this.options$.pipe(
    switchMap(options => options ?
      options.changes.pipe(
        map(optionsList => optionsList.toArray()),
        startWith<MatOption[]>(options.toArray()),
      ) : of(null)
    )
  );

  private optionsLength$: Observable<number> = this.optionsList$.pipe(
    map(options => options ? options.length : 0)
  );

  /** whether to show the no entries found message */
  public showNoEntriesFound$: Observable<boolean> = this.optionsLength$.pipe(
    map(optionsLength => this.value && !optionsLength)
  );

  /** Previously selected values */
  private previousSelectedValues: any[];

  /** Event that emits when the current value changes */
  private change = new EventEmitter<string>();

  /** Subject that emits when the component has been destroyed. */
  private onDestroy = new Subject<void>();

  onChange = (_?: any) => {};
  onTouched = (_?: any) => {};

  constructor(@Inject(MatSelect) public matSelect: MatSelect,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    // set custom panel class
    const panelClass = 'mat-select-search-panel';
    if (this.matSelect.panelClass) {
      if (Array.isArray(this.matSelect.panelClass)) {
        this.matSelect.panelClass.push(panelClass);
      } else if (typeof this.matSelect.panelClass === 'string') {
        this.matSelect.panelClass = [this.matSelect.panelClass, panelClass];
      } else if (typeof this.matSelect.panelClass === 'object') {
        this.matSelect.panelClass[panelClass] = true;
      }
    } else {
      this.matSelect.panelClass = panelClass;
    }

    // when the select dropdown panel is opened or closed
    this.matSelect.openedChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe((opened) => {
        if (opened) {
          // focus the search field when opening
          this.focus();
        } else {
          // clear it when closing
          this.resetSearch();
        }
      });

    // set the first item active after the options changed
    this.matSelect.openedChange
      .pipe(take(1))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.options = this.matSelect.options;
        this.options.changes
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            const keyManager = this.matSelect._keyManager;
            if (keyManager && this.matSelect.panelOpen) {
              // avoid "expression has been changed" error
              setTimeout(() => {
                keyManager.setFirstItemActive();
              });
            }
          });
      });

    // detect changes when the input changes
    this.change
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.initMultipleHandling();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param {KeyboardEvent} event
   * @private
   */
  handleKeydown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }

  }

  writeValue(value: string) {
    const valueChanged = value !== this.value;
    if (valueChanged) {
      this.value = value;
      this.change.emit(value);
    }
  }

  onInputChange(target) {
    const value = target.value;
    const valueChanged = value !== this.value;
    if (valueChanged) {
      this.value = value;
      this.onChange(value);
      this.change.emit(value);
    }
  }

  onBlur(target) {
    this.writeValue(target.value);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * Focuses the search input field
   * @private
   */
  public focus() {
    if (!this.searchSelectInput) {
      return;
    }
    // save and restore scrollTop of panel, since it will be reset by focus()
    const panel = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    // focus
    this.searchSelectInput.nativeElement.focus();
    panel.scrollTop = scrollTop;
  }

  /**
   * Resets the current search value
   * @param {boolean} focus whether to focus after resetting
   * @private
   */
  public resetSearch(focus?: boolean) {
    if (!this.searchSelectInput) {
      return;
    }
    this.searchSelectInput.nativeElement.value = '';
    this.onInputChange('');
    if (focus) {
      this.focus();
    }
  }

  /**
   * Initializes handling <mat-select [multiple]="true">
   */
  private initMultipleHandling() {
    // if <mat-select [multiple]="true">
    // store previously selected values and restore them when they are deselected
    // because the option is not available while we are currently filtering
    this.previousSelectedValues = this.matSelect.ngControl.value;
    this.matSelect.valueChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe((values) => {
        if (this.matSelect.multiple) {
          let restoreSelectedValues = false;
          if (this.value && this.value.length
            && this.previousSelectedValues && Array.isArray(this.previousSelectedValues)) {
            if (!values || !Array.isArray(values)) {
              values = [];
            }
            const optionValues = this.matSelect.options.map(option => option.value);
            this.previousSelectedValues.forEach(previousValue => {
              if (values.indexOf(previousValue) === -1 && optionValues.indexOf(previousValue) === -1) {
                // if a value that was selected before is deselected and not found in the options, it was deselected
                // due to the filtering, so we restore it.
                values.push(previousValue);
                restoreSelectedValues = true;
              }
            });
          }

          if (restoreSelectedValues) {
            this.matSelect._onChange(values);
          }

          this.previousSelectedValues = values;
        }
      });
  }
}

