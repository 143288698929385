import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentCheckBatch, PaymentBatchList, PaymentBatchAssignmentSummaryEntry } from './models';
import { PAYMENT_BATCHES } from '../../core/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class PaymentBatchService {

  constructor(private http: HttpClient) { }

  getPaymentBatchList(since?: string, to?: string): Observable<PaymentBatchList> {
    let httpParams = new HttpParams();
    if (since) {
      httpParams = httpParams.set('since', since);
    }
    if (to) {
      httpParams =  httpParams.set('to', to);
    }
    return this.http.get<PaymentBatchList>(PAYMENT_BATCHES, { params: httpParams});
  }

  createPaymentBatch(paymentBatch: PaymentCheckBatch): Observable<PaymentCheckBatch> {
    return this.http.post<PaymentCheckBatch>(`${PAYMENT_BATCHES}`, paymentBatch);
  }

  getPaymentBatch(id: string): Observable<PaymentCheckBatch> {
    return this.http.get<PaymentCheckBatch>(`${PAYMENT_BATCHES}/${id}`);
  }

  updatePaymentBatch(paymentBatch: PaymentCheckBatch): Observable<PaymentCheckBatch> {
    return this.http.put<PaymentCheckBatch>(`${PAYMENT_BATCHES}/${paymentBatch.id}`, paymentBatch);
  }

  declinePaymentBatch(paymentBatchId: number): Observable<PaymentCheckBatch> {
    return this.http.put<PaymentCheckBatch>(`${PAYMENT_BATCHES}/${paymentBatchId}/decline`, {});
  }

  getPaymentBatchDocument(paymentBatchId: number, paymentId: number, documentId: number): Observable<any> {
    return this.http.get(
      `${PAYMENT_BATCHES}/${paymentBatchId}/payments/${paymentId}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  uploadDocument(paymentBatchId: number, paymentId: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<PaymentCheckBatch>(`${PAYMENT_BATCHES}/${paymentBatchId}/payments/${paymentId}/documents`, fd);
  }

  deletePaymentBatchDocument(paymentBatchId: number, paymentId: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${PAYMENT_BATCHES}/${paymentBatchId}/payments/${paymentId}/documents/${documentId}`);
  }

  getPaymentAssignmentSummary(): Observable<PaymentBatchAssignmentSummaryEntry[]> {
    return this.http.get<PaymentBatchAssignmentSummaryEntry[]>(`${PAYMENT_BATCHES}/assignmentSummary`);
  }

  exportBatch(paymentBatchId: number): Observable<any> {
    return this.http.get(
      `${PAYMENT_BATCHES}/${paymentBatchId}/exportBatchACHMPI`,
      { observe: 'response', responseType: 'blob' }
    );
  }
}
