<mat-dialog-content class="browse-dialog">
  <app-invoice-expense-list [accountId]="data.accountId"
                    [isAccountInvoices]="data.isAccountInvoices"
                    [stationId]="data.stationId"
                    [isMultiselect]="data.isMultiselect"></app-invoice-expense-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="selectedExpenses"
          [disabled]="!selectedExpenses" cdkFocusInitial>Apply</button>
</mat-dialog-actions>
