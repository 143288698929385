<div [ngClass]="{'container': isReport, 'table-container': !isReport}">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Station + Media Type </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow || element.transactionDateHeader,
       'tier-bcgrnd': element.tierRow}"> {{getTitle(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionId">
      <th mat-header-cell *matHeaderCellDef class="w-180"> Transaction ID </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'tier-bcgrnd': element.tierRow}">
        <a  target="_blank" [routerLink]="[getTransactionLink(element)]"
           [queryParams]="getQueryParams(element)"
           *ngIf="element.type === ConsultantCompanyCommissionReportEntryType.COMMISSION
               || element.type === ConsultantCompanyCommissionReportEntryType.PAYMENT;
          else noHyperlink">
          {{getTransactionID(element)}}
        </a>
        <ng-template #noHyperlink>{{getTransactionID(element)}}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef>  Transaction Date </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'tier-bcgrnd': element.tierRow}"> {{getTransactionDate(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="ftc">
      <th mat-header-cell *matHeaderCellDef class="w-180"> FTC - LTC </th>
      <td mat-cell *matCellDef="let element">{{ element.ftc ? element.ftc + '-' + element.ltc : '&nbsp;' }}</td>
    </ng-container>

    <ng-container matColumnDef="quickBookReference">
      <th mat-header-cell *matHeaderCellDef>  QBref# </th>
      <td mat-cell *matCellDef="let element"> {{element.quickBookReference? element.quickBookReference : '&nbsp;'}} </td>
    </ng-container>

    <ng-container matColumnDef="grossAmount">
      <th mat-header-cell *matHeaderCellDef>  GROSS </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}"> {{element.grossAmount !== null ? (element.grossAmount | negativeCurrency) :  '&nbsp;'}} </td>
    </ng-container>

    <ng-container matColumnDef="netAmount">
      <th mat-header-cell *matHeaderCellDef>  NET </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}"> {{element.netAmount !== null ? (element.netAmount | negativeCurrency) : '&nbsp;'}} </td>
    </ng-container>

    <ng-container matColumnDef="cpmSplitAmount">
      <th mat-header-cell *matHeaderCellDef>  OUR SPLIT </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}">
        {{element.cpmSplitAmount !== null  ? (element.cpmSplitAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="consultantCompanySplitAmount">
      <th mat-header-cell *matHeaderCellDef>  {{consultantCommissionReport.consultantSplitColumnName }}</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}">
        {{element.consultantCompanySplitAmount !== null  ? (element.consultantCompanySplitAmount | negativeCurrency) :  '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="paidToConsultantCompanyAmount">
      <th mat-header-cell *matHeaderCellDef>   {{consultantCommissionReport.consultantPaidColumnName }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}">
        {{element.paidToConsultantCompanyAmount !== null ? (element.paidToConsultantCompanyAmount | negativeCurrency) : '&nbsp;'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="balanceWithConsultantAmount">
      <th mat-header-cell *matHeaderCellDef class="w-135"> {{consultantCommissionReport.consultantBalanceColumnName }}</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'bolder': element.totalRow}">
        {{element.balanceWithConsultantAmount !== null ? (element.balanceWithConsultantAmount | negativeCurrency) : '&nbsp;'}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="!isReport && dataSource.data.length === 0">
    <p class="no-data">No data</p>
  </ng-container>
</div>

<div class="table-container" *ngIf="isReport && dataSource.data.length === 0">
  <p class="no-data">No data</p>
</div>


