export interface TransactionsReport {
  filter: TransactionsReportFilter;
  sections: TransactionsReportSection[];
  total: TransactionsReportSectionEntry;
  balance: number;
}

export interface TransactionsReportSection {
  sort: number;
  name: string;
  id: number;
  entries: TransactionsReportSectionEntry[];
  subTotal: TransactionsReportSectionEntry;
}

export interface TransactionsReportSectionEntry {
  sortNumber: number;
  groupNumber: number;
  title: string;
  ftcOn: string;
  ltcOn: string;
  type: TransactionsReportSectionEntryType;
  transactionDate: string;
  transactionId: string;
  transactionTypeId: number;
  quickBookReference: string;
  grossOrderedAmount: number;
  netOrderedAmount: number;
  expenseAmount: number;
  paidAmount: number;
  vendorMemoInputAmount: number;
  refundAmount: number;
  invoicedAmount: number;
  balanceAmount: number;
  checkRequestCode: string;
  checkRequestId: number;
  total: boolean;
}

export interface TransactionsReportFilter {
  view: TransactionsReportType;
  accountsFilter: AccountsFilter;
  stationsFilter: StationsFilter;
  year: number;
  since: string;
  to: string;
  quickBookReference: string;
  transactionId: string;
}

export interface AccountsFilter {
  accountIds: number[];
}

export interface StationsFilter {
  accountId: number;
  stationIds: number[];
  mediaTypeIds: number[];
}

export enum TransactionsReportType {
  ACCOUNT_ORDER = 'ACCOUNT_ORDER',
  ACCOUNT_INVOICE = 'ACCOUNT_INVOICE',
  STATION_ORDER = 'STATION_ORDER',
  STATION_INVOICE = 'STATION_INVOICE'
}

export enum TransactionsReportSectionEntryType {
  STATION = 'STATION',
  SUB_TOTAL = 'SUB_TOTAL',
  TOTAL = 'TOTAL',
  VENDOR_MEMO_INPUT = 'VENDOR_MEMO_INPUT',
  FLIGHT = 'FLIGHT',
  PAYMENT = 'PAYMENT',
  EXPENSE_COMMISSIONABLE = 'EXPENSE_COMMISSIONABLE',
  EXPENSE_NON_COMMISSIONABLE = 'EXPENSE_NON_COMMISSIONABLE',
  REFUND = 'REFUND',
  STATION_INVOICE = 'STATION_INVOICE'
}
