import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StationInvoiceExpense } from '../shared/models';

@Component({
  selector: 'app-account-invoice-expenses',
  templateUrl: './account-invoice-expenses.component.html',
  styleUrls: ['./account-invoice-expenses.component.scss']
})
export class AccountInvoiceExpensesComponent implements OnInit {
  @Input() invoicedExpensesData: StationInvoiceExpense[];
  @Input() disableDelete = false;
  @Output() deleteClicked = new EventEmitter();
  displayedColumns: string[] = ['station', 'code', 'whoDidGoTo', 'amount', 'commissionable', 'delete'];

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = this.disableDelete ? ['station', 'code', 'whoDidGoTo', 'amount', 'commissionable'] : this.displayedColumns;
  }

  get totalAmount() {
    return this.invoicedExpensesData
      .map(item => item.amount)
      .reduce((a, b) => b ? a + b : a, 0);
  }

}
