export class BalanceTransactionType {
  static readonly VENDOR_MEMO_CREDIT  = new BalanceTransactionType(1, 'Vendor MEMO / CREDIT');
  static readonly REFUND = new BalanceTransactionType(2, 'Station REFUND');

  // private to disallow creating other instances of this type
  private constructor(private readonly id: number, public readonly type: string) {
  }

  toString() {
    return this.id;
  }
}
