import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AccountService } from '../shared/account.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import {
  customSortingDataAccessor,
  SortingDataTypes,
  sortListByLastEdit,
  getClientCandidateType
} from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { Account } from '../shared/models';
import { Role } from '../../shared/models/role.enum';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  @Input() isSelectMode: boolean;
  @Input() listMethod: Observable<any>;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'name',
    'code',
    'leadConsultant',
    'accountType',
    'runningOrderBalance',
    'runningInvoiceBalance',
    'menu'
  ];

  digitFilters = [
    { name: 'runningOrderBalance', filterDef: 'runningOrderBalance-filter' },
    { name: 'runningInvoiceBalance', filterDef: 'runningInvoiceBalance-filter' },
  ];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  isLoadingResults = true;
  selection = new SelectionModel<Account>(false, []);
  selectedId: number;
  hasEditPermission = this.authService.hasEditPermission;
  hasEditArchivedPermission = this.authService.hasPermission([Role.SENIOR_ACCOUNTANT, Role.SENIOR_BUYER]);

  tableFilterForm = new FormGroup({
    name: new FormControl(''),
    code: new FormControl(''),
    leadConsultant: new FormControl(''),
    accountType: new FormControl(''),
    runningOrderBalance: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    runningInvoiceBalance: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
  });

  constructor(private accountService: AccountService,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.displayedColumns = this.isSelectMode ? ['select', 'name', 'code', 'leadConsultant', 'accountType'] : this.displayedColumns;

    const accountList$ = this.listMethod || this.accountService.getAccountList(!this.isSelectMode);
    accountList$.subscribe(
      (data) => {
        this.setTableData(data);
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get account info.')
    );

    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = customSortingDataAccessor(SortingDataTypes.Account);

    this.dataSource.sort = this.sort;

    this.tableFilterForm.valueChanges.subscribe(value => {
      value.runningOrderBalance.value = value.runningOrderBalance?.value?.toString();
      value.runningInvoiceBalance.value = value.runningInvoiceBalance?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    });

    this.dataSource.filterPredicate = this.createFilter();
  }

  setTableData(data) {
    this.dataSource.data = sortListByLastEdit(data);
    this.dataSource.data.forEach(dataItem =>
      dataItem.leadConsultant = dataItem.consultantCompanies?.filter(consultant =>
        consultant.code === dataItem.leadConsultantCompanyCode)[0]?.name
    );

    this.isLoadingResults = false;

    if (this.selectedId) {
      this.initSelected(this.selectedId);
    }
  }

  applyDashboardFilter = () => {
    const runningOrderBalance = this.route.snapshot.queryParamMap.get('runningOrderBalance');  // Filters in case to go from dashboard
    if (runningOrderBalance) {
      const unbalanceRunningOrderBalance = {symbol: '!', value: '0'};
      this.tableFilterForm.patchValue({runningOrderBalance: unbalanceRunningOrderBalance});
    }

    const runningInvoiceBalance = this.route.snapshot.queryParamMap.get('runningInvoiceBalance');  // Filters in case to go from dashboard
    if (runningInvoiceBalance) {
      const unbalanceRunningInvoiceBalance = {symbol: '!', value: '0'};
      this.tableFilterForm.patchValue({runningInvoiceBalance: unbalanceRunningInvoiceBalance});
    }
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item)).filter(item => !!item))
    ];
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  onEditClicked(accountId: number) {
    this.router.navigate(['/entities/accounts/edit', accountId]);
  }

  initSelected(id: number) {
    if (this.isLoadingResults) {
      this.selectedId = id;
      return;
    }
    const row = this.dataSource.data.find((item: Account) => item.id === id);
    if (row) {
      this.selection.toggle(row as Account);
    }
  }

  toggleArchive($event) {
    this.isLoadingResults = true;
    this.accountService.getAccountList(!this.isSelectMode, $event.checked).pipe(take(1)).subscribe(
      (data) => this.setTableData(data),
      () => this.notificationService.error('Error occurred while trying to get account info.')
    );
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const nameSearch = FilterUtils.searchFunction(searchTerms.name, data.name);
      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const leadConsultantSearch = FilterUtils.searchFunction(searchTerms.leadConsultant, data.leadConsultant);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data));
      const orderBalanceSearch = this.createBalanceSearch('runningOrderBalance', searchTerms, data);
      const invoiceBalanceSearch = this.createBalanceSearch('runningInvoiceBalance', searchTerms, data);

      const filterFunctions = [
        nameSearch(), codeSearch(),
        leadConsultantSearch(), accountTypeSearch(),
        orderBalanceSearch(), invoiceBalanceSearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  createBalanceSearch = (searchTermName: string, searchTerms: any, data: any) => () => {
    if (!searchTerms[searchTermName].symbol || !searchTerms[searchTermName].value) {
      return true;
    }

    return FilterUtils.compare(searchTermName, searchTerms, data[searchTermName]);
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  checkEditRow(row: Account) {
    if (row.archived) {
      return this.hasEditArchivedPermission;
    }
    return this.hasEditPermission;
  }
}
