import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarSubmenuComponent } from './navbar-submenu/navbar-submenu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { NavbarComponent } from './navbar/navbar.component';
import { TableVersionHistoryComponent } from './table-version-history/table-version-history.component';
import { EditableCellComponent } from './editable-cell/editable-cell.component';
import { ViewModeDirective } from './editable-cell/view-mode.directive';
import { EditModeDirective } from './editable-cell/edit-mode.directive';
import { PositiveDecimalNumberDirective } from './directives/positive-decimal-number.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { BuildVersionComponent } from './build-version/build-version.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {ConsultantCommissionReportTableComponent} from './consultant-commission-report-table/consultant-commission-report-table.component';
import { NegativeCurrencyPipe } from './pipes/negative-currency.pipe';
import { NoteHeaderPipe } from './pipes/note-header.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarSubmenuComponent,
    TableVersionHistoryComponent,
    EditableCellComponent,
    ViewModeDirective,
    EditModeDirective,
    ConfirmationDialogComponent,
    NoteHeaderPipe,
    NegativeCurrencyPipe,
    PositiveDecimalNumberDirective,
    BuildVersionComponent,
    SelectSearchComponent,
    FileUploadComponent,
    ConsultantCommissionReportTableComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    RouterModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgxDropzoneModule
  ],
  exports: [
    NavbarComponent,
    NavbarSubmenuComponent,
    TableVersionHistoryComponent,
    EditableCellComponent,
    ViewModeDirective,
    EditModeDirective,
    NoteHeaderPipe,
    NegativeCurrencyPipe,
    PositiveDecimalNumberDirective,
    BuildVersionComponent,
    SelectSearchComponent,
    FileUploadComponent,
    ConsultantCommissionReportTableComponent
  ]
})
export class SharedModule { }
