import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountStationTransaction } from '../shared/models';
import { Role } from '../../shared/models/role.enum';
import { TransactionService } from '../shared/transaction.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';
import { sortListByLastEdit } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { MemoRefundVersionsDialogComponent } from '../memo-refund-versions-dialog/memo-refund-versions-dialog.component';
import { BalanceTransactionType } from '../shared/utils/balance-transaction-type';

@Component({
  selector: 'app-memo-refund-list',
  templateUrl: './memo-refund-list.component.html',
  styleUrls: ['./memo-refund-list.component.scss']
})
export class MemoRefundListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  selection = new SelectionModel<AccountStationTransaction>(true, []);
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'code',
    'accountName', 'accountCode',
    'station', 'transactionType', 'referenceNumber',
    'amount', 'enteredOn',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'lastUpdatedBy',
    'menu'
  ];

  digitFilters = [
    { name: 'amount', filterDef: 'amount-filter' },
  ];

  dateFilters = [
    {name: 'enteredOn', filterDef: 'enteredOn-filter'},
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
  ];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    accountName: new FormControl(''),
    accountCode: new FormControl(''),
    station: new FormControl(''),
    transactionType: new FormControl(''),
    referenceNumber: new FormControl(''),
    amount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    enteredOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl('')
  });

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  start: Date | null;
  end: Date | null;

  loading = true;
  hasEditPermission = this.authService.hasEditPermission;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private transactionService: TransactionService,
              private authService: AuthService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.subscription.add(this.transactionService.getTransactions().subscribe(
      data => {
        if (data?.length) {
          this.dataSource.data = sortListByLastEdit(data);
        }
        this.loading = false;
        setTimeout(this.applyDashboardFilter);
      },
      () => this.notificationService.error('Error occurred while trying to get transaction list.')
    ));

    this.manageDateFilter();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.enteredOn.value = value.enteredOn?.value?.toString();
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.amount.value = value.amount?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));
  }

  applyDashboardFilter = () => {
    const referenceNumber = this.route.snapshot.queryParamMap.get('quickBooks');  // Filters in case to go from dashboard
    const transactionType = this.route.snapshot.queryParamMap.get('transactionType');  // Filters in case to go from dashboard
    if (referenceNumber && transactionType) {
      this.tableFilterForm.patchValue({referenceNumber, transactionType});
    }
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'accountName':
        return item.account.name;
      case 'accountCode':
        return item.account.code;
      case 'station':
        return item.station.name;
      case 'transactionType':
        return item.type.type;
      case 'enteredOn':
        return  new Date(item.enteredOn);
      default: return item[property];
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllSelected() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: AccountStationTransaction): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  get transactionTypes() {
    return [BalanceTransactionType.REFUND.type, BalanceTransactionType.VENDOR_MEMO_CREDIT.type];
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.transactionService.getTransactionsInRange(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe((data) => this.dataSource.data = sortListByLastEdit(data),
        () => this.notificationService.error('Error occurred while trying to get station transaction list.'));
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.account?.name);
      const stationSearch = FilterUtils.searchFunction(searchTerms.station, data.station?.name);
      const accountCodeSearch = FilterUtils.searchFunction(searchTerms.accountCode, data.account?.code);
      const typeSearch = FilterUtils.createSelectSearch(searchTerms.transactionType, data.type.type);
      const referenceNumberSearch = FilterUtils.specificQuickBooksSearch(searchTerms.referenceNumber, data.referenceNumber);
      const amountSearch = FilterUtils.createDigitSearch('amount', 'amount', searchTerms, data);

      const enteredOnSearch = FilterUtils.createDateSearch('enteredOn', 'enteredOn', searchTerms, data);
      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);


      const filterFunctions = [
        codeSearch(), accountNameSearch(),
        accountCodeSearch(),
        stationSearch(), typeSearch(), referenceNumberSearch(),
        amountSearch(), enteredOnSearch(),
        createdAtSearch(), createdBySearch(),
        lastUpdatedAtSearch(), lastUpdatedBySearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  onViewClicked(transaction) {
    this.router.navigate([`/orders/memo-refund/edit`, transaction.id]);
  }

  onAddTransactionClicked() {
    this.router.navigate(['/orders/memo-refund/edit', '']);
  }

  showVersions(id: number) {
    this.dialog.open(MemoRefundVersionsDialogComponent, { data: { id } });
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
