import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AcceptPaymentService } from '../shared/accept-payment.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from '../../shared/notification.service';

import { sortListByLastEdit, getClientCandidateType } from '../../shared/utils/sort-list-util';
import { FilterUtils } from '../../shared/utils/filter-utils';
import { DateUtils } from '../../shared/utils/date-utils';
import { AcceptPayment } from '../shared/models';
import { Role } from '../../shared/models/role.enum';

import { AcceptPaymentVersionsDialogComponent } from '../accept-payment-versions-dialog/accept-payment-versions-dialog.component';

@Component({
  selector: 'app-accept-payment-list',
  templateUrl: './accept-payment-list.component.html',
  styleUrls: ['./accept-payment-list.component.scss']
})
export class AcceptPaymentListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  dataSource = new MatTableDataSource<AcceptPayment>();
  displayedColumns: string[] = [
    'code',
    'accountName', 'accountId', 'accountType',
    'totalInvoices', 'paymentAmount', 'quickBookReference',
    'paidOn', 'paidBy',
    'createdAt', 'createdBy', 'lastUpdatedAt', 'lastUpdatedBy',
    'menu'
  ];

  digitFilters = [
    {name: 'totalInvoices', filterDef: 'totalInvoices-filter'},
    {name: 'paymentAmount', filterDef: 'paymentAmount-filter'},
  ];

  dateFilters = [
    {name: 'paidOn', filterDef: 'paidOn-filter'},
    {name: 'createdAt', filterDef: 'createdAt-filter'},
    {name: 'lastUpdatedAt', filterDef: 'lastUpdatedAt-filter'},
  ];

  tableFilterForm = new FormGroup({
    code: new FormControl(''),
    accountName: new FormControl(''),
    accountId: new FormControl(''),
    accountType: new FormControl(''),
    paidOn: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    totalInvoices: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    paymentAmount: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    quickBookReference: new FormControl(''),
    paidBy: new FormControl(''),
    createdAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    createdBy: new FormControl(''),
    lastUpdatedAt: new FormGroup({
      symbol: new FormControl('='),
      value: new FormControl('')
    }),
    lastUpdatedBy: new FormControl('')
  });

  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  isLoadingResults = true;
  start: Date | null;
  end: Date | null;

  hasEditPermission = this.authService.hasPermission([Role.ACCOUNTANT, Role.SENIOR_ACCOUNTANT]);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private notificationService: NotificationService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private service: AcceptPaymentService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const paymentList$ = this.service.getAcceptPayments();
    this.subscription.add(paymentList$.subscribe(
      (data) => {
        this.dataSource.data = sortListByLastEdit(data);
        setTimeout(this.applyDashboardFilter);
        this.isLoadingResults = false;
      },
      () => this.notificationService.error('Error occurred while trying to get accept payment list.')
    ));

    this.subscription.add(this.tableFilterForm.valueChanges.subscribe(value => {
      value.createdAt.value = value.createdAt?.value?.toString();
      value.lastUpdatedAt.value = value.lastUpdatedAt?.value?.toString();
      value.paidOn.value = value.paidOn?.value?.toString();
      value.totalInvoices.value = value.totalInvoices?.value?.toString();
      value.paymentAmount.value = value.paymentAmount?.value?.toString();
      this.dataSource.filter = JSON.stringify(FilterUtils.processUndefinedValues(value));
    }));

    this.manageDateFilter();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filterPredicate = this.createFilter();
  }

  applyDashboardFilter = () => {
    const quickBookReference = this.route.snapshot.queryParamMap.get('quickBooks');  // Filters in case to go from dashboard
    if (quickBookReference) {
      this.tableFilterForm.patchValue({quickBookReference});
    }
  }

  manageDateFilter() {
    const inputChanges$ = this.range.valueChanges;
    inputChanges$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          const {start, end} = val;
          this.end = end;
          this.start = start;
          this.clearFilterForm();
          return this.service.getAcceptPayments(DateUtils.dateFormatToShort(start), DateUtils.dateFormatToShort(end));
        })
      )
      .subscribe((data) => this.dataSource.data = sortListByLastEdit(data),
        () => this.notificationService.error('Error occurred while trying to get accept payment list.'));
  }

  sortingDataAccessor = (item: any, property: string) => {
    switch (property) {
      case 'accountName':
        return item.accountBalance?.account?.name;
      case 'accountId':
        return item.accountBalance?.account?.code;
      case 'accountType':
        return this.getAccountType(item.accountBalance?.account);
      case 'totalInvoices':
        return this.getTotalInvoices(item);
      case 'paymentAmount':
        return this.getPaymentAmount(item);
      case 'paidOn':
        return new Date(item.paidOn);
      default:
        return item[property];
    }
  }

  getDetailLink(accountId, acceptPaymentId) {
    return `/orders/accept-payment/detail/${accountId}-${acceptPaymentId}`;
  }

  onEditClicked(acceptPaymentId: number, accountId: number) {
    this.router.navigate(['/orders/accept-payment/edit', `${accountId}-${acceptPaymentId}`]);
  }

  showVersions(acceptPaymentId: number, accountId: number) {
    this.dialog.open(AcceptPaymentVersionsDialogComponent, { data: { id: acceptPaymentId, accountId } });
  }

  get columnFilters() {
    return this.displayedColumns.map(column => `${column}-filter`);
  }

  get resultsLength() {
    return this.dataSource.data.length;
  }

  getTotalInvoices(payment): number {
    if (!payment.acceptPaymentInvoices) {
      return 0;
    }
    return payment.acceptPaymentInvoices.length;
  }

  getPaymentAmount(payment) {
    return payment.acceptPaymentInvoices.length > 0
      ? payment.acceptPaymentInvoices?.map(item => item.amount).reduce((a, b) => b ? a + b : a, 0)
      : payment.paidAmount;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAccountType(account) {
    return getClientCandidateType(account);
  }

  get accountTypes() {
    return [...new Set(this.dataSource.data.map(item => this.getAccountType(item.accountBalance?.account)).filter(item => !!item))
    ];
  }

  createFilter() {
    return (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);

      const codeSearch = FilterUtils.searchFunction(searchTerms.code, data.code);
      const accountIdSearch = FilterUtils.searchFunction(searchTerms.accountId, data.accountBalance?.account?.code);
      const accountNameSearch = FilterUtils.searchFunction(searchTerms.accountName, data.accountBalance?.account?.name);
      const accountTypeSearch = FilterUtils.createSelectSearch(searchTerms.accountType, this.getAccountType(data.accountBalance?.account));
      const totalInvoicesSearch = FilterUtils.createFieldDigitSearch('totalInvoices', searchTerms, this.getTotalInvoices(data));
      const paymentAmountSearch = FilterUtils.createFieldDigitSearch('paymentAmount', searchTerms, this.getPaymentAmount(data));
      const quickBookReferenceSearch = FilterUtils.specificQuickBooksSearch(searchTerms.quickBookReference, data.quickBookReference);

      const paidOnSearch = FilterUtils.createDateSearch('paidOn', 'paidOn', searchTerms, data);
      const paidBySearch = FilterUtils.searchFunction(searchTerms.paidBy, data.paidBy);

      const createdAtSearch = FilterUtils.createDateSearch('createdAt', 'createdAt', searchTerms, data);
      const createdBySearch = FilterUtils.searchFunction(searchTerms.createdBy, data.createdBy);
      const lastUpdatedAtSearch = FilterUtils.createDateSearch('lastUpdatedAt', 'lastUpdatedAt', searchTerms, data);
      const lastUpdatedBySearch = FilterUtils.searchFunction(searchTerms.lastUpdatedBy, data.lastUpdatedBy);

      const filterFunctions = [
        codeSearch(),
        accountNameSearch(),
        accountIdSearch(),
        accountTypeSearch(),
        totalInvoicesSearch(),
        paymentAmountSearch(),
        quickBookReferenceSearch(),
        paidOnSearch(), paidBySearch(),
        createdAtSearch(), createdBySearch(),
        lastUpdatedAtSearch(), lastUpdatedBySearch()
      ];

      return filterFunctions.every(searchFunction => searchFunction);
    };
  }

  clearFilterForm() {
    FilterUtils.clearFilterForm(this.tableFilterForm);
  }
}
