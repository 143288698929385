import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{

  constructor(private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    if (!this.authService.hasEditPermission) {
      this.router.navigateByUrl('/reports');
    }
  }
}
