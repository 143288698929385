import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'negativeCurrency'
})
export class NegativeCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: number, ...args: unknown[]): string {
    if (value < 0) {
      return `$(${this.currencyPipe.transform(Math.abs(value), '', '')})`;
    } else {
      return this.currencyPipe.transform(value);
    }
  }

}
