<div class="content" [ngClass]="{'dialog-content': dialogWindow}" role="main">
  <div class="header" *ngIf="!dialogWindow">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Station</h1>
  </div>
  <div class="loading" [ngClass]="{'dialog-spinner': dialogWindow}" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>

  <form [formGroup]="stationForm" (ngSubmit)="submit(stationForm.value)" *ngIf="!loading">
    <mat-card class="container" [ngClass]="{'dialog-mat-card': dialogWindow}">
      <mat-card-content class="form-content">
        <div class="form-content_column">
          <h2>General Info</h2>
          <mat-form-field>
            <mat-label>Station Name</mat-label>
            <input formControlName="name" matInput required maxlength="50">
            <mat-error *ngIf="stationForm.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="stationForm.get('name').hasError('validation')">
              {{stationForm.get('name').getError('validation')}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Media Type</mat-label>
            <mat-select formControlName="mediaType" required>
              <mat-option *ngFor="let option of mediaTypes" aria-selected="true" [value]="option">
                {{option.type}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="stationForm.get('mediaType').hasError('required')">
              Media Type is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Address Line 1</mat-label>
            <input formControlName="addressLine1" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Address Line 2</mat-label>
            <input formControlName="addressLine2" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Address Line 3</mat-label>
            <input formControlName="addressLine3" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput maxlength="50">
          </mat-form-field>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let option of states" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>ZIP Code</mat-label>
                <input formControlName="zipCode" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 1</mat-label>
                <input matInput formControlName="phone1" appPhoneMask maxlength="14">
                <mat-error *ngIf="addressFormGroup.get('phone1').hasError('pattern')">
                  Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 1 Extension</mat-label>
                <input formControlName="phone1Ext" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 2</mat-label>
                <input matInput formControlName="phone2" appPhoneMask maxlength="14">
                <mat-error *ngIf="addressFormGroup.get('phone2').hasError('pattern')">
                Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Phone 2 Extension</mat-label>
                <input formControlName="phone2Ext" matInput maxlength="10">
              </mat-form-field>
            </div>
          </div>
          <div class="inner-form-content">
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Fax 1</mat-label>
                <input formControlName="fax1" matInput>
              </mat-form-field>
            </div>
            <div class="inner-form-content_column">
              <mat-form-field [formGroup]="addressFormGroup">
                <mat-label>Fax 2</mat-label>
                <input formControlName="fax2" matInput maxlength="25">
              </mat-form-field>
            </div>
          </div>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Contact Name</mat-label>
            <input formControlName="contactName" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field [formGroup]="addressFormGroup">
            <mat-label>Format</mat-label>
            <input formControlName="format" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Channel</mat-label>
            <input formControlName="channel" matInput maxlength="50">
          </mat-form-field>
        </div>
        <div class="form-content_column">
          <h2>Payment Preferences</h2>
          <mat-form-field>
            <mat-label>Parent Company</mat-label>
            <input type="text"
              matInput
              formControlName="parentCompany"
              #parentCompanyInput
              (blur)="onBlurParentCompany(parentCompanyInput)"
              [matAutocomplete]="auto"
            >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayRelatedItem">
              <mat-option *ngFor="let option of filteredCompanies | async" [value]="option" (mousedown)="onParentCompanyOptionSelect(option)">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="browse-link" (click)="openDialog()">Browse Companies ></div>

          <mat-checkbox class="checkbox-control" formControlName="overwriteParentCompanyPaymentMethod">
            Override Parent Company Payment Preferences
          </mat-checkbox>

          <mat-form-field class="sub-header">
            <mat-label>Preferred Payment method</mat-label>
            <mat-select formControlName="paymentMethod" [disabled]="!stationForm.get('overwriteParentCompanyPaymentMethod').value">
              <mat-option *ngFor="let option of paymentMethods" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!!stationForm.get('overwriteParentCompanyPaymentMethod').value">
            <mat-label>Payment URL</mat-label>
            <input formControlName="paymentGuidelinesUrl" matInput maxlength="150">
            <mat-error
              *ngIf="stationForm.get('paymentGuidelinesUrl')?.touched && stationForm.get('paymentGuidelinesUrl')?.hasError('pattern')">
              Please enter a valid url
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!stationForm.get('overwriteParentCompanyPaymentMethod').value">
            <mat-label>Payment URL</mat-label>
            <input [value]="parentCompany?.paymentGuidelinesUrl" matInput disabled>
          </mat-form-field>

          <h2>Station Documentation</h2>
          <app-file-upload
            *ngIf="!!stationForm.get('overwriteParentCompanyPaymentMethod').value"
            [filesUploadedBefore]="documentsUploadedBefore"
            (filesChanges)="setDocumentation($event)"
            [mode]="mode"
            [id]="stationID"
            [component]="ComponentType.Station"
          >
          </app-file-upload>

          <app-file-upload
            *ngIf="!stationForm.get('overwriteParentCompanyPaymentMethod').value && parentCompany && parentCompany?.documents"
            [filesUploadedBefore]="parentCompany.documents"
            [id]="parentCompany.id"
            [component]="ComponentType.ParentCompany"
            [readonly]="true"
            [canUpload]="false"
          >
          </app-file-upload>

          <mat-form-field class="form-textarea">
            <mat-label>General Payment Notes</mat-label>
            <textarea formControlName="generalPaymentNotes" type="text" matInput maxlength="1000"></textarea>
          </mat-form-field>

          <h2 class="sub-header">Media Markets</h2>
          <mat-form-field>
            <mat-label>Media Markets</mat-label>
            <mat-select formControlName="mediaMarkets" multiple>
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let mediaMarket of selectedMarkets"
                            [removable]="true" (removed)="remove(mediaMarket.id)">
                    {{ mediaMarket.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <app-select-search [formControl]="marketFilterCtrl"></app-select-search>
              <mat-option *ngFor="let mediaMarket of filteredMarkets | async" [value]="mediaMarket"
              >{{ mediaMarket.name }}</mat-option>

            </mat-select>
          </mat-form-field>
          <br/>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card-actions class="form-action buttons-container" [ngClass]="{'dialog-actions': dialogWindow}">
      <button mat-raised-button appLoading color="primary" [loading]="saving" [disabled]="!stationForm.valid">Save</button>
      <button type="button" mat-raised-button appLoading color="primary"
              [disabled]="stationForm.invalid || saving"
              (click)="submit(stationForm.value, true)"
              *ngIf="!dialogWindow"
      >Save and Add New
      </button>
      <button mat-raised-button type="button" [disabled]="saving" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>
