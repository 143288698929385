<mat-card class="batch-station" [ngClass]="{'bg-white': index % 2}" [formGroup]="form">
  <div class="paid" *ngIf="isCompleted">
    <h2>Paid</h2>
    <div>{{paidInfo}}</div>
  </div>
  <div class="payment-title">
    <h2>{{station?.name}} ({{station?.mediaType?.type}})</h2>
    <div class="payment-market">{{markets}}</div>
  </div>
  <div class="flex-row">
    <div class="total" *ngIf="readonly">Requested Total: {{(payment?.payAmount || 0) | currency }}</div>
    <mat-form-field *ngIf="!readonly" class="three-column">
      <mat-label>Requested Total</mat-label>
      <input type="number"
             matInput
             required
             formControlName="payAmount"
             [readonly]="readonly"
             maxLength="17"
             oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      >
      <mat-icon matSuffix>attach_money</mat-icon>
      <mat-error *ngIf="form.get('payAmount').invalid">Requested Total is <strong>required</strong></mat-error>
    </mat-form-field>
    <div class="payment-method">Payment Method: {{payment?.paymentMethod?.name || 'no method'}}</div>
  </div>

  <div class="payment-info">
    <div class="flight-estimate-container" *ngIf="payment?.stationFlights && payment.stationFlights?.length">
      <div class="flight-estimate" *ngFor="let flight of payment.stationFlights">
        <div>
          <div>FLIGHT = {{flight.flight.ftcOn}} - {{flight.flight.ltcOn}}</div>
          <div>ESTIMATE # = {{flight.flight.estimateNumber || 'N/A'}}</div>
        </div>
      </div>
    </div>
    <div>
      <a target="_blank" [href]="paymentGuidelinesUrl" *ngIf="payment?.station.paymentGuidelinesUrl">{{payment?.station.paymentGuidelinesUrl}}</a>
      <app-file-upload
        [filesUploadedBefore]="payment?.station?.documents"
        [id]="payment?.station?.overwriteParentCompanyPaymentMethod ? payment?.station?.id : payment?.station?.parentCompany?.id"
        [component]="payment?.station?.overwriteParentCompanyPaymentMethod ? ComponentType.Station : ComponentType.ParentCompany"
        [readonly]="true"
        [canUpload]="false"
      >
      </app-file-upload>
    </div>
  </div>

  <h3 class="upload-title">Upload Proof of Payment</h3>
  <div class="payment-info">
    <div class="file-upload-container">
      <app-file-upload
        [filesUploadedBefore]="payment?.documents"
        [id]="paymentBatchId"
        [propId]="payment?.id"
        [component]="ComponentType.PaymentBatch"
        [readonly]="readonly"
        [mode]="mode"
        (filesChanges)="setBatchDocumentation($event)"
      >
      </app-file-upload>
    </div>
    <div class="payment-version">
      <a class="version-ref" (click)="showVersions()" *ngIf="payment.versions && payment.versions.length">Version {{payment.versionLabel || ''}}</a>
    </div>
  </div>
  <div class="flex-row space-between">
    <mat-form-field class="row-column">
      <mat-label>QBref#</mat-label>
      <input formControlName="quickBookReference" matInput maxlength="25" [readonly]="readonly">
    </mat-form-field>
    <mat-form-field class="row-column">
      <mat-label>Payment Sent Date</mat-label>
      <input formControlName="paidOn" matInput  [matDatepicker]="pickerPaidOn" required>
      <mat-datepicker-toggle matSuffix [for]="pickerPaidOn"></mat-datepicker-toggle>
      <mat-datepicker #pickerPaidOn></mat-datepicker>
      <mat-error *ngIf="form.get('paidOn').invalid"> Payment Sent Date is <strong>required</strong></mat-error>
    </mat-form-field>
  </div>
  <mat-checkbox *ngIf="!isCompleted" class="checkbox-control" formControlName="completed" [disabled]="readonly">
    <span class="checkbox-label">I have completed this payment</span></mat-checkbox>
</mat-card>
