import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TRANSACTIONS } from '../../core/apiUrls';
import { CheckRequestCreationFiler, PaymentCheckRequest } from './models';

const PAYMENT_CHECK_REQUESTS = `${TRANSACTIONS}/paymentCheckRequests`;

@Injectable({
  providedIn: 'root'
})
export class PaymentCheckRequestService {

  constructor(private http: HttpClient) { }

  getCheckRequestByTemplate(template: CheckRequestCreationFiler): Observable<PaymentCheckRequest> {
    return this.http.post<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/template`, template);
  }

  getCheckRequests(createdAfter?: string, createdBefore?: string): Observable<PaymentCheckRequest[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }
    return this.http.get<PaymentCheckRequest[]>(PAYMENT_CHECK_REQUESTS, { params: httpParams});
  }

  getCheckRequest(checkRequestId: string, toEdit = false): Observable<PaymentCheckRequest> {
    if (toEdit) {
      const params = new HttpParams({
        fromObject: { toEdit: 'true' },
      });
      return this.http.get<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/${checkRequestId}`, {
        params,
      });
    } else {
      return this.http.get<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/${checkRequestId}`);
    }
  }

  createCheckRequest(checkRequest: PaymentCheckRequest): Observable<PaymentCheckRequest> {
    return this.http.post<PaymentCheckRequest>(PAYMENT_CHECK_REQUESTS, checkRequest);
  }

  updateCheckRequest(checkRequestId: number, checkRequest: PaymentCheckRequest): Observable<PaymentCheckRequest> {
    return this.http.put<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/${checkRequestId}`, checkRequest);
  }

  resetWith(id: number, filter: CheckRequestCreationFiler): Observable<PaymentCheckRequest> {
    return this.http.post<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/resetWith/${id}`, filter);
  }

  disableCheckRequest(checkRequestId: number): Observable<boolean> {
    return this.http.put<boolean>(`${PAYMENT_CHECK_REQUESTS}/${checkRequestId}/disableCheckRequest`, {});
  }

  getReadyToPayCheckRequests(): Observable<PaymentCheckRequest[]> {
    return this.http.get<PaymentCheckRequest[]>(`${PAYMENT_CHECK_REQUESTS}/readyToPay`);
  }

  updateSummary(checkRequest: PaymentCheckRequest): Observable<PaymentCheckRequest> {
    return this.http.post<PaymentCheckRequest>(`${PAYMENT_CHECK_REQUESTS}/updateSummary`, checkRequest);
  }
}
