<div class="container">
  <ul *ngIf="filesUploadedBefore?.length" class="uploaded-before-files">
    <ng-template #fileLoadingBlock>
      <div class="loading-files-template">
        <div class="file-label">Loading files...</div>
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </ng-template>
    <ng-container *ngIf="!isLoadingFiles; else fileLoadingBlock">
      <li *ngFor="let fileUploadedBefore of filesUploadedBefore">
        <ng-container *ngIf="fileUploadedBefore.size; else fileLoadingBlock">
          <span *ngIf="!readonly && canUpload">
            <a title="Remove" (click)="confirmDeletionAndRemove(false, fileUploadedBefore)">
              <mat-icon>delete</mat-icon>
            </a>
          </span>
          <span class="file-label">{{fileUploadedBefore.name}}</span>
          <span class="file-size">{{getUploadSizeMB(fileUploadedBefore.size) || 'N/A '}}MB</span>
          <a class="file-download"
             [href]="getSantizeUrl(fileUploadedBefore.url)"
             [download]="fileUploadedBefore.fileName"
          >
            <mat-icon>get_app</mat-icon> download
          </a>
        </ng-container>

      </li>
    </ng-container>

  </ul>

  <ul *ngIf="files.length" class="new-files">
    <li *ngFor="let file of files">
      <span  *ngIf="!readonly">
        <a title="Remove" (click)="confirmDeletionAndRemove(true, file)">
          <mat-icon>delete</mat-icon>
        </a>
      </span>
      <span class="file-label">{{file.name}}</span>
      <span class="file-size">{{getUploadSizeMB(file.size)}}MB</span>
    </li>
  </ul>

  <ngx-dropzone *ngIf="canUpload" (change)="onSelect($event)" [disabled]="isLoading || readonly">
    <ngx-dropzone-label>
      <mat-icon>get_app</mat-icon><br>
      Drag and drop to upload<br>
      or<br>
      <div class="browse-btn">Browse for files</div>
    </ngx-dropzone-label>
  </ngx-dropzone>
</div>
