<div class="content" role="main">
  <div class="header">
    <button mat-icon-button (click)="returnClicked()">
      <span class="material-icons">keyboard_backspace</span>
    </button>
    <h1>Account invoice</h1>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <form [formGroup]="form" *ngIf="!loading">
    <mat-card class="container">
      <mat-card-content>
        <div class="form-content">
          <div class="form-content_column">
            <h2>General Info</h2>
            <mat-form-field *ngIf="isEditMode">
              <mat-label>Account Invoice ID#</mat-label>
              <input type="text" matInput [value]="accountInvoice.code" readonly disabled>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Account</mat-label>
              <input type="text"
                     matInput
                     required
                     formControlName="account"
                     #accountInput
                     (blur)="onBlurAccount(accountInput)"
                     [matAutocomplete]="autoAccount"
                     [readonly]="isEditMode"
              >
              <mat-autocomplete #autoAccount="matAutocomplete" [displayWith]="displayAccount">
                <mat-option *ngFor="let option of filteredAccounts | async" [value]="option"
                            (mousedown)="onAccountOptionSelect(option)">
                  {{option.code}} - {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="browse-link" (click)="openAccountDialog()" *ngIf="!isEditMode">
              Browse Accounts >
            </div>
            <div class="inner-form-content">
              <div class="inner-form-content_column">
                <mat-form-field class="inner-form-content_column">
                  <mat-label>Election type</mat-label>
                  <mat-select formControlName="electionType" [disabled]="!canChangeInvoiceData">
                    <mat-option [value]="">
                      N/A
                    </mat-option>
                    <mat-option *ngFor="let option of electionTypes" [value]="option">
                      {{option.type}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="inner-form-content_column">
                <mat-form-field>
                  <mat-label>Election Date</mat-label>
                  <input matInput [matDatepicker]="pickerElectionDate"
                         formControlName="electionDate" [disabled]="!canChangeInvoiceData">
                  <mat-datepicker-toggle matSuffix [for]="pickerElectionDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerElectionDate ></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-content_column">
            <h2>Associated Consultant Company *</h2>
            <div *ngFor="let item of associatedConsultants; let i = index;" class="flex-container">
              <div class="flex-row" [formGroup]="item">
                <mat-checkbox *ngIf="canChangeInvoiceData" class="flex-checkbox" formControlName="selected"
                              (change)="toggleConsultantCompany(item)"></mat-checkbox>
                <mat-form-field class="flex-item" disabled>
                  <mat-label>{{i===0 ? 'Lead' : 'Other'}} Consultant {{i + 1}}</mat-label>
                  <input matInput formControlName="name" readonly>
                </mat-form-field>
                <mat-form-field class="flex-item" disabled>
                  <mat-label>Consultant ID#</mat-label>
                  <input matInput formControlName="code" readonly>
                </mat-form-field>
                <mat-form-field class="flex-item-rate">
                  <mat-label>Rate</mat-label>
                  <input matInput formControlName="commission"
                         [readonly]="!item.controls.selected.value || !canChangeInvoiceData" type="number"
                         max="15" appPositiveDecimalNumber (change)="calculateGross()">
                  <span matSuffix>%</span>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row">
              <mat-form-field class="cpm-item" disabled>
                <input matInput [value]="account?.cpmCompany?.name || 'CPM Company'" readonly>
              </mat-form-field>
              <mat-form-field class="flex-item-rate">
                <mat-label>Rate</mat-label>
                <input matInput formControlName="cpmRate" type="number" min="0" max="15"
                       (change)="calculateGross()" appPositiveDecimalNumber>
                <span matSuffix>%</span>
              </mat-form-field>
            </div>
          </div>
        </div>

        <h2>Invoiced Orders</h2>
        <app-account-invoice-orders [invoicedOrdersData]="accountInvoice.invoicedOrdersData"
                                    [disabled]="!canChangeInvoiceData"
        ></app-account-invoice-orders>
        <button mat-stroked-button type="button" color="accent"
                [disabled]="!form.value.account || !canChangeInvoiceData" (click)="browseOrders()">
          View List of Orders
        </button>
        <button mat-stroked-button type="button" color="accent" class="flex-item"
                (click)="addRowToInvoicedOrder()" [disabled]="!canChangeInvoiceData">
          + ADD ROW
        </button>
        <div class="form-content" *ngIf="canShowCommission">
          <div class="stub_column"></div>
          <div class="summary_column">
            <div class="summary-row">
              <div class="summary-row-rate">{{form.get('cpmRate').value || 0}}%</div>
              <div class="summary-row-label">{{account?.cpmCompany?.name || 'CPM Company'}} Commission</div>
              <div class="summary-row-sum">{{getOrderCommission(form.get('cpmRate').value) | currency}}</div>
            </div>
            <div class="summary-row" *ngFor="let consultant of associatedConsultants">
              <div class="summary-row-rate">{{consultant.get('commission').value || '-'}}%</div>
              <div class="summary-row-label">{{consultant.get('consultantCompany').value.name}} Commission</div>
              <div class="summary-row-sum">{{getOrderCommission(consultant.get('commission').value) | currency}}</div>
            </div>
            <div class="summary-row total">
<!--        CPM-2084 Hide Commission total because of hide Expenses - don't delete it!!! will be uncommitted-->
<!--              <div class="summary-row-label">Total:</div>
              <div class="summary-row-sum">{{orderTotal | currency}}</div>-->
            </div>
          </div>
        </div>

<!--        CPM-1451 Hide the Expenses label and ADD EXPENSE button - don't delete it!!! will be uncommitted-->
<!--        <h2>Expenses</h2>-->
        <div class="form-content">
          <div class="stub_column">
            <app-account-invoice-expenses
              [disableDelete]="!canChangeInvoiceData"
              [invoicedExpensesData]="invoicedExpensesData"
              (deleteClicked)="handleDeleteExpense($event)"
            ></app-account-invoice-expenses>
<!--            <button mat-stroked-button type="button" color="accent"
                    (click)="openExpenseDialog()" [disabled]="!canAddExpense">
              + ADD EXPENSE
            </button>-->
          </div>
          <div class="summary_column" *ngIf="form.get('account').value && invoicedExpensesData?.length">
            <div class="summary-row">
              <div class="summary-row-label"><b>Commissionable total:</b></div>
              <div class="summary-row-sum">{{totalCommissionable | currency}}</div>
            </div>
            <div class="summary-row" *ngFor="let consultant of associatedConsultants">
              <div class="summary-row-rate">{{consultant.get('commission').value || '-'}}%</div>
              <div class="summary-row-label">{{consultant.get('consultantCompany').value.name}} Commission</div>
              <div class="summary-row-sum">{{calculateConsultantCommission(consultant.get('commission').value) | currency}}</div>
            </div>
            <div class="summary-row total">
              <div class="summary-row-label">Total:</div>
              <div class="summary-row-sum">{{summaryExpenseTotal | currency}}</div>
            </div>
          </div>
        </div>

        <div class="form-content" *ngIf="invoicedExpensesData?.length || accountInvoice?.invoicedOrdersData?.orderedData?.length">
          <div class="stub_column"></div>
          <div class="summary_column">
            <h2 class="summary-row">
              <div class="summary-row-label">Final Invoice Balance</div>
              <div class="summary-row-sum">{{finalInvoiceBalance | currency}}</div>
            </h2>
          </div>
        </div>

        <div class="form-content flex-container">
          <div class="form-content_column">
            <h2>Additional Info</h2>
            <mat-form-field>
              <mat-label>Bill To</mat-label>
              <input formControlName="billTo" matInput maxlength="25" [disabled]="!canChangeInvoiceData">
            </mat-form-field>
            <h2>Make All Checks Payable To:</h2>
            <div formGroupName="remitToAddress">
              <section class="form-element" *ngIf="officeNames?.length > 1">
                <mat-radio-group aria-label="Select Office" formControlName="officeName" class="margin-element"
                                 [disabled]="!canChangeInvoiceData">
                  <mat-radio-button *ngFor="let office of officeNames; let i = index" (click)="setAddress(form.get('account').value, i)" [value]="office">{{office}}</mat-radio-button>
                </mat-radio-group>
              </section>
              <mat-form-field>
                <mat-label>Address 1</mat-label>
                <input formControlName="addressLine1" matInput maxlength="50" [disabled]="!canChangeInvoiceData">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Address 2</mat-label>
                <input formControlName="addressLine2" matInput maxlength="25" [disabled]="!canChangeInvoiceData">
              </mat-form-field>
              <mat-form-field>
                <mat-label>City</mat-label>
                <input formControlName="city" matInput maxlength="50" [disabled]="!canChangeInvoiceData">
              </mat-form-field>
              <div class="inner-form-content">
                <div class="inner-form-content_column">
                  <mat-form-field>
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" [disabled]="!canChangeInvoiceData">
                      <mat-option *ngFor="let option of states" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="inner-form-content_column">
                  <mat-form-field>
                    <mat-label>ZIP Code</mat-label>
                    <input formControlName="zipCode" matInput maxlength="10" [readonly]="!canChangeInvoiceData">
                  </mat-form-field>
                </div>
              </div>
              <div class="inner-form-content">
                <div class="inner-form-content_column">
                  <mat-form-field>
                    <mat-label>Phone number</mat-label>
                    <input formControlName="phone1" appPhoneMask matInput maxlength="14"
                           [readonly]="!canChangeInvoiceData">
                    <mat-error *ngIf="form?.get('phone1')?.hasError('pattern') || false">
                      Allowed phone formats: xxx-xxxx, xxx-xxx-xxxx, x-xxx-xxx-xxxx.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="inner-form-content_column">
                  <mat-form-field>
                    <mat-label>FAX</mat-label>
                    <input formControlName="fax1" matInput maxlength="14" [readonly]="!canChangeInvoiceData">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-form-field *ngIf="isEditMode">
              <mat-label>Account Invoice ID#</mat-label>
              <input type="text" matInput [value]="accountInvoice.code" readonly>
            </mat-form-field>
            <h2>Notes</h2>
            <div *ngFor="let note of noteReverse" class="note">
              <div><b>{{note | noteHeader}}</b></div>
              <div><span>{{note.message}}</span></div>
            </div>
            <mat-form-field appearance="fill">
              <textarea rows="4" formControlName="noteToAdd" matInput [maxLength]="255"></textarea>
            </mat-form-field>
          </div>
          <div class="form-content_column" [formGroup]="wireToGroupControl">
            <h2>Wire to:</h2>
            <mat-form-field>
              <mat-label>Bank Information</mat-label>
              <input formControlName="bankInformation" matInput [disabled]="!canChangeInvoiceData">
            </mat-form-field>
            <mat-form-field>
              <mat-label>ABA Routing Number</mat-label>
              <input formControlName="abaRoutingNumber" matInput [disabled]="!canChangeInvoiceData">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Account Name</mat-label>
              <input formControlName="accountName" matInput [disabled]="!canChangeInvoiceData">
            </mat-form-field>
            <mat-form-field>
              <mat-label>FCB: Account Number</mat-label>
              <input formControlName="fcbAccountNumber" matInput [disabled]="!canChangeInvoiceData">
            </mat-form-field>

          </div>
        </div>

      </mat-card-content>

    </mat-card>
    <mat-card-actions class="form-action buttons-container">
      <button mat-raised-button appLoading type="submit" color="primary"
        [loading]="saving" [disabled]="isSaveDisabled"
        (click)="save()"
      >Save</button>
      <button mat-raised-button appLoading
              color="primary" type="button" class="header-button"
              [loading]="submitting" [disabled]="isSaveDisabled"
              (click)="saveAndSubmitAndExport()"
              *ngIf="canSubmit"
      >Submit and Export
      </button>
      <button mat-raised-button appLoading color="primary"
        [loading]="disabling" [disabled]="isSaveDisabled" type="button"
        (click)="disable()"
        *ngIf="canDisable"
      >Disable
      </button>
      <button mat-raised-button [disabled]="isRequesting" (click)="returnClicked()">Cancel</button>
    </mat-card-actions>
  </form>
</div>
