import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StationInvoiceExpense } from './models';
import { EXPENSES, TYPEAHEAD } from '../../core/apiUrls';
import { TypeaheadItem } from '../../shared/models/typeahead-item';

@Injectable({
  providedIn: 'root'
})
export class ExpensesService {

  constructor(private http: HttpClient) { }

  getExpenses(createdAfter?: string, createdBefore?: string): Observable<StationInvoiceExpense[]> {
    let httpParams = new HttpParams();
    if (createdAfter) {
      httpParams = httpParams.set('since', createdAfter);
    }
    if (createdBefore) {
      httpParams =  httpParams.set('to', createdBefore);
    }
    return this.http.get<StationInvoiceExpense[]>(EXPENSES, { params: httpParams});
  }

  getExpenseVersions(id: number): Observable<StationInvoiceExpense[]> {
    return this.http.get<StationInvoiceExpense[]>(`${EXPENSES}/${id}/versions`);
  }

  getExpense(id: string): Observable<StationInvoiceExpense> {
    return this.http.get<StationInvoiceExpense>(`${EXPENSES}/${id}`);
  }

  createExpense(expense: StationInvoiceExpense): Observable<StationInvoiceExpense> {
    return this.http.post<StationInvoiceExpense>(`${EXPENSES}`, expense);
  }

  updateExpense(expense: StationInvoiceExpense): Observable<StationInvoiceExpense> {
    return this.http.put<StationInvoiceExpense>(`${EXPENSES}/${expense.id}`, expense);
  }

  uploadExpenseDocument(expenseId: number, file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post<StationInvoiceExpense>(`${EXPENSES}/${expenseId}/documents`, fd);
  }

  deleteExpenseDocument(expenseId: number, documentId: number): Observable<any> {
    return this.http.delete<any>(`${EXPENSES}/${expenseId}/documents/${documentId}`);
  }

  getExpenseDocument(expenseId: number, documentId: number): Observable<any> {
    return this.http.get(
      `${EXPENSES}/${expenseId}/documents/${documentId}/download`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  getAccountList(): Observable<TypeaheadItem[]> {
    return this.http.get<TypeaheadItem[]>(`${TYPEAHEAD}/accounts`);
  }
}
