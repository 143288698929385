<div class="content-toolbar mat-toolbar mat-accent">
  <nav class="module-nav">
    <ul role="navigation">
      <li>
        <a class="nav-link" routerLink="/orders/cinvoices" routerLinkActive="active">
          <span class="nav-link-inner">Account Invoices</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/orders" routerLinkActive="active">
          <span class="nav-link-inner">Orders</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/checks" routerLinkActive="active">
          <span class="nav-link-inner">Check Requests</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/payments" routerLinkActive="active">
          <span class="nav-link-inner">Payment Batches</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/transactions/invoice" routerLinkActive="active">
          <span class="nav-link-inner">Station Invoices</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/expenses" routerLinkActive="active">
          <span class="nav-link-inner">Expenses</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/accept-payment" routerLinkActive="active">
          <span class="nav-link-inner">Accept Payment</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/memo-refund" routerLinkActive="active">
          <span class="nav-link-inner">Memo/Refund</span>
        </a>
      </li>
      <li>
        <a class="nav-link" routerLink="/orders/commissions" routerLinkActive="active">
          <span class="nav-link-inner">Commission</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
<router-outlet></router-outlet>
