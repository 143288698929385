import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ConsultantCompany } from '../shared/models';
import { ConsultantListComponent } from '../consultant-list/consultant-list.component';

@Component({
  selector: 'app-browse-consultant-dialog',
  templateUrl: './browse-consultant-dialog.component.html',
  styleUrls: ['./browse-consultant-dialog.component.scss']
})
export class BrowseConsultantDialogComponent implements AfterViewInit {
  @ViewChild(ConsultantListComponent, {static: true}) consultantList: ConsultantListComponent;
  selectedConsultant: ConsultantCompany | ConsultantCompany[];

  constructor(@Inject(MAT_DIALOG_DATA) public data:
                {selected: number | number[], transaction: boolean, accountId: number, exclude: number[],
                  consultantList: Observable<ConsultantCompany[]>}) {}

  ngAfterViewInit(): void {
    if (!this.consultantList) {
      return;
    }

    this.consultantList.selection.changed.subscribe(consultant => {
      this.selectedConsultant = !this.data.consultantList ? consultant.source.selected[0] : consultant.source.selected;
    });

    if (this.data.selected) {
      this.consultantList.initSelected(!this.data.consultantList ? [this.data.selected] : this.data.selected as any);
    }
  }

}
