import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { REPORTS } from '../../core/apiUrls';
import { ReportTypeFilter } from './models/report-type-filter';
import { AccountCheckReport, ConsultantCompanyCommissionReport, GlobalAccountBalanceReport, ReportFilter } from './models/report-type';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  generateReportByType(reportTypeFilter: ReportTypeFilter): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    // @ts-ignore
    return this.http.post(`${REPORTS}/byType`, reportTypeFilter,
      { observe: 'response' as 'body', responseType: 'blob'});
  }

  generateCommissionReport(reportFilter: ReportFilter): Observable<HttpResponse<any>> {
    // @ts-ignore
    return this.http.post(`${REPORTS}/commissionReports`, reportFilter,
      { observe: 'response' as 'body', responseType: 'blob'});
  }

  getCommissionPreview(filter: ReportFilter): Observable<ConsultantCompanyCommissionReport> {
    return this.http.post<ConsultantCompanyCommissionReport>(`${REPORTS}/commissionReports/preview`, filter);
  }

  generateAccountCheckReport(reportFilter: ReportFilter): Observable<HttpResponse<any>> {
    delete  reportFilter.reportType;
    // @ts-ignore
    return this.http.post(`${REPORTS}/accountCheckReport`, reportFilter,
      { observe: 'response' as 'body', responseType: 'blob'});
  }

  getAccountCheckPreview(filter: ReportFilter): Observable<AccountCheckReport> {
    return this.http.post<AccountCheckReport>(`${REPORTS}/accountCheckReport/preview`, filter);
  }

  generateGlobalAccountBalanceReport(reportFilter: ReportFilter): Observable<HttpResponse<any>> {
    delete  reportFilter.reportType;
    // @ts-ignore
    return this.http.post(`${REPORTS}/globalAccountBalanceReport`, reportFilter,
      { observe: 'response' as 'body', responseType: 'blob'});
  }

  getGlobalAccountBalancePreview(filter: ReportFilter): Observable<GlobalAccountBalanceReport> {
    return this.http.post<GlobalAccountBalanceReport>(`${REPORTS}/globalAccountBalanceReport/preview`, filter);
  }
}
